import { CORE, ENVIRONMENT } from 'core/datasets/action';
import {
    FetchEnvironmentSettingsAction,
    FetchEnvironmentSettingsRequestAction,
} from 'environment/types/redux/environmentActions';
import { DocumentNode } from 'graphql';

export const environmentActions = {
    fetchEnvironmentSettings(): FetchEnvironmentSettingsAction {
        return {
            type: ENVIRONMENT.FETCH_ENVIRONMENT_SETTINGS,
        };
    },
    fetchEnvironmentSettingsRequest(gql: DocumentNode, callback: string): FetchEnvironmentSettingsRequestAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                callbackType: callback,
            },
        };
    },
};
