import { useLazyQuery } from '@apollo/client';
import useQueryHelper from '../../../../core/hooks/useQueryHelper';
import useQueryErrorHandler from '../../../../core/hooks/useQueryErrorHandler';
import { QueryRentalAgreementsLazyReturn } from '../../../types/QueryRentalAgreementsLazyReturn';
import { queryRentalAgreementDocument } from './queryRentalAgreementDocument';

export const useQueryRentalAgreementsLazy = (): QueryRentalAgreementsLazyReturn => {
    const { ORDER_DIR, ORDER_BY } = useQueryHelper();
    const { errorHandler } = useQueryErrorHandler();

    const [getAgreements] = useLazyQuery(queryRentalAgreementDocument, {
        variables: {
            orderDir: ORDER_DIR.desc,
            orderBy: ORDER_BY.agreementDate,
        },
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            errorHandler(error);
        },
    });

    return {
        getAgreements,
    };
};
