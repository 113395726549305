import { gql } from 'graphql-tag';

export const queryMeterReadings = gql`
    query queryMeterReadings($rentalObjectId: Int!, $range: Period) {
        meterReadings(rentalObjectId: $rentalObjectId, range: $range) {
            id
            meter {
                id
                type
                unit
                identifier
            }
            date
            period
            amount
            rentalAgreement {
                id
            }
            createdAt
        }
    }
`;
