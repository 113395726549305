import { FILE, FILE_TEMP } from 'core/datasets/action';
import { GraphqlErrorResponseHandleAction } from 'core/types/redux/CoreStateActions';
import { filesActions } from 'file/redux/fileActions';
import { put } from 'redux-saga/effects';

export function* handleFilesErrorResponse({ payload }: GraphqlErrorResponseHandleAction): Generator {
    const { response, callbackType } = payload;

    // callback actions that failed to be triggered because of the error in the response
    const watchingCallbackActions = [FILE_TEMP.UPDATE_SELECTED_FILE_DATA, FILE.DOWNLOAD_FILE_ACTION];

    if (!watchingCallbackActions.find((type) => type === callbackType)) {
        return;
    }

    if (!response.data.data) {
        yield put(filesActions.markSelectedFileBroken());
    }
}
