import { ServiceRequestActions } from '../types/redux/serviceRequestActions';
import { SERVICE_REQUEST } from '../../core/datasets/action';
import { ServiceRequestState } from '../types/redux/serviceRequestReducer';

const initState: ServiceRequestState = {
    requestForm: {
        rentalObjectSelect: '',
        serviceType: '',
        requestTitle: '',
        requestDescription: '',
        files: [],
    },
};

export const serviceRequestReducer = (state = initState, action: ServiceRequestActions): ServiceRequestState => {
    switch (action.type) {
        case SERVICE_REQUEST.CHANGE_FIELD_VALUE:
            return {
                ...state,
                requestForm: {
                    ...state.requestForm,
                    [action.fieldName]: action.payload.value,
                },
            };
        case SERVICE_REQUEST.ADD_FILES:
            return {
                ...state,
                requestForm: {
                    ...state.requestForm,
                    files: [...state.requestForm.files, ...action.payload],
                },
            };
        case SERVICE_REQUEST.RESET_FORM:
            return {
                ...state,
                requestForm: initState.requestForm,
            };
        default:
            return state;
    }
};
