// Hooks
import useLoginFormData from '../../../../core/hooks/login/useLoginFormData';
import { useTranslation } from 'react-i18next';

import { Button, TextField } from '@material-ui/core';
import TermsAndConditionsCheck from '../../../../core/components/TermsAndConditionsCheck';
import PasswordField from '../../../../core/components/forms/PasswordField';
import { AlertNotice } from '../../../../core/components/modalsPortalsNotices/AlertNotice';
import LangSelect, { LANG_SELECT_VARIANT } from '../../../../core/components/LangSelect';
import SubmitButton from '../../../../core/components/buttons/SubmitButton';

import ResetPasswordModal from '../../../../core/components/modalsPortalsNotices/ResetPasswordModal';
import LoginHelpModal from '../../../../core/components/modalsPortalsNotices/LoginHelpModal';
import { KeyboardEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { LogoBig } from '../../../../core/components/Logo';
import { AppState } from 'core/redux/rootReducer';

import './LoginForm.css';

export const LoginForm = (): JSX.Element => {
    const { t } = useTranslation();

    const email = useSelector(({ tempAuth }: AppState) => tempAuth.email);
    const password = useSelector(({ tempAuth }: AppState) => tempAuth.password);

    const loading = useSelector(({ tempAuth }: AppState) => tempAuth.loading);
    const errorMessage = useSelector(({ tempAuth }: AppState) => tempAuth.errorMessage);

    const [restorePasswordModalClosed, setRestorePasswordModalClosed] = useState(true);

    const [supportInfoModalClosed, setSupportInfoModalClosed] = useState(true);

    const { handleChange, handleKeyEnterPressed, handleLogin, handleTerms, termsChecked } = useLoginFormData();

    const passwordRef = useRef<HTMLDivElement>(null);

    const handleChangeFocus = useCallback(({ nativeEvent }: KeyboardEvent<HTMLDivElement>) => {
        if (nativeEvent.key === 'Enter' && passwordRef && passwordRef.current?.firstChild) {
            (passwordRef.current.firstChild as HTMLElement).focus();
        }
    }, []);

    const primaryActionBtnArgs = useMemo(
        () => ({
            loading: loading,
            text: t('pages.login.loginBtnText'),
            actionHandler: handleLogin,
            disabled: email.invalid || password.invalid,
            loadingText: t('pages.login.loading'),
        }),
        [email.invalid, handleLogin, password.invalid, t],
    );

    const handleRestorePasswordModalClosed = () => {
        setRestorePasswordModalClosed((prev) => !prev);
    };

    const termsArgs = {
        checked: termsChecked,
        handleChecked: handleTerms,
    };

    const errorJSX = errorMessage && <AlertNotice open={true} level={'ERROR'} text={t(errorMessage)} />;

    return (
        <>
            <form className="login-form">
                <div className="login-form-container">
                    <div className="login-form__field-container login-form__field-container--lang">
                        <LogoBig />
                        <LangSelect variant={LANG_SELECT_VARIANT.ICON_STANDARD} />
                    </div>
                    <div className="login-form__field-container">
                        <TextField
                            autoComplete={'username'}
                            className="login-form__input login-form__input-email"
                            id="login-form__email"
                            name="email"
                            type="email"
                            label={t('pages.login.email')}
                            onKeyPress={handleChangeFocus}
                            variant="outlined"
                            required
                            onChange={handleChange}
                            autoFocus={true}
                            value={email.value}
                            error={email.changed ? email.invalid : false}
                            helperText={email.invalid && email.changed ? t('notices.invalidEmail') : ''}
                        />
                    </div>
                    <div className="login-form__field-container login-form__field-container--password">
                        <PasswordField
                            ref={passwordRef}
                            handleKeyEnterPressed={handleKeyEnterPressed}
                            handleChange={handleChange}
                            readyToSubmit={!(email.invalid || password.invalid)}
                        />
                    </div>
                    <div className="login-form__field-container login-form__field-container--checkbox">
                        <TermsAndConditionsCheck {...termsArgs} />
                    </div>
                    <div className="login-form__field-container login-form__action-btn-container ">
                        <SubmitButton {...primaryActionBtnArgs} />
                        <a href="#" onClick={handleRestorePasswordModalClosed} className="login-form__restore-password">
                            {t('pages.login.restorePassword')}
                        </a>
                    </div>
                    <div>{errorJSX}</div>
                </div>
                <Button
                    onClick={() => setSupportInfoModalClosed(false)}
                    className="login-form__help-button"
                    variant={'text'}
                >
                    {t('pages.login.helpModalOpen')}
                </Button>
            </form>
            <ResetPasswordModal
                handleModalClosed={handleRestorePasswordModalClosed}
                modalClosed={restorePasswordModalClosed}
            />
            <LoginHelpModal closed={supportInfoModalClosed} onClose={() => setSupportInfoModalClosed(true)} />
        </>
    );
};

export default LoginForm;
