import { LogoBaseProps } from '../../types/logo';
import { useResources } from '../../../resources/context';
import { useMemo } from 'react';
import Logo from './Logo';

export const LogoLogin = ({ classes = '', width, height }: LogoBaseProps): JSX.Element => {
    const { LogoLoginPage } = useResources();
    const logoClasses = useMemo(() => [classes, 'app-logo app-logo--mobile'].join(' '), [classes]);

    return (
        <Logo classes={logoClasses}>
            {typeof LogoLoginPage === 'string' ? (
                <img src={LogoLoginPage} width={width} height={height} />
            ) : (
                <LogoLoginPage width={width} height={height} />
            )}
        </Logo>
    );
};
