import { gql, useMutation } from '@apollo/client';
import useQueryErrorHandler from '../../../../core/hooks/useQueryErrorHandler';
import { ExtraServiceOrderFormData, OrderExtraServiceResponseType } from '../../../types/extraServiceOrder';
import { QueryStatusData } from 'core/hooks/useQueryHelper';

const OrderExtraServiceDocument = gql`
    mutation orderExtraService($extraServiceInput: ExtraServiceInput!) {
        orderExtraService(serviceData: $extraServiceInput) {
            isSuccessful
            message
        }
    }
`;

const useMutationOrderExtraService = (): MutationOrderExtraServiceReturnType => {
    const [makeExtraServiceOrder, { data, error, loading, called }] = useMutation(OrderExtraServiceDocument);
    const { errorHandler } = useQueryErrorHandler();

    const orderExtraService = (serviceData: ExtraServiceOrderFormData) => {
        makeExtraServiceOrder({
            variables: {
                extraServiceInput: {
                    ...serviceData,
                    fromDate: serviceData['fromDate']?.toSQLDate(),
                    untilDate: serviceData['untilDate'] ? serviceData['untilDate']?.toSQLDate() : null,
                },
            },
        }).catch(errorHandler);
    };
    return { data, error, loading, called, orderExtraService };
};

export default useMutationOrderExtraService;

type MutationOrderExtraServiceReturnType = QueryStatusData & {
    called: boolean;
    orderExtraService(serviceData: ExtraServiceOrderFormData): void;
    data: OrderExtraServiceResponseType;
};
