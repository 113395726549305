import Agreement from 'agreement/classes/Agreement';
import AgreementListViewItem from 'agreement/classes/AgreementListViewItem';
import HandleReceivedAgreementsAction from 'agreement/types/HandleReceivedAgreementsAction';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { agreementActions } from 'agreement/redux/agreementActions';
import IAgreementPropsData from 'agreement/types/IAgreementPropsData';
import { IAgreementListViewData } from 'agreement/types/IAgreementListViewData';
import { put } from 'redux-saga/effects';

type AgreementMapRecord = { [id: string]: IAgreementInstanceData };

export function* handleReceivedAgreements({ type, payload }: HandleReceivedAgreementsAction): Generator {
    const agreements: IAgreementPropsData[] = payload.data?.rentalAgreements;

    const list: IAgreementListViewData[] = [];
    const map: AgreementMapRecord = {};

    for (const data of agreements) {
        try {
            const agreement = new Agreement(data);
            list.push(new AgreementListViewItem(data).listViewData);
            map[`${agreement.id}`] = agreement.all;
        } catch (error) {
            console.error('Error parsing agreement data: ', data.id, { error });
        }
    }

    //@ts-ignore
    yield put(agreementActions.updateAgreementsData({ list, map, type }));
}
