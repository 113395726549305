import AgreementObject from 'agreementObject/classes/AgreementObject';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import { AppState } from 'core/redux/rootReducer';
import { apply, call, fork, join, put, select } from 'redux-saga/effects';
import LarvaWsClientWrapper from 'smartHome/larva/classes/LarvaWsClientWrapper';
import LarvaDeviceController from 'smartHome/larva/classes/LarvaDeviceController';
import LarvaUnit, { LarvaUnits } from 'smartHome/larva/classes/LarvaUnit';
import { LarvaUnitObjectsMap } from 'smartHome/larva/types/LarvaUnitObject';
import handleComponentBroadcast from 'smartHome/larva/utils/handleComponentBroadcast';
import { larvaId } from 'smartHome/larva/utils/makeId';
import { Task } from 'redux-saga';
import { CORE, LARVA, LARVA_TEMP, LARVA_WS } from 'core/datasets/action';
import LarvaUnitDevicesMap from 'smartHome/larva/types/LarvaUnitDevicesMap';
import { fetchAllLarvaData } from './fetchAllLarvaData';
import { makeUnitDeviceMapFromApiData } from 'smartHome/larva/utils/fetchUnitDevicesMap';
import { LarvaApiUnits } from 'smartHome/larva/types/larvaData';
import { AnyAction } from 'redux';
import createDeviceConnection from 'smartHome/larva/utils/createDeviceConnection';
import { store } from '../../../../core/redux/store';
import { UINodesLocal } from '../../types/UINodeLocal';

type NameMatcher = (name: string) => boolean;

function commercialMatcher(selectedObject: IAgreementObjectInstanceData): NameMatcher {
    const commercialRoomOrApartmentnumber = AgreementObject.isCommercial(selectedObject)
        ? selectedObject.roomNumber
        : selectedObject.apartmentNumber;

    return function (unitName: string) {
        return unitName.trim() === commercialRoomOrApartmentnumber?.trim();
    };
}

function getDashboardUnit(units: LarvaUnits, nameMatches: NameMatcher): LarvaUnit {
    return units.filter((unit) => nameMatches(unit.name))[0] || units[0];
}

function makeObjectsMap(units: LarvaUnits): LarvaUnitObjectsMap {
    return units.reduce((unitObjectsMap: LarvaUnitObjectsMap, unit: LarvaUnit) => {
        return { ...unitObjectsMap, [unit.id]: unit.toObject() };
    }, {});
}

export function* initUnitsUiNodesFetching(): Generator {
    const apiUnits = (yield select((state: AppState) => state.larva.apiUnits)) as LarvaApiUnits;

    if (!apiUnits || !apiUnits.length) {
        yield call(fetchAllLarvaData);
    } else {
        const deviceMap = makeUnitDeviceMapFromApiData(apiUnits as LarvaApiUnits);
        const unitsArray: LarvaUnit[] = Object.values(deviceMap as LarvaUnitDevicesMap) || [];

        if (!unitsArray.length) {
            console.error('NO LARVA UNITS IN DEVICE MAP FOUND');
            return;
        }

        const selectedObject = (yield select(
            ({ agreementObjects }: AppState) => agreementObjects?.selectedObject,
        )) as IAgreementObjectInstanceData;

        const objectIsCommercialNameMatcher = commercialMatcher(selectedObject);

        const dashboardUnit = getDashboardUnit(unitsArray, objectIsCommercialNameMatcher);

        // NB!!! All nodes management should start AFTER THIS COMMAND!
        yield runConnectTasksForUnits(unitsArray);

        const uiNodes: UINodesLocal = unitsArray
            ?.flatMap(({ uiNodes }) => uiNodes)
            ?.filter(({ ui }) => ui.favorite)
            ?.sort((nodeA, nodeB) => (parseInt(nodeA.ui.rating) > parseInt(nodeB.ui.rating) ? -1 : 1));

        yield put({
            type: LARVA_TEMP.DASHBOARD.LOADING_STARTED,
        });

        yield put({
            type: LARVA.UNIT_DEVICES_OBJECTS_MAP_INIT_ASYNC,
            payload: makeObjectsMap(unitsArray),
        });

        yield put({
            type: LARVA.DASHBOARD_UI_NODES_ADDED,
            payload: uiNodes,
        });

        yield put({
            type: LARVA.PUT_DASHBOARD_UNIT_OBJECT,
            payload: dashboardUnit.toObject(),
        });

        yield put({
            type: LARVA_TEMP.DASHBOARD.LOADING_COMPLETED,
        });
    }
}

export function* initOnlyWebsocketFetching(action: AnyAction): Generator {
    if (process.env.NODE_ENV !== 'production') console.log('WEBSOCKET ONLY FETCHING CALLED ON ACTION', { action });

    const apiUnits = (yield select((state: AppState) => state.larva.apiUnits)) as LarvaApiUnits;
    const deviceMap = makeUnitDeviceMapFromApiData(apiUnits as LarvaApiUnits);
    const unitsArray: LarvaUnit[] = Object.values(deviceMap as LarvaUnitDevicesMap) || [];

    if (unitsArray) {
        yield runConnectTasksForUnits(unitsArray);
    } else {
        yield call(fetchAllLarvaData);
    }
}

function* runConnectTasksForUnits(units: LarvaUnits): Generator {
    // Collection of tasks to connect to websockets
    const connectTasks: Task[] = [];

    // Here all units are prepared to initiate websocket connections
    for (const unit of units) {
        const task = (yield fork(getUnitUiNodes, unit)) as Task;
        connectTasks.push(task);
    }

    // Run all tasks for connections and wait them to finish
    yield join(connectTasks);
}

function* getUnitUiNodes(unit: LarvaUnit, shouldBeClosed?: boolean) {
    if (unit.hasDevices) {
        for (const device of unit.devices) {
            try {
                const connection = (yield call(createDeviceConnection, {
                    deviceId: device.id,
                    unitId: unit.id,
                    errorListener: (error) => {
                        console.log('Larva websocket connection error', { error });
                        store.dispatch({
                            type: LARVA_WS.WS_CONNECTION_ERROR,
                            payload: error,
                        });
                    },
                })) as LarvaWsClientWrapper;

                yield call(initDeviceConnection, connection, device, shouldBeClosed);
            } catch (error) {
                console.error('getUnitUiNodes', error);
                return;
            }
        }
    }
}

function* initDeviceConnection(
    websocketClient: LarvaWsClientWrapper,
    device: LarvaDeviceController,
    shouldBeClosed?: boolean,
) {
    const fullId = larvaId(device.unitId, device.id);

    try {
        yield apply(device, device.connect, [
            websocketClient,
            {
                broadCastCallback: handleComponentBroadcast,
            },
            shouldBeClosed,
        ]);
        yield put({
            type: CORE.DEVICE_REMOVE_ERROR,
            payload: { fullId },
        });
        yield put({
            type: LARVA_WS.SAVE_WS_CONNECTION,
            payload: {
                device,
                client: websocketClient,
            },
        });
    } catch (error) {
        const { message } = error as Error;

        yield put({
            type: CORE.DEVICE_GLOBAL_ERROR,
            payload: {
                id: fullId,
                showNotification: false,
                errorMessage: `${message}`,
            },
        });
    }
}
