import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { IAgreementListViewData } from 'agreement/types/IAgreementListViewData';

export const selectFirstAgreementFromListOrMap = (
    agreementId: number | string | null,
    list: IAgreementListViewData[],
    map: { [id: number]: IAgreementInstanceData },
): IAgreementInstanceData | null => {
    let _agreementId = agreementId;

    if (!_agreementId) {
        const firstAgreementViewItem =
            list.find((agreementViewData) => {
                const id = agreementViewData.meta?.id;

                return !!id && +id in map;
            }) || null;

        // prefer to select item from list to keep the initial order of insertion in the view; in map it is sorted by keys
        if (firstAgreementViewItem && firstAgreementViewItem.meta) {
            _agreementId = +firstAgreementViewItem.meta.id;
        }
    }

    if (_agreementId && _agreementId in map) {
        return Object.values(map).find((agreement) => parseInt(`${_agreementId}`) === agreement.id) || null;
    }

    return null;
};
