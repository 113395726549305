import { LARVA_DOOR_ACCESS, LARVA_DOOR_ACCESS_TEMP } from 'core/datasets/action';
import IListViewData from 'core/types/IListViewData';
import { NavigateFunction } from 'react-router-dom';
import DoorAccess from 'smartHome/larva/classes/DoorAccess';
import DoorCardRawData from 'smartHome/larva/classes/DoorCardRawData';
import DoorCodeRawData from 'smartHome/larva/classes/DoorCodeRawData';
import ILarvaDoorAccess from 'smartHome/larva/types/doorAccess/ILarvaDoorAccess';
import ILarvaSelectedDoorAccess from 'smartHome/larva/types/doorAccess/ILarvaSelectedDoorAccess';
import {
    StoreDoorAccessListViewDataAction,
    StoreDoorAccessMapAction,
    StoreDoorCardsAction,
    StoreDoorCodesAction,
    DoorAccessFormCreateAction,
    DoorAccessManageRightChangeAction,
} from 'smartHome/larva/types/redux/larvaDoorAccessActions';
import {
    DoorAccessListViewItemSelectAction,
    DoorAccessSelectAction,
    DoorAccessSelectByIdAction,
    SelectFirstAvailableDoorAccessSignal,
    ShowInChildFirstAvailableDoorAccess,
} from 'smartHome/larva/types/redux/TempLarvaStateActions';

export const larvaDoorAccessActions = {
    storeDoorCards(cards: { [cardId: string]: DoorCardRawData }): StoreDoorCardsAction {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_CARDS_RECEIVED,
            payload: cards,
        };
    },
    updateAccess(data: { [field: string]: string }, access: ILarvaDoorAccess) {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_UPDATE_USER_DATA,
            payload: {
                data,
                access,
            },
        };
    },
    changeRightToManageAccesses(value: boolean): DoorAccessManageRightChangeAction {
        return {
            type: LARVA_DOOR_ACCESS.CHANGED_RIGHT_TO_MANAGE_ACCESSES,
            payload: value,
        };
    },
    storeDoorCodes(codes: { [codeId: string]: DoorCodeRawData }): StoreDoorCodesAction {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_CODES_RECEIVED,
            payload: codes,
        };
    },
    storeDoorAccessListViewData(list: IListViewData[]): StoreDoorAccessListViewDataAction {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_LIST_VIEW_DATA_RECEIVED,
            payload: list,
        };
    },
    storeDoorAccessMapData(map: { [accessId: string]: ILarvaDoorAccess }): StoreDoorAccessMapAction {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_MAP_RECEIVED,
            payload: map,
        };
    },
    selectDoorAccessListViewItem(id: string): DoorAccessListViewItemSelectAction {
        return {
            type: LARVA_DOOR_ACCESS_TEMP.DOOR_ACCESS_LIST_VIEW_DATA_ITEM_SELECTED,
            payload: id,
        };
    },
    selectDoorAccess(access: ILarvaDoorAccess): DoorAccessSelectAction {
        return {
            type: LARVA_DOOR_ACCESS_TEMP.DOOR_ACCESS_SELECTED,
            payload: access,
        };
    },
    selectDoorAccessById(id: string): DoorAccessSelectByIdAction {
        return {
            type: LARVA_DOOR_ACCESS_TEMP.DOOR_ACCESS_SELECTED_BY_ID,
            payload: id,
        };
    },
    createDoorAccess(access: DoorAccess, navigate: NavigateFunction): DoorAccessFormCreateAction {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_FORM_COMMANDS.CREATE_DOOR_ACCESS,
            payload: {
                access,
                navigate,
            },
        };
    },
    updatePin(access: ILarvaSelectedDoorAccess) {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_UPDATE_PIN_ASYNC,
            payload: access,
        };
    },
    sendEmail(access: ILarvaSelectedDoorAccess) {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_SEND_EMAIL_ASYNC,
            payload: access,
        };
    },
    sendSms(access: ILarvaSelectedDoorAccess) {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_SEND_SMS_ASYNC,
            payload: access,
        };
    },
    unsetPin(access: ILarvaSelectedDoorAccess) {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_UNSET_PIN_ASYNC,
            payload: access,
        };
    },
    deleteAccess(access: ILarvaSelectedDoorAccess, navigate: NavigateFunction) {
        return {
            type: LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_DELETE_ASYNC,
            payload: {
                access,
                navigate,
            },
        };
    },
    showInChildFirstAvailableDoorAccess(navigate: NavigateFunction): ShowInChildFirstAvailableDoorAccess {
        return {
            type: LARVA_DOOR_ACCESS_TEMP.SHOW_FIRST_AVAILABLE_DOOR_ACCESS,
            payload: navigate,
        };
    },
    signal: {
        pinCodeSentByEmail() {
            return {
                type: LARVA_DOOR_ACCESS.DOOR_ACCESS_ACTION_STATUS.DOOR_ACCESS_EMAIL_SENT,
            };
        },
        pinCodeSentBySMS() {
            return {
                type: LARVA_DOOR_ACCESS.DOOR_ACCESS_ACTION_STATUS.DOOR_ACCESS_SMS_SENT,
            };
        },
        callRefreshDoorAccess() {
            return {
                type: LARVA_DOOR_ACCESS.SIGNALS.REFRESH_DOOR_ACCESS,
            };
        },
        newDoorAccessCreated() {
            return {
                type: LARVA_DOOR_ACCESS.SIGNALS.NEW_DOOR_ACCESS_CREATED,
            };
        },
        doorAccessDeleted() {
            return {
                type: LARVA_DOOR_ACCESS.DOOR_ACCESS_ACTION_STATUS.DOOR_ACCESS_DELETED,
            };
        },
        selectFirstDoorAccess(): SelectFirstAvailableDoorAccessSignal {
            return {
                type: LARVA_DOOR_ACCESS_TEMP.SIGNAL_SELECT_FIRST_AVAILABLE_DOOR_ACCESS,
            };
        },
        doorAccessPinUpdated() {
            return {
                type: LARVA_DOOR_ACCESS.DOOR_ACCESS_ACTION_STATUS.DOOR_ACCESS_PIN_UPDATED,
            };
        },
        doorAccessPinUnset() {
            return {
                type: LARVA_DOOR_ACCESS.DOOR_ACCESS_ACTION_STATUS.DOOR_ACCESS_PIN_UNSET,
            };
        },
    },
};
