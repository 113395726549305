import { agreementActions } from 'agreement/redux/agreementActions';
import { tempAgreementSelector } from 'agreement/redux/tempAgreementSelector';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import {
    AgreementExtensionProcessCallAction,
    AgreementTerminationProcessCallAction,
} from 'agreement/types/redux/TempAgreementStateActions';
import { AGREEMENT_TEMP } from 'core/datasets/action';
import { DateTime } from 'luxon';
import { put, select } from 'redux-saga/effects';

export function* handleAgreementChangeCalls({
    type,
    payload: objectAgreementUuid,
}: AgreementTerminationProcessCallAction | AgreementExtensionProcessCallAction): Generator {
    yield put(agreementActions.openChangeRequestModal());
    const agreement = yield select(tempAgreementSelector.selected);

    if (!agreement) return;

    if (AGREEMENT_TEMP.TERMINATION_PROCESS_CALLED === type) {
        yield initTerminationProcess(objectAgreementUuid);
    } else {
        yield initExtensionProcess(agreement as IAgreementInstanceData, objectAgreementUuid);
    }
}

function* initTerminationProcess(objectAgreementUuid: string | null): Generator {
    yield put(
        agreementActions.initTerminationProcess({
            objectAgreementUuid: objectAgreementUuid || null,
            date: DateTime.now(),
        }),
    );
}

function* initExtensionProcess(agreement: IAgreementInstanceData, objectAgreementUuid: string | null): Generator {
    let agreementEnd = agreement.agreementEnd;

    if (objectAgreementUuid) {
        const objectAgreement = agreement.objects.filter(
            (objectAgreement) => objectAgreement.agreement?.uuid === objectAgreementUuid,
        );
        agreementEnd = objectAgreement[0]?.agreement?.agreementEnd || agreementEnd;
    }

    yield put(
        agreementActions.initExtensionProcess({
            date: agreementEnd === null ? null : DateTime.fromSQL(agreementEnd).plus({ days: 1 }),
            objectAgreementUuid: objectAgreementUuid,
        }),
    );
}
