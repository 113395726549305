import { MENU } from 'core/datasets/action';
import {
    MenuItemPropertyValueChangeAction,
    MenuItemPropertyValueChangeActionProps,
} from 'core/types/redux/MenuActions';

export const menuActions = {
    changeMenuItemPropertyValue({
        itemId,
        property,
        value,
    }: MenuItemPropertyValueChangeActionProps): MenuItemPropertyValueChangeAction {
        return {
            type: MENU.MENU_ITEM_PROPERTY_CHANGED,
            payload: {
                itemId,
                property,
                value,
            },
        };
    },
};
