import { isFutureDate, formatDate } from 'core/utils/datesHelper';
import RentalAgreementType from '../types/RentalAgreementType';

export const getAgreementStatusForObjectID = (
    objectID: string,
    rentalAgreements: RentalAgreementType[],
): getAgreementStatusForObjectIDReturn => {
    if (!rentalAgreements) return null;

    const agreements = filterRentalAgreementsByObjectID(objectID, rentalAgreements);
    if (!agreements.length) return null;

    const agreement = filterLatestAgreements(agreements)[0];
    const { agreementDate: agreementStart, agreementEnd, id: contractId, uuid } = agreement;

    if (!agreementStart) return null;

    const isEndlessContract = !agreementEnd;

    const isNotExpiredContract = isEndlessContract ? true : isFutureDate(agreementEnd);
    const periodEndText = isEndlessContract ? '...' : formatDate(agreementEnd);
    return {
        contractId,
        periodStart: formatDate(agreementStart),
        periodEnd: periodEndText,
        isNotExpiredContract,
        isEndlessContract,
        uuid,
    };
};

export const filterRentalAgreementsByObjectID = (
    objectID: string,
    rentalAgreements: RentalAgreementType[],
): RentalAgreementType[] => {
    const agreementWithObjectID = ({ rentalObject }: RentalAgreementType) => rentalObject?.id === +objectID;
    const result = rentalAgreements.filter(agreementWithObjectID);
    return result && result.length ? result : [];
};

export const filterLatestAgreements = (agreements: RentalAgreementType[]): RentalAgreementType[] => {
    if (!!agreements && typeof agreements === 'object' && agreements.length) {
        const theLatestAgreements = (prev: RentalAgreementType, current: RentalAgreementType) => {
            const { id: currentAgreementId } = current;
            const { id: prevAgreementId } = prev;
            return currentAgreementId > prevAgreementId ? current : prev;
        };
        return [agreements.reduce(theLatestAgreements)];
    }
    return [];
};

interface getAgreementStatusArgs {
    objectID: string;
    rentalAgreements: RentalAgreementType[];
}

/**
 * Get contract (rental agreement) status text with validity dates, active status flag and contract id
 * @param objectID
 * @param rentalAgreements
 */
export const getAgreementStatus = ({ objectID, rentalAgreements }: getAgreementStatusArgs) => {
    if (!rentalAgreements || '0' === objectID) {
        return null;
    }

    const contractStatus = getAgreementStatusForObjectID(objectID, rentalAgreements);

    if (!contractStatus) return null;
    const { contractId, isNotExpiredContract, periodStart, periodEnd, uuid } = contractStatus;

    const statusText = isNotExpiredContract ? 'agreementPeriod' : 'inactiveContract';

    return {
        contractId,
        isNotExpiredContract,
        statusText,
        uuid,
        periodStart,
        periodEnd,
    };
};

type getAgreementStatusForObjectIDReturn = null | {
    contractId: number;
    periodStart: string;
    periodEnd: string;
    isNotExpiredContract: boolean;
    isEndlessContract: boolean;
    uuid: string;
};
