import { call, put, select } from 'redux-saga/effects';
import { signInWithCustomToken } from 'firebase/auth';
import { AppState } from 'core/redux/rootReducer';
import { getFirebaseAuth } from '../../utils/getFirebaseAuth';
import { firebaseActions } from '../../redux/firebaseActions';

export function* getNewFirebaseUser(): Generator {
    const firebaseAuth = getFirebaseAuth();
    const token = (yield select(({ firebase }: AppState) => firebase.token)) as string;

    const userCredential = yield call(() =>
        signInWithCustomToken(firebaseAuth, token).catch((error) => {
            console.log('Message Center, auth error: ', error);
        }),
    );

    if (userCredential) {
        yield put(firebaseActions.setUser((userCredential as any).user));
    }
}
