import { AgreementChangeRequestType } from 'agreement/classes/AgreementChangeRequestType';
import { AgreementChangeRequestGraphQLVariables } from 'agreement/types/AgreementChangeRequestGraphQLVariables';
import AgreementLicensePlatesVariables from 'agreement/types/AgreementLicensePlatesVariables';
import AgreementsGraphQLRequestVariables from 'agreement/types/AgreementsGraphQLRequestVariables';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { IAgreementListViewData } from 'agreement/types/IAgreementListViewData';
import {
    AgreementSelectedAction,
    AgreementsFetchedAction,
    CallInitAgreementsFetching,
    CallUpdateForAllAgreementsData,
    ChangeLicencePlatesAction,
    FetchAgreementsActionAsync,
    UpdateAgreementsDataAction,
} from 'agreement/types/redux/AgreementActions';
import {
    AgreementChangeInitAction,
    AgreementChangeRequestModalStateAction,
    AgreementChangeResetCall,
    AgreementChangeSubmitCall,
    AgreementExtensionProcessCallAction,
    AgreementsLoadingStateChangeAction,
    AgreementTerminationProcessCallAction,
    ChangesRequestDateChangeAction,
    FetchAllAgreementsAction,
    RequestAgreementChangeAction,
    RequestLicencePlatesChangeAction,
    SelectAgreementByIdAction,
    SelectAgreementListItemAction,
} from 'agreement/types/redux/TempAgreementStateActions';
import { AGREEMENT, AGREEMENT_TEMP, CORE } from 'core/datasets/action';
import { DocumentNode } from 'graphql';
import { DateTime } from 'luxon';

export const agreementActions = {
    fetchAgreementsAsync(token: string): FetchAgreementsActionAsync {
        return {
            type: AGREEMENT.FETCH_AGREEMENTS_ASYNC,
            payload: { token },
        };
    },
    agreementsFetched(agreements: { [id: number]: IAgreementInstanceData }): AgreementsFetchedAction {
        return {
            type: AGREEMENT.AGREEMENTS_FETCHED,
            payload: agreements,
        };
    },
    selectAgreement(agreement: IAgreementInstanceData): SelectAgreementListItemAction {
        return {
            type: AGREEMENT_TEMP.LIST_ITEM_SELECTED,
            payload: agreement,
        };
    },
    selectAgreementById(id: string): SelectAgreementByIdAction {
        return {
            type: AGREEMENT_TEMP.LIST_ITEM_SELECTED_BY_ID,
            payload: id,
        };
    },
    fetchAllAgreements(
        gql: DocumentNode,
        variables: AgreementsGraphQLRequestVariables,
        callback?: string,
    ): FetchAllAgreementsAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: variables,
                callbackType: callback || AGREEMENT.ALL_AGREEMENTS_RECEIVED,
            },
        };
    },
    updateAgreementsData(payload: {
        list: IAgreementListViewData[];
        map: { [id: number]: IAgreementInstanceData };
        type?: typeof AGREEMENT.ALL_AGREEMENTS_RECEIVED | typeof AGREEMENT.INIT_AGREEMENTS_RECEIVED;
    }): UpdateAgreementsDataAction {
        return {
            type: AGREEMENT.DATA_UPDATED,
            payload,
        };
    },
    callFetchAllAgreementsDataUpdate(): CallUpdateForAllAgreementsData {
        return {
            type: AGREEMENT.FETCH_ALL_AGREEMENTS,
        };
    },

    callInitAgreementsFetching(): CallInitAgreementsFetching {
        return {
            type: AGREEMENT.INIT_AGREEMENTS_FETCHING,
        };
    },
    changeLicencePlates(data: AgreementLicensePlatesVariables): ChangeLicencePlatesAction {
        return {
            type: AGREEMENT.CHANGE_LICENSE_PLATES,
            payload: data,
        };
    },
    setSelectedObjectAgreement(agreement: IAgreementInstanceData | null): AgreementSelectedAction {
        return {
            type: AGREEMENT.AGREEMENT_SELECTED,
            payload: agreement,
        };
    },
    requestLicencePlatesChange(
        gql: DocumentNode,
        variables: AgreementLicensePlatesVariables,
    ): RequestLicencePlatesChangeAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: { data: { ...variables } },
                callbackType: AGREEMENT.LICENSE_PLATES_CHANGED,
            },
        };
    },
    changeLoadingState(status: boolean): AgreementsLoadingStateChangeAction {
        return {
            type: AGREEMENT_TEMP.LOADING_STATE_CHANGED,
            payload: status,
        };
    },
    callExtensionProcess(objectAgreementUuid?: string): AgreementExtensionProcessCallAction {
        return {
            type: AGREEMENT_TEMP.EXTENSION_PROCESS_CALLED,
            payload: objectAgreementUuid || null,
        };
    },
    callTerminationProcess(objectAgreementUuid?: string): AgreementTerminationProcessCallAction {
        return {
            type: AGREEMENT_TEMP.TERMINATION_PROCESS_CALLED,
            payload: objectAgreementUuid || null,
        };
    },
    openChangeRequestModal(): AgreementChangeRequestModalStateAction {
        return {
            type: AGREEMENT_TEMP.REQUEST_MODAL_STATE_CHANGED,
            payload: true,
        };
    },
    closeChangeRequestModal(): AgreementChangeRequestModalStateAction {
        return {
            type: AGREEMENT_TEMP.REQUEST_MODAL_STATE_CHANGED,
            payload: false,
        };
    },
    initTerminationProcess({
        date,
        objectAgreementUuid,
    }: {
        date: DateTime | null;
        objectAgreementUuid: string | null;
    }): AgreementChangeInitAction {
        return {
            type: AGREEMENT_TEMP.TERMINATION_PROCESS_INIT,
            payload: {
                rentalAgreementObjectUuid: objectAgreementUuid,
                date: date,
                requestType: AgreementChangeRequestType.TERMINATION,
            },
        };
    },
    initExtensionProcess({
        date,
        objectAgreementUuid,
    }: {
        date: DateTime | null;
        objectAgreementUuid: string | null;
    }): AgreementChangeInitAction {
        return {
            type: AGREEMENT_TEMP.EXTENSION_PROCESS_INIT,
            payload: {
                rentalAgreementObjectUuid: objectAgreementUuid,
                date: date,
                requestType: AgreementChangeRequestType.EXTENSION,
            },
        };
    },
    clearChangeRequestCall(): AgreementChangeResetCall {
        return {
            type: AGREEMENT_TEMP.CHANGE_REQUEST_RESET,
        };
    },
    callSubmitChangeRequest(): AgreementChangeSubmitCall {
        return {
            type: AGREEMENT_TEMP.CHANGE_REQUEST_SUBMIT_CALLED,
        };
    },
    changeChangesRequestDate(date: DateTime | null): ChangesRequestDateChangeAction {
        return {
            type: AGREEMENT_TEMP.CHANGE_REQUEST_DATE_CHANGED,
            payload: date,
        };
    },
    requestAgreementChange(
        gql: DocumentNode,
        variables: AgreementChangeRequestGraphQLVariables,
    ): RequestAgreementChangeAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: { data: { ...variables } },
                callbackType: AGREEMENT_TEMP.REQUESTED_CHANGES_RESPONSE_RECEIVED,
            },
        };
    },
};
