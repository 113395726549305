import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { ControlledInputFieldProps } from 'core/types/ControlledInputFieldProps';

const ControlledInputField = ({
    name,
    label,
    type,
    control,
    defaultValue,
    isRequired,
    baseClassName,
    classes,
    disabled,
    helperText,
    variant,
    handleChange,
    ...otherProps
}: ControlledInputFieldProps): JSX.Element => {
    const DEFAULT_TYPE = 'text';

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                return (
                    <TextField
                        {...field}
                        required={isRequired}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message || (helperText as string) || ' '}
                        className={`${baseClassName} ${classes && classes}`}
                        label={label}
                        fullWidth={true}
                        // @ts-ignore
                        {...(handleChange && { onChange: (e) => field.onChange(handleChange(e)) })}
                        autoComplete={'off'}
                        variant={variant || 'outlined'}
                        type={type || DEFAULT_TYPE}
                        disabled={disabled}
                        {...otherProps}
                    />
                );
            }}
        />
    );
};
export default ControlledInputField;
