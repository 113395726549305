import Agreement from 'agreement/classes/Agreement';
import { AgreementType } from 'agreement/classes/AgreementType';
import { IListViewItem } from 'core/types/IListViewItem';
import { IAgreementListViewData } from 'agreement/types/IAgreementListViewData';
import { ViewVariantType } from 'core/classes/ViewVariantType';
import { PAGE_URL } from 'core/datasets/page_url';
import IAgreementPropsData from 'agreement/types/IAgreementPropsData';

export default class AgreementListViewItem implements IListViewItem {
    static PATH = `/${PAGE_URL.RENTAL_AGREEMENT}`;
    private readonly agreement: Agreement;

    constructor(rawData: IAgreementPropsData, parentAgreementId?: number) {
        this.agreement = new Agreement(rawData);
        if (parentAgreementId) {
            this.agreement.parentAgreementId = parentAgreementId;
        }
    }

    get listViewData(): IAgreementListViewData {
        const key = 'agreement.listItem';

        const isActive = Agreement.isActive(this.agreement.all);
        const isFuture = Agreement.isFuture(this.agreement.all);

        const path = this.agreement.id;

        return {
            path: `${AgreementListViewItem.PATH}/${path}`,
            labelText:
                AgreementType.isMultiObject(this.agreement.type) && this.agreement.objects.length > 1
                    ? `${key}.multiObjectAgreement`
                    : `${key}.agreement`,
            primaryText: isActive ? `${key}.agreementPeriod` : isFuture ? `${key}.activeInFuture` : `${key}.inactive`,
            leftBlockVariant: isActive ? ViewVariantType.SUCCESS : ViewVariantType.DISABLED,
            meta: {
                //@ts-ignore
                id: path,
                startDate: this.agreement.agreementDateFormatted,
                endDate: this.agreement.agreementEndFormatted,
                isActive,
            },
        };
    }

    toJSON(): IAgreementListViewData {
        return this.listViewData;
    }
}
