const isLocalhost = Boolean(
    window?.location?.hostname === 'localhost' ||
        window?.location?.hostname === '[::1]' ||
        window?.location?.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config): void {
    const locationOrigin = window?.location?.origin;

    if ('serviceWorker' in navigator) {
        const publicURL = new URL(process.env.PUBLIC_URL || '', window.location.href);

        if (publicURL.origin !== locationOrigin) {
            return;
        }

        try {
            window?.addEventListener('load', () => {
                const swUrl = `/service-worker.js`;

                if (isLocalhost) {
                    checkValidServiceWorker(swUrl, config);
                    navigator.serviceWorker.ready.then(() => {
                        console.log(
                            'SERVICE WORKER:',
                            'This web app is being served cache-first by a service ' +
                                'worker. To learn more, visit https://cra.link/PWA',
                        );
                    });
                } else {
                    registerValidSW(swUrl, config);
                }
            });
        } catch (error) {
            console.error('Service worker registration error', { error });
        }
    }
}

function registerValidSW(swUrl: string, config?: Config) {
    // eslint-disable-next-line compat/compat
    navigator.serviceWorker
        .register(swUrl, { scope: './' })
        .then((registration) => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            console.log(
                                'SERVICE WORKER:',
                                'New content is available and will be used when all ' +
                                    'tabs for this page are closed. See https://cra.link/PWA.',
                            );

                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            console.log('SERVICE WORKER:', 'Content is cached for offline use.');

                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch((error) => {
            console.error('Error during service worker registration:', error);
        });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window?.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log('SERVICE WORKER:', 'No internet connection found. App is running in offline mode.');
        });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister().then(() => {
                    localStorage?.clear();
                    window?.location?.reload();
                });
            })
            .catch((error) => {
                console.error('SERVICE WORKER:', error.message);
            });
    }
}
