export default class FileMimeType {
    public static readonly IMAGE_TYPE_REGEX = /^image\//i;
    public static readonly PDF_TYPE_REGEX = /^application\/pdf$/i;
    public static readonly APP_TYPE_REGEX = /^application\//i;
    public static readonly PLAIN_TEXT_TYPE_REGEX = /^text\/plain$/i;
    public static readonly ZIP_TYPE_REGEX = /^application\/zip$/i;

    static isValidType(value: unknown): boolean {
        return typeof value === 'string' && !!value.trim();
    }

    static isImage(type: string): boolean {
        return FileMimeType.isValidType(type) && FileMimeType.IMAGE_TYPE_REGEX.test(type.trim());
    }

    static isPdf(type: string): boolean {
        return FileMimeType.isValidType(type) && FileMimeType.PDF_TYPE_REGEX.test(type.trim());
    }

    static isApplication(type: string): boolean {
        return FileMimeType.isValidType(type) && FileMimeType.APP_TYPE_REGEX.test(type.trim());
    }

    static isPlainText(type: string): boolean {
        return FileMimeType.isValidType(type) && FileMimeType.PLAIN_TEXT_TYPE_REGEX.test(type.trim());
    }

    static isZip(type: string): boolean {
        return FileMimeType.isValidType(type) && FileMimeType.ZIP_TYPE_REGEX.test(type.trim());
    }
}
