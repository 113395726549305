import { AUTH, LARVA } from 'core/datasets/action';
import { Middleware } from 'redux';
import { larvaActions } from 'smartHome/larva/redux/actions/larvaActions';

export const larvaActionsQueueMiddleware: Middleware = ({ dispatch }) => {
    const queue = new Set<{ type: string; payload: unknown }>();

    return (next) => (action) => {
        switch (action.type) {
            case AUTH.LOGOUT: {
                queue.clear();
                break;
            }
            case LARVA.QUEUE_ACTION: {
                queue.add(action.payload);
                break;
            }
            case AUTH.EXPIRED_TOKEN_REFRESHED: {
                dispatch(larvaActions.handleQueuedActions([...queue.values()]));
                queue.clear();
                break;
            }
        }
        next(action);
    };
};
