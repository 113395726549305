import React, { LazyExoticComponent, useCallback, useRef } from 'react';
import { lazyLoad } from 'core/utils/componentUtils';
import { LAZY_COMPONENTS_MAP_CONFIG } from 'core/config/lazy_components_config';

const useLazyLoad = (): UseLazyLoadReturn => {
    const componentsMap = useRef<LazyLoadComponents>({});

    const importComponent = useCallback(
        (componentName: string, parentFolder: string, fallBackComponentName: string) => {
            if (!componentsMap.current[componentName]) {
                const args = {
                    componentName,
                    fallBackComponentName,
                    parentFolder,
                };

                try {
                    return lazyLoad(args);
                } catch (e: unknown) {
                    console.error({ e });
                    return;
                }
            }
        },
        [],
    );

    const loadMappedComponents = useCallback(
        (lazyComponentsKey: string) => {
            if (!(lazyComponentsKey in LAZY_COMPONENTS_MAP_CONFIG)) {
                console.error(`No key ${lazyComponentsKey} in LAZY_COMPONENTS_MAP_CONFIG`);
                return;
            }

            const config = LAZY_COMPONENTS_MAP_CONFIG[lazyComponentsKey as keyof typeof LAZY_COMPONENTS_MAP_CONFIG];
            const { map, componentKey, parentFolder, fallBackComponentName } = config;

            for (const item in map) {
                //@ts-ignore
                componentsMap.current[map[item][componentKey]] = importComponent(
                    map[item][componentKey],
                    parentFolder,
                    fallBackComponentName,
                );
            }
        },
        [importComponent],
    );

    return { loadMappedComponents, lazyComponents: componentsMap.current };
};
export default useLazyLoad;

type LazyLoadComponents = {
    [componentName: string]: LazyExoticComponent<React.FC>;
};

type UseLazyLoadReturn = {
    loadMappedComponents: (lazyComponentsKey: string) => void;
    lazyComponents: LazyLoadComponents;
};
