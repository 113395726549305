import { agreementActions } from 'agreement/redux/agreementActions';
import { PAGE_URL } from 'core/datasets/page_url';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export interface RentalObjectContractStatusProps {
    agreementId: string;
    isActiveAgreement: boolean;
    period: string;
}

const RentalObjectContractStatus = ({
    agreementId,
    isActiveAgreement,
    period,
}: RentalObjectContractStatusProps): JSX.Element => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = () => {
        if (!agreementId) return;

        dispatch(agreementActions.selectAgreementById(agreementId));

        navigate(`/${PAGE_URL.RENTAL_AGREEMENT}/${agreementId}`);
    };

    return (
        <a
            className={
                'rental-agreement__status rental-agreement__status--' + (isActiveAgreement ? 'active' : 'inactive')
            }
            onClick={handleClick}
        >
            {agreementId ? period : t(`rentalObject.agreementStatus.missingActiveAgreements`)}
        </a>
    );
};
export default RentalObjectContractStatus;
