import IconButton from '@material-ui/core/IconButton';
import { useResources } from 'resources/context';

export interface AddNewItemButtonProps {
    onClick?: () => void;
    isVisible?: boolean;
}

const AddNewItemButton = ({ onClick, isVisible = false }: AddNewItemButtonProps): JSX.Element | null => {
    const { CloseIcon } = useResources();
    return isVisible ? (
        <IconButton onClick={onClick}>
            <CloseIcon className="header-icon__add-icon" />
        </IconButton>
    ) : null;
};
export default AddNewItemButton;
