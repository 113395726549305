import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import ExtraServiceOrderForm from '../../../ExtraService/components/ExtraServiceOrderForm';
import { EXTRA_SERVICE_ORDER_ACTION } from '../../../../extraService/datasets/extra_service_order_action';
import { formatMoneyValue } from '../../../../core/utils/useMoneyHelper';
import { DateTime } from 'luxon';
import SlidePanel from '../../../../core/components/SlidePanel';
import { INormalizedExtraService } from '../../../../extraService/types/extraServices';

interface IExtraServiceOrderSlideIn {
    selectedService: INormalizedExtraService | null;
    open: boolean;
    onClose: () => void;
}

export default function ExtraServiceOrderSlideIn({ selectedService, open, onClose }: IExtraServiceOrderSlideIn) {
    const { t } = useTranslation();

    return (
        <SlidePanel
            panelId={`dashboard-extra-service-order-create`}
            isOpen={open}
            handleClose={onClose}
            headerText={t('extraServices.order.title')}
        >
            <>
                {!!selectedService && (
                    <>
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <Typography variant={'h6'}>{selectedService.name}</Typography>
                            <Typography>{selectedService.description}</Typography>
                            <div>{t(`extraServices.order.description.${selectedService.paymentType}`)}</div>
                        </Box>
                        <ExtraServiceOrderForm
                            isConfirmed={false}
                            paymentType={selectedService.paymentType}
                            action={EXTRA_SERVICE_ORDER_ACTION.ORDER}
                            serviceName={selectedService.name}
                            price={formatMoneyValue(selectedService.price, selectedService.currency) || '0'}
                            handleParentClose={onClose}
                            dateFrom={DateTime.now().toSQLDate()}
                            orderId={selectedService.id}
                        />
                    </>
                )}
            </>
        </SlidePanel>
    );
}
