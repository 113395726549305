import useResetPasswordFormSchema from 'tenantUser/hooks/useResetPasswordFormSchema';
import { Control, FieldErrors, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ResetPasswordData from 'tenantUser/types/ResetPasswordData';

interface ResetPasswordFormControllerProps {
    onSubmit: (password: string) => void;
    loading: boolean;
}

const useResetPasswordFormController = ({
    onSubmit,
    loading,
}: ResetPasswordFormControllerProps): ResetPasswordFormControllerReturnType => {
    const { t } = useTranslation();
    const _t = (toTranslate: string) => t(`tenantUser.resetPassword.${toTranslate}`);

    const { control, getValues } = useResetPasswordFormSchema();

    const { errors, isValid, isDirty } = useFormState({ control });

    const submitButtonArgs = {
        loading: loading,
        text: _t('resetPassword'),
        actionHandler: () => onSubmit((getValues() as ResetPasswordData).password),
        disabled: !(isValid && isDirty),
        loadingText: t('notices.processing'),
    };

    return { control, submitButtonArgs, _t, errors, loading };
};
export default useResetPasswordFormController;

type ResetPasswordFormControllerReturnType = {
    control: Control;
    loading: boolean;
    errors: FieldErrors;
    submitButtonArgs: {
        loading: boolean;
        text: string;
        actionHandler: () => void;
        disabled: boolean;
        loadingText: string;
    };
    _t(key: string): string;
};
