import { QUERY_FETCH_POLICY } from 'core/hooks/useQueryHelper';
import { AppState } from 'core/redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { IOrderedExtraServiceFromApi } from 'extraService/types/extraServices';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getNormalizedExtraServiceOrders } from '../../../utils/useExtraServiceHelper';

export const ExtraServicesOrdersQueryDocument = gql`
    query orderedExtraServices($rentalAgreementId: Int!) {
        orderedExtraServices(rentalAgreementId: $rentalAgreementId) {
            id
            active
            price
            objectName
            objectType
            payment_type
            service_date
            valid_from
            valid_to
            extra_service {
                id
                active
                amount
                currency
                icon
                price
                visibleInTenantApp
                paymentType
                description {
                    langCode
                    text
                }
                name {
                    langCode
                    text
                }
            }
        }
    }
`;

type ApiResponse = { orderedExtraServices: IOrderedExtraServiceFromApi[] };

export default function useQueryExtraServicesOrders() {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const selectedAgreementId = useSelector(({ agreements }: AppState) => agreements.selectedAgreement?.id);
    const selectedAgreementParentId = useSelector(
        ({ agreements }: AppState) => agreements.selectedAgreement?.parentAgreementId,
    );

    const variables = {
        rentalAgreementId: selectedAgreementParentId || selectedAgreementId,
    };

    const { data, loading, error, called, refetch } = useQuery<ApiResponse>(ExtraServicesOrdersQueryDocument, {
        variables,
        ...QUERY_FETCH_POLICY,
        skip: !selectedAgreementId,
    });

    const orders = getNormalizedExtraServiceOrders(i18n.language, data?.orderedExtraServices ?? []);

    useEffect(() => {
        dispatch({
            type: 'EXTRA_SERVICE/SET_EXTRA_SERVICES_LOADING',
            payload: loading,
        });
    }, [dispatch, loading]);

    useEffect(() => {
        dispatch({
            type: 'EXTRA_SERVICE/SET_ORDERED_EXTRA_SERVICES',
            payload: orders,
        });
    }, [dispatch, data]);

    return {
        loading,
        error,
        orders,
        called,
        getOrders: refetch,
    };
}
