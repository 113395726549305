import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useQueryExtraServicesCollection from '../../../../extraService/api/gql/useQueryExtraServicesCollection';
import { FullSpaceLoader } from '../../../../core/components/LoadingIndicator';
import ItemWithArrowList from '../../../../core/components/lists/ItemWithArrowList';
import { IServiceRequestTypeInList } from '../../../../serviceRequest/types/serviceRequest';
import { Box, styled, Typography } from '@material-ui/core';
import ExtraServiceItemIcon from '../ExtraServiceItemIcon';
import LabeledText from '../../../../core/components/documentItems/LabeledText';
import { formatMoneyValue } from '../../../../core/utils/useMoneyHelper';
import { PaymentType } from '../../../../extraService/datasets/payment_type';
import ExtraServiceOrderSlideIn from './ExtraServiceOrderSlideIn';
import { isEmpty } from 'lodash';

const NoServicesWrapper = styled(Box)({
    height: 'calc(100vh - 200px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export default function AvailableServicesList(): JSX.Element {
    const { t } = useTranslation();
    const { extraServices, loading, extraServicesMap } = useQueryExtraServicesCollection();
    const [selectedServiceId, setSelectedServiceId] = useState<null | number>(null);

    const selectedService = selectedServiceId ? extraServicesMap[selectedServiceId] : null;

    const servicesList: IServiceRequestTypeInList[] = extraServices.map(
        ({ id, name, icon, price, paymentType, currency }) => {
            const period = paymentType === PaymentType.Recurring ? `/${t('extraServices.period.month')}` : '';
            const servicePrice = formatMoneyValue(price, currency);
            const priceText = `${servicePrice} ${period}`;

            return {
                id,
                icon: <ExtraServiceItemIcon name={icon} />,
                content: <LabeledText labelText={name} primaryText={priceText} />,
            };
        },
    );

    return (
        <>
            <Box style={{ width: '100%' }}>
                {loading && <FullSpaceLoader height={'calc(100vh - 160px)'} />}
                {!loading && isEmpty(servicesList) && (
                    <NoServicesWrapper>
                        <Typography>{t('extraServices.noAvailableExtraServices')}</Typography>
                    </NoServicesWrapper>
                )}
                {!loading && !isEmpty(servicesList) && (
                    <ItemWithArrowList data={servicesList} clickHandler={({ id }) => setSelectedServiceId(id)} />
                )}
            </Box>
            <ExtraServiceOrderSlideIn
                selectedService={selectedService}
                open={!!selectedServiceId}
                onClose={() => setSelectedServiceId(null)}
            />
        </>
    );
}
