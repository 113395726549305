import { ENVIRONMENT } from 'core/datasets/action';
import { EnvironmentReducerState } from 'environment/types/redux/environmentState';
import { EnvironmentActions } from 'environment/types/redux/environmentActions';
import { AppState } from 'core/redux/rootReducer';

const initState: EnvironmentReducerState = {
    invoiceAsPaymentNotification: false,
    tipsAndTricksEnabled: false,
    emissionsEnabled: false,
    messagingSettings: { isMessagingEnabled: false, displayName: null },
    settingsLoaded: false,
    agreementExtensionButtonEnabled: false,
    agreementTerminationMinimumPriorToDays: 30,
};

export const environmentReducer = (state = initState, action: EnvironmentActions): EnvironmentReducerState => {
    switch (action.type) {
        case ENVIRONMENT.SET_ENVIRONMENT_SETTINGS: {
            const { data } = action.payload;

            if (!data) return state;

            const {
                invoiceAsPaymentNotification,
                messagingSettings,
                tipsAndTricksEnabled,
                emissionsEnabled,
                agreementExtensionButtonEnabled,
                agreementTerminationMinimumPriorToDays,
            } = data.getEnvironmentSettings;

            return {
                ...state,
                invoiceAsPaymentNotification,
                tipsAndTricksEnabled,
                emissionsEnabled,
                messagingSettings,
                settingsLoaded: true,
                agreementExtensionButtonEnabled,
                agreementTerminationMinimumPriorToDays,
            };
        }
        default:
            return state;
    }
};

export const tipsAndTricksEnabledSelector: (state: AppState) => boolean = (state) =>
    state.environment.tipsAndTricksEnabled;

export const emissionsEnabledSelector: (state: AppState) => boolean = (state) => state.environment.emissionsEnabled;

export const minTerminationDaySelector: (state: AppState) => number = (state) =>
    state.environment.agreementTerminationMinimumPriorToDays;
