import { LarvaState, LarvaUnitObjectsSelector } from '../types/redux/larvaState';
import { ACTION, LARVA, LARVA_DOOR_ACCESS, LARVA_WS } from 'core/datasets/action';
import { LarvaNewCardFormData } from '../types/larvaData';
import { v4 as uuid } from 'uuid';
import { AppState } from 'core/redux/rootReducer';
import { UINodeLocal } from '../types/UINodeLocal';
import { AnyAction } from 'redux';

const initLarvaState: LarvaState = {
    doorAccess: {
        cards: {},
        codes: {},
        list: [],
        doorAccessMap: {},
        isAllowedToManage: false,
    },
    newCardFormData: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '+372',
        id: '',
        pin: 'GENERATE',
        cloud: false,
        accessPolicyRole: 'user',
        autoSchedulePinSMS: true,
        autoSchedulePinEmail: true,
    },
    newCardFormStatus: {
        hasError: false,
        submitted: false,
        helperText: '',
    },
    requests: {},
    newCardFormFieldsStatus: {},
    smartHomeConfig: null,
    webComponentsProps: {},
    connections: {},
    larvaWebComponentsGroupProps: {},
    dashboardUnitObject: null,
    unitDevicesMap: {},
    unitDevicesObjectMap: {},
    dashboardUiNodes: [],
    unitDevicesObjectsMap: {},
    apiUnits: [],
};

export const larvaReducer = (state = initLarvaState, action: AnyAction): LarvaState => {
    switch (action.type) {
        case LARVA_DOOR_ACCESS.CHANGED_RIGHT_TO_MANAGE_ACCESSES: {
            if (state.doorAccess.isAllowedToManage === action.payload) return state;

            return {
                ...state,
                doorAccess: {
                    ...state.doorAccess,
                    isAllowedToManage: action.payload,
                },
            };
        }
        case LARVA.CHANGE_LARVA_FIELD:
            return <LarvaState>{
                ...state,
                newCardFormData: {
                    ...state.newCardFormData,
                    id: uuid(),
                    [action.fieldName as keyof LarvaNewCardFormData]: action.payload,
                },
            };
        case LARVA.CHANGE_LARVA_CHECKBOX_FIELD:
            return <LarvaState>{
                ...state,
                newCardFormData: {
                    ...state.newCardFormData,
                    [action.fieldName as keyof LarvaNewCardFormData]:
                        !state.newCardFormData[action.fieldName as keyof LarvaNewCardFormData],
                },
            };
        case LARVA.DOOR_CODE_CREATED:
            return {
                ...initLarvaState,
                newCardFormStatus: {
                    hasError: false,
                    submitted: true,
                    helperText: action.payload,
                },
            };
        case LARVA.RESET_LARVA_FORM:
            return { ...initLarvaState };
        case LARVA.RESET_LARVA_FORM_PIN_CODE_FIELD:
            return {
                ...state,
                newCardFormData: {
                    ...state.newCardFormData,
                    pin: initLarvaState.newCardFormData.pin,
                },
            };
        case LARVA.ERROR_ON_SUBMIT_LARVA_FORM:
            return {
                ...state,
                newCardFormStatus: {
                    hasError: true,
                    submitted: false,
                    helperText: action.payload,
                },
            };
        case LARVA.VALIDATE_LARVA_FORM_FIELD:
            return {
                ...state,
                newCardFormFieldsStatus: {
                    ...state.newCardFormFieldsStatus,
                    [action.fieldName as keyof LarvaNewCardFormData]: {
                        hasError: action.payload.hasError,
                        touched: true,
                        helperText: action.payload.helperText,
                    },
                },
                newCardFormStatus: {
                    ...state.newCardFormStatus,
                    hasError: false,
                    submitted: false,
                },
            };
        case LARVA.SAVE_SMART_HOME_CONFIG:
            return {
                ...state,
                smartHomeConfig: action.payload,
            };
        case LARVA.SAVE_WEB_COMPONENTS_PROPS: {
            const unitWebComponents = action.webComponents;
            return {
                ...state,
                webComponentsProps: unitWebComponents,
            };
        }
        case LARVA.SAVE_UNIT_WEB_COMPONENTS_PROPS: {
            return {
                ...state,
                webComponentsProps: {
                    ...state.webComponentsProps,
                    [action.unitId]: action.webComponents,
                },
            };
        }
        case LARVA.SAVE_UNIT_CONNECTIONS: {
            return {
                ...state,
                connections: action.payload,
            };
        }
        case LARVA.RESET_LARVA: {
            return initLarvaState;
        }
        case LARVA.DROP_CONNECTIONS: {
            return {
                ...state,
                connections: {},
            };
        }
        case LARVA.SAVE_WEB_COMPONENTS_GROUP_PROPS: {
            return {
                ...state,
                larvaWebComponentsGroupProps: action.payload,
            };
        }
        case ACTION.AUTH.LOGOUT: {
            return initLarvaState;
        }
        case LARVA.PUT_DASHBOARD_UNIT_OBJECT: {
            return {
                ...state,
                dashboardUnitObject: action.payload,
            };
        }
        case LARVA.DASHBOARD_UI_NODES_ADDED: {
            return {
                ...state,
                dashboardUiNodes: action.payload,
            };
        }
        case LARVA.PUT_UNIT_DEVICES_MAP: {
            return {
                ...state,
                unitDevicesMap: action.payload,
            };
        }
        case LARVA.UNIT_DEVICES_OBJECTS_MAP_INIT_ASYNC: {
            return {
                ...state,
                unitDevicesObjectsMap: action.payload,
            };
        }
        case LARVA_DOOR_ACCESS.DOOR_CARDS_RECEIVED: {
            return {
                ...state,
                doorAccess: {
                    ...state.doorAccess,
                    cards: action.payload,
                },
            };
        }
        case LARVA_DOOR_ACCESS.DOOR_CODES_RECEIVED: {
            return {
                ...state,
                doorAccess: {
                    ...state.doorAccess,
                    codes: action.payload,
                },
            };
        }
        case LARVA_DOOR_ACCESS.DOOR_ACCESS_LIST_VIEW_DATA_RECEIVED: {
            return {
                ...state,
                doorAccess: {
                    ...state.doorAccess,
                    list: action.payload,
                },
            };
        }
        case LARVA_DOOR_ACCESS.DOOR_ACCESS_MAP_RECEIVED: {
            return {
                ...state,
                doorAccess: {
                    ...state.doorAccess,
                    doorAccessMap: action.payload,
                },
            };
        }
        case LARVA_WS.CLEAR_WS_CONNECTION: {
            return {
                ...state,
                webComponentsProps: {},
                connections: {},
                larvaWebComponentsGroupProps: {},
                dashboardUiNodes: [],
                dashboardUnitObject: null,
            };
        }
        case LARVA.PUT_API_UNITS: {
            return {
                ...state,
                apiUnits: action.payload,
            };
        }
        case LARVA.CLEAR_ALL_DATA: {
            return { ...initLarvaState };
        }
        default:
            return state;
    }
};

export const larvaUnitObjectsSelector: LarvaUnitObjectsSelector = (state) => state.larva.unitDevicesObjectsMap;

export const larvaDashboardUiUnitsSelector: (state: AppState) => UINodeLocal[] = (state) =>
    state.larva.dashboardUiNodes || [];
