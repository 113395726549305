import Intro from '../../Intro';
import useWindowSize, { VIEWPORT_BREAKPOINTS } from '../../../hooks/useWindowSize';
import './PublicPageContainer.css';
import { MainLogo } from 'pages/Login';

interface LoginPageContainerProps {
    children: JSX.Element | JSX.Element[];
    isLoading: boolean;
    hideContentOnLoading?: boolean;
    customClasses?: string;
}
//@ts-ignore
const PublicPageContainer = ({
    children,
    isLoading,
    hideContentOnLoading = false,
    customClasses = '',
}: LoginPageContainerProps): JSX.Element => {
    const { getScreenWidthClassName } = useWindowSize();

    const SCREEN_CLASS_NAME = getScreenWidthClassName(VIEWPORT_BREAKPOINTS.w680);

    if (SCREEN_CLASS_NAME === 'mobile') {
        return (
            <div className={`public-page-container public-page-container--${SCREEN_CLASS_NAME}`}>
                <div className={`login-page login-page--mobile`}>
                    <MainLogo />
                    <InnerContainer
                        hideContentOnLoading={hideContentOnLoading}
                        isLoading={isLoading}
                        screenClassName={SCREEN_CLASS_NAME}
                        customClasses={customClasses}
                    >
                        {children}
                    </InnerContainer>
                </div>
            </div>
        );
    }

    return (
        <div className={'public-page-container public-page-container--' + SCREEN_CLASS_NAME}>
            <Intro isLoading={isLoading} classes="public-page__intro" />
            <InnerContainer
                hideContentOnLoading={hideContentOnLoading}
                isLoading={isLoading}
                screenClassName={SCREEN_CLASS_NAME}
                customClasses={customClasses}
            >
                {children}
            </InnerContainer>
        </div>
    );
};

function InnerContainer({
    hideContentOnLoading,
    isLoading,
    customClasses,
    screenClassName,
    children,
}: {
    hideContentOnLoading: boolean;
    isLoading: boolean;
    screenClassName: string;
    customClasses: string;
    children: JSX.Element | JSX.Element[];
}): JSX.Element | null {
    const isVisible = hideContentOnLoading ? !isLoading : true;

    if (isVisible) {
        return (
            <div className={`public-page__content-container login-page__login-form-container--${screenClassName}`}>
                <div className={`public-page__content-container-content ${customClasses}`}>{children}</div>
            </div>
        );
    }
    return null;
}

export default PublicPageContainer;
