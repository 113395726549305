import { USER_PROFILE } from 'core/datasets/action';
import { CURRENT_LANGUAGE, LOCAL_STORAGE } from 'core/datasets/local_storage';
import { Middleware } from 'redux';
import i18n from 'i18next';

export const languageMiddleware: Middleware = () => {
    return (next) => (action) => {
        if (action.type === USER_PROFILE.CHANGE_LANGUAGE) {
            const language = action.payload;
            const userProfile = localStorage.getItem(LOCAL_STORAGE.USER_PROFILE);
            const userProfileObj = userProfile ? JSON.parse(userProfile) : {};
            const updatedProfile = {
                ...userProfileObj,
                [CURRENT_LANGUAGE]: language,
            };

            localStorage.setItem(LOCAL_STORAGE.USER_PROFILE, JSON.stringify(updatedProfile));
            i18n.changeLanguage(language).finally(() => {
                console.log('[LANG]: ', language);
            });
        }
        next(action);
    };
};

export default languageMiddleware;
