import { CORE, FILE, FILE_TEMP } from 'core/datasets/action';
import {
    fetchFilePreview,
    handleFilesErrorResponse,
    handlePreviewLoadingStateChangeAction,
    handleSelectedFileUpdate,
    handleSelectedListItemAsBrokenAction,
} from 'file/saga/workers';
import { downloadFile } from 'file/saga/workers/downloadFile';
import { fetchAllFiles } from 'file/saga/workers/fetchAllFiles';
import { fetchFileDownloadLink } from 'file/saga/workers/fetchFileDownloadLink';
import { handleDownloadingFileState } from 'file/saga/workers/handleDownloadingFileState';
import { handleFileSelectionById } from 'file/saga/workers/handleFileSelectionById';
import { handleReceivedFiles } from 'file/saga/workers/handleReceivedFiles';
import { all, fork, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

function* watchCallToFetchFiles(): Generator {
    yield takeLeading(FILE.FETCH_ALL_FILES, fetchAllFiles);
}

function* watchDownloadFileRequests(): Generator {
    yield takeLeading(FILE.DOWNLOAD_FILE_REQUEST, fetchFileDownloadLink);
}

function* watchPreviewFileRequests(): Generator {
    yield takeLatest(FILE_TEMP.GET_PREVIEW_REQUEST, fetchFilePreview);
}

function* watchDownloadFileAction(): Generator {
    yield takeLeading(FILE.DOWNLOAD_FILE_ACTION, downloadFile);
}

function* watchDownloadingFileStateAction(): Generator {
    yield takeLeading(FILE.DOWNLOAD_FILE_REQUEST, handleDownloadingFileState);
    yield takeLeading(FILE.DOWNLOAD_FILE_ACTION, handleDownloadingFileState);
    yield takeLeading(CORE.RECEIVED_ERROR_GRAPHQL_RESPONSE, handleDownloadingFileState);
}

function* watchReceivedFiles(): Generator {
    yield takeLeading(FILE.ALL_FILES_RECEIVED, handleReceivedFiles);
}

function* watchFileSelectedById(): Generator {
    yield takeLeading(FILE_TEMP.LIST_ITEM_SELECTED_BY_ID, handleFileSelectionById);
}

function* watchCallToUpdateSelectedFile(): Generator {
    yield takeLeading(FILE_TEMP.UPDATE_SELECTED_FILE_DATA, handleSelectedFileUpdate);
}

function* watchPreviewLoadingStateAction(): Generator {
    yield takeLeading(FILE_TEMP.GET_PREVIEW_REQUEST, handlePreviewLoadingStateChangeAction);
    yield takeLeading(FILE_TEMP.DATA_URL_GENERATED, handlePreviewLoadingStateChangeAction);
    yield takeLeading(CORE.RECEIVED_ERROR_GRAPHQL_RESPONSE, handlePreviewLoadingStateChangeAction);
}

function* watchErrorResponse(): Generator {
    yield takeLeading(CORE.RECEIVED_ERROR_GRAPHQL_RESPONSE, handleFilesErrorResponse);
}

function* watchFileStateChanges(): Generator {
    yield takeEvery(FILE_TEMP.MARK_SELECTED_BROKEN, handleSelectedListItemAsBrokenAction);
}
export function* fileWatcher(): Generator {
    yield all([
        fork(watchDownloadFileAction),
        fork(watchCallToFetchFiles),
        fork(watchReceivedFiles),
        fork(watchDownloadFileRequests),
        fork(watchFileSelectedById),
        fork(watchDownloadingFileStateAction),
        fork(watchPreviewFileRequests),
        fork(watchCallToUpdateSelectedFile),
        fork(watchPreviewLoadingStateAction),
        fork(watchErrorResponse),
        fork(watchFileStateChanges),
    ]);
}
