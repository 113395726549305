import {
    ChangeFilterAction,
    ResetFilterAction,
    SetFilterGroupsAction,
    SetFilterServiceRequestTypesMap,
} from '../../types/redux/filterActions';
import { FILTER } from 'core/datasets/action';
import { Filter as FilterProps, FilterCategoryType, FilterGroupType } from '../../types/filter';
import { ServiceRequestTypeTitlesMap } from 'serviceRequest/types/serviceRequest';

export const changeFilterValue = (filter: FilterProps): ChangeFilterAction => {
    return {
        type: FILTER.CHANGE_FILTER,
        payload: filter,
    };
};

interface resetFilterGroupProps {
    filterGroupName: keyof FilterGroupType;
    context: FilterCategoryType;
}
export const resetFilterGroup = ({ filterGroupName, context }: resetFilterGroupProps): ResetFilterAction => {
    return {
        type: FILTER.RESET_FILTER_GROUP,
        payload: {
            context,
            filterGroupName,
        },
    };
};

interface SetServiceRequestFilterGroupsProps {
    groups: FilterGroupType;
    context: FilterCategoryType;
}
export const setServiceRequestFilterGroups = ({
    groups,
    context,
}: SetServiceRequestFilterGroupsProps): SetFilterGroupsAction => {
    return {
        type: FILTER.SET_FILTER_GROUPS,
        payload: {
            groups,
            context,
        },
    };
};

export const setServiceRequestTitlesMap = (payload: ServiceRequestTypeTitlesMap): SetFilterServiceRequestTypesMap => {
    return {
        type: FILTER.SET_SERVICE_REQUEST_TYPES_MAP,
        payload,
    };
};
