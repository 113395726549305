import { createPortal } from 'react-dom';
import usePortal from './hooks/usePortal/usePortal';
import { ReactPortal } from 'react';

interface PortalProps {
    id: string;
    children: JSX.Element | JSX.Element[];
}

const Portal = ({ id, children }: PortalProps): ReactPortal => {
    const target = usePortal(id);
    return createPortal(children, target);
};

export default Portal;
