import {
    ExtraService,
    ExtraServiceFromOrder,
    ExtraServiceOrder,
    FlatExtraService,
    INormalizedExtraService,
    IOrderedExtraService,
    IOrderedExtraServiceFromApi,
} from '../types/extraServices';
import { TranslationType } from 'core/types/TranlationType';
import PAYMENT_TYPE, { PaymentType } from '../datasets/payment_type';
import { capitalizeAll } from '../../core/utils/stringUtils';
import { ExtraServiceOrderFormData } from '../types/extraServiceOrder';
import DATE_PICKER_TYPE from '../../core/datasets/date_picker_type';
import { APP_LUX_DATE_FORMAT, DB_LUX_DATE_FORMAT, formatDbDateTimeToAppDate } from '../../core/utils/datesHelper';
import { DateTime } from 'luxon';
import { makeComponentClasses } from '../../core/utils/componentUtils';

export type DatePickerDate = (typeof DATE_PICKER_TYPE)[keyof typeof DATE_PICKER_TYPE];

export interface GenerateDatePickerDate {
    formData: ExtraServiceOrderFormData;
    dateType: DatePickerDate;
    paymentType: string;
}

export const getServiceTranslation = (
    translations: TranslationType[],
    langCode: string,
    defaultLanguage = 'en',
): string => {
    if (!translations || translations.length === 0) return 'No translations';

    const translation = translations.find(
        (translation) => translation?.langCode === langCode && !!translation?.text?.trim(),
    );
    if (translation) return translation.text;

    const defaultTranslation = translations.find(
        (translation) => translation?.langCode === defaultLanguage && !!translation?.text?.trim(),
    );
    if (defaultTranslation) return defaultTranslation.text;

    const firstNotEmptyTranslation = translations.find(
        (translation) => !!translation.text?.trim() && !!translation?.text?.trim(),
    );
    if (firstNotEmptyTranslation) return firstNotEmptyTranslation.text;

    return process.env.NODE_ENV === 'development' ? `Missing valid translation for langCode: ${langCode}.` : '';
};

export const getNumberOfConfirmedOrders = (orders: ExtraServiceOrder[]): number => {
    if (!orders || typeof orders !== 'object' || !('length' in orders) || orders.length === 0) return 0;

    const confirmedOrders = orders.filter(({ active }: ExtraServiceOrder) => active);
    return confirmedOrders.length;
};

const getServiceIconName = (icon: string) => {
    const capitalizedName = capitalizeAll(icon);
    return capitalizedName ? (capitalizedName as string) : '';
};

export const getNormalizedExtraService = (langCode: string, service: ExtraService | null): FlatExtraService | null =>
    service
        ? {
              ...service,
              icon: getServiceIconName(service.icon),
              name: getServiceTranslation(service.name, langCode),
              description: getServiceTranslation(service.description, langCode),
              confirmedOrdersAmount: getNumberOfConfirmedOrders(service.orders),
          }
        : null;

export const getNormalizedExtraServicesData = (langCode: string, data: ExtraService[]): FlatExtraService[] => {
    const services = data ? data : [];
    return services.map((service) => ({
        ...service,
        icon: getServiceIconName(service.icon),
        name: getServiceTranslation(service.name, langCode),
        description: getServiceTranslation(service.description, langCode),
        confirmedOrdersAmount: getNumberOfConfirmedOrders(service.orders),
    }));
};

export const getNormalizedExtraServiceOrders = (
    langCode: string,
    orders: IOrderedExtraServiceFromApi[],
): IOrderedExtraService[] => {
    return orders.map((order) => ({
        id: order.id,
        validFrom: order.valid_from,
        validTo: order.valid_to,
        active: order.active,
        price: order.price,
        serviceDate: order.service_date,
        objectType: order.objectType,
        objectName: order.objectName,
        paymentType: order.payment_type,
        extraService: {
            ...order.extra_service,
            icon: getServiceIconName(order.extra_service?.icon),
            name: getServiceTranslation(order.extra_service?.name, langCode),
            description: getServiceTranslation(order.extra_service?.description, langCode),
        },
    }));
};

export const getNormalizedExtraServices = (
    langCode: string,
    orders: ExtraServiceFromOrder[],
): INormalizedExtraService[] => {
    return orders.map((service) => ({
        ...service,
        icon: getServiceIconName(service?.icon),
        name: getServiceTranslation(service?.name, langCode),
        description: getServiceTranslation(service?.description, langCode),
    }));
};

export const addPeriodToFormattedServicePrice = (
    formattedPrice: string,
    paymentType: string,
    periodName: string,
): string => {
    return formattedPrice + (paymentType === PAYMENT_TYPE.REPEATING ? '/' + periodName : '');
};

const getDateFromFormData = (formData: ExtraServiceOrderFormData): string =>
    formData[DATE_PICKER_TYPE.DATE_FROM]?.toFormat(DB_LUX_DATE_FORMAT) || '';

export const generateMinDate = (props: GenerateDatePickerDate): string => {
    const { formData, paymentType, dateType } = props;
    let minDate: DateTime | string = '';

    if (dateType === DATE_PICKER_TYPE.DATE_UNTIL && paymentType !== PAYMENT_TYPE.SINGLE) {
        minDate = getDateFromFormData(formData);
    }

    return minDate;
};

export const generateMaxDate = (props: GenerateDatePickerDate): string => {
    const { formData, paymentType, dateType } = props;

    let maxDate: DateTime | string = DateTime.now().plus({ years: 10 }).toFormat(DB_LUX_DATE_FORMAT);

    if (
        dateType === DATE_PICKER_TYPE.DATE_FROM &&
        formData[DATE_PICKER_TYPE.DATE_UNTIL]?.isValid &&
        paymentType !== PAYMENT_TYPE.SINGLE
    ) {
        maxDate = formData[DATE_PICKER_TYPE.DATE_UNTIL]?.toFormat(DB_LUX_DATE_FORMAT) || '';
    }

    return maxDate;
};

export const generatedExtraServiceFormClasses = makeComponentClasses(
    {
        base: 'extra-service-order-form',
        periodContainer: 'period-container',
        inputContainer: 'input',
        dateInput: 'date-input',
        dateInput__one_time: 'date-input--single',
        dateInput__recurring: 'date-input--repeating',
        dateFromInput: 'date-from-input',
        dateUntilInput: 'date-until-input',
        confirmationDataContainer: 'confirmation-data-container',
        confirmationDataRow: 'confirmation-data-row',
        confirmationDataItem: 'confirmation-data-item',
        submitButtonContainer: 'submit-button-container',
        submitButton: 'submit-button',
    },
    '',
);

export const generateExtraServiceFormInitialData = (
    serviceId: number,
    rentalAgreementId: number,
): ExtraServiceOrderFormData => ({
    fromDate: DateTime.now().startOf('day'),
    untilDate: null,
    amount: 1,
    serviceId,
    rentalAgreementId,
});

export const datesAreValidAndSameAndHasAmount = (
    formData: ExtraServiceOrderFormData,
    dateFrom?: string | null,
    dateUntil?: string | null,
): boolean => {
    const formDateFrom = formData.fromDate?.toFormat(APP_LUX_DATE_FORMAT);
    const formDateUntil = formData.untilDate?.toFormat(APP_LUX_DATE_FORMAT);

    const formattedDateFrom = formatDbDateTimeToAppDate(dateFrom as string);
    const formattedDateUntil = formatDbDateTimeToAppDate(dateUntil as string);

    const isSameValue =
        formattedDateFrom === formDateFrom &&
        ((!formattedDateUntil && !formDateUntil) || formattedDateUntil === formDateUntil) &&
        !!formData.amount &&
        formData.amount === 1;

    return isSameValue;
};

export const createOrderedServicePeriod = ({ validFrom, validTo, serviceDate, paymentType }: IOrderedExtraService) => {
    const start = formatDbDateTimeToAppDate(validFrom);
    const end = formatDbDateTimeToAppDate(validTo) || '...';

    const isRecurring = paymentType === PaymentType.Recurring;
    return isRecurring ? `${start} - ${end}` : formatDbDateTimeToAppDate(serviceDate) || '';
};
