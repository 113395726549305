import imageCompression from 'browser-image-compression';
import { ChangeEvent } from 'react';
import { Button } from '@material-ui/core';

interface FileUploadButtonProps {
    className: string;
    id: string;
    title: string;
    handleChange: (files: File[]) => void;
}

type BlobWithName = Blob & { name: string };

function asyncCompressionQueue(files: FileList | null): Promise<BlobWithName>[] {
    const compressionQueue = [];
    const compressionOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
    };

    for (const file of files || []) {
        compressionQueue.push(imageCompression(file, compressionOptions));
    }

    return compressionQueue;
}

export function FileUploadButton({ className, id, title, handleChange }: FileUploadButtonProps): JSX.Element {
    async function handleFileUploadChange(event: ChangeEvent<HTMLInputElement>) {
        const files = event.currentTarget.files;
        // eslint-disable-next-line compat/compat
        const compressedBlobs = await Promise.all(asyncCompressionQueue(files));
        const compressedFiles = compressedBlobs.map((blob) => new File([blob], blob.name));
        handleChange(compressedFiles);
    }

    return (
        <Button variant="outlined" component="label" className={className} id={id}>
            {title}
            <input
                type="file"
                accept="image/png, image/jpeg"
                hidden
                name="files"
                multiple={true}
                onChange={handleFileUploadChange}
            />
        </Button>
    );
}
