import { handleTempAgreementSelection } from 'agreement/saga/workers/handleAgreementSelection';
import { handleAgreementSelectionByObjectId } from 'agreement/saga/workers/handleAgreementSelectionByObjectId';
import { AGREEMENT, AGREEMENT_OBJECT, AGREEMENT_TEMP, AUTH, CORE } from 'core/datasets/action';
import { all, fork, takeEvery, takeLeading } from 'redux-saga/effects';
import {
    changeAgreementLicensePlates,
    fetchAllAgreements,
    handleActiveAgreementSelection,
    handleAgreementChangeCalls,
    handleAgreementSelectionBiId,
    handleAgreementsLoadingStateChange,
    handleLicensePlatesChangedAction,
    handleReceivedAgreements,
    initDataRefreshingOnAppActiveStateChanged,
    initDataRefreshingOnNetworkChanged,
    initFetchAgreementsAndAgreementObjects,
    handleAgreementChangeRequestClearingOnDelay,
    handleAgreementChangeRequestSubmitCalls,
    handleAgreementChangesRequestResponse,
} from 'agreement/saga/workers';

function* watchLogin(): Generator {
    yield takeEvery(AUTH.LOGIN_SUCCESS, initFetchAgreementsAndAgreementObjects);
}

function* watchAgreementsLoadingState(): Generator {
    yield takeEvery(AGREEMENT.FETCH_ALL_AGREEMENTS, handleAgreementsLoadingStateChange);
    yield takeEvery(AGREEMENT.INIT_AGREEMENTS_FETCHING, handleAgreementsLoadingStateChange);
    yield takeEvery(AGREEMENT.DATA_UPDATED, handleAgreementsLoadingStateChange);
}

function* watchDataFetchingCommand(): Generator {
    yield takeLeading(AGREEMENT.FETCH_AGREEMENTS_ASYNC, initFetchAgreementsAndAgreementObjects);
}

function* watchAppActiveStateChange(): Generator {
    yield takeEvery(CORE.APP_ACTIVE_STATUS_CHANGED, initDataRefreshingOnAppActiveStateChanged);
}

function* watchNetworkStatusChange(): Generator {
    yield takeEvery(CORE.INIT_NETWORK_STATUS, initDataRefreshingOnNetworkChanged);
}

function* watchCallToFetchAllAgreements(): Generator {
    yield takeLeading(AGREEMENT.FETCH_ALL_AGREEMENTS, fetchAllAgreements);
}

function* watchCallsForInitAgreementsFetching(): Generator {
    yield takeLeading(AGREEMENT.INIT_AGREEMENTS_FETCHING, fetchAllAgreements);
}

function* watchReceivedAgreements(): Generator {
    yield takeEvery(AGREEMENT.ALL_AGREEMENTS_RECEIVED, handleReceivedAgreements);
    yield takeEvery(AGREEMENT.INIT_AGREEMENTS_RECEIVED, handleReceivedAgreements);
}

function* watchAgreementSelectedById(): Generator {
    yield takeEvery(AGREEMENT_TEMP.LIST_ITEM_SELECTED_BY_ID, handleAgreementSelectionBiId);
}

function* watchAgreementLicensePlatesChange(): Generator {
    yield takeEvery(AGREEMENT.CHANGE_LICENSE_PLATES, changeAgreementLicensePlates);
}

function* watchLicensePlatesChangedAction(): Generator {
    yield takeEvery(AGREEMENT.LICENSE_PLATES_CHANGED, handleLicensePlatesChangedAction);
}

function* watchAgreementDataUpdate(): Generator {
    yield takeEvery(AGREEMENT.DATA_UPDATED, handleActiveAgreementSelection);
}
function* watchTempAgreementDataUpdate(): Generator {
    yield takeEvery(AGREEMENT.DATA_UPDATED, handleTempAgreementSelection);
}

function* watchAgreementObjectSelectionById(): Generator {
    yield takeEvery(AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED_BY_ID, handleAgreementSelectionByObjectId);
}

function* watchAgreementChangeCalls(): Generator {
    yield takeEvery(
        [AGREEMENT_TEMP.EXTENSION_PROCESS_CALLED, AGREEMENT_TEMP.TERMINATION_PROCESS_CALLED],
        handleAgreementChangeCalls,
    );
}

function* watchAgreementChangeSubmitCalls(): Generator {
    yield takeEvery(AGREEMENT_TEMP.CHANGE_REQUEST_SUBMIT_CALLED, handleAgreementChangeRequestSubmitCalls);
}

function* watchAgreementChangeModalStateCalls(): Generator {
    yield takeEvery([AGREEMENT_TEMP.REQUEST_MODAL_STATE_CHANGED], handleAgreementChangeRequestClearingOnDelay);
}

function* watchAgreementChangeRequestsResponse(): Generator {
    yield takeEvery([AGREEMENT_TEMP.REQUESTED_CHANGES_RESPONSE_RECEIVED], handleAgreementChangesRequestResponse);
}

export function* agreementWatchers(): Generator {
    yield all([
        fork(watchLogin),
        fork(watchDataFetchingCommand),
        fork(watchAppActiveStateChange),
        fork(watchNetworkStatusChange),
        fork(watchCallToFetchAllAgreements),
        fork(watchReceivedAgreements),
        fork(watchAgreementSelectedById),
        fork(watchAgreementLicensePlatesChange),
        fork(watchLicensePlatesChangedAction),
        fork(watchCallsForInitAgreementsFetching),
        fork(watchAgreementDataUpdate),
        fork(watchAgreementObjectSelectionById),
        fork(watchTempAgreementDataUpdate),
        fork(watchAgreementsLoadingState),
        fork(watchAgreementChangeCalls),
        fork(watchAgreementChangeModalStateCalls),
        fork(watchAgreementChangeSubmitCalls),
        fork(watchAgreementChangeRequestsResponse),
    ]);
}
