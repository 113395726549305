import Store from './Store';
import { ShelveKey, UserSettingsKey } from 'core/types/storage';
import shelves from 'core/datasets/storages/store_name.json';
import USER_SETTINGS from 'core/datasets/storages/user_setting.json';
import { SmartHome } from 'smartHome/larva/types/SmartHome';
import LarvaConfiguration from 'smartHome/larva/types/LarvaConfiguration';

/**
 * Storage place for user settings.
 * Depending on defined storage type, it stores data in local or session storage
 *
 * @deprecated avoid using localstorage directly, use persistent redux store instead
 */
export default class UserSettingsStore extends Store {
    USER_SETTINGS: ShelveKey;
    SELECTED_AGREEMENT_ID: UserSettingsKey;
    SELECTED_AGREEMENT_UUID;
    SELECTED_AGREEMENT_VALID_FROM_DATE;
    SELECTED_OBJECT_ROOM_NUMBER;
    SMART_HOME;
    LARVA_SMART_HOME_CONFIG;

    DEFAULT_SMART_HOME_CONFIG = {
        isAvailable: false,
        type: '',
        meters_reading: '',
        organization_name: '',
        organization_id: '',
        external_id: '',
    };

    constructor(type?: 'local' | 'session') {
        super(shelves.USER_SETTINGS, type);
        this.USER_SETTINGS = shelves.USER_SETTINGS;
        this.SELECTED_AGREEMENT_ID = USER_SETTINGS.selectedObjectAgreementID;
        this.SELECTED_AGREEMENT_UUID = USER_SETTINGS.selectedObjectAgreementUUID;
        this.SELECTED_OBJECT_ROOM_NUMBER = USER_SETTINGS.selectedObjectRoomNumber;
        this.SELECTED_AGREEMENT_VALID_FROM_DATE = USER_SETTINGS.selectedObjectAgreementValidFromDate;
        this.SMART_HOME = USER_SETTINGS.smartHome;
        this.LARVA_SMART_HOME_CONFIG = USER_SETTINGS.larvaSmartHomeConfig;
    }

    public getSelectedAgreementID = (): number => this.getShelveData(this.SELECTED_AGREEMENT_ID) as number;
    public setSelectedAgreementID = (id: number): void => {
        if (Number.isFinite(+id)) {
            this.setShelveData(this.SELECTED_AGREEMENT_ID, `${id}`);
            return;
        }
        console.error('Agreement ID should be of integer type, received', { id, type: typeof id });
        throw ReferenceError('Agreement ID should be of integer type');
    };

    public getSelectedAgreementUUID = (): string => this.getShelveData(this.SELECTED_AGREEMENT_UUID) as string;
    public setSelectedAgreementUUID = (value: string): void => {
        if (!!value) this.setShelveData(this.SELECTED_AGREEMENT_UUID, value);
    };

    public getSelectedObjectRoomNumber = (): string => this.getShelveData(this.SELECTED_OBJECT_ROOM_NUMBER) as string;
    public setSelectedObjectRoomNumber = (value: string): void => {
        if (!!value) this.setShelveData(this.SELECTED_OBJECT_ROOM_NUMBER, value);
    };

    public getSelectedRentalAgreementValidFromDate = (): string => {
        return this.getShelveData(this.SELECTED_AGREEMENT_VALID_FROM_DATE) as string;
    };

    public setSelectedRentalAgreementValidFromDate = (value: string): void => {
        if (!!value) this.setShelveData(this.SELECTED_AGREEMENT_VALID_FROM_DATE, value);
    };
    public setSelectedSmartHomeConfig = (smartHome: SmartHome | null): void => {
        this.setShelveData(this.SMART_HOME, JSON.stringify(smartHome ? smartHome : this.DEFAULT_SMART_HOME_CONFIG));
    };
    public getSelectedSmartHomeConfig = (): SmartHome => {
        const smartHomeConfigInStore = this.getShelveData(this.SMART_HOME) as string;
        try {
            const smartHomeConfig = JSON.parse(smartHomeConfigInStore);
            if (smartHomeConfig) {
                return smartHomeConfig;
            }
        } catch (e: unknown) {
            console.error({ smartHomeConfigInStore, e });
        }

        return this.DEFAULT_SMART_HOME_CONFIG;
    };

    public setLarvaSmartHomeConfig = (smartHomeConfig: LarvaConfiguration | null): void => {
        this.setShelveData(this.LARVA_SMART_HOME_CONFIG, JSON.stringify(smartHomeConfig));
    };

    public getLarvaSmartHomeConfig = (): LarvaConfiguration | null => {
        const config = this.getShelveData(this.LARVA_SMART_HOME_CONFIG) as string;
        if (!config) return null;

        try {
            const smartHomeConfig = JSON.parse(config);
            if (smartHomeConfig) {
                return smartHomeConfig;
            }
        } catch (e: unknown) {
            console.error({ config, e });
        }
        return null;
    };
}
