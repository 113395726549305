import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './styles/TermsCheckbox.css';
import { TermsAndConditionsProps } from '../../types/TermsAndConditionsProps';
import { useResources } from 'resources/context';

export const TermsAndConditionsCheck: TermsHook = ({
    checked,
    handleChecked,
    label,
    externalLink,
    externalLinkText,
}: TermsAndConditionsProps) => {
    const { t } = useTranslation();
    const { UncheckedIcon, CheckedIcon } = useResources();

    const labelText = label ? label : t('pages.login.keepLoggedIn');
    const agreementLinkText = (
        <>
            <a onClick={() => handleChecked}>{labelText}</a>
            {externalLink && (
                <a target={'_blank'} href={externalLink} rel="noreferrer">
                    {externalLinkText}
                </a>
            )}
        </>
    );

    return (
        <FormControlLabel
            control={
                <Checkbox
                    disableRipple={true}
                    disableFocusRipple={true}
                    className="terms-checkbox"
                    required={false}
                    checked={checked}
                    onChange={handleChecked}
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                />
            }
            label={agreementLinkText}
        />
    );
};

type TermsHook = (props: TermsAndConditionsProps) => JSX.Element;

export default TermsAndConditionsCheck;
