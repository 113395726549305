import { useNavigate } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';

export function AppUrlListener(): null {
    const navigate = useNavigate();
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            // This strange splitter is used to enable testing on test backend,
            // because it makes links not to .app, but to test web version on amplify.
            const splitter = event.url.includes('.bidrento.com') ? '.bidrento.com' : 'amplifyapp.com';

            const slug = event.url.split(splitter).pop();

            if (slug) {
                navigate(slug);
            }
        });

        return () => {
            App.removeAllListeners().catch((error) => console.log('Error removing all listeners from App', error));
        };
    }, [navigate]);

    return null;
}
