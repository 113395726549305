import MenuButton from '../buttons/MenuButton';
import { UserPreferencesButtonProps } from '../../../tenantUser/types/userPreferencesButton';
import SlidePanel from '../SlidePanel';
import useSlidePanel from '../SlidePanel/hooks/useSlidePanel/useSlidePanel';
import PAGE_CONTEXT from '../../datasets/page_context';
import { useResources } from 'resources/context';

const UserPreferencesButton = ({ classes }: Partial<UserPreferencesButtonProps>): JSX.Element => {
    const { UserIcon } = useResources();
    const { getSlidePanelArgs, getSlidePanelContent, handleClose } = useSlidePanel({
        pageContext: PAGE_CONTEXT.USER_PREFERENCES,
    });
    const jointClasses = ['user-preferences-button', classes].join(' ');
    const slidePanelArgs = getSlidePanelArgs(PAGE_CONTEXT.USER_PREFERENCES);
    const slidePanelContent = getSlidePanelContent(PAGE_CONTEXT.USER_PREFERENCES);
    return (
        <>
            <MenuButton classes={jointClasses} onClick={handleClose}>
                <UserIcon />
            </MenuButton>
            <SlidePanel {...slidePanelArgs}>
                <div>{slidePanelContent}</div>
            </SlidePanel>
        </>
    );
};
export default UserPreferencesButton;
