export const capitalize = (word: unknown): string | boolean => {
    if (typeof word === 'string') return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    return false;
};

export const capitalizeAll = (text: unknown, separator: unknown = '-', startFrom: unknown = 1): string | boolean => {
    if (typeof text !== 'string' || !text.trim()) return false;

    if (typeof separator !== 'string' || !separator) return capitalize(text.trim());

    const splitted = text
        .trim()
        .split(separator)
        .map((part, i) => {
            const startFromPos =
                typeof startFrom !== 'number' || startFrom < 0 || startFrom > text.length ? 1 : startFrom;
            return i >= startFromPos ? capitalize(part) : part;
        });

    return splitted.join('');
};

export const pascalize = (s: string): string => {
    const name = s.replace(/-./g, (x) => x.toUpperCase()[1]);
    return name.charAt(0).toUpperCase() + name.slice(1);
};

export const sanitizeString = (text: string, replacer?: string): string => {
    const regex = /[\W]/i;
    let sanitizedString = text;

    while (regex.test(sanitizedString)) {
        sanitizedString = sanitizedString.replace(regex, replacer || '');
    }

    return sanitizedString;
};

export const stringUtils = {
    pascalize,
    sanitizeString,
    capitalize,
    capitalizeAll,
};
