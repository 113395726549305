import { BreakPointRange, WindowSize } from '../../../../classes/WindowSize';
import { MENU_AREA } from '../../../../datasets/menuArea';
import { IMenuItem } from '../../../../types/redux/MenuReducerState';
import { IAgreementListViewData } from 'agreement/types/IAgreementListViewData';
import { MENU_ITEM_ID } from 'core/config/menu_config';

export function filterButtonVisibilityFromNavBar(
    id: string,
    windowSizeRange: BreakPointRange | undefined,
    menuItems: { [p: string]: IMenuItem },
): boolean {
    if (!windowSizeRange || !menuItems[id]) return false;

    const { hideOnWindowSize, hideFromMenuArea } = menuItems[id];

    if (
        hideOnWindowSize.includes(WindowSize.BREAKPOINT_ALL_RANGE_NAME) ||
        hideFromMenuArea.includes(MENU_AREA.navBar)
    ) {
        return false;
    }

    return windowSizeRange && !hideOnWindowSize.includes(windowSizeRange);
}

export function hasActiveAgreement(agreements: IAgreementListViewData[]): boolean {
    return agreements.some((agreement) => agreement.meta?.isActive);
}

export function forNotActiveAgreement(id: string): boolean {
    return ![MENU_ITEM_ID.MESSAGING, MENU_ITEM_ID.TIPS_AND_TRICKS].includes(id);
}
