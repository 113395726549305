import { ViewVariantType } from 'core/classes/ViewVariantType';
import IListViewData from 'core/types/IListViewData';
import DoorAccessType from 'smartHome/larva/classes/DoorAccessType';
import IDoorCardRawData from 'smartHome/larva/types/doorAccess/IDoorCardRawData';

export default class DoorCardRawData {
    static PATH = '/door-access';
    private readonly _id;
    private readonly _orgId: string;
    private readonly _externalId: string;
    private readonly _accessGroupId: string;
    private readonly _accessPolicyId: string;
    private readonly _firstname: string;
    private readonly _lastname: string;
    private readonly _secondLastname: string;
    private readonly _title: string;
    private readonly _email: string;
    private readonly _phone: string;
    private readonly _pin: 'SET' | 'UNSET';
    private readonly _createdAt: string;
    private readonly _updatedAt: string;

    constructor({
        id,
        orgId,
        externalId,
        accessGroupId,
        accessPolicyId,
        firstname,
        lastname,
        secondLastname,
        title,
        email,
        phone,
        pin,
        createdAt,
        updatedAt,
    }: IDoorCardRawData) {
        this._id = id;
        this._orgId = orgId;
        this._externalId = externalId;
        this._accessGroupId = accessGroupId;
        this._accessPolicyId = accessPolicyId;
        this._firstname = firstname;
        this._lastname = lastname;
        this._secondLastname = secondLastname;
        this._title = title;
        this._email = email;
        this._phone = phone;
        this._pin = pin;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
    }

    static fromJSON(data: IDoorCardRawData): DoorCardRawData {
        return new DoorCardRawData(data);
    }
    get updatedAt(): string {
        return this._updatedAt;
    }
    get createdAt(): string {
        return this._createdAt;
    }
    get pin(): 'SET' | 'UNSET' {
        return this._pin;
    }
    get phone(): string {
        return this._phone;
    }
    get email(): string {
        return this._email;
    }
    get title(): string {
        return this._title;
    }
    get secondLastname(): string {
        return this._secondLastname;
    }
    get lastname(): string {
        return this._lastname;
    }
    get firstname(): string {
        return this._firstname;
    }
    get accessPolicyId(): string {
        return this._accessPolicyId;
    }
    get accessGroupId(): string {
        return this._accessGroupId;
    }
    get externalId(): string {
        return this._externalId;
    }
    get orgId(): string {
        return this._orgId;
    }
    get id() {
        return this._id;
    }

    get all(): IDoorCardRawData {
        return {
            id: this.id,
            orgId: this.orgId,
            externalId: this.externalId,
            accessGroupId: this.accessGroupId,
            accessPolicyId: this.accessPolicyId,
            firstname: this.firstname,
            lastname: this.lastname,
            secondLastname: this.secondLastname,
            title: this.title,
            email: this.email,
            phone: this.phone,
            pin: this.pin,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
        };
    }

    get isPinCodeSet(): boolean {
        return /^set$/i.test(this.pin);
    }

    get listViewData(): IListViewData {
        return {
            path: `${DoorCardRawData.PATH}/${this.id}`,
            labelText: DoorAccessType.CARD,
            primaryText: `${this.firstname} ${this.lastname}`,
            leftBlockVariant: this.isPinCodeSet ? ViewVariantType.SUCCESS : ViewVariantType.DISABLED,
            rightBlockVariant: undefined,
            meta: {
                id: this.id,
                type: DoorAccessType.CARD,
            },
        };
    }

    toJSON(): IDoorCardRawData {
        return this.all;
    }
}
