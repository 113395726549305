import { WindowSize } from 'core/classes/WindowSize';
import { MENU_ITEM_ID } from 'core/config/menu_config';
import { AGREEMENT, AGREEMENT_OBJECT, ENVIRONMENT, LARVA_DOOR_ACCESS } from 'core/datasets/action';
import { MENU_AREA } from 'core/datasets/menuArea';
import { MENU_ITEM_PROPERTY_NAME } from 'core/datasets/menuItemPropertyName';
import { menuActions } from 'core/redux/actions/menuActions';
import { AppState } from 'core/redux/rootReducer';
import { Middleware } from 'redux';

export const menuMiddleware: Middleware = ({ dispatch, getState }) => {
    return (next) => (action) => {
        next(action);

        switch (action.type) {
            case ENVIRONMENT.SET_ENVIRONMENT_SETTINGS: {
                const { data } = action.payload;

                if (data?.getEnvironmentSettings) {
                    const { messagingSettings, invoiceAsPaymentNotification, tipsAndTricksEnabled, emissionsEnabled } =
                        data.getEnvironmentSettings;

                    if (messagingSettings) {
                        dispatch(
                            menuActions.changeMenuItemPropertyValue({
                                itemId: MENU_ITEM_ID.MESSAGING,
                                property: MENU_ITEM_PROPERTY_NAME.hideOnWindowSize,
                                value: !messagingSettings?.isMessagingEnabled
                                    ? [WindowSize.BREAKPOINT_ALL_RANGE_NAME]
                                    : [],
                            }),
                        );
                    }

                    if (invoiceAsPaymentNotification) {
                        dispatch(
                            menuActions.changeMenuItemPropertyValue({
                                itemId: MENU_ITEM_ID.INVOICES,
                                property: MENU_ITEM_PROPERTY_NAME.labelText,
                                value: invoiceAsPaymentNotification ? 'payments' : 'invoices',
                            }),
                        );
                    }

                    if (tipsAndTricksEnabled) {
                        dispatch(
                            menuActions.changeMenuItemPropertyValue({
                                itemId: MENU_ITEM_ID.TIPS_AND_TRICKS,
                                property: MENU_ITEM_PROPERTY_NAME.hideOnWindowSize,
                                value: [],
                            }),
                        );
                    }

                    if (emissionsEnabled) {
                        dispatch(
                            menuActions.changeMenuItemPropertyValue({
                                itemId: MENU_ITEM_ID.CO2METERS,
                                property: MENU_ITEM_PROPERTY_NAME.hideOnWindowSize,
                                value: [],
                            }),
                        );
                    }
                }

                break;
            }
            case AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED: {
                if (!action.payload?.smartHome) {
                    break;
                }

                const { smartHome } = action.payload;

                dispatch(
                    menuActions.changeMenuItemPropertyValue({
                        itemId: MENU_ITEM_ID.SMART_HOME,
                        property: MENU_ITEM_PROPERTY_NAME.hideOnWindowSize,
                        value: !smartHome.isAvailable ? [WindowSize.BREAKPOINT_ALL_RANGE_NAME] : [],
                    }),
                );
                dispatch(
                    menuActions.changeMenuItemPropertyValue({
                        itemId: MENU_ITEM_ID.METER_READINGS,
                        property: MENU_ITEM_PROPERTY_NAME.hideFromMenuArea,
                        value: smartHome.isAvailable ? [MENU_AREA.navBar] : [MENU_AREA.slideIn],
                    }),
                );

                break;
            }
            case AGREEMENT.AGREEMENT_SELECTED: {
                const { agreementObjects } = getState() as AppState;

                if (!agreementObjects.selectedObject) break;

                const { smartHome } = agreementObjects.selectedObject;

                if (smartHome) {
                    dispatch(
                        menuActions.changeMenuItemPropertyValue({
                            itemId: MENU_ITEM_ID.SMART_HOME,
                            property: MENU_ITEM_PROPERTY_NAME.hideOnWindowSize,
                            value: !smartHome.isAvailable ? [WindowSize.BREAKPOINT_ALL_RANGE_NAME] : [],
                        }),
                    );
                    dispatch(
                        menuActions.changeMenuItemPropertyValue({
                            itemId: MENU_ITEM_ID.METER_READINGS,
                            property: MENU_ITEM_PROPERTY_NAME.hideFromMenuArea,
                            value: smartHome.isAvailable ? [MENU_AREA.navBar] : [MENU_AREA.slideIn],
                        }),
                    );
                }

                break;
            }
            case LARVA_DOOR_ACCESS.CHANGED_RIGHT_TO_MANAGE_ACCESSES: {
                const isAllowedToManage = action.payload;

                dispatch(
                    menuActions.changeMenuItemPropertyValue({
                        itemId: MENU_ITEM_ID.DOOR_CODES,
                        property: MENU_ITEM_PROPERTY_NAME.hideOnWindowSize,
                        value: isAllowedToManage ? [] : [WindowSize.BREAKPOINT_ALL_RANGE_NAME],
                    }),
                );

                break;
            }
        }
    };
};
