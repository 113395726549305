import { Button, TextField } from '@material-ui/core';
import { ModalWindow } from '../ModalWindow';
import { AppState } from 'core/redux/rootReducer';

import { useTranslation } from 'react-i18next';
import useLoginFormData from '../../../hooks/login/useLoginFormData';
import { useSelector } from 'react-redux';
import useResetPasswordDataDecorator from './hooks/useResetPasswordDataDecorator';

const ResetPasswordModal = ({ handleModalClosed, modalClosed }: ResetPasswordModalProps): JSX.Element => {
    const { t } = useTranslation();
    const email = useSelector(({ tempAuth }: AppState) => tempAuth.email);
    const { handleChange } = useLoginFormData();
    const { handleResetPwd, responseText, error, loading, isRequestable } = useResetPasswordDataDecorator();

    const modalArgs = {
        closed: modalClosed,
        handleClosed: handleModalClosed,
        size: 'sm',
        showTitle: true,
        modalTitle: t('modalWindow.resetYourPassword'),
    };
    const handleSubmit = () => {
        handleResetPwd(email.value);
    };
    const invalidEmailText = email.invalid && email.changed ? t('notices.invalidEmail') : '';
    const requestErrorText = error ? error.message : '';
    const defaultText = loading ? '' : t('modalWindow.resetPasswordHelperText');

    const helperText = !!responseText || !!requestErrorText ? [responseText, requestErrorText].join(' ') : defaultText;
    return (
        <ModalWindow {...(modalArgs as any)}>
            <div className="modal-items">
                <div className="modal-items__input-field modal-item">
                    <TextField
                        className="login-form__input login-form__input-email"
                        id="login-form__email"
                        name="email"
                        type="email"
                        label={t('pages.login.email')}
                        variant="outlined"
                        required
                        value={email.value}
                        onChange={handleChange}
                        autoFocus={true}
                        error={email.changed ? email.invalid : false}
                        helperText={invalidEmailText ? invalidEmailText : helperText}
                    />
                </div>
                {isRequestable && (
                    <div className="modal-items__action-btns modal-item">
                        <Button
                            onClick={handleSubmit}
                            className="modal-item__action-btn  modal-item__action-btn--primary"
                        >
                            {t('callToAction.sendEmail')}
                        </Button>
                    </div>
                )}
            </div>
        </ModalWindow>
    );
};
export default ResetPasswordModal;
type ResetPasswordModalProps = {
    handleModalClosed: () => void;
    modalClosed: boolean;
};
