import { useLocation } from 'react-router-dom';
import { NoItemsNotice } from '../../core/components/modalsPortalsNotices/NoItemsNotice';

interface ErrorPageProps {
    message?: string;
}
const ErrorPage = ({ message }: ErrorPageProps): JSX.Element => {
    const { state } = useLocation();

    return (
        <div className={'error-page-container'}>
            <NoItemsNotice message={(state as { [error: string]: string })?.error || message} />
        </div>
    );
};
export default ErrorPage;
