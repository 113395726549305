import { ConnectionStatus, Network } from '@capacitor/network';
import { Toast } from '@capacitor/toast';
import { App } from '@capacitor/app';
import { NotificationAlert } from 'core/classes/NotificationAlert';

import { AlertLevel, AlertNotice } from 'core/components/modalsPortalsNotices/AlertNotice';
import { changeAppActiveStatus, changeNetworkStatus, setInitialNetworkStatus } from 'core/redux/actions/coreActions';
import { AppState } from 'core/redux/rootReducer';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useResources } from 'resources/context';
import { Capacitor } from '@capacitor/core';
import { clearAllWsConnections } from 'smartHome/larva/utils/createDeviceConnection';

const ConnectionStatusNotifier = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isConnected = useSelector((state: AppState) => state.core.networkStatus.isConnected);

    useEffect(() => {
        Toast.show({
            text: t(`notices.${isConnected ? 'internetConnectionRestored' : 'internetConnectionLost'}`),
        });
    }, [isConnected, t]);

    useEffect(() => {
        if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Network')) {
            Network.getStatus().then((networkStatus) => {
                dispatch(
                    setInitialNetworkStatus({
                        isConnected: networkStatus.connected,
                        connectionType: networkStatus.connectionType,
                    }),
                );
            });
        } else {
            dispatch(
                setInitialNetworkStatus({
                    isConnected: true,
                    connectionType: 'wifi',
                }),
            );
        }
    }, [dispatch]);

    useEffect(() => {
        const goOnline = () => dispatch(changeNetworkStatus({ isConnected: true, connectionType: 'wifi' }));
        const goOffline = () => dispatch(changeNetworkStatus({ isConnected: false, connectionType: 'wifi' }));

        if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Network')) {
            Network.addListener('networkStatusChange', async (networkStatus: ConnectionStatus) => {
                dispatch(
                    changeNetworkStatus({
                        isConnected: networkStatus.connected,
                        connectionType: networkStatus.connectionType,
                    }),
                );
            }).finally();
        } else {
            if (window) {
                window.addEventListener('online', goOnline);
                window.addEventListener('offline', goOffline);
            }
        }

        return () => {
            if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Network')) {
                Network.removeAllListeners();
            } else {
                window.removeEventListener('online', goOnline);
                window.removeEventListener('online', goOffline);
            }

            clearAllWsConnections();
        };
    }, [dispatch]);

    useEffect(() => {
        App.addListener('appStateChange', (activeStatus) => {
            if (process.env.NODE_ENV !== 'production') console.log('App status changed to', { activeStatus });

            dispatch(changeAppActiveStatus(activeStatus.isActive));
        }).finally();

        App.addListener('pause', () => {
            if (process.env.NODE_ENV !== 'production') console.log('App paused and status changed to false');

            dispatch(changeAppActiveStatus(false));
        }).finally();

        App.addListener('resume', () => {
            if (process.env.NODE_ENV !== 'production') console.log('App resumed and status changed to true');

            dispatch(changeAppActiveStatus(true));
        }).finally();

        return () => {
            App.removeAllListeners().finally();
        };
    }, [dispatch]);

    return <NoConnectionAlert />;
};

function NoConnectionAlert(): JSX.Element | null {
    const { WarningBubbleIcon } = useResources();
    const isConnected = useSelector(({ core }: AppState) => core.networkStatus.isConnected);
    const { t } = useTranslation();

    if (isConnected) return null;

    return (
        <AlertNotice
            level={(!isConnected ? NotificationAlert.WARNING : NotificationAlert.SUCCESS) as AlertLevel}
            text={t(`notices.${isConnected ? 'internetConnectionRestored' : 'internetConnectionLost'}`)}
            open={!isConnected}
            autoHide={null}
            isClosable={false}
            hasOverlay={true}
            location={{ vertical: 'top', horizontal: 'center' }}
            alertIcon={<WarningBubbleIcon />}
        />
    );
}

export default ConnectionStatusNotifier;
