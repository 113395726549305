import { QueryStatusData } from 'core/hooks/useQueryHelper';
import useQueryErrorHandler from 'core/hooks/useQueryErrorHandler';
import { useConfig } from '../../../resources/config';
import { EnvironmentType } from 'core/types/EnvironmentType';
import { gql, useMutation } from '@apollo/client';

const resetPasswordMutationDocument = gql`
    mutation ResetPwd($resetPasswordRequestInput: ResetPasswordRequestInput!) {
        requestResetPasswordLink(resetPasswordRequestInput: $resetPasswordRequestInput) {
            message
        }
    }
`;

const useMutationResetPwd: MutationResetPwdHook = () => {
    const [resetPwd, { data, error, loading }] = useMutation(resetPasswordMutationDocument);
    const { errorHandler } = useQueryErrorHandler();
    const config = useConfig();

    let applicationType: EnvironmentType | string = config.appType;

    //Front and backend developers did not agee on vahi app identifier,
    //So, this resulted in such ugly fix HAL-4263
    if (applicationType === 'vahi') {
        applicationType = 'vahi minilaod';
    }

    const handleResetPwd = (email: string) => {
        resetPwd({
            variables: {
                resetPasswordRequestInput: {
                    email: email,
                    applicationType,
                },
            },
        }).catch(errorHandler);
    };
    return { data, error, loading, handleResetPwd };
};

export default useMutationResetPwd;

type MutationResetPwdHook = () => QueryStatusData & {
    data?: {
        requestResetPasswordLink: {
            message: string;
        };
    } | null;
    handleResetPwd: (email: string) => void;
};
