import { IObjectAgreementScopeIData } from 'agreement/classes/ObjectAgreementScope';
import IAgreementObjectInitData from 'agreementObject/types/IAgreementObjectInitData';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import Serializable from 'core/types/Serialisable';

class AgreementObject implements Serializable {
    static COMMERCIAL = 'commercial';
    static APARTMENT = 'apartment';

    allowAddMeterReadings;
    apartmentNumber;
    city;
    country;
    houseNumber;
    id;
    meters;
    rentalType;
    roomNumber;
    smartHome;
    street;
    private _agreement: null | IObjectAgreementScopeIData = null;
    constructor(props: IAgreementObjectInitData) {
        this.id = props.id;
        this.allowAddMeterReadings = props.allowAddMeterReadings;
        this.apartmentNumber = props.apartmentNumber;
        this.city = props.city;
        this.country = props.country;
        this.houseNumber = props.houseNumber;
        this.meters = props.meters;
        this.rentalType = props.rentalType;
        this.roomNumber = props.roomNumber;
        this.smartHome = props.smartHome;
        this.street = props.street;
        this.agreement = props.agreement || null;
    }
    get agreement(): IObjectAgreementScopeIData | null {
        return this._agreement;
    }
    set agreement(agreement: IObjectAgreementScopeIData | null) {
        this._agreement = agreement;
    }
    get name(): string {
        const roomApartmentNumbers = this.apartmentNumber
            ? `-${this.apartmentNumber}`
            : this.roomNumber
            ? `-${this.roomNumber}`
            : '';

        return `${this.street} ${this.houseNumber}${roomApartmentNumbers}, ${this.city}`;
    }

    get all(): IAgreementObjectInstanceData {
        return {
            id: this.id,
            allowAddMeterReadings: this.allowAddMeterReadings,
            apartmentNumber: this.apartmentNumber,
            city: this.city,
            country: this.country,
            houseNumber: this.houseNumber,
            meters: this.meters,
            rentalType: this.rentalType,
            roomNumber: this.roomNumber,
            smartHome: this.smartHome,
            street: this.street,
            agreement: this._agreement,
            name: this.name,
        };
    }

    toJSON(): IAgreementObjectInstanceData {
        return this.all;
    }

    static isApartment(object: IAgreementObjectInstanceData): boolean {
        return /apartment/i.test(object.rentalType.toLowerCase());
    }
    static isCommercial(object: IAgreementObjectInstanceData): boolean {
        return /commercial/i.test(object.rentalType.toLowerCase());
    }
}

export default AgreementObject;
