import { ApolloError } from '@apollo/react-hooks';
import { gql, useQuery } from '@apollo/client';
import InvitedTenantData from 'tenantUser/types/InvitedTenantData';

const ValidateTenantInviteHashDocument = gql`
    query validateTenantInviteHash($hash: String!) {
        validateTenantInviteHash(hash: $hash) {
            isValid
            message
            tenant {
                cognito_uuid
                email
                firstName
                lastName
                phone
                language
            }
        }
    }
`;

interface QueryValidateInviteHashProps {
    hash: string;
}

const useQueryValidateTenantInviteHash = ({
    hash,
}: QueryValidateInviteHashProps): QueryValidateTenantInviteHashReturnType => {
    let tenant, message, isValid;

    const { loading, error, data } = useQuery(ValidateTenantInviteHashDocument, {
        variables: {
            hash,
        },
        fetchPolicy: 'cache-and-network',
    });

    if (data && 'validateTenantInviteHash' in data) {
        tenant = data.validateTenantInviteHash.tenant;
        isValid = data.validateTenantInviteHash.isValid;
        message = data.validateTenantInviteHash.message;
    }

    return {
        loading: loading,
        error,
        tenant: tenant ?? null,
        isValid: isValid ?? false,
        message: message ?? '',
    };
};
export default useQueryValidateTenantInviteHash;

type QueryValidateTenantInviteHashReturnType = {
    loading: boolean;
    error?: ApolloError;
    tenant: InvitedTenantData | null;
    isValid: boolean;
    message: string;
};
