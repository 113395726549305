import { FILE_TEMP } from 'core/datasets/action';
import TempFilesState from 'file/types/TempFilesState';
import { TempFilesStateActions } from 'file/types/TempFilesStateActions';

const initState: TempFilesState = {
    loading: false,
    selected: null,
    downloading: false,
    previewLoading: false,
    url: '',
    isBroken: false,
};

export const tempFilesReducer = (state = initState, action: TempFilesStateActions): TempFilesState => {
    switch (action.type) {
        case FILE_TEMP.DATA_URL_GENERATED: {
            return {
                ...state,
                url: action.payload,
            };
        }
        case FILE_TEMP.LOADING_STATE_CHANGED: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case FILE_TEMP.PREVIEW_LOADING_STATE_CHANGED: {
            return {
                ...state,
                previewLoading: action.payload,
            };
        }
        case FILE_TEMP.DOWNLOADING_STATE_CHANGED: {
            return {
                ...state,
                downloading: action.payload,
            };
        }
        case FILE_TEMP.LIST_ITEM_SELECTED: {
            return {
                ...state,
                selected: action.payload,
                isBroken: false,
            };
        }
        case FILE_TEMP.RESET_SELECTED: {
            return {
                ...initState,
            };
        }
        case FILE_TEMP.MARK_SELECTED_BROKEN: {
            return {
                ...state,
                isBroken: true,
            };
        }
        default:
            return state;
    }
};
