import { onError } from 'apollo-link-error';
import { ServerError } from '@apollo/client/link/utils';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { QUERY_ERROR_RESPONSE } from 'core/hooks/useQueryErrorHandler';
import { fromPromise } from 'apollo-link';
import { coreActions } from 'core/redux/actions/coreActions';
import { getRefreshToken } from 'core/utils/localStorage/localStorage';
import { authActions, refreshExpiredToken } from 'tenantUser/redux/authActions';
import fetchRefreshToken from './fetchRefreshToken';
import { store } from 'core/redux/store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getRefreshTokenLink = (gqlUri: string) => {
    // @ts-ignore
    return onError(({ graphQLErrors, networkError, operation, forward }) => {
        // @ts-ignore
        if (networkError && 'result' in networkError && 'message' in networkError.result) {
            console.error('[NET ERR]: ', networkError);

            switch ((networkError as ServerError).result.message) {
                case QUERY_ERROR_RESPONSE.EXPIRED_AUTH_TOKEN: {
                    const refreshToken = getRefreshToken();

                    if (!refreshToken) return forward(operation);

                    const handleToken = (token: string) => {
                        store.dispatch(refreshExpiredToken(token));
                    };

                    const handleError = () => {
                        store.dispatch(authActions.setLogout());
                        store.dispatch(
                            coreActions.notification.set(
                                new NotificationAlert({
                                    message: 'tenantUser.notices.authExpired',
                                    level: NotificationAlert.WARNING,
                                }),
                            ),
                        );
                    };

                    // @ts-ignore
                    return fromPromise(fetchRefreshToken(gqlUri, handleToken, handleError)).flatMap(() =>
                        // @ts-ignore
                        forward(operation),
                    );
                }
                default:
                    return forward(operation);
            }
        }

        if (graphQLErrors) {
            console.error('[GQL ERR]', graphQLErrors);
        }

        return forward(operation);
    });
};

export default getRefreshTokenLink;
