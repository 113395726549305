import { useParams } from 'react-router-dom';
import { useLanguageSelection } from '../../LangSelect/hooks/useLanguageSelection';
import { useEffect } from 'react';

export const useUpdateLangOnParams = (): void => {
    const { lang } = useParams();
    const { setCurrentLanguage } = useLanguageSelection();

    useEffect(() => {
        setCurrentLanguage(lang);
    }, [lang, setCurrentLanguage]);
};
