import { put, select } from 'redux-saga/effects';
import { AppState } from 'core/redux/rootReducer';
import { LARVA, LARVA_WS } from 'core/datasets/action';

export function* fetchAllConnections(): Generator {
    const isActive = (yield select((state: AppState) => state.core.isAppActive)) as boolean;
    const isOnline = (yield select((state: AppState) => state.core.networkStatus.isConnected)) as boolean;

    if (isOnline && isActive) {
        yield put({ type: LARVA.WEBSOCKET_CONNECTIONS_REQUESTED });
    } else {
        yield put({ type: LARVA_WS.CLEAR_WS_CONNECTION });
    }
}
