import { makeComponentClasses } from '../../../utils/componentUtils';
import ListItemWithArrow from '../../buttons/ListItemWithArrow';
import { useTranslation } from 'react-i18next';
import { IServiceRequestTypeInList } from 'serviceRequest/types/serviceRequest';

export interface ItemWithArrowListProps {
    data: IServiceRequestTypeInList[];
    customClasses?: string;
    clickHandler: (value: IServiceRequestTypeInList) => void;
    tBaseKey?: string;
}

const ItemWithArrowList = ({
    customClasses = '',
    data,
    clickHandler,
    tBaseKey = '',
}: ItemWithArrowListProps): JSX.Element => {
    const { t } = useTranslation();
    if (!data) return <></>;
    const classes = makeComponentClasses(
        {
            base: 'item-with-arrow-list',
        },
        customClasses,
    );

    const arrowedItem = ({ id, title, icon, content }: IServiceRequestTypeInList) => {
        const text = !!tBaseKey ? t(`${tBaseKey}.${title}`) : title;
        const actionHandler = () => {
            clickHandler({ id, title });
        };
        return (
            <ListItemWithArrow
                key={id}
                text={text}
                customClasses={title + ' arrow-list-item'}
                clickHandler={actionHandler}
                icon={icon}
                content={content}
            />
        );
    };

    return <div className={classes.base}>{data.map(arrowedItem)}</div>;
};

export default ItemWithArrowList;
