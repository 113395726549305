import { BreakPointRange, WindowSize } from 'core/classes/WindowSize';
import { CORE } from 'core/datasets/action';
import { VIEWPORT_BREAKPOINTS } from 'core/hooks/useWindowSize';
import { coreActions } from 'core/redux/actions/coreActions';
import { Middleware } from 'redux';

export const windowSizeListenerMiddleware: Middleware = ({ dispatch }) => {
    const hasWindow = typeof window !== 'undefined';

    let windowWidth: number;
    let isFullModeView: boolean;
    let isFullModeViewOld = false;

    let isMobileWidth: boolean;
    let isMobileWidthOld = false;

    let windowWidthRange: BreakPointRange | undefined;
    let windowWidthRangeOld: BreakPointRange | undefined;

    if (hasWindow) {
        windowWidth = Math.round(window?.visualViewport?.width) as number;
        windowWidthRange = WindowSize.getRangeName(windowWidth);

        isFullModeView = windowWidth >= VIEWPORT_BREAKPOINTS.w960;
        isMobileWidth = windowWidth < VIEWPORT_BREAKPOINTS.w480;

        window.addEventListener('resize', (event: UIEvent) => {
            //@ts-ignore
            windowWidth = Math.round(event.target.visualViewport?.width);
            windowWidthRange = WindowSize.getRangeName(windowWidth);

            isFullModeView = windowWidth >= VIEWPORT_BREAKPOINTS.w960;

            if (windowWidthRange !== windowWidthRangeOld && WindowSize.isValidRangeName(windowWidthRange)) {
                windowWidthRangeOld = windowWidthRange;
                dispatch(coreActions.setWindowSizeRange(windowWidthRange!));
            }

            if (isFullModeViewOld !== isFullModeView) {
                isFullModeViewOld = isFullModeView;
                dispatch(coreActions.changeFullViewMode(isFullModeView));
            }

            isMobileWidth = windowWidth < VIEWPORT_BREAKPOINTS.w480;

            if (isMobileWidthOld !== isMobileWidth) {
                isMobileWidthOld = isMobileWidth;
                dispatch(coreActions.setIsMobileState(isMobileWidth));
            }
        });
    } else {
        console.error('[windowSizeListenerMiddleware]: failed to register window size listener');
    }

    return (next) => (action) => {
        next(action);

        if (windowWidthRange !== windowWidthRangeOld && WindowSize.isValidRangeName(windowWidthRange)) {
            windowWidthRangeOld = windowWidthRange;
            dispatch(coreActions.setWindowSizeRange(windowWidthRange!));
        }

        if (isFullModeViewOld !== isFullModeView) {
            isFullModeViewOld = isFullModeView;
            dispatch(coreActions.changeFullViewMode(isFullModeView));
        }

        if (isMobileWidthOld !== isMobileWidth) {
            isMobileWidthOld = isMobileWidth;
            dispatch(coreActions.setIsMobileState(isMobileWidth));
        }

        switch (action.type) {
            case CORE.LAYOUT_DATA_REFRESHED: {
                dispatch(coreActions.changeFullViewMode(isFullModeView));
                dispatch(coreActions.setIsMobileState(isMobileWidth));

                break;
            }
        }
    };
};
