import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from 'core/utils/localStorage/localStorage';
import getRefreshTokenLink from './getRefreshTokenLink';

const windowLocationsIsTestAppUrl: boolean =
    window?.location?.hostname === process.env.REACT_APP_TEST_APP_URL ||
    window?.location?.hostname === process.env.REACT_EFTEN_APP_TEST_APP_URL ||
    window?.location?.hostname === process.env.REACT_TTY_APP_TEST_APP_URL ||
    window?.location?.hostname === process.env.REACT_VAHI_APP_TEST_APP_URL ||
    false;

export const gqlUri: string = windowLocationsIsTestAppUrl
    ? (process.env.REACT_APP_GQL_DEV_SERVER_URL as string)
    : (process.env.REACT_APP_GQL_PROD_SERVER_URL as string);

// GraphQL Server
const link = createHttpLink({
    uri: gqlUri,
});

const refreshTokenLink = getRefreshTokenLink(gqlUri || '');

const authLink = setContext((_, { headers }) => {
    const token = getToken();

    // server doesn't handle empty values in 'X-Auth-Token'
    return token && typeof token === 'string' && !!token.trim()
        ? {
              headers: {
                  ...headers,
                  'X-Auth-Token': token,
              },
          }
        : headers;
});

export const client = new ApolloClient({
    //@ts-ignore
    link: ApolloLink.from([refreshTokenLink, authLink, link]),
    cache: new InMemoryCache({
        typePolicies: {
            RentalAgreement: {
                fields: {
                    id: {
                        merge(existing, incoming, { mergeObjects }) {
                            // Correct, thanks to invoking nested merge functions.
                            return mergeObjects(existing, incoming);
                        },
                    },
                },
            },
        },
    }),
    connectToDevTools: true,
});
