import ResetPassword from 'pages/ResetPassword';
import React, { Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Hooks
import useMenuAdapter from '../../utils/useMenuAdapter';

// Components
import { Protected } from '../Protected';
import PageLayout from '../layout/PageLayout';
import Intro from '../Intro';
import { AppState } from 'core/redux/rootReducer';
import useLazyLoad from '../../hooks/useLazyLoad/useLazyLoad';
import TenantRegistration from '../../../pages/TenantRegistration';
import Support from '../../../pages/Suppport/Support';
import Login from '../../../pages/Login';
import { useLoginLinkWithLang } from '../LangSelect/hooks/useLoginLinkWithLang';
import { ErrorBoundary } from '@sentry/react';

/**
 * Routes switcher
 * Routes/Components are defined in config file MENU_ROUTES
 * @constructor
 */
export const RoutesSwitcher = (): JSX.Element => {
    const { PARENT_ROUTES, CHILDREN_ROUTES } = useMenuAdapter();

    const { isLoggedIn } = useSelector(({ auth }: AppState) => auth);
    const { loadMappedComponents, lazyComponents: pages } = useLazyLoad();
    const loginLinkWithLang = useLoginLinkWithLang();

    const IntroConst = useMemo(() => Intro, []);

    try {
        loadMappedComponents('pages');
    } catch (e: unknown) {
        console.error(e);
        throw ReferenceError('Failed to load mapped components for pages');
    }

    const parentPages = useMemo(() => {
        return (
            <Suspense fallback={<></>}>
                <Routes>
                    {pages &&
                        Object.keys(pages) &&
                        PARENT_ROUTES.map((route) => (
                            <React.Fragment key={route.id}>
                                {route.paths.map((path) => (
                                    <Route
                                        key={`${path} ${route.id}`}
                                        path={path}
                                        element={<Protected Component={pages[route.page]} />}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    <Route path="*" element={<Navigate to={'/'} />} />
                </Routes>
            </Suspense>
        );
    }, [pages, PARENT_ROUTES]);

    const childrenRoutes = useMemo(() => {
        return CHILDREN_ROUTES.map((route) => (
            <React.Fragment key={route.id}>
                {route.paths.map((path) => (
                    <Route
                        key={`${path} ${route.id}`}
                        path={path}
                        element={<Protected Component={pages[route.page]} />}
                    />
                ))}
            </React.Fragment>
        ));
    }, [CHILDREN_ROUTES, pages]);

    const childrenPages = useMemo(
        () => (
            <Suspense fallback={<></>}>
                <Routes>{childrenRoutes}</Routes>
            </Suspense>
        ),
        [childrenRoutes],
    );

    return isLoggedIn || !Object.keys(pages) ? (
        <PageLayout parent={parentPages} child={childrenPages} />
    ) : (
        <Suspense fallback={<IntroConst isLoading={true} />}>
            <ErrorBoundary fallback={<Navigate to={'/login'} />}>
                <Routes>
                    <Route path={'/:lang/register/:hash'} element={<TenantRegistration />} />
                    <Route path={'/register/:hash'} element={<TenantRegistration />} />
                    <Route path={'/:lang/reset/:hash'} element={<ResetPassword />} />
                    <Route path={'/reset/:hash'} element={<ResetPassword />} />
                    <Route path={'/:lang/login'} element={<Login />} />
                    <Route path={'/login'} element={<Login />} />
                    <Route path={'/:lang/support'} element={<Support />} />
                    <Route path={'/support'} element={<Support />} />
                    <Route path="*" element={<Navigate to={loginLinkWithLang} />} />
                </Routes>
            </ErrorBoundary>
        </Suspense>
    );
};
export default RoutesSwitcher;
