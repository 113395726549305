import { ViewVariantType } from 'core/classes/ViewVariantType';
import IListViewDataItem from 'core/types/IListViewDataItem';
import IListViewData from 'core/types/IListViewData';
import IListViewMetaData from 'core/types/ListViewMetaData';

export default class ListViewDataItem implements IListViewDataItem {
    private _labelText = '';
    private _path = '';
    private _primaryText = '';
    private readonly _meta: IListViewMetaData;
    private _variant?: ViewVariantType;
    private _rightBlockVariant?: ViewVariantType;

    constructor({ path, labelText, primaryText, leftBlockVariant, rightBlockVariant, meta }: IListViewData) {
        this.path = path;
        this.labelText = labelText;
        this.primaryText = primaryText;
        this.leftBlockVariant = leftBlockVariant;
        this.rightBlockVariant = rightBlockVariant;
        this._meta = meta as IListViewMetaData;
    }

    get rightBlockVariant(): ViewVariantType | undefined {
        return this._rightBlockVariant;
    }

    set rightBlockVariant(value: unknown) {
        if (value) this._rightBlockVariant = value as ViewVariantType;
    }

    get leftBlockVariant(): ViewVariantType {
        if (this._variant) {
            return this._variant;
        }

        throw new ReferenceError('Undefined variant');
    }

    set leftBlockVariant(value: ViewVariantType) {
        if (ViewVariantType.isValid(value)) {
            this._variant = value as ViewVariantType;
            return;
        }

        throw new TypeError('Invalid variant type');
    }
    get primaryText(): string {
        return this._primaryText;
    }

    set primaryText(value: string) {
        this._primaryText = value;
    }
    get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
    }
    get labelText(): string {
        return this._labelText;
    }

    set labelText(value: string) {
        this._labelText = value;
    }

    get meta(): IListViewMetaData {
        return this._meta;
    }

    get all(): IListViewData {
        return {
            path: this.path,
            labelText: this.labelText,
            primaryText: this.primaryText,
            leftBlockVariant: this.leftBlockVariant,
            meta: this.meta,
        };
    }

    toJSON(): IListViewData {
        return this.all;
    }
}
