import { DataSnapshot, getDatabase, off, ref } from 'firebase/database';
import { put, take } from 'redux-saga/effects';
import { EventFirebase } from '../../classes';

export function* onItemChangedWatcher(
    path: string,
    actionType: string,
    transformer?: (data: DataSnapshot) => any,
    disableOldEvent = false,
) {
    if (disableOldEvent) {
        const db = getDatabase();
        const userChannelsRef = ref(db, path);
        off(userChannelsRef, EventFirebase.CHILD_CHANGED);
    }

    const updateChannel = EventFirebase.createEventChannelFirebase(path, EventFirebase.CHILD_CHANGED);

    while (true) {
        const item: DataSnapshot = yield take(updateChannel);
        const payload = transformer ? transformer(item) : item;
        if (payload !== null || payload !== undefined) {
            yield put({ type: actionType, payload: payload });
        }
    }
}
