import { ReactElement } from 'react';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResources } from '../../../resources/context';
import { ViewVariantType } from '../../../core/classes/ViewVariantType';
import ListItemIcon from '../../../core/components/icons/ListItemIcon/ListItemIcon';

export default function ExtraServiceItemIcon(props: {
    name: string;
    active?: boolean;
    isEnded?: boolean;
}): ReactElement {
    const { name, active } = props;
    const { HomeExtraServicesIcon } = useResources();

    let result: ReactElement | undefined;

    if (!active) {
        result = <HomeExtraServicesIcon className={'extra-service-icon-home'} />;
    }

    if (!active && name && name in solidIcons) {
        // @ts-ignore - fas icon type
        result = <FontAwesomeIcon size={'lg'} icon={solidIcons[name]} className={'font-awesome-icon'} />;
    }

    return (
        <ListItemIcon
            classes={`extra-service-list-item__icon-container ${props.isEnded && active ? 'ended' : ''}`}
            variant={active && !props.isEnded ? ViewVariantType.SUCCESS : ViewVariantType.DISABLED}
            icon={result}
        />
    );
}
