const handleComponentBroadcast = (event: CustomEvent /* @typeof CustomEvent */) => {
    const detail = event && event.detail ? event.detail : {};
    const nodeId = detail.evt;
    const payload = detail.payload || {};

    if (!nodeId) {
        return;
    }
    // if we have larva node loaded to dom with this node id
    const node = document.querySelector(`*[node-id='${nodeId}']`);

    if (node) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        node.input(payload.data);
    }
};

export default handleComponentBroadcast;
