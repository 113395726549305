import { put } from 'redux-saga/effects';
import { environmentActions } from 'environment/redux/environmentActions';
import { ENVIRONMENT } from 'core/datasets/action';
import { getEnvironmentSettingsDocument } from '../../gql/getEnvironmentSettingsDocument';

export function* fetchEnvironmentSettings(): Generator {
    yield put(
        environmentActions.fetchEnvironmentSettingsRequest(
            getEnvironmentSettingsDocument,
            ENVIRONMENT.SET_ENVIRONMENT_SETTINGS,
        ),
    );
}
