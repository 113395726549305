import React, { useContext } from 'react';
import { fullLanguageList, SysConfig } from 'resources/sysConfig';

export const ConfigContext = React.createContext<SysConfig>({
    languages: fullLanguageList,
    lng: 'et',
    fallbackLng: 'en',
    tenantRegistration: {
        terms: null,
        extraTerms: null,
    },
    dashboardComponents: {},
    larva: {
        showIfAvailable: true,
        alwaysAvailableForEmails: [],
    },
    meterReading: {
        delete: {
            allowedInHours: 72,
        },
    },
    appType: 'bidrento',
});

export const useConfig = (): SysConfig => useContext(ConfigContext);
