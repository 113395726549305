import { ViewVariantType } from 'core/classes/ViewVariantType';
import './ListItemIcon.css';
import { ListItemIconVariantType } from 'core/types/ListItemIconVariantType';
import { useResources } from 'resources/context';

interface ListItemIconProps {
    variant?: ListItemIconVariantType;
    classes?: string;
    icon?: JSX.Element;
}
export const ListItemIcon = ({
    icon,
    variant = ViewVariantType.SUCCESS,
    classes = '',
}: ListItemIconProps): JSX.Element => {
    const { ConfirmCheckWhiteIcon } = useResources();
    if (!ViewVariantType.isValid(variant)) {
        throw new TypeError('Invalid view variant');
    }

    const containerClasses = `list-item-icon ${classes} list-item-icon--${
        ViewVariantType.isValid(variant) ? variant : ViewVariantType.SUCCESS
    }`;

    const Icon = () => (icon ? icon : <ConfirmCheckWhiteIcon />);

    return (
        <span className={containerClasses}>
            <Icon />
        </span>
    );
};

export default ListItemIcon;
