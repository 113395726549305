import { MESSAGING } from 'core/datasets/action';
import { all, fork, takeEvery } from 'redux-saga/effects';
import { channelSelected } from './workers/channelSelected';
import { channelViewed } from './workers/channelViewed';
import { fetchChannelDataByKey } from './workers/fetchChannelDataByKey';
import { fetchChannelMessages } from './workers/fetchChannelMessages';
import { fetchChannelsList } from './workers/fetchChannelsList';
import { sendNewMessage } from './workers/sendNewMessage';

function* watchRequestChannels(): Generator {
    yield takeEvery(MESSAGING.REQUEST_CHANNELS, fetchChannelsList);
}

function* watchNewChannelKeys(): Generator {
    yield takeEvery(MESSAGING.ADD_CHANNEL_KEY, fetchChannelDataByKey);
}

function* watchSendNewMessage(): Generator {
    yield takeEvery(MESSAGING.SEND_NEW_MESSAGE, sendNewMessage);
}

function* watchFetchNewMessages(): Generator {
    yield takeEvery(MESSAGING.FETCH_CHANNEL_MESSAGES, fetchChannelMessages);
}

function* watchChannelSelected(): Generator {
    yield takeEvery(MESSAGING.SELECT_CHANNEL, channelSelected);
}

function* watchChannelViewed(): Generator {
    yield takeEvery(MESSAGING.CHANNEL_VIEWED, channelViewed);
}

export function* messagingWatchers(): Generator {
    yield all([
        fork(watchRequestChannels),
        fork(watchSendNewMessage),
        fork(watchNewChannelKeys),
        fork(watchFetchNewMessages),
        fork(watchChannelSelected),
        fork(watchChannelViewed),
    ]);
}
