import { Capacitor } from '@capacitor/core';
import { getApp } from 'firebase/app';
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';

export function getFirebaseAuth(): Auth {
    let auth;
    if (Capacitor.isNativePlatform()) {
        const app = getApp();
        auth = initializeAuth(app, {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        auth = getAuth();
    }
    return auth;
}
