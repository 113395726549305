import GraphQLClient from 'core/classes/GraphQLClient';
import WebClient from 'core/classes/WebClient';
import WebClientConfig from 'core/classes/WebClientConfig';
import { gqlUri } from 'core/config/gql/client';
import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { changeFieldValue, startLogin } from 'tenantUser/redux/authActions';

export const useLoginFormData: LoginFormDataHook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [termsChecked, setTermsChecked] = useState(false);

    const handleTerms = () => {
        setTermsChecked((prev) => !prev);
    };

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(changeFieldValue(event));
        },
        [dispatch],
    );

    const handleLogin = () => {
        const config = new WebClientConfig({
            baseURL: gqlUri,
        });

        const client = new GraphQLClient(new WebClient(config));

        dispatch(startLogin({ client, navigate }));
    };

    const handleKeyEnterPressed = ({ nativeEvent }: KeyboardEvent<HTMLInputElement>) => {
        if (!!nativeEvent && 'key' in nativeEvent && 'Enter' === nativeEvent.key) handleLogin();
    };

    return {
        handleChange,
        handleLogin,
        handleTerms,
        termsChecked,
        handleKeyEnterPressed,
    };
};
export default useLoginFormData;

type LoginFormDataHook = () => LoginFormDataReturn;
type LoginFormDataReturn = {
    handleChange: HandleEvent;
    termsChecked: boolean;
    handleTerms: () => void;
    handleLogin: () => void;
    // userData: UserData;
    handleKeyEnterPressed: (event: KeyboardEvent<HTMLInputElement>) => void;
};
type HandleEvent = (event: ChangeEvent<HTMLInputElement>) => void;
