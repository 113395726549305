import { ThemeProvider } from '@material-ui/core';
import { AppRoot } from 'appRoot';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ResourceContext } from 'resources/context';
import { resources } from 'resources/vahi';
import theme from 'resources/vahi/theme';
import { ConfigContext } from 'resources/config';

import './resources/vahi/theme.css';
import './resources/vahi/styles.css';
import './resources/vahi/addition.css';
import { vahiConfig } from './resources/vahi/vahiConfig';

window.addEventListener('load', () => {
    ReactDOM.render(
        <ResourceContext.Provider value={resources}>
            <ConfigContext.Provider value={vahiConfig}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <AppRoot />
                    </BrowserRouter>
                </ThemeProvider>
            </ConfigContext.Provider>
        </ResourceContext.Provider>,
        document.getElementById('root'),
    );
});
