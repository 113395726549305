export class AgreementChangeRequestType {
    static EXTENSION = 'extension';
    static TERMINATION = 'termination';

    static isExtension(value: unknown): boolean {
        return typeof value === 'string' && /extension/.test(value);
    }

    static isTermination(value: unknown): boolean {
        return typeof value === 'string' && /termination/.test(value);
    }
}
