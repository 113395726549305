import useWindowSize from '../../../hooks/useWindowSize';
import { DatePicker } from '@material-ui/pickers';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FormDatePickerProps } from 'core/types/formDatePicker';
import { APP_LUX_DATE_FORMAT } from 'core/utils/datesHelper';
import { DatePickerView } from '@material-ui/pickers/DatePicker/DatePicker';

/**
 * Material UI date picker, should be called only within FormContainer wrapper, that provides required context
 * @param classes
 * @param label
 * @param value
 * @param onChange
 * @param minDate
 * @param maxDate
 * @param clearable
 * @param isRequired
 * @param isDisabled
 * @param variant
 * @param format
 * @param disablePast
 * @param disableToolbar
 * @param helperText
 * @param hasError
 * @param openTo
 * @constructor
 */
const FormDatePicker = ({
    classes,
    label,
    value,
    onChange,
    minDate,
    maxDate,
    clearable,
    isRequired = false,
    isDisabled = false,
    variant = 'outlined',
    format = APP_LUX_DATE_FORMAT,
    disablePast = true,
    disableToolbar = true,
    helperText = '',
    hasError = false,
    openTo = 'date',
    name,
}: FormDatePickerProps): JSX.Element => {
    const { isMobileWidth } = useWindowSize();
    const datePickerVariant = isMobileWidth ? 'dialog' : 'inline';
    return (
        <DatePicker
            clearable={isMobileWidth ? clearable : undefined}
            error={hasError}
            helperText={helperText}
            disabled={isDisabled}
            required={isRequired}
            disablePast={disablePast}
            className={classes}
            autoOk
            disableToolbar={disableToolbar}
            variant={datePickerVariant}
            inputVariant={variant as TextFieldProps['variant']}
            label={label}
            value={value}
            onChange={(date) => onChange(date)}
            format={format}
            minDate={!!minDate ? minDate : undefined}
            maxDate={!!maxDate ? maxDate : undefined}
            views={['year', 'month', 'date']}
            openTo={openTo as DatePickerView}
            name={name}
            role={'widget'}
        />
    );
};
export default FormDatePicker;
