import TempInvoiceState from 'invoice/types/redux/TempInvoiceState';
import {
    InvoicePdfUrlGenerateAction,
    SelectedInvoiceIdStoreAction,
    StoreInvoicePdfAction,
    TempInvoiceStateActions,
} from 'invoice/types/redux/TempInvoiceStateActions';
import { INVOICE, INVOICE_TEMP } from 'core/datasets/action';

const initState: TempInvoiceState = {
    loading: false,
    initialLoading: false,
    firstDataLoaded: false,
    currentInvoiceId: null,
    pdf: null,
    url: null,
};

export const tempInvoicesReducer = (state = initState, action: TempInvoiceStateActions): TempInvoiceState => {
    switch (action.type) {
        case INVOICE.FETCH_ALL_INVOICES: {
            return {
                ...state,
                loading: true,
                initialLoading: !state.firstDataLoaded,
            };
        }
        case INVOICE.ALL_INVOICES_RECEIVED: {
            return {
                ...state,
                loading: false,
                initialLoading: false,
                firstDataLoaded: true,
            };
        }
        case INVOICE_TEMP.PDF_DATA_STORED: {
            const { payload: pdfData } = action as StoreInvoicePdfAction;

            if (!pdfData) {
                return state;
            }

            return {
                ...state,
                pdf: pdfData,
            };
        }
        case INVOICE_TEMP.SELECTED_INVOICE_ID_STORED: {
            const { payload: id } = action as SelectedInvoiceIdStoreAction;

            return state.currentInvoiceId !== id
                ? {
                      ...state,
                      currentInvoiceId: id,
                  }
                : state;
        }
        case INVOICE_TEMP.PDF_URL_GENERATED: {
            const { payload } = action as InvoicePdfUrlGenerateAction;

            return {
                ...state,
                url: payload,
            };
        }
        case INVOICE_TEMP.SELECTED_INVOICE_TEMP_DATA_DROPPED: {
            return {
                ...state,
                currentInvoiceId: null,
                url: null,
                pdf: null,
            };
        }
        default:
            return state;
    }
};
