import { FILTER, INVOICE, INVOICE_TEMP } from 'core/datasets/action';
import { fetchInvoicePdfData } from 'invoice/saga/workers/fetchInvoicePdfData';
import { handleOpeningInvoicePdfOnAndroidPlatform } from 'invoice/saga/workers/handleOpeningInvoicePdfOnAndroidPlatform';
import { handleReceivedInvoicePdf } from 'invoice/saga/workers/handleReceivedInvoicePdf';
import { all, fork, takeEvery, takeLeading } from 'redux-saga/effects';
import { fetchAllInvoices } from './workers/fetchAllInvoices';
import { handleReceivedInvoices } from './workers/handleReceivedInvoices';

function* watchCallToFetchAllAgreements(): Generator {
    yield takeLeading(INVOICE.FETCH_ALL_INVOICES, fetchAllInvoices);
}

function* watchReceivedAgreements(): Generator {
    yield takeEvery(INVOICE.ALL_INVOICES_RECEIVED, handleReceivedInvoices);
}

function* watchInvoiceFilterChanged(): Generator {
    yield takeEvery(FILTER.CHANGE_FILTER, fetchAllInvoices);
    yield takeEvery(FILTER.RESET_FILTER_GROUP, fetchAllInvoices);
}

function* watchInvoicePdfRequest(): Generator {
    yield takeEvery(INVOICE_TEMP.REQUEST_PDF, fetchInvoicePdfData);
}

function* watchReceivedInvoicePdf(): Generator {
    yield takeEvery(INVOICE_TEMP.PDF_DATA_RECEIVED, handleReceivedInvoicePdf);
}

function* watchOpenInvoicePdfActions(): Generator {
    yield takeEvery(INVOICE_TEMP.PDF_FILE_OPENED_ON_ANDROID_PLATFORM, handleOpeningInvoicePdfOnAndroidPlatform);
}

export function* invoiceWatchers(): Generator {
    yield all([
        fork(watchCallToFetchAllAgreements),
        fork(watchReceivedAgreements),
        fork(watchInvoiceFilterChanged),
        fork(watchInvoicePdfRequest),
        fork(watchReceivedInvoicePdf),
        fork(watchOpenInvoicePdfActions),
    ]);
}
