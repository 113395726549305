import { useEffect, useState } from 'react';

import { IconButton, Snackbar, SnackbarOrigin } from '@material-ui/core';

import './styles/AlertNotice.css';

export const AlertNotice: AlertNotice = ({
    level,
    text,
    open,
    headerText = '',
    autoHide = 10000,
    inline = false,
    onClose,
    alertIcon: AlertIcon,
    location,
    isClosable = true,
    hasOverlay = false,
}: AlertNoticeProps) => {
    const [opened, setOpened] = useState(open);
    const handleClose = () => {
        setOpened(false);
        onClose && onClose();
    };

    useEffect(() => {
        setOpened(open);

        if (!open) {
            onClose && onClose();
        }
    }, [open]);

    return (
        <>
            {open && (
                <div className="alert-notice-snack__container">
                    {hasOverlay && <div className={'alert-notice-snack__overlay'} />}
                    <Snackbar
                        className={'alert-notice-snack ' + (inline ? ' inline-snack' : '')}
                        open={opened}
                        autoHideDuration={autoHide}
                        onClose={isClosable ? handleClose : undefined}
                        anchorOrigin={location || { vertical: 'bottom', horizontal: 'center' }}
                    >
                        <div
                            className={
                                'alert-notice-snack-alert__container  alert-notice-snack-alert__' + level.toLowerCase()
                            }
                        >
                            <div className={`alert-notice-snack-alert__icon-container${!AlertIcon ? '--hidden' : ''}`}>
                                <>{AlertIcon && AlertIcon}</>
                            </div>
                            <div className="alert-notice-snack-alert__text-container">
                                {headerText && (
                                    <span className={'alert-notice-snack-alert__message-title'}>{headerText}</span>
                                )}
                                <span className="alert-notice-snack-alert__message">{text}</span>
                            </div>
                            {isClosable && (
                                <a className="alert-notice-snack-alert__btn" onClick={handleClose}>
                                    <IconButton
                                        size="small"
                                        className="bta-icon-times alert-notice-snack-alert__btn-close"
                                    />
                                </a>
                            )}
                        </div>
                    </Snackbar>
                </div>
            )}
        </>
    );
};
export default AlertNotice;

type AlertNotice = (props: AlertNoticeProps) => JSX.Element;
export type AlertNoticeProps = {
    level: AlertLevel;
    text: string;
    headerText?: string;
    open?: boolean;
    inline?: boolean;
    autoHide?: number | null;
    onClose?: () => void;
    alertIcon?: JSX.Element;
    location?: SnackbarOrigin;
    isClosable?: boolean;
    hasOverlay?: boolean;
};
export type AlertLevel = 'ERROR' | 'WARNING' | 'SUCCESS' | 'INFO';
