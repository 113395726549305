import { useTranslation } from 'react-i18next';
import { Grid, InputLabel, MenuItem, TextField } from '@material-ui/core';
import IRentalObjectSelect from 'rentalObject/types/IRentalObjectSelect';
import { LoadingIndicator } from 'core/components/LoadingIndicator';

import './styles/RentalObjectSelect.css';

const AgreementObjectSelect = ({
    selectedObjectId,
    agreementObjects,
    handleSelect,
    loading,
}: IRentalObjectSelect): JSX.Element => {
    const { t } = useTranslation();

    const ObjectItems: JSX.Element[] = [];

    const hasAgreementObjects = !!(agreementObjects && Object.values(agreementObjects).length);

    if (hasAgreementObjects) {
        for (const [id, object] of Object.entries(agreementObjects)) {
            ObjectItems.push(
                <MenuItem value={id} key={id}>
                    {object.name}
                </MenuItem>,
            );
        }
    }

    return (
        <Grid className="select-rental-object-container" item xs={12}>
            <InputLabel id="select-rental-object-label">
                {t('documents.meterReadings.rentalObjectFilterLabel')}
            </InputLabel>

            <TextField
                translate="no"
                id="select-rental-object"
                className="select-rental-object"
                variant="outlined"
                value={selectedObjectId || '0'}
                onChange={handleSelect}
                disabled={!hasAgreementObjects || Object.values(agreementObjects).length < 2}
                select
            >
                <MenuItem key="0" disabled={true} value="0">
                    {hasAgreementObjects ? t('notices.selectRentalObject') : t('notices.noRentalObjectsFound')}
                </MenuItem>

                {ObjectItems}

                {loading && (
                    <MenuItem disabled={true} value="0">
                        <LoadingIndicator />
                    </MenuItem>
                )}
            </TextField>
        </Grid>
    );
};
export default AgreementObjectSelect;
