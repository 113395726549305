import AppVersion from 'core/components/AppVersion';
import { AppState } from 'core/redux/rootReducer';
import AgreementObjectSelectContainer from 'pages/Settings/components/AgreementObjectSelectContainer';
import AgreementStatusContainer from 'pages/Settings/components/AgreementStatusContainer';
import { useSelector } from 'react-redux';
import { UserData } from 'tenantUser/types/user';
import LangSelect from '../../../../core/components/LangSelect';
import { useConfig } from '../../../../resources/config';
import DeleteAccountLink from './DeleteAccountLink';
import useSelectedTenant from './hooks/useSelectedTenant';
import { PrivacyPolicyLinkBidrento, PrivacyPolicyLinkEften, PrivacyPolicyLinkTTU } from './PrivacyPolicyLinkBidrento';
import SettingsListItemField from './SettingsListItemField';
import SettingsListTTU from './SettingsListTTU';

const SettingsList = (): JSX.Element => {
    const user = useSelector(({ auth }: AppState) => auth.user) as UserData;
    const isMobileWidth = useSelector(({ core }: AppState) => core.isMobileWidth);
    const { appType } = useConfig();

    const selectedTenant = useSelectedTenant();
    const nameAndEmail = `${user.firstName} (${user.email})`;
    const viewAdditionalData = appType === 'ecampus' && selectedTenant;

    return (
        <div className="settings-list">
            {viewAdditionalData ? (
                <SettingsListTTU user={user} tenant={selectedTenant} />
            ) : (
                <SettingsListItemField value={nameAndEmail} label={'User'} />
            )}
            {!isMobileWidth && (
                <div className="settings-list__item">
                    <AgreementObjectSelectContainer />
                    <AgreementStatusContainer />
                </div>
            )}
            <div className="settings-list__item">
                <LangSelect />
            </div>

            <PrivacyPolicyLinkBidrento />

            {appType === 'ecampus' && <PrivacyPolicyLinkTTU />}
            {appType === 'eften' && <PrivacyPolicyLinkEften />}

            <DeleteAccountLink />

            <AppVersion />
        </div>
    );
};
export default SettingsList;
