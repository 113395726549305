import Agreement from 'agreement/classes/Agreement';
import { AgreementType } from 'agreement/classes/AgreementType';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { DateTime } from 'luxon';

export interface AgreementStatusProps {
    agreement: IAgreementInstanceData | null;
    objectId: number | null;
}

export class AgreementStatus {
    static isActive({ agreement, objectId }: AgreementStatusProps): boolean {
        if (!agreement) return false;

        if (AgreementType.isSingleObject(agreement.type) || (agreement?.objects && agreement?.objects.length < 2))
            return Agreement.isActive(agreement);

        let objectAgreement = null;

        if (objectId) {
            objectAgreement = agreement.objects.find((_object) => _object.id === objectId);
            return !!objectAgreement?.agreement && Agreement.isActive(objectAgreement?.agreement);
        }

        for (const object of agreement.objects) {
            if (object.agreement && Agreement.isActive(object.agreement)) {
                return true;
            }
        }

        return false;
    }

    static getPeriodStartDate({ agreement, objectId }: AgreementStatusProps): string | null {
        if (!agreement) return null;

        if (AgreementType.isSingleObject(agreement)) return agreement?.agreementDate || null;

        if (agreement?.objects && agreement?.objects.length < 2) {
            return agreement?.agreementDate || null;
        }

        if (!objectId) return null;

        return agreement?.objects?.filter((object) => object?.id === objectId)[0]?.agreement?.agreementStart || null;
    }

    static getPeriodStartDateTime({ agreement, objectId }: AgreementStatusProps): DateTime | null {
        const date = AgreementStatus.getPeriodStartDate({ agreement, objectId });

        return date !== null && DateTime.fromSQL(date).isValid ? DateTime.fromSQL(date) : null;
    }

    static getPeriodEndDate({ agreement, objectId }: AgreementStatusProps): string | null {
        if (!agreement) return null;

        if (AgreementType.isSingleObject(agreement)) return agreement?.agreementEnd || null;

        if (agreement?.objects && agreement?.objects.length < 2) {
            return agreement?.agreementEnd || null;
        }

        if (!objectId) return null;

        return (
            agreement?.objects?.filter((object) => {
                return object?.id === objectId;
            })[0]?.agreement?.agreementEnd || null
        );
    }
}
