import React, { LazyExoticComponent } from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';

interface ProtectedProps {
    Component: LazyExoticComponent<React.ComponentType> | React.FC;
}

export const Protected = ({ Component }: ProtectedProps): JSX.Element => {
    const { isLoggedIn } = useSelector(({ auth }: AppState) => auth);
    const location = useLocation();

    return isLoggedIn ? (
        <Component />
    ) : (
        <Navigate
            to="/login"
            state={{
                from: location,
            }}
        />
    );
};
