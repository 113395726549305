import MenuButton from '../MenuButton';

interface MenuInlineLabelButtonProps {
    classes?: string;
    children: JSX.Element;
    label: string;
    onClick?: () => void;
    iconColor?: 'secondary' | 'primary' | 'default';
}
const InlineLabelMenuButton = ({
    children,
    label,
    onClick,
    iconColor,
    classes = '',
}: MenuInlineLabelButtonProps): JSX.Element => {
    const buttonArgs = {
        classes,
        labelText: label,
        baseClass: 'inline-label-menu-button',
        onClick: onClick,
        iconColor,
    };
    return <MenuButton {...buttonArgs}>{children}</MenuButton>;
};
export default InlineLabelMenuButton;
