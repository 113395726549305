/**
 * Icon names
 * - part of a corresponding css class (.bta-icon-____) for loading icon from font-family: 'bta icons';
 * - defined in index.css
 */
export const ICON_ID = {
    SETTINGS: 'settings-gear',
    INVOICES: 'wallet',
    RENTAL_AGREEMENTS: 'files',
    MESSAGES: 'message',
    SEND_MESSAGE: 'send-email',
    SMART_HOME: 'home-with-remote',
    DOOR_CODE: 'door-code',
    INSURANCE: 'shield',
    EXTRA_SERVICES: 'wrench',
    METER_READINGS: 'analytics-graph-bar',
    DASHBOARD: 'home',
    FILES: 'files',
    MENU_TOGGLE: 'menu',
    TIPS_AND_TRICKS: 'tipsandtricks',
    CO2_METERS: 'co2-meters',
    LOGOUT: 'log-out',
};
