import { gql } from '@apollo/client';

export const createMessagingTenantLandlordChannel = gql`
    mutation createMessagingTenantLandlordChannel($data: CreateMessagingTenantLandlordChannelInput!) {
        createMessagingTenantLandlordChannel(data: $data) {
            id
        }
    }
`;

export const sendMessagingChannelMessage = gql`
    mutation sendMessagingChannelMessage($data: SendMessagingChannelMessageInput!) {
        sendMessagingChannelMessage(data: $data) {
            id
        }
    }
`;

export const updateMessagingLastActivity = gql`
    mutation updateMessagingLastActivity($data: UpdateMessagingLastActivityInput!) {
        updateMessagingLastActivity(data: $data) {
            lastActivityTime
        }
    }
`;
