import { ACTION, Inception } from 'core/datasets/action';
import { CREATE_INCEPTION_ACTIVITY } from '../saga/workers';
import { AnyAction } from 'redux';
import { InceptionStatusApiResponse, InceptionUnit } from '../types/inception';

const inceptionCreateActivity = (data: { state: unknown; uuid: string }): AnyAction => {
    return {
        type: ACTION.CORE.MAKE_GRAPHQL_REQUEST,
        payload: {
            query: CREATE_INCEPTION_ACTIVITY,
            variables: {
                data,
            },
        },
    };
};

const inceptionStatusUpdated = (payload: InceptionStatusApiResponse): AnyAction => {
    return {
        type: Inception.UNIT_STATUS_RECEIVED,
        payload,
    };
};

const inceptionUnitListFetched = (units: InceptionUnit[]): AnyAction => {
    return { type: Inception.UNIT_LIST_FETCHED, payload: units };
};

export const inceptionActions = {
    activity: inceptionCreateActivity,
    statusUpdated: inceptionStatusUpdated,
    unitsFetched: inceptionUnitListFetched,
};
