import RequestStatus from 'core/classes/RequestStatus';
import { fork, put } from 'redux-saga/effects';
import LarvaQueuedRequest from 'smartHome/larva/classes/LarvaQueuedRequest';
import { larvaWSActions } from 'smartHome/larva/redux/actions/larvaWSActions';
import { HandleQueuePendingRequestAction } from 'smartHome/larva/types/redux/larvaWsActions';

export function* handleQueuedRequests({ payload: requests }: HandleQueuePendingRequestAction): Generator {
    for (const request of requests) {
        yield fork(tryRequestAgain, request);
    }
}

export function* tryRequestAgain(queue: LarvaQueuedRequest): Generator {
    queue.status = RequestStatus.PENDING;
    if (queue.type === LarvaQueuedRequest.NODE_OUTPUT) {
        yield put(larvaWSActions.handleNodeOutput(queue));
        return;
    }

    yield put(larvaWSActions.handleNodeRequest(queue));
}
