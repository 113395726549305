import { Capacitor } from '@capacitor/core';
import { WindowSize } from 'core/classes/WindowSize';
import { CORE } from 'core/datasets/action';
import CoreState, { IsMobileWidthSelector } from 'core/types/redux/CoreState';
import { CoreStateActions } from 'core/types/redux/CoreStateActions';
import { IDashboardNotificationData } from '../../classes/DashboardNotification';

const initState: CoreState = {
    networkStatus: {
        isConnected: true,
        connectionType: 'unknown',
    },
    isAppActive: true,
    loading: {
        doorAccesses: false,
    },
    error: {
        doorAccesses: '',
    },
    isFullViewMode: false,
    isMobileWidth: false,
    windowSizeRange: Capacitor.isNativePlatform()
        ? WindowSize.BREAKPOINT_XS_RANGE_NAME
        : WindowSize.BREAKPOINT_LG_RANGE_NAME,
    slidePanel: {
        isOpen: false,
    },
    notification: null,
    deviceErrors: [],
    dashboardNotifications: [],
};

export const coreReducer = (state = initState, action: CoreStateActions): CoreState => {
    switch (action.type) {
        case CORE.IS_MOBILE_WIDTH_STATE_CHANGED: {
            return {
                ...state,
                isMobileWidth: action.payload,
            };
        }
        case CORE.INIT_NETWORK_STATUS:
        case CORE.NETWORK_STATUS_CHANGED: {
            return {
                ...state,
                networkStatus: action.payload,
            };
        }
        case CORE.APP_ACTIVE_STATUS_CHANGED: {
            return {
                ...state,
                isAppActive: action.payload,
            };
        }
        case CORE.COMPONENT_STARTED_LOADING: {
            const componentId = action.payload;
            return {
                ...state,
                loading: { [componentId]: true },
            };
        }
        case CORE.COMPONENT_FINISHED_LOADING: {
            const componentId = action.payload;
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [componentId]: false,
                },
            };
        }
        case CORE.SET_COMPONENT_ERROR: {
            const { error, componentId } = action.payload;

            return {
                ...state,
                error: {
                    ...state.error,
                    [componentId]: error,
                },
            };
        }
        case CORE.RESET_COMPONENT_ERROR: {
            const componentId = action.payload;

            return {
                ...state,
                error: {
                    ...state.error,
                    [componentId]: '',
                },
            };
        }
        case CORE.FULL_VIEW_MODE_CHANGED: {
            return {
                ...state,
                isFullViewMode: action.payload,
            };
        }
        case CORE.SLIDE_PANEL_OPEN_STATE_CHANGED: {
            return {
                ...state,
                slidePanel: {
                    ...state.slidePanel,
                    isOpen: !state.slidePanel.isOpen,
                },
            };
        }
        case CORE.SET_NOTIFICATION: {
            return {
                ...state,
                notification: action.payload,
            };
        }
        case CORE.CLEAR_NOTIFICATION: {
            return {
                ...state,
                notification: null,
            };
        }
        case CORE.DEVICE_ADD_ERROR: {
            return {
                ...state,
                deviceErrors: [...state.deviceErrors, action.payload],
            };
        }
        case CORE.DEVICE_REMOVE_ERROR: {
            if (!state.deviceErrors.length) return state;
            const filtered = state.deviceErrors.filter((item) => item.fullId !== action.payload.fullId);
            return filtered.length === state.deviceErrors.length
                ? state
                : {
                      ...state,
                      deviceErrors: filtered,
                  };
        }
        case CORE.PUSH_DASHBOARD_NOTIFICATION: {
            // Due to lack of understanding how overall system SHOULD work,  and also not clear why rental agreement is
            // reselected out of blue, for now filter out notifications of certain type 'No rental agreement'
            const isNoRentalAgreement = (notification: IDashboardNotificationData) =>
                notification.message === 'rentalObject.agreementStatus.missingActiveAgreements';

            if (state.dashboardNotifications.some(isNoRentalAgreement)) {
                return { ...state };
            }
            return {
                ...state,
                dashboardNotifications: [...state.dashboardNotifications, action.payload],
            };
        }
        case CORE.CLEAR_DASHBOARD_NOTIFICATION: {
            return {
                ...state,
                dashboardNotifications: [],
            };
        }
        case CORE.WINDOW_WIDTH_RANGE_CHANGED: {
            return {
                ...state,
                windowSizeRange: action.payload,
            };
        }
        default:
            return state;
    }
};

export const isMobileWidthSelector: IsMobileWidthSelector = (state) => state.core.isMobileWidth;
