import axios, { AxiosResponse } from 'axios';
import WebClientConfig from 'core/classes/WebClientConfig';
import ClientType from 'core/classes/ClientType';
import IWebClient from 'core/types/IWebClient';
import RequestMethodType from 'core/classes/RequestMethodType';

class WebClient implements IWebClient {
    private _type = ClientType.WEB_CLIENT;

    private _config?: WebClientConfig;

    constructor(config: WebClientConfig) {
        this.config = config;
    }

    // url param in the axios options
    private static isValidUrl(value: unknown): boolean {
        return !!value && typeof value === 'string' && !!value.trim().length;
    }

    // data param in the axios options
    private static isValidPayload(value: unknown): boolean {
        switch (typeof value) {
            case 'object': {
                if (!value) return false;

                return Array.isArray(value) ? !!value?.length : !!Object.keys(value)?.length;
            }
            case 'function':
            case 'symbol': {
                return false;
            }
            default: {
                return !!value;
            }
        }
    }

    get type(): string {
        return this._type;
    }

    get config(): WebClientConfig {
        if (this._config) {
            return this._config;
        }
        throw new ReferenceError('Undefined config');
    }

    set config(value: unknown) {
        if (value instanceof WebClientConfig) {
            this._config = value;
            return;
        }

        throw new TypeError('Invalid config');
    }

    async get(url: unknown): Promise<AxiosResponse> {
        if (WebClient.isValidUrl(url)) {
            return axios({
                ...this.config.all,
                url: url as string,
                method: RequestMethodType.GET,
            });
        }

        throw new TypeError('Invalid url');
    }

    async delete(url: unknown): Promise<AxiosResponse> {
        if (WebClient.isValidUrl(url)) {
            return axios({
                ...this.config.all,
                url: url as string,
                method: RequestMethodType.DELETE,
            });
        }

        throw new TypeError('Invalid url');
    }

    async post(url: unknown, payload: unknown): Promise<AxiosResponse> {
        if (!WebClient.isValidUrl(url)) {
            throw new TypeError('Invalid url');
        }

        if (!WebClient.isValidPayload(payload)) {
            throw new TypeError('Invalid payload');
        }

        return axios({
            ...this.config.all,
            url: url as string,
            method: RequestMethodType.POST,
            data: payload,
        });
    }

    async postToBaseURL(payload: unknown): Promise<AxiosResponse> {
        if (!WebClient.isValidPayload(payload)) {
            throw new TypeError('Invalid payload');
        }

        return axios({
            ...this.config.all,
            method: RequestMethodType.POST,
            data: payload,
        });
    }

    async patch(url: unknown, payload: unknown): Promise<AxiosResponse> {
        if (!WebClient.isValidUrl(url)) {
            throw new TypeError('Invalid url');
        }

        if (!WebClient.isValidPayload(payload)) {
            throw new TypeError('Invalid payload');
        }

        return axios({
            ...this.config.all,
            url: url as string,
            method: RequestMethodType.PATCH,
            data: payload,
        });
    }
}
export default WebClient;
