import { FiltersState } from 'core/types/redux/filtersState';
import { FILTER as FILTER_ACTION } from '../../datasets/action';
import FILTER, { FILTER_GROUP } from '../../datasets/filter';
import { FilterActions } from 'core/types/redux/filterActions';
import { Filter as FilterType, FilterGroupType, FilterValueType } from '../../types/filter';
import { getDefaultValuesForGroup, hasEnabled, setDefaultValuesForGroup } from 'core/utils/useFiltersHelper';
import { getValueFromStorage } from 'core/utils/localStorage';
import PAGE_CONTEXT from '../../datasets/page_context';

const defaultInvoicesFilterState: FilterType = {
    isEnabled: false, // enabled only if one of its values is false
    name: FILTER.INVOICES,
    resetAllKey: 'all', // key in values that is used for resetting all filter's values to default, disable filter
    groups: {
        [FILTER_GROUP.STATUS]: {
            all: true,
            paid: true,
            notPaid: true,
        },
    },
};

const defaultServicesFilterGroupValues = (group: (typeof FILTER_GROUP)[keyof typeof FILTER_GROUP]) => {
    const serviceRequests: { [key: string]: string | number } | string =
        (getValueFromStorage && getValueFromStorage(PAGE_CONTEXT.SERVICE_REQUESTS)) || '';
    return getDefaultValuesForGroup(group, serviceRequests);
};

const defaultServicesFilterState: FilterType = {
    isEnabled: false, // enabled only if any of group values' is false
    name: FILTER.SERVICE_REQUESTS,
    resetAllKey: 'all', // key in values that is used for resetting all filter's values
    groups: {
        [FILTER_GROUP.STATUS]: defaultServicesFilterGroupValues(FILTER_GROUP.STATUS),
        [FILTER_GROUP.TYPE_ID]: defaultServicesFilterGroupValues(FILTER_GROUP.TYPE_ID),
    },
};

export const initialState: FiltersState = {
    [FILTER.INVOICES]: { ...defaultInvoicesFilterState },
    [FILTER.SERVICE_REQUESTS]: Object.freeze({ ...defaultServicesFilterState }),
    serviceRequestTypeTitlesMap: {},
};

export const filtersReducer = (state = initialState, action: FilterActions): FiltersState => {
    defaultServicesFilterGroupValues(FILTER_GROUP.TYPE_ID);
    switch (action.type) {
        case FILTER_ACTION.CHANGE_FILTER: {
            const currentFilter: FilterType = state[action.payload.name as keyof FiltersState];
            const groups = {
                ...currentFilter.groups,
                ...action.payload.groups,
            };
            return {
                ...state,
                [action.payload.name]: {
                    ...currentFilter,
                    isEnabled: hasEnabled(groups),
                    groups: groups,
                },
            };
        }
        case FILTER_ACTION.RESET_FILTER_GROUP: {
            const { filterGroupName, context } = action.payload;

            const currentFilter: FilterType = state[context as keyof FiltersState];
            const currentFilterGroups: FilterGroupType = currentFilter.groups;
            const currentFilterGroupValues: FilterValueType = currentFilterGroups[filterGroupName];

            const defaultValues = setDefaultValuesForGroup({
                defaultValue: true,
                groupValues: currentFilterGroupValues,
            });

            const groups = {
                ...currentFilterGroups,
                [filterGroupName]: defaultValues,
            };

            return {
                ...state,
                [context]: {
                    ...currentFilter,
                    isEnabled: hasEnabled(groups),
                    groups: groups,
                },
            };
        }
        case FILTER_ACTION.SET_FILTER_GROUPS: {
            const { context, groups } = action.payload;
            const currentFilter: FilterType = state[context as keyof FiltersState];
            return {
                ...state,
                [context]: {
                    ...currentFilter,
                    groups: groups,
                },
            };
        }

        case FILTER_ACTION.SET_SERVICE_REQUEST_TYPES_MAP: {
            return {
                ...state,
                serviceRequestTypeTitlesMap: { ...state.serviceRequestTypeTitlesMap, ...action.payload },
            };
        }

        default:
            return state;
    }
};
