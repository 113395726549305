import { IconButton } from '@material-ui/core';
import { ActionIconButtonProps } from '../../../types/ActionIconButtonProps';

const ActionIconButton = ({
    onClick,
    onBlur,
    onFocus,
    Icon,
    classes = '',
    isActive = true,
    isEnabled = true,
    isHidden = false,
}: ActionIconButtonProps): JSX.Element | null => {
    const baseClass = 'action-button';
    const stateClasses = `${isActive ? '' : baseClass + '--inactive'} ${
        isEnabled ? '' : baseClass + '--disabled'
    }`.trim();
    const baseClasses = `${baseClass} ${stateClasses}`.trim();
    const _classes = `${baseClasses} ${classes}`.trim();
    const _iconClasses = _classes
        .split(' ')
        .map((_class) => `${_class}__icon`)
        .join(' ')
        .trim();
    return isHidden ? null : (
        <IconButton className={_classes} onFocus={onFocus} onBlur={onBlur} onClick={onClick} disabled={!isEnabled}>
            <Icon className={_iconClasses} />
        </IconButton>
    );
};

export default ActionIconButton;
