import Serializable from 'core/types/Serialisable';
import { DateTime } from 'luxon';

export interface InvoicePdfProps {
    content: string;
    name: string;
    file: PdfFileData | null;
    mimetype: string;
    invoiceId: number;
}

interface PdfFileData {
    url: string;
    lifetime: number;
}

export interface InvoicePdfInstanceData {
    content: string;
    name: string;
    fileUrl: string | null;
    mimeType: string;
    fileLifetime: number | null;
    receivedAt: number;
    invoiceId: number;
}

export class InvoicePdf implements Serializable {
    get mimeType(): string {
        return this._mimeType;
    }

    set mimeType(value: string) {
        this._mimeType = value;
    }
    get invoiceId(): number {
        if (this._invoiceId && typeof this._invoiceId === 'number') {
            return this._invoiceId;
        }

        throw new TypeError('[InvoicePdf.get]: invalid invoiceId');
    }

    set invoiceId(value: unknown) {
        if (value && typeof value === 'number') {
            this._invoiceId = value;
            return;
        }

        throw new TypeError('[InvoicePdf.set]: invalid invoiceId passed');
    }

    get file(): PdfFileData | null {
        return this._file;
    }

    set file(value: PdfFileData | null) {
        this._file = value;
    }
    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
    get content(): string {
        return this._content;
    }

    set content(value: string) {
        this._content = value;
    }
    get receivedAt(): number {
        return DateTime.now().toMillis();
    }

    private _mimeType = 'application/pdf"';

    private _content = '';
    private _name = '';
    private _file: null | PdfFileData = null;
    private _invoiceId: number | null = null;

    constructor({ content, name, file, invoiceId, mimetype }: InvoicePdfProps) {
        this.file = file;
        this.content = content;
        this.name = name;
        this.invoiceId = invoiceId;
        this.mimeType = mimetype;
    }

    get all(): InvoicePdfInstanceData {
        return {
            content: this.content,
            fileLifetime: this.file?.lifetime || null,
            fileUrl: this.file?.url || null,
            mimeType: this._mimeType,
            name: this.name,
            receivedAt: this.receivedAt,
            invoiceId: this.invoiceId,
        };
    }

    toJSON(): InvoicePdfInstanceData {
        return this.all;
    }

    static isLinkALive(receivedAt: number, lifetime: number): boolean {
        const receivedDateTime = DateTime.fromMillis(receivedAt);

        if (!receivedDateTime.isValid) {
            console.error('[InvoicePdf.isLinkALive]: Pdf receivedAt time is invalid');
            return false;
        }

        const networkDelayTime = 20; // possible network delays
        const deathDateTime = receivedDateTime.plus({ seconds: lifetime - networkDelayTime });

        return deathDateTime > DateTime.now();
    }
}
