import { AxiosError, AxiosResponse } from 'axios';
import ListViewDataItem from 'core/classes/ListViewDataItem';
import { LARVA, LARVA_DOOR_ACCESS } from 'core/datasets/action';
import { coreActions } from 'core/redux/actions/coreActions';
import { AppState } from 'core/redux/rootReducer';
import { handleErrorResponse } from 'core/saga/workers/handleErrorResponse';
import IListViewData from 'core/types/IListViewData';
import { apply, call, put, select } from 'redux-saga/effects';
import DoorAccess from 'smartHome/larva/classes/DoorAccess';
import DoorAccessType from 'smartHome/larva/classes/DoorAccessType';
import DoorCardRawData from 'smartHome/larva/classes/DoorCardRawData';
import DoorCodeRawData from 'smartHome/larva/classes/DoorCodeRawData';
import LarvaConnectionConfig from 'smartHome/larva/classes/LarvaConnectionConfig';
import LarvaDoorAccessController from 'smartHome/larva/classes/LarvaDoorAccessController';
import LarvaWebClient from 'smartHome/larva/classes/LarvaWebClient';
import { larvaDoorAccessActions } from 'smartHome/larva/redux/actions/larvaDoorAccessActions';
import IDoorCardRawData from 'smartHome/larva/types/doorAccess/IDoorCardRawData';
import IDoorCodeRawData from 'smartHome/larva/types/doorAccess/IDoorCodeRawData';
import ILarvaDoorAccess from 'smartHome/larva/types/doorAccess/ILarvaDoorAccess';
import { LarvaApiUnits } from 'smartHome/larva/types/larvaData';
import { UserData } from 'tenantUser/types/user';
import { fetchLarvaApiUnits } from '../../utils/fetchUnitDevicesMap';
import LarvaConnectionData from '../../types/LarvaConnectionData';

export const isCurrentUserTenantSelector = (state: AppState): boolean => {
    const selectedAgreement = state.agreements.selectedAgreement;
    const tenants = selectedAgreement?.tenants as UserData[];
    const user = state.auth.user as UserData;

    if (!tenants || !user || !user.email) {
        return false;
    }

    return tenants.some((tenant) => user.email === tenant.email);
};

export const objectHasLarvaSupportSelector = (state: AppState): boolean => {
    const selectedObject = state.agreementObjects.selectedObject;
    return !!(
        selectedObject &&
        selectedObject.smartHome &&
        selectedObject.smartHome.isAvailable &&
        selectedObject.smartHome.type === 'larva'
    );
};

export const connectionDataSelector = (state: AppState): LarvaConnectionData | null => {
    const selectedObject = state.agreementObjects.selectedObject;

    if (
        !selectedObject?.smartHome ||
        !selectedObject?.smartHome?.isAvailable ||
        selectedObject.smartHome.type !== 'larva' ||
        !selectedObject?.smartHome.organization_id ||
        !selectedObject?.smartHome.external_id
    ) {
        console.error('Selected object does not support larva smart home', { selectedObject });
        return null;
    }

    const selectedAgreement = state.agreements.selectedAgreement;
    if (!selectedAgreement) {
        console.error('No agreement selected');
        return null;
    }

    const token = state.auth.token;

    const { organization_id, external_id } = selectedObject.smartHome;

    return {
        token: token as string,
        orgId: organization_id,
        externalId: external_id,
        agreementUUID: selectedAgreement.uuid as string,
    };
};

// This function prepares connection configuration and emits events to fetch units/devices and door access
export function* fetchAllLarvaData(): Generator {
    yield put({ type: LARVA.API_UNITS_FETCH_STARTED });
    yield put({ type: LARVA.API_DOOR_ACCESS_FETCH_STARTED });
}

export function* fetchApiUnits(): Generator {
    const supportsLarva = (yield select(objectHasLarvaSupportSelector)) as boolean;
    if (!supportsLarva) {
        return;
    }

    const connectionData = (yield select(connectionDataSelector)) as LarvaConnectionData | null;
    if (!connectionData) {
        return;
    }

    try {
        const result = (yield call(fetchLarvaApiUnits, new LarvaConnectionConfig(connectionData))) as LarvaApiUnits;

        yield put({
            type: LARVA.PUT_API_UNITS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: LARVA.API_UNITS_REQUEST_FAILED,
            payload: error,
        });
    }
}

export function* changeDoorAccessRights(): Generator {
    const isCurrentUserTenant = yield select(isCurrentUserTenantSelector);
    yield put({
        type: LARVA_DOOR_ACCESS.CHANGED_RIGHT_TO_MANAGE_ACCESSES,
        payload: isCurrentUserTenant,
    });
}

export function* fetchDoorAccess(): Generator {
    const supportsLarva = (yield select(objectHasLarvaSupportSelector)) as boolean;
    if (!supportsLarva) {
        return;
    }

    yield call(changeDoorAccessRights);

    const doorAccess = (yield select((state: AppState) => state.larva.doorAccess.isAllowedToManage)) as boolean;
    if (!doorAccess) {
        return;
    }

    const connectionData = (yield select(connectionDataSelector)) as LarvaConnectionData | null;
    if (!connectionData) {
        return;
    }

    try {
        const webClient = new LarvaWebClient(new LarvaConnectionConfig(connectionData));
        const controller = new LarvaDoorAccessController(webClient);

        const cards = (yield call(fetchDoorCards, controller)) as { [p: string]: DoorCardRawData };
        const codes = (yield call(fetchDoorCodes, controller)) as { [p: string]: DoorCodeRawData };

        if (!cards || !codes) {
            return;
        }

        const tenantHasCardsOrCodes = Object.values(codes).length || Object.values(codes).length;

        if (tenantHasCardsOrCodes) {
            // @ts-ignore
            yield call(makeDoorAccessDataLists, { cards, codes });
        }
    } catch (error) {
        yield put(coreActions.device.error.notification('Failed to fetch access data or lists', error));
    }
}

function* makeDoorAccessDataLists(
    ...args: ({ [p: string]: DoorCardRawData } | { [p: string]: DoorCodeRawData })[]
): Generator {
    const list: IListViewData[] = [];
    const doorAccessMap: { [id: string]: ILarvaDoorAccess } = {};

    for (const [type, values] of Object.entries(args[0])) {
        for (const [id, access] of Object.entries(values)) {
            doorAccessMap[id] = new DoorAccess({
                ...(access as DoorCardRawData | DoorCodeRawData).all,
                accessType: /codes/i.test(type) ? DoorAccessType.CODE : DoorAccessType.CARD,
            }).data;

            list.push(new ListViewDataItem((access as DoorCardRawData | DoorCodeRawData).listViewData));
        }
    }

    yield put(larvaDoorAccessActions.storeDoorAccessListViewData(list));
    yield put(larvaDoorAccessActions.storeDoorAccessMapData(doorAccessMap));
}

function* fetchDoorCards(controller: LarvaDoorAccessController): Generator {
    const cards: { [cardId: string]: DoorCardRawData } = {};
    let cardsData: IDoorCardRawData[] = [];

    try {
        cardsData = (yield apply(controller, controller.getCards, [])) as IDoorCardRawData[];
    } catch (reason) {
        const response = (reason as AxiosError)?.response;
        yield call(handleErrorResponse, response as AxiosResponse);
        yield put(coreActions.device.error.notification('Failed to fetch cards', reason));
        return;
    }

    for (const data of cardsData as IDoorCardRawData[]) {
        const card = new DoorCardRawData(data);
        cards[card.id] = card;
    }

    yield put(larvaDoorAccessActions.storeDoorCards(cards));
    return cards;
}

function* fetchDoorCodes(controller: LarvaDoorAccessController): Generator {
    const codes: { [codeId: string]: DoorCodeRawData } = {};
    let codesRawData: IDoorCodeRawData[] = [];

    try {
        codesRawData = (yield apply(controller, controller.getCodes, [])) as IDoorCodeRawData[];
    } catch (reason) {
        yield put(coreActions.device.error.notification(`Failed to fetch codes`, reason));
        const response = (reason as AxiosError)?.response;
        yield call(handleErrorResponse, response as AxiosResponse);
        return;
    }

    for (const data of codesRawData as IDoorCodeRawData[]) {
        const code = new DoorCodeRawData(data);
        codes[code.id] = code;
    }

    yield put(larvaDoorAccessActions.storeDoorCodes(codes));
    return codes;
}
