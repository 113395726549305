import CountryCode from 'core/classes/CountryCode';
import Email from 'core/classes/Email';
import TelephoneNumber from 'core/classes/TelephoneNumber';
import DoorPinCode from 'smartHome/larva/classes/DoorPinCode';
import DoorCodeAccessFormData from 'smartHome/larva/types/doorAccess/DoorAccessFormData';
import DoorAccessType from 'smartHome/larva/classes/DoorAccessType';
import DoorCodeAccessPostData from 'smartHome/larva/types/doorAccess/DoorCodeAccessPostData';
import DoorCodePostData from 'smartHome/larva/types/doorAccess/DoorCodePostData';
import ILarvaDoorAccess from 'smartHome/larva/types/doorAccess/ILarvaDoorAccess';
import { v4 as uuid } from 'uuid';

class DoorAccess {
    private _id?: string;
    private _cardId?: string;
    private _pin?: DoorPinCode;
    private _firstname?: string;
    private _lastname?: string;
    private _email?: Email | null;
    private _phone?: TelephoneNumber;
    private _autoSchedulePinSMS?: boolean;
    private _autoSchedulePinEmail?: boolean;
    private _accessType?: string;
    private _countryCode?: CountryCode;
    private _rawPhone: string | undefined;
    private _isEditable?: boolean;
    private _accessPolicyRole: 'user' | 'master' = 'user';

    constructor({
        accessType,
        firstname,
        lastname,
        email,
        phone,
        card,
        pin,
        autoSchedulePinSMS,
        autoSchedulePinEmail,
        countryCode,
        id,
        externalId,
        accessPolicyRole,
    }: DoorCodeAccessFormData) {
        this.accessType = accessType;

        this.cardId = card;
        this.pin = pin;
        this.firstname = firstname;
        this.lastname = lastname;
        this.email = email;
        this.sendEmail = autoSchedulePinEmail;
        this.sendSms = autoSchedulePinSMS;
        this.id = id;

        this.countryCode = countryCode;
        this.phone = phone;

        this._rawPhone = phone as string;

        this.isEditable = !externalId;

        this.accessPolicyRole = accessPolicyRole;
    }

    get accessPolicyRole(): 'user' | 'master' {
        return this._accessPolicyRole;
    }

    set accessPolicyRole(value: unknown) {
        if (typeof value === 'string' && /^user$|^master$/i.test(value)) {
            this._accessPolicyRole = value as 'user' | 'master';
        }
    }

    get isEditable(): boolean {
        if (typeof this._isEditable === 'boolean') return this._isEditable;
        return true;
    }

    set isEditable(value: boolean) {
        this._isEditable = value;
    }

    get countryCode(): CountryCode | null {
        if (this._countryCode) return this._countryCode;
        return null;
    }

    set countryCode(value: unknown) {
        if (value) {
            this._countryCode = new CountryCode(value as string);
        }
    }

    get rawPhone(): string | undefined {
        return this._rawPhone;
    }

    get accessType(): DoorAccessType {
        if (this._accessType) return this._accessType;

        throw new ReferenceError('Undefined access type');
    }

    set accessType(value: unknown) {
        if (DoorAccessType.isValid(value)) {
            this._accessType = value as string;
            return;
        }
        throw new TypeError('Invalid access type');
    }

    set id(value: unknown) {
        this._id = !!value && typeof value === 'string' ? value : uuid();
    }

    get id(): string {
        if (this._id) {
            return this._id;
        }

        throw new ReferenceError('Undefined id');
    }

    get phone(): TelephoneNumber {
        if (this._phone) return this._phone;
        throw new ReferenceError('Undefined telephone');
    }

    set phone(value: unknown) {
        let number: unknown = value;

        if (typeof value === 'string') {
            number = Number.parseInt(value);
        }

        if (typeof number === 'number' && this.countryCode) {
            this._phone = new TelephoneNumber({
                number: number,
                countryCode: this.countryCode,
            });
        }
    }

    get email(): Email | null {
        if (this._email || this._email === null) return this._email;

        throw new ReferenceError('Undefined email');
    }

    set email(value: unknown) {
        if (value) {
            this._email = new Email(value as string);
            return;
        }
        this._email = null;
    }

    get lastname(): string {
        if (this._lastname) return this._lastname;

        throw new ReferenceError('Undefined lastname');
    }

    set lastname(value: unknown) {
        if (!!value && typeof value === 'string' && value.trim().length) {
            this._lastname = value;
            return;
        }

        throw new TypeError('Invalid lastname');
    }

    get firstname(): string {
        if (this._firstname) return this._firstname;

        throw new ReferenceError('Undefined firstname');
    }

    set firstname(value: unknown) {
        if (!!value && typeof value === 'string' && value.trim().length) {
            this._firstname = value;
            return;
        }
        throw new TypeError('Invalid firstname');
    }

    set cardId(card: unknown) {
        if (!!card && typeof card === 'string' && card.trim().length) {
            this._cardId = card;
            return;
        }
    }

    get cardId(): string {
        if (!!this._cardId) {
            return this._cardId;
        }

        throw new ReferenceError('Undefined id');
    }

    get pin(): DoorPinCode {
        if (this._pin) return this._pin;

        throw new ReferenceError('Undefined pin');
    }

    set pin(value: unknown) {
        if (!value) {
            this._pin = new DoorPinCode();
            return;
        }

        if (DoorPinCode.isValid(value)) {
            this._pin = new DoorPinCode(value as string);
            return;
        }
        throw new TypeError('Invalid pin code');
    }

    set sendSms(value: unknown) {
        this._autoSchedulePinSMS = typeof value === 'boolean' ? value : false;
    }

    get sendSms(): boolean {
        return typeof this._autoSchedulePinSMS === 'boolean' ? this._autoSchedulePinSMS : false;
    }

    get sendEmail(): boolean {
        return typeof this._autoSchedulePinEmail === 'boolean' ? this._autoSchedulePinEmail : false;
    }

    set sendEmail(value: unknown) {
        this._autoSchedulePinEmail = typeof value === 'boolean' ? value : false;
    }

    get cardData(): DoorCodePostData {
        if (this.accessType !== DoorAccessType.CARD) {
            throw new TypeError('Called wrong access type data');
        }

        return {
            card: this.cardId,
            firstname: this.firstname,
            lastname: this.lastname,
            pin: this.pin.value,
            email: this.email ? this.email.value : null,
            phone: this.phone.fullNumber,
        };
    }

    get codeData(): DoorCodeAccessPostData {
        if (this.accessType !== DoorAccessType.CODE) {
            throw new TypeError('Called wrong access type data');
        }

        return {
            firstname: this.firstname,
            lastname: this.lastname,
            pin: this.pin.value,
            email: this.email ? this.email.value : null,
            phone: this.phone.fullNumber,
            id: this.id,
            cloud: false,
            accessPolicyRole: 'user',
            autoSchedulePinSMS: this.sendSms,
            autoSchedulePinEmail: this.sendEmail,
        };
    }

    get data(): ILarvaDoorAccess {
        return {
            accessType: this.accessType,
            id: this.id,
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email ? this.email.value : null,
            phone: `${this.rawPhone}`,
            pin: this.pin.value as 'SET' | 'UNSET',
            isEditable: this.isEditable,
            accessPolicyRole: this.accessPolicyRole,
        };
    }

    get toJSON(): ILarvaDoorAccess {
        return this.data;
    }
}
export default DoorAccess;
