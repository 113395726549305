import { useTranslation } from 'react-i18next';

function PrivacyPolicyLink(props: { to: string; title: string }) {
    return (
        <div className="settings-list__item">
            <a href={props.to} target={'_blank'} rel="noreferrer">
                {props.title}
            </a>
        </div>
    );
}

export function PrivacyPolicyLinkBidrento(): JSX.Element {
    const { i18n } = useTranslation();

    let title = 'Privacy Policy (Bidrento)';

    if (i18n.language === 'et') {
        title = 'Andmetetöötluse teavitus (Bidrento)';
    }

    let url = 'https://bidrento.com/privacy-policy/';

    if (i18n.language === 'et') {
        url = 'https://bidrento.com/et/andmetootluse-teavitus/';
    }

    if (i18n.language === 'pl') {
        url = 'https://bidrento.com/pl/polityka-prywatnosci/';
    }

    return <PrivacyPolicyLink to={url} title={title} />;
}

export function PrivacyPolicyLinkTTU(): JSX.Element {
    const { i18n } = useTranslation();

    let title = 'Privacy Policy (MTÜ TTÜ  Üliõpilasküla)';

    if (i18n.language === 'et') {
        title = 'Privaatsuspoliitika (MTÜ TTÜ  Üliõpilasküla)';
    }

    const url = 'https://bidrento.com/wp-content/uploads/privacy.html';

    return <PrivacyPolicyLink to={url} title={title} />;
}

export function PrivacyPolicyLinkEften(): JSX.Element {
    const { i18n } = useTranslation();

    let title = 'EFTEN Capital AS PRIVACY POLICY';

    if (i18n.language === 'et') {
        title = 'Privaatsuspoliitika';
    }

    let url = 'https://eftenliving.ee/en/privacy-policy/';

    if (i18n.language === 'et') {
        url = 'https://eftenliving.ee/privaatsuspoliitika/';
    }

    return <PrivacyPolicyLink to={url} title={title} />;
}
