import RequestStatus from 'core/classes/RequestStatus';
import LarvaQueueRequestProps from 'smartHome/larva/types/LarvaQueueRequestProps';
import LarvaRequestObject from 'smartHome/larva/types/LarvaRequestObject';
import { v4 as uuid } from 'uuid';
export type LarvaQueueRequestType = typeof LarvaQueuedRequest.NODE_REQUEST | typeof LarvaQueuedRequest.NODE_OUTPUT;

export default class LarvaQueuedRequest {
    static NODE_OUTPUT = 'node_output';
    static NODE_REQUEST = 'node_request';

    private _type: LarvaQueueRequestType | null = null;
    private _request: unknown;
    private _source: unknown;
    private _id: string | null = null;
    private _status: RequestStatus | null = null;

    constructor({ type, request, source }: LarvaQueueRequestProps) {
        this.source = source;
        this.request = request;
        this.type = type;
        this._status = RequestStatus.PENDING;
    }

    get type() {
        if (this._type) return this._type;

        throw new TypeError('Invalid request type');
    }

    set type(value: unknown) {
        if (value === LarvaQueuedRequest.NODE_REQUEST || value === LarvaQueuedRequest.NODE_OUTPUT) {
            this._type = value as LarvaQueueRequestType;
            return;
        }

        throw new TypeError('Invalid request type');
    }

    get id() {
        if (this._id) {
            return this._id;
        }

        throw new ReferenceError('Invalid id');
    }

    set request(value: unknown) {
        if (typeof value !== 'undefined') {
            this._request = value;
            this._id = uuid();
            return;
        }

        throw new TypeError('Invalid request');
    }

    get request() {
        if (this._request) {
            return this._request;
        }

        throw new ReferenceError('Undefined request');
    }

    set source(value: unknown) {
        if (typeof value !== 'undefined') {
            this._source = value;
            return;
        }

        throw new ReferenceError('Undefined source');
    }

    get source() {
        if (this._source) {
            return this._source;
        }

        throw new ReferenceError('Undefined source');
    }

    set status(value: unknown) {
        if (RequestStatus.isValid(value)) {
            this._status = value as RequestStatus;
            return;
        }

        throw new TypeError('Invalid status');
    }

    get status() {
        if (this._status) {
            return this._status;
        }

        throw new ReferenceError('Undefined status');
    }

    get data(): LarvaRequestObject {
        if (!this._type || !this._request || !this._source || !this._id || !this._status) {
            throw new ReferenceError('Undefined queue data');
        }

        return {
            id: this._id,
            type: this._type,
            request: this._request,
            source: this._source,
            status: this._status,
        };
    }
}
