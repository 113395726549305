import IAgreementInstanceData from 'agreement/types/IAgreementData';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import { AxiosError, AxiosResponse } from 'axios';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { ResponseStatus } from 'core/classes/ResponseStatus';
import { LARVA, LARVA_DOOR_ACCESS } from 'core/datasets/action';
import { coreActions } from 'core/redux/actions/coreActions';
import { AppState } from 'core/redux/rootReducer';
import { handleErrorResponse } from 'core/saga/workers/handleErrorResponse';
import { apply, call, put, select } from 'redux-saga/effects';
import DoorAccess from 'smartHome/larva/classes/DoorAccess';
import DoorAccessType from 'smartHome/larva/classes/DoorAccessType';
import LarvaConnectionConfig from 'smartHome/larva/classes/LarvaConnectionConfig';
import LarvaDoorAccessController from 'smartHome/larva/classes/LarvaDoorAccessController';
import LarvaWebClient from 'smartHome/larva/classes/LarvaWebClient';
import { larvaDoorAccessActions } from 'smartHome/larva/redux/actions/larvaDoorAccessActions';
import { AnyAction } from 'redux';

//@ts-ignore
export function* handleDoorAccessCommands(action: AnyAction): Generator {
    const selectedObject = (yield select(
        ({ agreementObjects }: AppState) => agreementObjects.selectedObject,
    )) as IAgreementObjectInstanceData;
    const selectedAgreement = (yield select(
        ({ agreements }: AppState) => agreements.selectedAgreement,
    )) as IAgreementInstanceData;

    const agreementUUID = selectedAgreement.uuid;

    const token = yield select(({ auth }: AppState) => auth.token);

    const connectionConfig = new LarvaConnectionConfig({
        token: token as string,
        orgId: selectedObject?.smartHome?.organization_id as string,
        externalId: selectedObject.smartHome?.external_id as string,
        agreementUUID: agreementUUID as string,
    });

    const client = new LarvaWebClient(connectionConfig);
    const controller = new LarvaDoorAccessController(client);

    const { type, payload } = action;

    switch (type) {
        case LARVA_DOOR_ACCESS.SIGNALS.REFRESH_DOOR_ACCESS: {
            yield put({ type: LARVA.API_DOOR_ACCESS_FETCH_STARTED, payload: connectionConfig });
            break;
        }

        case LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_SEND_SMS_ASYNC: {
            const access = payload;

            if (!DoorAccessType.isCode(access.accessType)) {
                break;
            }

            try {
                const response = (yield controller.sendPinCodeBySMS(access)) as AxiosResponse;

                if (ResponseStatus.isOk(response.status)) {
                    yield put(larvaDoorAccessActions.signal.pinCodeSentBySMS());

                    yield put(
                        coreActions.notification.set(
                            new NotificationAlert({
                                message: 'smartHome.larva.doorAccess.notifications.pinCodeSent',
                                level: NotificationAlert.SUCCESS,
                            }).all,
                        ),
                    );
                    break;
                }
                console.log('Failed to update new access: ', response);
            } catch (reason) {
                console.error(reason);

                const response = (reason as AxiosError)?.response;
                yield call(handleErrorResponse, response as AxiosResponse, action);
            }

            break;
        }

        case LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_SEND_EMAIL_ASYNC: {
            const access = payload;

            if (!DoorAccessType.isCode(access.accessType)) {
                break;
            }

            try {
                const response = (yield controller.sendPinCodeByEmail(access)) as AxiosResponse;

                if (ResponseStatus.isOk(response.status)) {
                    yield put(larvaDoorAccessActions.signal.pinCodeSentByEmail());

                    yield put(
                        coreActions.notification.set(
                            new NotificationAlert({
                                message: 'smartHome.larva.doorAccess.notifications.pinCodeSent',
                                level: NotificationAlert.SUCCESS,
                            }).all,
                        ),
                    );
                    break;
                }
                console.log('Failed to update new access: ', response);
            } catch (reason) {
                console.error(reason);

                const response = (reason as AxiosError)?.response;
                yield call(handleErrorResponse, response as AxiosResponse, action);
            }

            break;
        }

        case LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_UNSET_PIN_ASYNC: {
            const access = payload;

            try {
                const response = (yield controller.unsetAccessPin(access)) as AxiosResponse;

                if (ResponseStatus.isOk(response.status)) {
                    yield put({ type: LARVA.API_DOOR_ACCESS_FETCH_STARTED, payload: connectionConfig });

                    yield put(larvaDoorAccessActions.selectDoorAccessById(access.id));
                    yield put(larvaDoorAccessActions.signal.doorAccessPinUnset());

                    yield put(
                        coreActions.notification.set(
                            new NotificationAlert({
                                message: 'smartHome.larva.doorAccess.notifications.pinCodeUnset',
                                level: NotificationAlert.INFO,
                            }).all,
                        ),
                    );
                    return;
                }
                console.log('Failed to update new access: ', response);
            } catch (reason) {
                console.error(reason);

                const response = (reason as AxiosError)?.response;
                yield call(handleErrorResponse, response as AxiosResponse, action);
            }

            break;
        }

        case LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_UPDATE_PIN_ASYNC: {
            const access = payload;

            try {
                const response = (yield controller.updateAccessPin(access)) as AxiosResponse;
                console.log(response);

                if (ResponseStatus.isOk(response.status)) {
                    const newAccess = new DoorAccess({
                        ...response.data,
                        accessType: access.accessType,
                    });

                    yield put({ type: LARVA.API_DOOR_ACCESS_FETCH_STARTED, payload: connectionConfig });

                    yield put(larvaDoorAccessActions.selectDoorAccess(newAccess.data));
                    yield put(larvaDoorAccessActions.signal.doorAccessPinUpdated());

                    yield put(
                        coreActions.notification.set(
                            new NotificationAlert({
                                message: 'smartHome.larva.doorAccess.notifications.pinCodeUpdated',
                                level: NotificationAlert.SUCCESS,
                            }).all,
                        ),
                    );
                    return;
                }
                console.log('Failed to update new access: ', response);
                yield put(
                    coreActions.notification.set(
                        new NotificationAlert({
                            message: 'Failed to update!',
                            level: NotificationAlert.ERROR,
                        }).all,
                    ),
                );
            } catch (reason) {
                console.error(reason);

                const response = (reason as AxiosError)?.response;
                yield call(handleErrorResponse, response as AxiosResponse, action);
            }
            break;
        }

        case LARVA_DOOR_ACCESS.DOOR_ACCESS_FORM_COMMANDS.CREATE_DOOR_ACCESS: {
            const { access, navigate } = payload;
            const accessId = access.id;

            try {
                const response = (yield controller.createAccess(access)) as AxiosResponse;

                if (ResponseStatus.isOk(response.status)) {
                    const newAccess = new DoorAccess({
                        ...response.data,
                        accessType: access.accessType,
                    });

                    yield put({ type: LARVA.API_DOOR_ACCESS_FETCH_STARTED, payload: connectionConfig });

                    yield put(larvaDoorAccessActions.signal.newDoorAccessCreated());
                    yield put(larvaDoorAccessActions.selectDoorAccess(newAccess.data));
                    yield apply(navigate, navigate, [`/door-access/${accessId}`]);

                    yield put(coreActions.slidePanel.changeOpenState());

                    yield put(
                        coreActions.notification.set(
                            new NotificationAlert({
                                message: 'smartHome.larva.doorAccess.notifications.doorAccessCreated',
                                level: NotificationAlert.SUCCESS,
                            }).all,
                        ),
                    );
                    break;
                }

                console.log('Cannot create new access: ', response);
                console.log(response);
            } catch (reason) {
                console.error('Failed to create new access: ', (reason as Error).message);
                console.log((reason as AxiosError).response);

                const response = (reason as AxiosError)?.response;
                yield call(handleErrorResponse, response as AxiosResponse, action);
            }
            break;
        }

        case LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_DELETE_ASYNC: {
            const { access, navigate } = payload;

            try {
                yield controller.deleteAccess(access);
                yield put(larvaDoorAccessActions.signal.doorAccessDeleted());

                yield put({ type: LARVA.API_DOOR_ACCESS_FETCH_STARTED, payload: connectionConfig });
                yield put(larvaDoorAccessActions.showInChildFirstAvailableDoorAccess(navigate));

                yield put(
                    coreActions.notification.set(
                        new NotificationAlert({
                            message: 'smartHome.larva.doorAccess.notifications.doorAccessDeleted',
                            level: NotificationAlert.SUCCESS,
                        }).all,
                    ),
                );
            } catch (reason) {
                console.error('Failed to delete the door access: ', reason);
                console.log(reason);
                const response = (reason as AxiosError)?.response;
                yield call(handleErrorResponse, response as AxiosResponse, action);
            }
            break;
        }

        case LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND.DOOR_ACCESS_UPDATE_USER_DATA: {
            const { access, data } = payload;

            try {
                const response = (yield controller.updateAccessData(access, data)) as AxiosResponse;

                if (ResponseStatus.isOk(response.status)) {
                    const newAccess = new DoorAccess({
                        ...response.data,
                        accessType: access.accessType,
                    });

                    yield put({ type: LARVA.API_DOOR_ACCESS_FETCH_STARTED, payload: connectionConfig });
                    yield put(larvaDoorAccessActions.selectDoorAccess(newAccess.data));
                    yield put(larvaDoorAccessActions.signal.doorAccessPinUpdated());

                    yield put(
                        coreActions.notification.set(
                            new NotificationAlert({
                                message: 'smartHome.larva.doorAccess.notifications.doorAccessDataUpdated',
                                level: NotificationAlert.SUCCESS,
                            }).all,
                        ),
                    );
                    return;
                }
                console.log('Cannot update the access: ', response);
            } catch (reason) {
                console.error('Failed to update the access: ', reason);

                const response = (reason as AxiosError)?.response;
                yield call(handleErrorResponse, response as AxiosResponse, action);
            }
            break;
        }
    }
}
