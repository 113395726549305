import { AppState } from 'core/redux/rootReducer';
import { ChangeEvent } from 'react';
import { LARVA, LARVA_TEMP } from 'core/datasets/action';
import ILarvaWebSocketClient from 'smartHome/larva/types/ILarvaWebSocketClient';
import LarvaUnitObject from 'smartHome/larva/types/LarvaUnitObject';
import LarvaWebComponentsGroupProps from 'smartHome/larva/types/LarvaWebComponentsGroupProps';
import {
    DisableDashboardLoadingStateAction,
    EnableDashboardLoadingStateAction,
} from 'smartHome/larva/types/redux/TempLarvaStateActions';
import WebComponentProps from 'smartHome/larva/types/WebComponentProps';
import {
    ChangedLarvaCheckboxFieldAction,
    ChangedLarvaFieldAction,
    DashboardComponentMountedSignal,
    DashboardComponentUnmountedSignal,
    DashboardUiNodesAddAction,
    DropLarvaConnectionsAction,
    ErrorOnSubmitFormAction,
    HandleQueuedActionsAction,
    PutDashboardWebComponentsAction,
    QueueActionAction,
    ResetLarvaAction,
    ResetLarvaAddCardFormAction,
    ResetLarvaAddCardFormPinCodeFieldAction,
    SaveLarvaSmartHomeConfigAction,
    SaveUnitWebComponentsPropsAction,
    SaveWebComponentsConnectionsAction,
    SaveWebComponentsGroupPropsAction,
    SaveWebComponentsPropsAction,
    SuccessfullyCreatedCardAction,
    UnitDevicesMapInitAction,
    UnitDevicesObjectsMapInitAsyncAction,
    UpdateLarvaCardsAction,
    ValidateFieldValueAction,
} from 'smartHome/larva/types/redux/larvaActions';
import { CardProps, LarvaNewCardFieldStatus } from 'smartHome/larva/types/larvaData';
import LarvaConfiguration from 'smartHome/larva/types/LarvaConfiguration';
import { UINodeLocal } from 'smartHome/larva/types/UINodeLocal';
import { AnyAction } from 'redux';

export const changeLarvaFormFieldValue = (name: string, value: boolean | string): ChangedLarvaFieldAction => {
    return {
        type: LARVA.CHANGE_LARVA_FIELD,
        fieldName: name,
        payload: value,
    };
};

export const changeLarvaFormCheckboxFieldValue = (name: string): ChangedLarvaCheckboxFieldAction => {
    return {
        type: LARVA.CHANGE_LARVA_CHECKBOX_FIELD,
        fieldName: name,
    };
};

export const changeLarvaFieldValue = ({ target }: ChangeEvent<HTMLInputElement>): ChangedLarvaFieldAction => {
    const { name, value } = target;
    return changeLarvaFormFieldValue(name, value);
};

export const resetLarvaAddNewCardFormOnSubmit = (helperText: string): SuccessfullyCreatedCardAction => {
    return { type: LARVA.DOOR_CODE_CREATED, payload: helperText };
};

export const resetLarvaAddNewCardForm = (): ResetLarvaAddCardFormAction => {
    return { type: LARVA.RESET_LARVA_FORM };
};

export const resetLarvaAddNewCardFormPinCodeField = (): ResetLarvaAddCardFormPinCodeFieldAction => {
    return { type: LARVA.RESET_LARVA_FORM_PIN_CODE_FIELD };
};

export const showErrorOnSubmitForm = (helperText: string): ErrorOnSubmitFormAction => {
    return {
        type: LARVA.ERROR_ON_SUBMIT_LARVA_FORM,
        payload: helperText,
    };
};

export const setFormFieldValidStatus = (
    fieldName: string,
    payload: LarvaNewCardFieldStatus,
): ValidateFieldValueAction => {
    return {
        type: LARVA.VALIDATE_LARVA_FORM_FIELD,
        fieldName,
        payload: payload,
    };
};

export const updateLarvaCards = (cards: CardProps[]): UpdateLarvaCardsAction => {
    return {
        type: LARVA.UPDATE_LARVA_CARDS,
        payload: cards,
    };
};

export const saveLarvaSmartHomeConfig = (smartHomeConfig: LarvaConfiguration): SaveLarvaSmartHomeConfigAction => {
    return {
        type: LARVA.SAVE_SMART_HOME_CONFIG,
        payload: smartHomeConfig,
    };
};

export const saveWebComponentsProps = (webComponents: {
    [unitId: string]: WebComponentProps[];
}): SaveWebComponentsPropsAction => {
    return {
        type: LARVA.SAVE_WEB_COMPONENTS_PROPS,
        webComponents,
    };
};

export const saveUnitWebComponentsProps = (
    unitId: string,
    webComponents: WebComponentProps[],
): SaveUnitWebComponentsPropsAction => {
    return {
        type: LARVA.SAVE_UNIT_WEB_COMPONENTS_PROPS,
        unitId,
        webComponents,
    };
};

export const saveUnitConnections = (map: {
    [unitId: string]: { [id: string]: ILarvaWebSocketClient };
}): SaveWebComponentsConnectionsAction => {
    return {
        type: LARVA.SAVE_UNIT_CONNECTIONS,
        payload: map,
    };
};

export const resetLarva = (): ResetLarvaAction => {
    return {
        type: LARVA.RESET_LARVA,
    };
};

export const dropLarvaConnections = (): DropLarvaConnectionsAction => {
    return {
        type: LARVA.DROP_CONNECTIONS,
    };
};

export const closeLarvaConnections =
    () =>
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async (dispatch, getState): Promise<void> => {
        const store: AppState = getState();
        const connections = store.larva?.connections;
        console.log('called from action ', connections);
        // eslint-disable-next-line no-debugger
        //@ts-ignore
        for (const connection of connections) {
            try {
                console.log('CLOSE --', connection);
                const result = await connection.close();
                console.log('CLOSE --', result);
            } catch (error: unknown) {
                console.error(error);
            }
        }

        const storeAfter: AppState = getState();
        const connectionsAfter = storeAfter.larva?.connections;
        console.log('CLOSE connectionsAfter', connectionsAfter);
    };

export const saveWebComponentsGroupProps = (props: {
    [id: string]: LarvaWebComponentsGroupProps;
}): SaveWebComponentsGroupPropsAction => {
    return {
        type: LARVA.SAVE_WEB_COMPONENTS_GROUP_PROPS,
        payload: props,
    };
};

export const initObjectUnitDevicesMap = (map: { [id: string]: LarvaUnitObject }): UnitDevicesMapInitAction => {
    return {
        type: LARVA.UNIT_DEVICES_OBJECTS_MAP_INIT_ASYNC,
        payload: map,
    };
};

export const putDashboardUnit = (unit: LarvaUnitObject): PutDashboardWebComponentsAction => {
    return {
        type: LARVA.PUT_DASHBOARD_UNIT_OBJECT,
        payload: unit,
    };
};

export const signalLarvaDashboardComponentMounted = (): DashboardComponentMountedSignal => {
    return {
        type: LARVA.LARVA_DASHBOARD_COMPONENT_MOUNTED,
    };
};

export const signalLarvaDashboardComponentUnmounted = (): DashboardComponentUnmountedSignal => {
    return {
        type: LARVA.LARVA_DASHBOARD_COMPONENT_UNMOUNTED,
    };
};

export const putDashboardUiNodes = (uiNodes: UINodeLocal[]): DashboardUiNodesAddAction => {
    return {
        type: LARVA.DASHBOARD_UI_NODES_ADDED,
        payload: uiNodes,
    };
};

export const larvaActions = {
    putDashboardUiNodes(uiNodes: UINodeLocal[]): DashboardUiNodesAddAction {
        return {
            type: LARVA.DASHBOARD_UI_NODES_ADDED,
            payload: uiNodes,
        };
    },
    putDashboardUnit(unit: LarvaUnitObject): PutDashboardWebComponentsAction {
        return {
            type: LARVA.PUT_DASHBOARD_UNIT_OBJECT,
            payload: unit,
        };
    },
    addUnitDevicesObjectsMap(map: { [unitId: string]: LarvaUnitObject }): UnitDevicesObjectsMapInitAsyncAction {
        return {
            type: LARVA.UNIT_DEVICES_OBJECTS_MAP_INIT_ASYNC,
            payload: map,
        };
    },
    queueAction(action: AnyAction): QueueActionAction {
        return {
            type: LARVA.QUEUE_ACTION,
            payload: action,
        };
    },
    handleQueuedActions(actions: { type: string; payload: unknown }[]): HandleQueuedActionsAction {
        return {
            type: LARVA.HANDLE_QUEUED_ACTIONS,
            payload: actions,
        };
    },
    enableDashboardLoadingState(): EnableDashboardLoadingStateAction {
        return {
            type: LARVA_TEMP.DASHBOARD.LOADING_STARTED,
        };
    },
    disableDashboardLoadingState(): DisableDashboardLoadingStateAction {
        return {
            type: LARVA_TEMP.DASHBOARD.LOADING_COMPLETED,
        };
    },
};
