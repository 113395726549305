import { WindowSize } from 'core/classes/WindowSize';
import AppVersion from 'core/components/AppVersion';
import { MENU_ITEM_ID } from 'core/config/menu_config';
import { MENU_AREA } from 'core/datasets/menuArea';
import { AppState } from 'core/redux/rootReducer';
import AgreementObjectSelectContainer from 'pages/Settings/components/AgreementObjectSelectContainer';
import AgreementStatusContainer from 'pages/Settings/components/AgreementStatusContainer';
import { useCallback } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from 'tenantUser/redux/authActions';

// Components
import MenuIconButton from '../../buttons/MenuIconButton';
import AppHeader from '../../layout/AppHeader';
import { Container } from '@material-ui/core';

// Styles
import './MenuIconBtnList.css';
import UserPreferencesButton from '../../UserPreferencesButton';
import { useApolloClient } from '@apollo/react-hooks';
import {
    hasActiveAgreement,
    forNotActiveAgreement,
} from 'core/components/menu/AppNavBar/helpers/filterButtonVisibilityFromNavBar';
import { menuItemsSelector } from '../../menu/selectors/selectorsAndPatchers';
import { useConfig } from '../../../../resources/config';

export interface IMenuIconBtnListProps {
    dataTestId?: string;
    actionHandler: () => void;
    className?: string;
    activeItem?: string;
}

/**
 * Creates a list of menu buttons with icons.
 * @param menuData
 * @param dataTestId
 * @param actionHandler
 * @param className
 * @constructor
 */
export const MenuIconBtnList = ({ dataTestId, actionHandler, className = '' }: IMenuIconBtnListProps): JSX.Element => {
    const dispatch = useDispatch();
    const client = useApolloClient();

    const { appType } = useConfig();
    const menuItems = useSelector(menuItemsSelector(appType));

    const slideInIds = useSelector(({ menu }: AppState) => menu.slideInIds);
    const windowSizeRange = useSelector(({ core }: AppState) => core.windowSizeRange);
    const hasUnreadMessages = useSelector(({ messaging }: AppState) => messaging.hasUnreadMessages);
    const agreements = useSelector((state: AppState) => state.agreements.list);
    const shouldNotFilterIds = hasActiveAgreement(agreements);

    const { t } = useTranslation();
    const { pathname } = useLocation();

    const MENU_HEADER_TEXT = t('pageHeader.menu');

    const logOutHandler = useCallback(() => {
        client
            .clearStore()
            .catch((e) => console.error(e))
            .then(() => {
                dispatch(setLogout());
            });
    }, [client, dispatch]);

    const hiddenIds = (id: string) => {
        if (!menuItems[id]) {
            return false;
        }

        const { hideOnWindowSize, hideFromMenuArea } = menuItems[id];

        if (
            hideOnWindowSize.includes(WindowSize.BREAKPOINT_ALL_RANGE_NAME) ||
            hideFromMenuArea.includes(MENU_AREA.slideIn)
        ) {
            return false;
        }

        return windowSizeRange && !hideOnWindowSize.includes(windowSizeRange);
    };

    const isSecondary = (secondary: string) => matchPath(secondary, pathname);

    const filteredIds = shouldNotFilterIds
        ? slideInIds.filter(hiddenIds)
        : slideInIds.filter(forNotActiveAgreement).filter(hiddenIds);

    const MenuItemComponentList = filteredIds.map((id, i: number) => {
        const { mainRoute, secondaryRoutes, labelText, iconName, icon } = menuItems[id];

        const activePath = secondaryRoutes?.some(isSecondary);
        const inlineLabelText = t('menu.' + labelText);

        return (
            <NavLink
                key={i}
                onClick={id === MENU_ITEM_ID.LOGOUT ? logOutHandler : actionHandler}
                className="menu-list__item"
                to={mainRoute}
            >
                <MenuIconButton
                    iconColor={id !== MENU_ITEM_ID.LOGOUT && activePath ? 'secondary' : 'default'}
                    iconName={iconName}
                    labelText={labelText}
                    inlineLabelText={inlineLabelText}
                    isNavBarMenuItem={false}
                    hasBadge={id === MENU_ITEM_ID.MESSAGING && hasUnreadMessages}
                    badgeColor={'secondary'}
                    icon={icon}
                />
            </NavLink>
        );
    });

    return (
        <Container>
            <nav className={'menu-list menu-list--vertical ' + className} data-testid={dataTestId}>
                <div className="app-menu-header--mobile">
                    <AppHeader title={MENU_HEADER_TEXT} />
                    <div className="app-header-menu__user-preferences-container--mobile">
                        <UserPreferencesButton classes="app-header-menu__user-preferences" />
                    </div>
                </div>
                <div className="settings-list__item">
                    <AgreementObjectSelectContainer />
                    <AgreementStatusContainer />
                </div>
                <hr className={'settings-list__breaker'} />
                {MenuItemComponentList}
                <AppVersion />
            </nav>
        </Container>
    );
};
export default MenuIconBtnList;
