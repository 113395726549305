import { agreementActions } from 'agreement/redux/agreementActions';
import { AppState } from 'core/redux/rootReducer';
import { InitNetworkStatusAction } from 'core/types/redux/CoreStateActions';
import { put, select } from 'redux-saga/effects';

export function* initDataRefreshingOnNetworkChanged({ payload: status }: InitNetworkStatusAction): Generator {
    const { isConnected } = status;

    if (!isConnected) return;

    const token = yield select(({ auth }: AppState) => auth.token);

    if (!token) return;

    yield put(agreementActions.fetchAgreementsAsync(token as string));
}
