import { BreakPointRange } from 'core/classes/WindowSize';
import { MENU_ITEM_ID, MENU_LOCATION } from 'core/config/menu_config';
import { ICON_ID } from 'core/datasets/icon_id';
import { AppState } from 'core/redux/rootReducer';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIconButton from '../../buttons/MenuIconButton';
import MenuToggleButton from '../../buttons/MenuToggleButton';

// Style
import './AppNavBar.css';
import { IMenuItem } from '../../../types/redux/MenuReducerState';
import {
    filterButtonVisibilityFromNavBar,
    forNotActiveAgreement,
    hasActiveAgreement,
} from './helpers/filterButtonVisibilityFromNavBar';
import { useConfig } from '../../../../resources/config';
import { menuItemsSelector } from '../selectors/selectorsAndPatchers';

export interface IProps {
    dataTestId?: string;
    actionHandler: (key: string) => void;
    activeItem?: string;
    isOpen: boolean;
}

/**
 * Menu bar for mobile screens
 *
 * @param dataTestId
 * @param actionHandler
 * @param activeItem
 * @constructor
 */
const AppNavBar = ({ dataTestId, actionHandler, isOpen }: IProps): JSX.Element => {
    const MENU_TOGGLE = {
        id: MENU_ITEM_ID.MENU_TOGGLE,
        iconName: ICON_ID.MENU_TOGGLE,
        labelText: 'menu',
        location: MENU_LOCATION.NAV_BAR,
    };

    const { pathname } = useLocation();

    const navBarIds = useSelector((state: AppState) => {
        return hasActiveAgreement(state.agreements.list)
            ? state.menu.navBarIds
            : state.menu.navBarIds.filter(forNotActiveAgreement);
    });

    const { appType } = useConfig();
    const menuItems = useSelector(menuItemsSelector(appType));

    const windowSizeRange = useSelector(({ core }: AppState) => core.windowSizeRange);
    const hasUnreadMessages = useSelector(({ messaging }: AppState) => messaging.hasUnreadMessages);

    return (
        <AppBar
            elevation={0}
            className="nav-bar"
            position="fixed"
            component="nav"
            color="default"
            data-testid={dataTestId}
        >
            <Toolbar className="nav-bar__toolbar" disableGutters={true}>
                <NavBarMenuComponentList
                    navBarIds={navBarIds}
                    menuItems={menuItems}
                    pathname={pathname}
                    windowSizeRange={windowSizeRange}
                    hasUnreadMessages={hasUnreadMessages}
                    actionHandler={actionHandler}
                />
                <MenuToggleButton isOpen={isOpen} onClick={() => actionHandler(MENU_TOGGLE.id)} />
            </Toolbar>
        </AppBar>
    );
};

interface NavBarMenuComponentList {
    navBarIds: string[];
    menuItems: { [p: string]: IMenuItem };
    windowSizeRange: BreakPointRange | undefined;
    pathname: string;
    actionHandler: (id: string) => void;
    hasUnreadMessages: boolean;
}

function NavBarMenuComponentList({
    navBarIds,
    menuItems,
    windowSizeRange,
    pathname,
    actionHandler,
    hasUnreadMessages,
}: NavBarMenuComponentList): JSX.Element {
    return (
        <>
            {navBarIds
                .filter((id: string) => filterButtonVisibilityFromNavBar(id, windowSizeRange, menuItems))
                .map((id: string) => {
                    try {
                        const { mainRoute, secondaryRoutes, labelText, iconName } = menuItems[id];
                        const isActiveBtn = secondaryRoutes.some((secondary: string) => matchPath(secondary, pathname));

                        return (
                            <Link key={id} to={mainRoute} className="nav-bar__menu-link">
                                <MenuIconButton
                                    iconName={iconName}
                                    labelText={labelText}
                                    isNavBarMenuItem={true}
                                    iconColor={isActiveBtn ? 'secondary' : 'default'}
                                    hasBadge={id === MENU_ITEM_ID.MESSAGING && hasUnreadMessages}
                                    badgeColor={'secondary'}
                                    onClick={() => actionHandler(id)}
                                />
                            </Link>
                        );
                    } catch (reason) {
                        console.error(`[NavBarMenuComponentList]: Failed read menu item for id '${id}' in`, menuItems);
                    }
                })}
        </>
    );
}

export default AppNavBar;
