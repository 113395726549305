import {
    EventType,
    getDatabase,
    limitToLast,
    off,
    onChildAdded,
    onChildChanged,
    Query,
    query,
    ref,
    onValue,
} from 'firebase/database';
import { eventChannel, EventChannel } from 'redux-saga';

export class EventFirebase {
    static readonly CHILD_ADDED = 'child_added';
    static readonly CHILD_CHANGED = 'child_changed';

    private static registerEvent(firebaseQuery: Query, event: EventType, emit: (input: unknown) => void) {
        switch (event) {
            case EventFirebase.CHILD_ADDED:
                return onChildAdded(
                    firebaseQuery,
                    (data) => emit(data),
                    (error) => console.log('FIREBASE REGISTER EVENT ERROR', error),
                );

            case EventFirebase.CHILD_CHANGED:
                return onChildChanged(
                    firebaseQuery,
                    (data) => emit(data),
                    (error) => console.log('FIREBASE REGISTER EVENT ERROR', error),
                );
        }

        console.error('unsupported event type', event);
        return null;
    }

    public static createEventChannelFirebase(path: string, event: EventType, limit?: number): EventChannel<string> {
        const db = getDatabase();
        const listener = eventChannel((emit: (input: any) => void) => {
            const pathRef = ref(db, path);
            const firebaseQuery = limit !== undefined ? query(pathRef, limitToLast(limit)) : query(pathRef);
            this.registerEvent(firebaseQuery, event, emit);
            return () => off(firebaseQuery, event);
        });
        return listener as EventChannel<string>;
    }

    public static async subscribeToUnreadMessagesCount(
        userUid: string,
        callbackAction: (unreadMessagesCounts: { [channelId: string]: number }) => void,
    ): Promise<void> {
        const db = getDatabase();
        const userUnreadMessagesCountRef = ref(db, `userUnreadMessagesCount/${userUid}`);
        onValue(
            userUnreadMessagesCountRef,
            (data) => callbackAction(data.val()),
            (error) => console.log('error:', error),
        );
    }
}
