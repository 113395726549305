import { CallRefreshAction, ChangeLanguageActions } from 'tenantUser/types/redux/userProfileActions';
import { LangValueType } from 'tenantUser/types/redux/userProfileStates';
import { USER_PROFILE } from 'core/datasets/action';

export const changeLanguage = (lang: LangValueType): ChangeLanguageActions => {
    return {
        type: USER_PROFILE.CHANGE_LANGUAGE,
        payload: lang,
    };
};

export const callRefresh = (): CallRefreshAction => {
    return {
        type: USER_PROFILE.REFRESH,
    };
};
