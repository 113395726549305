import { useResources } from 'resources/context';
import './styles/NoItemsNotice.css';

export const NoItemsNotice: NoItemsNotice = ({ message, type = 'other' }: NoItemsNoticeProps) => {
    const { NoItemsImage, WriteSomethingImage, DrawSomethingImage, GreatSearchImage } = useResources();
    let picture;
    const sizes = {
        width: '100%',
        height: '100%',
    };
    switch (type) {
        case 'noMessages':
            picture = <WriteSomethingImage {...sizes} />;
            break;
        case 'newMessage':
            picture = <DrawSomethingImage {...sizes} />;
            break;
        case 'error':
            picture = <GreatSearchImage {...sizes} />;
            break;
        default:
            picture = <NoItemsImage {...sizes} />;
    }

    return (
        <div className="no-items-notice">
            <picture className="no-items-notice__img">{picture}</picture>
            <p className="no-items-notice__message">{message}</p>
        </div>
    );
};

type NoItemsNotice = (props: NoItemsNoticeProps) => JSX.Element;
export type NoItemsNoticeProps = {
    message?: string;
    type?: NoticeType;
};

type NoticeType = 'newMessage' | 'noMessages' | 'other' | 'error';
