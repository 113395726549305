import { CORE } from 'core/datasets/action';
import { DeviceErrorAction } from 'core/types/redux/CoreStateActions';
import { put } from 'redux-saga/effects';

export function* handleDeviceErrors(action: DeviceErrorAction): Generator {
    const { errorMessage, errorData, id } = action.payload;
    console.error(`LARVA Device error: `, errorMessage, errorData);

    if (id) {
        yield put({
            type: CORE.DEVICE_ADD_ERROR,
            payload: {
                fullId: id,
                error: errorMessage,
            },
        });
    }
}
