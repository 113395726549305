import { AGREEMENT, CORE } from 'core/datasets/action';
import { handleBase64DataConversion } from 'core/saga/workers/handleBase64DataConversion';
import { handleGraphQLRequest } from 'core/saga/workers/handleGraphQLRequest';
import { handleDashboardNotifications } from 'core/saga/workers/handleDashboardNotifications';
import { all, call, takeEvery } from 'redux-saga/effects';
import { handleDeviceErrors } from './workers/handleDeviceError';

function* watchGraphqlRequest(): Generator {
    yield takeEvery(CORE.MAKE_GRAPHQL_REQUEST, handleGraphQLRequest);
}

function* watchDeviceErrors(): Generator {
    yield takeEvery(CORE.DEVICE_GLOBAL_ERROR, handleDeviceErrors);
    yield takeEvery(CORE.ERROR_LOGGED, console.error);
}

function* watchCallsToConvertBase64ToUrl(): Generator {
    yield takeEvery(CORE.CONVERT_BASE64_TO_URL, handleBase64DataConversion);
}

function* watchDashboardNotifications(): Generator {
    yield takeEvery([AGREEMENT.AGREEMENT_SELECTED, AGREEMENT.INIT_AGREEMENTS_FETCHING], handleDashboardNotifications);
}

export function* coreWatcher(): Generator {
    yield all([
        call(watchGraphqlRequest),
        call(watchCallsToConvertBase64ToUrl),
        call(watchDeviceErrors),
        call(watchDashboardNotifications),
    ]);
}
