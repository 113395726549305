import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { useResources } from 'resources/context';

export interface FilterButtonProps {
    clickHandle?: () => void;
    isEnabled: boolean;
    isVisible?: boolean;
}

const FilterButton = ({ clickHandle, isEnabled, isVisible = false }: FilterButtonProps): JSX.Element | null => {
    const { FilterIcon } = useResources();
    return isVisible ? (
        <Badge
            className="filter-button filter-button-badge"
            invisible={!isEnabled}
            color="secondary"
            variant="dot"
            overlap="rectangular"
        >
            <IconButton className="app-header__filter-icon" onClick={clickHandle}>
                <FilterIcon />
            </IconButton>
        </Badge>
    ) : null;
};
export default FilterButton;
