export const config = {
    apiKey: 'AIzaSyCVno5ptlAVGKEHAPkT1eXQJkT1fhtRW9M',
    authDomain: 'bidrento-3c3e7.firebaseapp.com',
    databaseURL: 'https://bidrento-3c3e7-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'bidrento-3c3e7',
    storageBucket: 'bidrento-3c3e7.appspot.com',
    messagingSenderId: '637935821169',
    appId: '1:637935821169:web:2d284c24dc8d9e1ae396d0',
} as const;

export const serverKey = 'BNSsVBOUfZ373k3rhpKnEjDeOSG8CMrQ9xGnlElaLLZw2_NTjOP_2zWfYz7BSHLIF9KSm71rsB3-h5XV1pKibKw';
