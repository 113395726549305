import { DateTime } from 'luxon';
import { LangValueType, UserProfileReducerStates } from '../types/redux/userProfileStates';
import { UserProfileActions } from '../types/redux/userProfileActions';
import { LANGUAGES } from 'core/datasets/languages';
import { USER_PROFILE } from 'core/datasets/action';
import { LOCAL_STORAGE } from 'core/datasets/local_storage';

const userProfile = localStorage.getItem(LOCAL_STORAGE.USER_PROFILE);
const currentLang: LangValueType = userProfile ? (JSON.parse(userProfile).currentLang as LangValueType) : LANGUAGES.EST;

const initState: UserProfileReducerStates = {
    currentLang: currentLang,
    refresh: DateTime.now().toMillis(),
};

export const useProfileReducer = (state = initState, action: UserProfileActions): UserProfileReducerStates => {
    switch (action.type) {
        case USER_PROFILE.CHANGE_LANGUAGE:
            return {
                ...state,
                currentLang: action.payload,
            };
        case USER_PROFILE.REFRESH:
            return {
                ...state,
                refresh: DateTime.now().toMillis(),
            };
        default:
            return state;
    }
};
