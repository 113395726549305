import { ChangeEvent, useState } from 'react';
import { TextField } from '@material-ui/core';
import { extraServiceAmountIsValid } from './helpers';
import { useTranslation } from 'react-i18next';

const EMPTY_ERROR = ''; // Fix for input field jumps

function OrderServiceAmountTextField(props: {
    className: string;
    label: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    value?: number | null;
}): JSX.Element {
    const [error, setError] = useState(EMPTY_ERROR);
    const { t } = useTranslation();

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (extraServiceAmountIsValid(event.target.value)) {
            setError(EMPTY_ERROR);
        } else {
            setError(t(`pageHeader.extraServicesError`));
        }

        props.onChange(event);
    };

    return (
        <div>
            <TextField
                required
                variant={'outlined'}
                type="number"
                className={props.className}
                label={props.label}
                onChange={handleAmountChange}
                error={error !== EMPTY_ERROR}
                helperText={error}
                inputProps={{ step: 1 }}
                value={props.value || undefined}
            />
        </div>
    );
}

export default OrderServiceAmountTextField;
