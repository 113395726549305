import { FILE } from 'core/datasets/action';
import { FilesActions } from 'file/types/FilesActions';
import FilesState from 'file/types/FilesState';

const initState: FilesState = {
    list: [],
    map: {},
};

export const filesReducer = (state = initState, action: FilesActions): FilesState => {
    switch (action.type) {
        case FILE.DATA_UPDATED: {
            const { map, list } = action.payload;

            return {
                ...state,
                list: list,
                map: map,
            };
        }
        case FILE.MARK_SELECTED_LIST_ITEM_BROKEN: {
            return {
                ...state,
                list: action.payload,
            };
        }
        default:
            return state;
    }
};
