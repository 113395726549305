import {
    AGREEMENT,
    AGREEMENT_OBJECT,
    AUTH,
    CORE,
    LARVA,
    LARVA_DOOR_ACCESS,
    LARVA_DOOR_ACCESS_TEMP,
    LARVA_WS,
} from 'core/datasets/action';
import { all, fork, takeEvery, takeLatest, takeLeading, throttle } from 'redux-saga/effects';
import {
    fetchAllConnections,
    fetchAllData,
    fetchAllLarvaData,
    handleQueuedActions,
    handleQueuedRequests,
    initUnitsUiNodesFetching,
    selectDoorAccess,
    showFirstAvailableDoorAccess,
} from 'smartHome/larva/saga/workers';
import { handleDoorAccessCommands } from 'smartHome/larva/saga/workers/handleDoorAccessCommands';
import { initConnections } from './workers/updateWsConnections';
import { fetchApiUnits, fetchDoorAccess } from './workers/fetchAllLarvaData';

export function* watchUnitDevicesRequest(): Generator {
    yield takeLeading([AGREEMENT.AGREEMENT_SELECTED], fetchAllLarvaData);
}

export function* watchWebsocketsConnectionRequest(): Generator {
    yield takeLatest(LARVA.WEBSOCKET_CONNECTIONS_REQUESTED, initUnitsUiNodesFetching);
}

export function* watchUnitDevicesApiRequest(): Generator {
    yield takeLeading(LARVA.API_UNITS_FETCH_STARTED, fetchApiUnits);
}

export function* watchDoorAccessApiRequest(): Generator {
    yield takeLeading(LARVA.API_DOOR_ACCESS_FETCH_STARTED, fetchDoorAccess);
}

export function* watchToRetryDoorAccess(): Generator {
    try {
        yield throttle(
            15000,
            [
                LARVA_WS.WS_CONNECTION_ERROR,
                CORE.APP_ACTIVE_STATUS_CHANGED,
                LARVA.PUT_API_UNITS,
                LARVA.PUT_UNIT_DEVICES_MAP,
                LARVA_WS.TRY_UPDATE_WS_CONNECTIONS,
                LARVA_WS.REQUEST_ERROR,
            ],
            fetchDoorAccess,
        );
    } catch (error) {
        console.error('Larva fetch all smart home door access error', { error });
    }
}

export function* watchToResetAllConnections(): Generator {
    try {
        yield throttle(10000, [LARVA.PUT_API_UNITS], fetchAllConnections);
    } catch (error) {
        console.error('Larva fetch all smart home connections error', { error });
    }
}

export function* watchToLarvaWebsocketConnectionErrors(): Generator {
    try {
        yield takeLeading(LARVA_WS.WS_CONNECTION_ERROR, fetchAllData);
    } catch (error) {
        console.error('Larva fetch all smart home connections error', { error });
    }
}

export function* watchToCoreNetworkStatus(): Generator {
    try {
        yield takeEvery([CORE.NETWORK_STATUS_CHANGED, CORE.APP_ACTIVE_STATUS_CHANGED], fetchAllData);
    } catch (error) {
        console.error('Larva fetch all smart home connections error', { error });
    }
}

export function* watchToFetchUnitDevices(): Generator {
    try {
        yield takeEvery(
            [AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED, AUTH.EXPIRED_TOKEN_REFRESHED],
            initUnitsUiNodesFetching,
        );
    } catch (error) {
        console.error('Larva fetch all smart home data error', { error });
    }
}

function* watchHandlingRequestsCall(): Generator {
    yield takeLeading(LARVA_WS.HANDLE_QUEDED_PENDING_REQUESTS, handleQueuedRequests);
}

function* watchDoorAccess(): Generator {
    try {
        const doorCodeFormActions = Object.values(LARVA_DOOR_ACCESS.DOOR_ACCESS_COMMAND);

        yield takeEvery(
            [
                ...doorCodeFormActions,
                LARVA_DOOR_ACCESS.DOOR_ACCESS_FORM_COMMANDS.CREATE_DOOR_ACCESS,
                LARVA_DOOR_ACCESS.SIGNALS.REFRESH_DOOR_ACCESS,
            ],
            handleDoorAccessCommands,
        );
        yield takeEvery([LARVA_DOOR_ACCESS_TEMP.DOOR_ACCESS_LIST_VIEW_DATA_ITEM_SELECTED], selectDoorAccess);
        yield takeEvery([LARVA_DOOR_ACCESS_TEMP.DOOR_ACCESS_SELECTED_BY_ID], selectDoorAccess);
        yield takeEvery([LARVA_DOOR_ACCESS_TEMP.SHOW_FIRST_AVAILABLE_DOOR_ACCESS], showFirstAvailableDoorAccess);
    } catch (error) {
        console.error('Larva fetch all door access commands error', { error });
    }
}

function* watchActionsQueue() {
    yield takeLeading(LARVA.HANDLE_QUEUED_ACTIONS, handleQueuedActions);
}

export function* watchInitConnections(): Generator {
    yield takeEvery(LARVA_WS.INIT_WS_CONNECTION, initConnections);
}

export function* larvaSmartHomeWatcher(): Generator {
    yield all([
        fork(watchHandlingRequestsCall),
        fork(watchDoorAccess),
        fork(watchActionsQueue),
        fork(watchInitConnections),
        fork(watchToFetchUnitDevices),
        fork(watchToResetAllConnections),
        fork(watchUnitDevicesRequest),
        fork(watchWebsocketsConnectionRequest),
        fork(watchUnitDevicesApiRequest),
        fork(watchDoorAccessApiRequest),
        fork(watchToRetryDoorAccess),
        fork(watchToLarvaWebsocketConnectionErrors),
        fork(watchToCoreNetworkStatus),
    ]);
}
