import { AppState } from 'core/redux/rootReducer';
import { handleWsErrorResponse } from 'core/saga/workers/handleErrorResponse';
import { apply, call, put, select } from 'redux-saga/effects';
import LarvaWsClientWrapper from 'smartHome/larva/classes/LarvaWsClientWrapper';
import LarvaDeviceController from 'smartHome/larva/classes/LarvaDeviceController';
import { larvaWSActions } from 'smartHome/larva/redux/actions/larvaWSActions';
import LarvaUnitObject from 'smartHome/larva/types/LarvaUnitObject';
import handleComponentBroadcast from 'smartHome/larva/utils/handleComponentBroadcast';
import { coreActions } from 'core/redux/actions/coreActions';
import { larvaId } from 'smartHome/larva/utils/makeId';
import { CORE, LARVA_WS } from 'core/datasets/action';
import { AnyAction } from 'redux';
import createDeviceConnection from 'smartHome/larva/utils/createDeviceConnection';

export function* updateWsConnections(action: AnyAction): Generator {
    const { isConnected } = action.payload;
    if (!isConnected) {
        return;
    }

    const isLoggedIn = yield select(({ auth }: AppState) => auth.isLoggedIn);
    if (!isLoggedIn) {
        yield put({
            type: CORE.ERROR_LOGGED,
            payload: 'Cannot update Larva WS connections, user is not logged in',
        });
        return;
    }

    const map = (yield select(({ larva }: AppState) => larva.unitDevicesObjectsMap)) as {
        [p: string]: LarvaUnitObject;
    };

    const unitsArray = (map && Object.values(map)) || [];

    if (!unitsArray.length) {
        yield put({
            type: CORE.ERROR_LOGGED,
            payload: 'No larva units yet',
        });
        return;
    }

    const token = (yield select(({ auth }: AppState) => auth.token)) as string;
    if (!token) {
        yield put({
            type: CORE.ERROR_LOGGED,
            payload: 'No authentication token',
        });
        return;
    }

    for (const unitId in map) {
        yield put({
            type: LARVA_WS.INIT_WS_CONNECTION,
            payload: {
                device: map[unitId],
                token: token,
                shouldBeClosed: false,
            },
        });
    }
}

export function* initConnections(action: AnyAction): Generator {
    const { unit, token, shouldBeClosed } = action.payload;

    if (unit && Object.values(unit.devices) && token) {
        for (const deviceId in unit.devices) {
            const deviceObject = unit.devices[deviceId];
            try {
                const connection = (yield call(createDeviceConnection, {
                    deviceId,
                    unitId: unit.id,
                })) as LarvaWsClientWrapper;

                const device = new LarvaDeviceController({
                    id: deviceId,
                    name: deviceObject.name,
                    unitId: deviceObject.unitId,
                });

                yield call(initDeviceConnection, connection, device, shouldBeClosed);
            } catch (error) {
                yield put(
                    coreActions.device.error.component(
                        larvaId(deviceObject.unitId, deviceId),
                        'Device connection failed',
                        error,
                    ),
                );
                throw new Error(`Failed  initDeviceConnection: ${error}`);
            }
        }
    }
}

function* initDeviceConnection(client: LarvaWsClientWrapper, device: LarvaDeviceController, shouldBeClosed?: boolean) {
    try {
        yield apply(device, device.connect, [
            client,
            {
                broadCastCallback: handleComponentBroadcast,
            },
            shouldBeClosed,
        ]);
    } catch (error) {
        yield handleWsErrorResponse(error as string);
        if (device.unitId) {
            yield put(
                coreActions.device.error.component(
                    larvaId(device.unitId, device.id),
                    'Failed initDeviceConnection',
                    error,
                ),
            );
        }
        throw new Error('Failed to initDeviceConnection');
    }

    yield put(larvaWSActions.saveConnection(device, client));
}
