import { ViewVariantType } from 'core/classes/ViewVariantType';
import { PAGE_URL } from 'core/datasets/page_url';
import FileDto from 'file/classes/FileDto';
import FileSize from 'file/classes/FileSize';
import IFileData from 'file/types/IFileData';
import IFileListViewItemData from 'file/types/IFileListViewItemData';
import IFileRawData from 'file/types/IFileRawData';

export default class FileListViewItem {
    static PATH = `/${PAGE_URL.FILES}`;
    private data: IFileData;

    constructor(rawData: IFileRawData) {
        this.data = new FileDto(rawData).all;
    }

    get listViewData(): IFileListViewItemData {
        return {
            path: `${FileListViewItem.PATH}/${this.data.id}`,
            labelText: FileSize.convert(this.data.size),
            primaryText: this.data.name,
            leftBlockVariant: ViewVariantType.SUCCESS,
            meta: {
                id: `${this.data.id}`,
                mimeType: this.data.mimeType,
            },
        };
    }

    toJSON(): IFileListViewItemData {
        return this.listViewData;
    }
}
