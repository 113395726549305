import { ExtraService, ExtraServicesReducerStay } from '../types/extraServices';
import { AnyAction } from 'redux';
import { produce, setAutoFreeze } from 'immer';

setAutoFreeze(false);

const defaultState: ExtraServicesReducerStay = {
    extraServiceIds: [],
    extraServices: {},
    orderedExtraServices: [],
    loading: true,
};

export const extraServicesReducer = (state = defaultState, action: AnyAction): ExtraServicesReducerStay => {
    switch (action.type) {
        case 'EXTRA_SERVICE/SET_EXTRA_SERVICES': {
            const extraServices = action.payload as ExtraService[];
            const extraServiceIds: string[] = [];

            return produce(state, (draftState: ExtraServicesReducerStay) => {
                for (const extraService of extraServices) {
                    draftState.extraServices[extraService.id.toString()] = extraService;
                    extraServiceIds.push(extraService.id.toString());
                }

                draftState.extraServiceIds = extraServiceIds;
            });
        }
        case 'EXTRA_SERVICE/SET_EXTRA_SERVICES_LOADING': {
            const loading = action.payload as boolean;
            return produce(state, (draftState: ExtraServicesReducerStay) => {
                draftState.loading = loading;
            });
        }
        case 'EXTRA_SERVICE/SET_ORDERED_EXTRA_SERVICES': {
            return produce(state, (draftState: ExtraServicesReducerStay) => {
                draftState.orderedExtraServices = action.payload;
            });
        }
        default:
            return state;
    }
};
