import { ShelveKey } from 'core/types/storage';

/**
 * @deprecated avoid using local storage directly, use persistent redux store instead
 */
class Store {
    type: 'local' | 'session' | undefined;
    STORE_SHELVE_NAME: string;
    constructor(shelve: string, type?: 'local' | 'session') {
        this.type = type ? type : 'local';
        this.STORE_SHELVE_NAME = shelve;
    }

    // only for localStorage
    protected static _getValueDirectly(shelve: string, key: string): unknown {
        const rawStore = localStorage.getItem(shelve);
        if (!rawStore?.trim()) return null;

        const parsedStore = JSON.parse(rawStore);

        if (!(key in parsedStore)) return null;

        return !!parsedStore[key]?.trim() ? JSON.parse(parsedStore[key]) : null;
    }

    protected static _removeStore(shelve: ShelveKey): void {
        localStorage.removeItem(shelve);
    }

    private storage(): Storage {
        return this.type === 'local' ? localStorage : sessionStorage;
    }

    protected _getValue(key: string): string | null {
        const store = this.storage();
        return store.getItem(key);
    }

    protected _setValue(key: string, value: string): void {
        this.storage().setItem(key, value);
    }

    public getShelveData = (key?: ShelveKey): unknown | null => {
        if (!key) return null;

        const rawData = this._getValue(this.STORE_SHELVE_NAME);
        const parsedData =
            rawData && typeof JSON.parse(rawData as string) === 'object' ? JSON.parse(rawData as string) : null;

        return parsedData ? parsedData[key] : null;
    };

    protected setShelveData = (key: string, value: string): void => {
        if (!key || typeof key !== 'string' || !value || typeof value !== 'string') return;

        const prevData = this._getValue(this.STORE_SHELVE_NAME);

        const parsedData = prevData ? JSON.parse(prevData) : null;

        const updatedData = !parsedData
            ? {
                  [key]: value,
              }
            : {
                  ...parsedData,
                  [key]: value,
              };

        this._setValue(this.STORE_SHELVE_NAME, JSON.stringify(updatedData));
    };
}
export default Store;
