import { agreementActions } from 'agreement/redux/agreementActions';
import { AppState } from 'core/redux/rootReducer';
import { AppActiveStateAction } from 'core/types/redux/CoreStateActions';
import { put, select } from 'redux-saga/effects';

export function* initDataRefreshingOnAppActiveStateChanged({ payload: isActive }: AppActiveStateAction): Generator {
    if (!isActive) return;

    const token = yield select(({ auth }: AppState) => auth.token);

    if (!token) return;

    const isConnected = yield select(({ core }: AppState) => core.networkStatus.isConnected);

    if (!isConnected) return;

    yield put(agreementActions.fetchAgreementsAsync(token as string));
}
