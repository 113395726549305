import { agreementActions } from 'agreement/redux/agreementActions';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { SelectAgreementByIdAction } from 'agreement/types/redux/TempAgreementStateActions';
import { AppState } from 'core/redux/rootReducer';
import { put, select } from 'redux-saga/effects';

export function* handleAgreementSelectionBiId({ payload: id }: SelectAgreementByIdAction): Generator {
    const map = (yield select(({ agreements }: AppState) => agreements.map)) as {
        [id: number | string]: IAgreementInstanceData;
    };
    yield put(agreementActions.selectAgreement(map[id]));
}
