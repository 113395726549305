import { ITenant } from 'agreement/types/ITenant';
import { AppState } from 'core/redux/rootReducer';
import { settingsSelectors } from 'core/redux/selectors/settingsSelectors';
import { intersectionBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserData } from 'tenantUser/types/user';

export default function useSelectedTenant(): ITenant | null {
    const [selectedTenant, setSelectedTenant] = useState<ITenant | null>(null);

    const selectedAgreement = useSelector(settingsSelectors.selectedAgreement);
    const user = useSelector(({ auth }: AppState) => auth.user) as UserData;

    useEffect(() => {
        if (selectedAgreement) {
            const tenantIntersection = intersectionBy(selectedAgreement.tenants, user.connectedTenants, 'id');
            setSelectedTenant(tenantIntersection.length ? tenantIntersection[0] : null);
        }
    }, [selectedAgreement, user]);

    return selectedTenant;
}
