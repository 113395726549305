import { agreementActions } from 'agreement/redux/agreementActions';
import AgreementsGraphQLRequestVariables from 'agreement/types/AgreementsGraphQLRequestVariables';
import { AGREEMENT } from 'core/datasets/action';
import { ORDER_BY, ORDER_DIR } from 'core/hooks/useQueryHelper';
import { put } from 'redux-saga/effects';
import { queryRentalAgreementDocument } from '../../api/gql/useQueryRentalAgreements/queryRentalAgreementDocument';

export function* fetchAllAgreements({ type }: { type: (typeof AGREEMENT)[keyof typeof AGREEMENT] }): Generator {
    const callbackAction =
        type === AGREEMENT.INIT_AGREEMENTS_FETCHING
            ? AGREEMENT.INIT_AGREEMENTS_RECEIVED
            : AGREEMENT.ALL_AGREEMENTS_RECEIVED;

    const variables: AgreementsGraphQLRequestVariables = {
        orderDir: ORDER_DIR.desc,
        orderAgreementsBy: ORDER_BY.agreementDate,
    };

    yield put(agreementActions.fetchAllAgreements(queryRentalAgreementDocument, variables, callbackAction));
}
