import PageContextType from '../../../../../types/pageContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../redux/rootReducer';
import { ChangeEvent } from 'react';
import { changeFilterValue, resetFilterGroup } from '../../../../../redux/actions/filterActions';
import {
    Filter as FilterProps,
    FilterCategoryType,
    FilterGroupType,
    FilterValueType,
} from '../../../../../types/filter';
import { ServiceRequestTypeTitlesMap } from 'serviceRequest/types/serviceRequest';

interface UseFilterInProps {
    context: PageContextType;
}
const useFilterIn = ({ context }: UseFilterInProps): UseFilterInReturn => {
    const DEFAULT_RESET_ALL_KEY = 'all';

    const filters = useSelector(({ filters }: AppState) => filters);
    const dispatch = useDispatch();

    const filter: FilterProps = filters[context as keyof typeof filters];
    const filterGroups = filter.groups;
    const resetAllKey = 'resetAllKey' in filter ? filter.resetAllKey : DEFAULT_RESET_ALL_KEY;
    const setTargetFilterActive = (targetKey: keyof FilterValueType, targetGroupName: keyof FilterGroupType) => {
        const currentFilterValues: FilterValueType = Object.assign({}, filterGroups[targetGroupName]);
        const newValues: FilterValueType = currentFilterValues;

        for (const key in currentFilterValues as FilterValueType) {
            (newValues as FilterValueType)[key as keyof FilterValueType] = false;

            if (key === targetKey) (newValues as FilterValueType)[key] = true;
        }
        return newValues;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const currentFieldName = e.target.name as keyof FilterValueType;
        const currentFilterGroup = e.target.dataset.group as keyof FilterGroupType;
        const isCallToResetValues = currentFieldName === resetAllKey;

        if (isCallToResetValues) {
            dispatch(
                resetFilterGroup({
                    filterGroupName: currentFilterGroup,
                    context: context as FilterCategoryType,
                }),
            );
            return;
        }
        const newValues = setTargetFilterActive(currentFieldName, currentFilterGroup); // Reset values to default

        const newFilter = {
            ...filter,
            groups: {
                [currentFilterGroup as keyof FilterGroupType]: newValues,
            },
        };
        dispatch(changeFilterValue(newFilter));
    };
    const currentFilterArgs = {
        ...filter,
        groups: filter.groups,
        handleChange,
    };
    return { currentFilterArgs, serviceRequestTypeTitlesMap: filters.serviceRequestTypeTitlesMap };
};
export default useFilterIn;

type UseFilterInReturn = {
    currentFilterArgs: FilterProps & {
        handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    };
    serviceRequestTypeTitlesMap: ServiceRequestTypeTitlesMap;
};
