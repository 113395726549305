import { TIPS_AND_TRICKS } from 'core/datasets/action';
import { AppState } from 'core/redux/rootReducer';
import { ITipsAndTricksReducerState, TipsAndTricksActions } from 'tipsAndTricks/types/reduxTypes';
import { ITipsAndTricks } from '../types/tipsAndTricks';

const initState: ITipsAndTricksReducerState = {
    articles: null,
};

export const tipsAndTricksReducer = (state = initState, action: TipsAndTricksActions): ITipsAndTricksReducerState => {
    switch (action.type) {
        case TIPS_AND_TRICKS.SET_ARTICLES: {
            return {
                ...state,
                articles: action.payload,
            };
        }
        default:
            return state;
    }
};

export const helpArticlesSelector: (state: AppState) => ITipsAndTricks[] | null = (state) =>
    state.tipsAndTricks.articles;
