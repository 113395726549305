import { ENVIRONMENT, FIREBASE } from 'core/datasets/action';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { getNewFirebaseUser } from './workers/getNewFirebaseUser';
import { getNewToken } from './workers/getNewToken';
import { firebaseActions } from '../redux/firebaseActions';

function* watchGetNewToken(): Generator {
    yield takeEvery([FIREBASE.GET_NEW_TOKEN], getNewToken);
}

function* watchEnvironmentSettings(): Generator {
    yield takeEvery([ENVIRONMENT.SET_ENVIRONMENT_SETTINGS], function* (): Generator {
        yield put(firebaseActions.getNewToken());
    });
}

function* watchNewTokenSet(): Generator {
    yield takeEvery([FIREBASE.SET_TOKEN], getNewFirebaseUser);
}

export function* firebaseWatchers(): Generator {
    yield all([fork(watchGetNewToken), fork(watchEnvironmentSettings), fork(watchNewTokenSet)]);
}
