import { memo, ReactElement } from 'react';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResources } from 'resources/context';

function ExtraServiceIcon(props: { name: string }): ReactElement {
    const { name } = props;
    const { HomeExtraServicesIcon } = useResources();

    if (name && name in solidIcons) {
        // @ts-ignore - fas icon type
        return <FontAwesomeIcon size={'lg'} icon={solidIcons[`${name}`]} className={'font-awesome-icon'} />;
    }

    return <HomeExtraServicesIcon className={'extra-service-icon-home'} />;
}

export default memo(ExtraServiceIcon);
