import { ApolloError } from '@apollo/react-hooks';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { ErrorPolicy } from 'apollo-boost';
import PageContextType from '../../types/pageContext';
import { AxiosError } from 'axios';
import PAGE_CONTEXT from '../../datasets/page_context';
import { FiltersState } from 'core/types/redux/filtersState';
import { Filter as FilterType, FilterValueType } from '../../types/filter';
import { store } from 'core/redux/store';
import { FILTER_GROUP } from '../../datasets/filter';

type FilterValue = {
    [key: string]: string;
} | null;

export const getFilterValue = (context: PageContextType): FilterValue => {
    if (!context) return null;

    const filter: FilterType = store.getState().filters[context as keyof FiltersState];
    const resetKey = filter.resetAllKey;
    const groups = filter.groups;
    const groupNames = Object.keys(groups);
    let filterValue: FilterValue = null;

    for (const name of groupNames) {
        switch (context) {
            case PAGE_CONTEXT.INVOICES: {
                // @ts-ignore
                const { paid, notPaid } = groups[name];
                filterValue = paid !== notPaid ? { isPaid: paid } : null;
                break;
            }
            case PAGE_CONTEXT.SERVICE_REQUESTS: {
                // @ts-ignore
                const filterValueKeys = Object.keys(groups[name]);
                const selectedFilterValue = filterValueKeys.filter(
                    // @ts-ignore
                    (key) => groups[name][key as keyof FilterValueType],
                );
                if (selectedFilterValue.includes(resetKey)) break;

                const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1);
                const filterKey = `by${capitalizedName}`;
                // @ts-ignore
                const prevFilterValue = filterValue ? filterValue : {};
                filterValue = {
                    ...prevFilterValue,
                    [filterKey as string]:
                        name === FILTER_GROUP.TYPE_ID ? Number(selectedFilterValue[0]) : selectedFilterValue[0],
                };
                break;
            }
            default:
                filterValue = null;
        }
    }
    return filterValue;
};

const useQueryHelper = (): QueryHelper => {
    const ORDER_DIR = {
        asc: 'asc',
        desc: 'desc',
    };
    const ORDER_BY = {
        invoiceDate: 'invoiceDate',
        invoiceNumber: 'invoiceNumber',
        agreementDate: 'agreementDate',
    };

    return { ORDER_DIR, ORDER_BY, getFilterValue };
};
export default useQueryHelper;

type QueryHelper = {
    ORDER_DIR: {
        [id: string]: string;
    };
    ORDER_BY: {
        [id: string]: string;
    };
    getFilterValue: (context: PageContextType) => null | {
        [key: string]: boolean | string;
    };
};

export const QUERY_FETCH_POLICY: QueryFetchPolicy = {
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    pollInterval: 0,
};
export const QUERY_NO_CACHE_FETCH_POLICY: QueryFetchPolicy = {
    partialRefetch: true,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    pollInterval: 0,
};
export const QUERY_DASHBOARD_FETCH_POLICY: QueryFetchPolicy = {
    ...QUERY_FETCH_POLICY,
};
export const QUERY_MEtERS_FETCH_POLICY: QueryFetchPolicy = {
    ...QUERY_FETCH_POLICY,
};

export type QueryStatusData = { loading: boolean; error?: ApolloError | AxiosError };

export enum ORDER_BY {
    invoiceDate = 'invoiceDate',
    invoiceNumber = 'invoiceNumber',
    agreementDate = 'agreementDate',
    submitDate = 'submitDate',
    deadline = 'deadline',
    orderPosition = 'orderPosition',
}

export enum ORDER_DIR {
    asc = 'asc',
    desc = 'desc',
}
type QueryFetchPolicy = {
    partialRefetch: boolean;
    fetchPolicy: WatchQueryFetchPolicy;
    notifyOnNetworkStatusChange: boolean;
    errorPolicy: ErrorPolicy;
    pollInterval: number;
};
