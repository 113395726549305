import { ApolloError } from 'apollo-boost';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import PublicPageContainer from 'core/components/layout/PublicPageContainer';
import { useUpdateLangOnParams } from 'core/components/RoutesSwitcher/hooks/useUpdateLangOnParams';
import { useLoginLinkWithLang } from '../../core/components/LangSelect/hooks/useLoginLinkWithLang';
import { coreActions } from 'core/redux/actions/coreActions';
import ResetPasswordForm from 'pages/ResetPassword/components/ResetPasswordForm';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useResetPasswordController from 'tenantUser/hooks/useResetPasswordController';

const ResetPassword = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { hash } = useParams<{ hash: string }>();
    useUpdateLangOnParams();
    const loginLinkWithLang = useLoginLinkWithLang();

    const {
        handleResetPassword,
        validationMessage,
        loading,
        isValidHash,
        validationError,
        resetPasswordLoading,
        resetPasswordError,
    } = useResetPasswordController(hash || '');

    if (!(hash && hash.trim())) {
        return <Navigate to={'/login'} />;
    }

    if (!loading && (!isValidHash || !!validationError)) {
        console.error('Failed to validate hash: ', validationMessage);

        navigate(loginLinkWithLang);

        dispatch(
            coreActions.notification.set({
                message: 'tenantUser.notices.invalidResetPasswordLink',
                level: NotificationAlert.ERROR,
            }),
        );
    }

    if (!resetPasswordLoading && resetPasswordError) {
        navigate(loginLinkWithLang);

        dispatch(
            coreActions.notification.set({
                message: (resetPasswordError as ApolloError).message,
                level: NotificationAlert.ERROR,
            }),
        );
    }

    return (
        <PublicPageContainer isLoading={loading} hideContentOnLoading>
            <ResetPasswordForm loading={loading} onSubmit={handleResetPassword} />
        </PublicPageContainer>
    );
};

export default ResetPassword;
