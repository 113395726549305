import ClientType from 'core/classes/ClientType';
import GraphQLPayload from 'core/types/GraphQLPayload';
import IWebClient from 'core/types/IWebClient';
import IWebClientConfig from 'core/types/IWebClientConfig';
import { DocumentNode } from 'graphql';

class GraphQLClient {
    private _type = ClientType.GQL_CLIENT;

    private _client?: IWebClient;

    constructor(client: IWebClient) {
        this.client = client;
    }

    get type(): string {
        return this._type;
    }

    set client(value: unknown) {
        if (
            value &&
            typeof value === 'object' &&
            'type' in value &&
            (value as IWebClient).type === ClientType.WEB_CLIENT
        ) {
            this._client = value as IWebClient;
            return;
        }
    }

    get client(): IWebClient {
        if (this._client) {
            return this._client;
        }

        throw new ReferenceError('Undefined client');
    }

    get config(): IWebClientConfig {
        return this.client.config;
    }

    static makePayload(query: DocumentNode, variables: unknown): GraphQLPayload {
        const requestBody = query.loc?.source?.body;
        // @ts-ignore
        const operationName = query.definitions[0]?.name?.value;

        return {
            operationName: operationName,
            query: requestBody,
            variables: variables || {},
        };
    }

    async request(query: DocumentNode, variables?: unknown): Promise<unknown> {
        if (this.client && this.client.postToBaseURL) {
            return await this.client.postToBaseURL(GraphQLClient.makePayload(query, variables));
        }

        throw new ReferenceError('Undefined web client');
    }

    static async request(query: DocumentNode, client: IWebClient, variables?: unknown): Promise<unknown> {
        if (client && client.postToBaseURL && client.type === ClientType.WEB_CLIENT) {
            return client.postToBaseURL(GraphQLClient.makePayload(query, variables));
        }

        throw new ReferenceError('Undefined web client');
    }
}

export default GraphQLClient;
