import { FILE } from 'core/datasets/action';
import { filesActions } from 'file/redux/fileActions';
import { put } from 'redux-saga/effects';

export function* handleDownloadingFileState({ type }: { type: string }): Generator {
    switch (type) {
        case FILE.DOWNLOAD_FILE_REQUEST: {
            yield put(filesActions.changeDownloadingState(true));
            break;
        }
        default: {
            yield put(filesActions.changeDownloadingState(false));
        }
    }
}
