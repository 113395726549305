import INotificationAlertInstanceData from 'core/types/INotificationInstanceData';
import Serializable from 'core/types/Serialisable';

export default interface INotificationAlertInitData {
    message: string;
    level: NotificationAlertLevel;
}

export class NotificationAlert implements Serializable {
    static ERROR = 'ERROR';
    static WARNING = 'WARNING';
    static INFO = 'INFO';
    static SUCCESS = 'SUCCESS';

    private readonly _message: string;
    private readonly _level: NotificationAlertLevel;

    constructor({ message, level }: INotificationAlertInitData) {
        this._message = message;
        this._level = level;
    }

    get message(): string {
        return this._message;
    }

    get level(): NotificationAlertLevel {
        return this._level;
    }

    get all(): INotificationAlertInstanceData {
        return {
            message: this.message,
            level: this.level,
        };
    }

    toJSON(): INotificationAlertInstanceData {
        return this.all;
    }
}

export type NotificationAlertLevel =
    | typeof NotificationAlert.ERROR
    | typeof NotificationAlert.WARNING
    | typeof NotificationAlert.INFO
    | typeof NotificationAlert.SUCCESS;
