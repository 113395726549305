import { Base64DataConversionAction } from 'core/types/redux/CoreStateActions';
import { InvoicePdfInstanceData } from 'invoice/classes/InvoicePdf';
import { IInvoiceData } from 'invoice/types/IInvoiceData';
import { IInvoiceListViewData } from 'invoice/types/IInvoiceListViewData';

import {
    InvoicesFetchedAction,
    CallUpdateForAllInvoicesData,
    UpdateInvoicesDataAction,
    FetchAllInvoicesAction,
    UpdateSingleInvoiceDataAction,
    StartSingleInvoiceUpdate,
    StopSingleInvoiceUpdate,
} from 'invoice/types/redux/InvoiceActions';

import { INVOICE, CORE, INVOICE_TEMP } from 'core/datasets/action';
import { DocumentNode } from 'graphql';
import { InvoicesGraphQLRequestVariables } from 'invoice/types/InvoicesGraphQLRequestVariables';
import {
    FetchInvoicePdfAction,
    InvoicePdfOpenOnNativePlatformAction,
    InvoicePdfUrlGenerateAction,
    RequestPdfAction,
    SelectedInvoiceIdStoreAction,
    StoreInvoicePdfAction,
    TempInvoiceDataDropCall,
} from 'invoice/types/redux/TempInvoiceStateActions';
import { IInvoicePropsData } from '../types/IInvoicePropsData';

export const invoiceActions = {
    invoiceFetched(invoices: { [id: number]: IInvoiceData }): InvoicesFetchedAction {
        return {
            type: INVOICE.INVOICES_FETCHED,
            payload: invoices,
        };
    },
    fetchAllInvoices(gql: DocumentNode, variables: InvoicesGraphQLRequestVariables): FetchAllInvoicesAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: variables,
                callbackType: INVOICE.ALL_INVOICES_RECEIVED,
            },
        };
    },
    updateInvoicesData(payload: {
        list: IInvoiceListViewData[];
        map: { [id: number]: IInvoiceData };
    }): UpdateInvoicesDataAction {
        return {
            type: INVOICE.DATA_UPDATED,
            payload,
        };
    },
    updateSingleInvoice(id: number, data: IInvoicePropsData): UpdateSingleInvoiceDataAction {
        return {
            type: INVOICE.SINGLE_INVOICE_UPDATE,
            payload: { id, data },
        };
    },
    startSingleInvoiceUpdate(id: string): StartSingleInvoiceUpdate {
        return {
            type: INVOICE.START_SINGLE_INVOICE_UPDATE,
            payload: { id },
        };
    },
    stopSingleInvoiceUpdate(id: string): StopSingleInvoiceUpdate {
        return {
            type: INVOICE.STOP_SINGLE_INVOICE_UPDATE,
            payload: { id },
        };
    },
    callFetchAllInvoicesDataUpdate(): CallUpdateForAllInvoicesData {
        return {
            type: INVOICE.FETCH_ALL_INVOICES,
        };
    },
    requestPdf(invoiceId: number): RequestPdfAction {
        return {
            type: INVOICE_TEMP.REQUEST_PDF,
            payload: invoiceId,
        };
    },
    fetchInvoicePdf(gql: DocumentNode, variables: { id: number }): FetchInvoicePdfAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: variables,
                callbackType: INVOICE_TEMP.PDF_DATA_RECEIVED,
            },
        };
    },
    storeInvoicePdf(data: InvoicePdfInstanceData): StoreInvoicePdfAction {
        return {
            type: INVOICE_TEMP.PDF_DATA_STORED,
            payload: data,
        };
    },
    storeSelectedInvoiceId(id: number): SelectedInvoiceIdStoreAction {
        return {
            type: INVOICE_TEMP.SELECTED_INVOICE_ID_STORED,
            payload: id,
        };
    },
    convertBase64PdfDataToUrl(b64Data: string): Base64DataConversionAction {
        return {
            type: CORE.CONVERT_BASE64_TO_URL,
            payload: {
                b64Data,
                mimeType: 'application/pdf',
                callbackAction: INVOICE_TEMP.PDF_URL_GENERATED,
            },
        };
    },
    dropSelectedInvoiceTempData(): TempInvoiceDataDropCall {
        return {
            type: INVOICE_TEMP.SELECTED_INVOICE_TEMP_DATA_DROPPED,
        };
    },
    storePdfBLobUrl(url: string): InvoicePdfUrlGenerateAction {
        return {
            type: INVOICE_TEMP.PDF_URL_GENERATED,
            payload: url,
        };
    },
    openPdfOnAndroidPlatform(pdfData: InvoicePdfInstanceData): InvoicePdfOpenOnNativePlatformAction {
        return {
            type: INVOICE_TEMP.PDF_FILE_OPENED_ON_ANDROID_PLATFORM,
            payload: pdfData,
        };
    },
};
