import { AGREEMENT_OBJECT, RENTAL_OBJECT } from 'core/datasets/action';
import { all, call, takeEvery } from 'redux-saga/effects';
import { handleAgreementObjectSelected, handleReceiveMeterReadings, handleRequestMeterReadings } from './workers';
import { handleSetChartsData } from './workers/handleSetChartsData';

function* watchRequestMeterReadings(): Generator {
    yield takeEvery(RENTAL_OBJECT.REQUEST_METER_READINGS_DATA_INTO_STORE, handleRequestMeterReadings);
}

function* watchReceiveMeterReadings(): Generator {
    yield takeEvery(RENTAL_OBJECT.RECEIVE_METER_READINGS, handleReceiveMeterReadings);
}

function* watchChangeAgreement(): Generator {
    yield takeEvery(AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED, handleAgreementObjectSelected);
}

function* watchSetChartsData(): Generator {
    yield takeEvery(RENTAL_OBJECT.SET_CHARTS_DATA, handleSetChartsData);
}

export function* rentalObjectsWatchers(): Generator {
    yield all([
        call(watchRequestMeterReadings),
        call(watchChangeAgreement),
        call(watchReceiveMeterReadings),
        call(watchSetChartsData),
    ]);
}
