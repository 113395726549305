import { put, select, take } from 'redux-saga/effects';
import { messagingActions } from 'messaging/redux/messagingActions';
import { AppState } from 'core/redux/rootReducer';
import { FIREBASE } from 'core/datasets/action';
import { ChannelViewedAction } from 'messaging/types/redux/messagingActions';
import { updateMessagingLastActivity } from '../../gql/messagigMutations';

export function* channelViewed({ payload }: ChannelViewedAction): Generator {
    let firebaseToken = (yield select(({ firebase }: AppState) => firebase.token)) as any;
    if (!firebaseToken) {
        yield take(FIREBASE.SET_TOKEN);
        firebaseToken = (yield select(({ firebase }: AppState) => firebase.token)) as any;
    }

    if (payload) {
        yield put(messagingActions.updateMessagingLastActivity(updateMessagingLastActivity, payload, firebaseToken));
    }
}
