import moment, { Moment } from 'moment';
import { IMeterReadingsData } from 'rentalObject/types/rentalObjects';
import {
    GroupedChartDataByType,
    IChartData,
    PeriodsUnion,
    PeriodUnit,
    SeededMeterReadingsDataAmongPeriodProps,
} from '../../types/charts';
import {
    APP_DATE_FORMAT,
    DB_DATE_FORMAT,
    formatDate,
    getCurrentDBDate,
    getDay,
    getMonth,
    getYear,
} from 'core/utils/datesHelper';
import CHART_X_UNIT from '../../datasets/chart_x_unit';
import { isEmpty, sum, sumBy } from 'lodash';

/**
 * Seed meter readings data with default values among whole period range
 * Prepare data for manipulating and using in charts
 *
 * PeriodUnit year (switching) ->  xAxisUnit = month (graduation unit on Axis line in chart)
 * PeriodUnit month -> xAxisUnit = day
 * Full Period range: from the oldest meters data period until now
 *
 * @param {IMeterReadingsData[]} meterReadingsData
 * @param {string} chartUnit - defines the X Axis unit, the minimal date unit
 * @param periodEndYear - for test
 */
export const populateMeterDataInFullPeriod = ({
    meterReadingsData,
    chartUnit = CHART_X_UNIT.MONTH,
}: // periodEndYear,

SeededMeterReadingsDataAmongPeriodProps): GroupedChartDataByType[] => {
    let populatedGroupedData: GroupedChartDataByType[] = [];

    if (!meterReadingsData || isEmpty(meterReadingsData)) {
        return populatedGroupedData;
    }

    // Chart data grouped by meter type
    const groupedChartDataByTypes = groupMetersByType(meterReadingsData);
    // We get date range for each counter type
    const { rangeStart, rangeEnd } = getFullPeriodRange(meterReadingsData);

    for (const chartData of groupedChartDataByTypes) {
        const typeWithId = `${chartData.type}__${chartData.meterId}`;
        const { meterId, identifier, type } = chartData;

        const nextChartData: GroupedChartDataByType = {
            typeWithId,
            data: [],
            meterId,
            type,
            identifier,
        };

        // Add diff values for all meters, to ensure that differense is calulated across the whole date range
        const metersDataWithDiffs: IChartData[] = prepareMetersDataWithDiffs(chartData.data);

        const startDate = moment(rangeStart);
        const endDate = moment(rangeEnd);

        // Calculate chart data for every month and add to the grouped chart data
        while (startDate.isSameOrBefore(endDate)) {
            const year = startDate.format('YYYY');
            const month = startDate.format('MM');
            const day = startDate.format('DD');
            const date = startDate.format(APP_DATE_FORMAT);

            nextChartData.data.push({
                year,
                month,
                day,
                date,
                typeWithId,
                type,
                meterId,
                unit: chartData.data[0].unit || 'None',
                amount: getAmountForPeriod(metersDataWithDiffs, startDate),
                diff: getDiffByPeriod(metersDataWithDiffs, startDate),
            });
            startDate.add(1, chartUnit);
        }

        populatedGroupedData = [...populatedGroupedData, nextChartData];
    }

    return populatedGroupedData;
};

export const getAmountFromDataOnDate = (
    data: IChartData[],
    date: moment.Moment,
    chartUnit: PeriodUnit = CHART_X_UNIT.MONTH,
): { amount: number; difference: number } => {
    const currentYear = date.format('YYYY');
    const currentMonth = date.format('MM');

    const filterByMonth = ({ year, month }: IChartData): boolean => {
        if (year && month) {
            return +year === +currentYear && +month === +currentMonth;
        }

        return false;
    };

    const filterByDay = ({ year, month, day }: IChartData): boolean => {
        if (year && month && day) {
            const currentDay = date.format('DD');
            return +year === +currentYear && +month === +currentMonth && +day === +currentDay;
        }

        return false;
    };

    const meterData = data.filter(chartUnit === CHART_X_UNIT.MONTH ? filterByMonth : filterByDay);

    const difference = getTotalDifference(meterData);
    const amount = meterData && meterData.length > 0 ? meterData[0].amount : 0;

    return { amount, difference };
};

function getTotalDifference(meterData: IChartData[]): number {
    const diffs: number[] = meterData
        .map((chartData) => chartData.amount)
        .map((amount, index, amountsList): number => {
            const nextAmount = amountsList[index + 1];
            if (nextAmount) {
                return parseFloat((amount - nextAmount).toFixed(2));
            }

            return 0;
        });

    return parseFloat(sum(diffs).toFixed(2));
}

export const getDiffAmountFromDataOnDate = (
    data: IChartData[],
    date: moment.Moment,
    chartUnit: PeriodUnit = CHART_X_UNIT.MONTH,
): number => {
    const { amount: currentAmount } = getAmountFromDataOnDate(data, date, chartUnit as PeriodUnit);
    const prevDate = date.clone().subtract(1, chartUnit);
    const { amount: prevAmount } = getAmountFromDataOnDate(data, prevDate, chartUnit);

    const diff = currentAmount - prevAmount;

    return 0 < diff ? diff : 0;
};

const getFullPeriodRange = (meterReadingsData: IMeterReadingsData[]): { rangeStart: string; rangeEnd: string } => {
    const theOldestMeterDate = getTheEarliestMeterReadingDate(meterReadingsData);

    return {
        rangeStart: theOldestMeterDate.startOf('year').startOf('month').format(DB_DATE_FORMAT),
        rangeEnd: moment(getCurrentDBDate()).endOf('year').endOf('month').format(DB_DATE_FORMAT),
    };
};

const groupMetersByType = (meterReadingsData: IMeterReadingsData[]): GroupedChartDataByType[] => {
    const groupedData: GroupedChartDataByType[] = [];

    const typesWithId = getMeterTypesFrom(meterReadingsData).sort();
    const flatChartData = getFlatDataFrom(meterReadingsData);

    for (const typeWithId of typesWithId) {
        const chartData = flatChartData.filter((item) => item.typeWithId === typeWithId);

        if (isEmpty(chartData)) {
            continue;
        }

        const [type] = typeWithId.split('__');
        const meterId = chartData[0].meterId;
        const identifier = chartData[0].identifier;

        groupedData.push({
            data: chartData,
            typeWithId,
            type,
            meterId,
            identifier,
        });
    }

    return groupedData;
};

const getTheEarliestMeterReadingDate = (meterReadingsData: IMeterReadingsData[]): Moment => {
    let theEarliestMeterReadingDate = moment(meterReadingsData[0].period);

    for (const meterReading of meterReadingsData) {
        const nextDate = moment(meterReading.period);
        if (nextDate.isValid() && nextDate.isBefore(theEarliestMeterReadingDate)) {
            theEarliestMeterReadingDate = nextDate;
        }
    }

    return theEarliestMeterReadingDate;
};

export const getMeterTypesFrom = (meterReadings: IMeterReadingsData[]): string[] => {
    let meterTypes: string[] = [];

    if (meterReadings && meterReadings.length > 0) {
        const allMeterTypes = meterReadings.map(({ meter }) => {
            const { type, id } = meter;

            return `${type}__${id}`;
        });

        meterTypes = allMeterTypes.filter((v, i, a) => a.indexOf(v) === i);
    }

    return meterTypes;
};

export const getDataYears = (meterReadingsData: IMeterReadingsData[]): (undefined | string)[] => {
    const flatData = getFlatDataFrom(meterReadingsData);

    return flatData && flatData.length > 0
        ? flatData.map((item) => item.year).filter((v, i, a) => i === a.indexOf(v))
        : [];
};

const getFlatDataFrom = (data: IMeterReadingsData[]): IChartData[] => {
    return data.map(({ date, meter, period, amount }: IMeterReadingsData): IChartData => {
        const formattedDate = formatDate(date);
        const { type, identifier, unit, id } = meter;

        return {
            date: formattedDate,
            period: formatDate(period),
            year: getYear(period ? period : date),
            month: getMonth(period ? period : date),
            day: getDay(period ? period : date),
            amount: amount,
            diff: 0,
            unit: unit,
            type: type,
            typeWithId: `${type}__${id}`,
            identifier: identifier,
            meterId: id,
        };
    });
};

export const prepareDataForChartsWithXAxisUnit = (
    metersData: IMeterReadingsData[],
    chartUnit: PeriodsUnion,
): GroupedChartDataByType[] =>
    populateMeterDataInFullPeriod({
        meterReadingsData: metersData,
        chartUnit,
        periodEndYear: undefined, // WTF? Not enough time to properly investigate this variable.
    });

export { findLastNotZeroAmount } from './findLastNotZeroAmount';

function prepareMetersDataWithDiffs(chartsData: IChartData[]): IChartData[] {
    const metersDataWithDiffs: IChartData[] = [];
    let index = 0;

    for (const meterData of chartsData) {
        const nextMeterData = chartsData[index + 1];
        if (nextMeterData) {
            meterData.diff = parseFloat((meterData.amount - nextMeterData.amount).toFixed(2));
        }

        metersDataWithDiffs.push(meterData);
        index += 1;
    }

    return metersDataWithDiffs;
}

function collectChartDateForPeriod(chartsData: IChartData[], period: Moment): IChartData[] {
    const chartsDataForPeriod: IChartData[] = [];

    for (const chart of chartsData) {
        const chartPeriod = moment(chart.period, APP_DATE_FORMAT);
        if (chartPeriod.isValid() && chartPeriod.isSame(period, 'year') && chartPeriod.isSame(period, 'month')) {
            chartsDataForPeriod.push(chart);
        }
    }

    return chartsDataForPeriod;
}

function getAmountForPeriod(chartsData: IChartData[], period: Moment): number {
    const chartsDataForPeriod = collectChartDateForPeriod(chartsData, period);

    return isEmpty(chartsDataForPeriod) ? 0 : parseFloat(chartsDataForPeriod[0].amount.toFixed(2));
}

function getDiffByPeriod(chartsData: IChartData[], period: Moment): number {
    const chartsDataForPeriod: IChartData[] = [];

    for (const chart of chartsData) {
        const chartPeriod = moment(chart.period, APP_DATE_FORMAT);
        if (chartPeriod.isValid() && chartPeriod.isSame(period, 'year') && chartPeriod.isSame(period, 'month')) {
            chartsDataForPeriod.push(chart);
        }
    }

    const diff = parseFloat(sumBy(chartsDataForPeriod, 'diff').toFixed(2));

    return diff;
}
