import { AppState } from 'core/redux/rootReducer';
import { apply, put, select } from 'redux-saga/effects';
import { larvaDoorAccessActions } from 'smartHome/larva/redux/actions/larvaDoorAccessActions';
import ILarvaDoorAccess from 'smartHome/larva/types/doorAccess/ILarvaDoorAccess';
import {
    DoorAccessListViewItemSelectAction,
    SelectFirstAvailableDoorAccessAndRedirectToViewAction,
} from 'smartHome/larva/types/redux/TempLarvaStateActions';

export function* selectDoorAccess({ payload: id }: DoorAccessListViewItemSelectAction): Generator {
    const doorAccessMap = (yield select(({ larva }: AppState) => larva.doorAccess.doorAccessMap)) as {
        [id: string]: ILarvaDoorAccess;
    };

    if (Object.keys(doorAccessMap).indexOf(id) === -1) {
        console.error('Door access Id not found', [doorAccessMap, id]);
        return;
    }

    yield put(larvaDoorAccessActions.selectDoorAccess(doorAccessMap[id]));
}

export function* showFirstAvailableDoorAccess({
    payload: navigate,
}: SelectFirstAvailableDoorAccessAndRedirectToViewAction): Generator {
    const doorAccessMap = (yield select(({ larva }: AppState) => larva.doorAccess.doorAccessMap)) as {
        [id: string]: ILarvaDoorAccess;
    };

    const isFullViewMode = yield select(({ core }: AppState) => core.isFullViewMode);

    if (Object.values(doorAccessMap).length) {
        const access = Object.values(doorAccessMap)[0];

        const url = `/door-access/${isFullViewMode ? access.id : ''}`;

        yield put(larvaDoorAccessActions.selectDoorAccessListViewItem(access.id));

        //@ts-ignore
        yield apply(navigate, navigate, [url]);
    }
}
