import { updateAgreementObjectsData } from 'agreementObject/saga/workers/updateAgreementObjectsData';
import { AGREEMENT, AGREEMENT_OBJECT } from 'core/datasets/action';
import { all, fork, takeEvery } from 'redux-saga/effects';

import {
    initSelectObject,
    selectAgreementObjectById,
    selectAgreementObjectOnSelectedAgreementChange,
} from 'agreementObject/saga/workers';

function* watchPutObjects(): Generator {
    yield takeEvery(AGREEMENT_OBJECT.PUT_AGREEMENT_OBJECTS, initSelectObject);
}

function* watchAgreementSelected(): Generator {
    yield takeEvery(AGREEMENT.AGREEMENT_SELECTED, selectAgreementObjectOnSelectedAgreementChange);
}

function* watchAgreementObjectSelect(): Generator {
    yield takeEvery(AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED_BY_ID, selectAgreementObjectById);
}

function* watchAgreementsDataUpdate(): Generator {
    yield takeEvery(AGREEMENT.DATA_UPDATED, updateAgreementObjectsData);
}

export function* agreementObjectWatchers(): Generator {
    yield all([
        fork(watchPutObjects),
        fork(watchAgreementObjectSelect),
        fork(watchAgreementsDataUpdate),
        fork(watchAgreementSelected),
    ]);
}
