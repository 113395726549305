import { RentalObjectActions } from 'rentalObject/types/redux/rentalObjectActions';
import { RentalObjectsState } from '../types/redux/rentalObjectsState';
import { ACTION, RENTAL_OBJECT } from 'core/datasets/action';
import UserSettingsStore from '../../core/utils/localStorage/UserSettingsStore';

const userSettings = new UserSettingsStore();
const smartHome = userSettings.getSelectedSmartHomeConfig();

const initialState: RentalObjectsState = {
    selectedObjectID: '0',
    selectedObject: {
        id: '0',
        agreementID: 0,
        agreementUUID: userSettings.getSelectedAgreementUUID() || '',
        roomNumber: userSettings.getSelectedObjectRoomNumber() || '',
        meters: [],
        allowAddMeterReadings: false,
        smartHome: smartHome,
    },
    meterReadingsData: [],
    rentalObjectsMeterReadings: {},
    chartsDataSet: {
        chartsData: {
            types: [],
            data: [],
        },
        dataYears: [],
        paths: [],
    },
    metersInLoad: false,
};

/**
 *
 * @param state
 * @param action
 * @deprecated use agreementObject instead
 */
export const rentalObjectsReducer = (state = initialState, action: RentalObjectActions): RentalObjectsState => {
    switch (action.type) {
        case RENTAL_OBJECT.SELECT_RENTAL_OBJECT:
            return {
                ...state,
                selectedObjectID: action.payload,
                selectedObject: {
                    ...state.selectedObject,
                    agreementUUID: userSettings.getSelectedAgreementUUID(),
                    agreementID: userSettings.getSelectedAgreementID(),
                    roomNumber: userSettings.getSelectedObjectRoomNumber(),
                    id: action.payload,
                },
            };
        case RENTAL_OBJECT.SAVE_SMART_HOME_CONFIG:
            return {
                ...state,
                selectedObject: {
                    ...state.selectedObject,
                    smartHome: action.payload,
                },
            };
        case RENTAL_OBJECT.PUT_METER_READINGS_DATA_INTO_STORE: {
            const rentalObjectsMeterReadings = {
                ...state.rentalObjectsMeterReadings,
                [state.selectedObjectID]: action.payload,
            };
            // TODO: remove side effect action from redux
            localStorage.setItem('rentalObjectsMeterReadings', JSON.stringify(rentalObjectsMeterReadings));
            return {
                ...state,
                meterReadingsData: action.payload,
                rentalObjectsMeterReadings: rentalObjectsMeterReadings,
            };
        }
        case RENTAL_OBJECT.SET_CHARTS_DATA:
            return {
                ...state,
                chartsDataSet: action.payload,
            };
        case RENTAL_OBJECT.CLEAN_CHARTS_DATA:
            return {
                ...state,
                meterReadingsData: initialState.meterReadingsData,
                rentalObjectsMeterReadings: initialState.rentalObjectsMeterReadings,
                chartsDataSet: initialState.chartsDataSet,
            };
        case RENTAL_OBJECT.SET_OBJECT_METERS: {
            const { meters, allowAddMeterReadings } = action.payload;
            return {
                ...state,
                selectedObject: {
                    ...state.selectedObject,
                    meters,
                    allowAddMeterReadings,
                },
            };
        }
        case RENTAL_OBJECT.REQUEST_METER_READINGS_DATA_INTO_STORE:
            return {
                ...state,
                metersInLoad: true,
            };
        case RENTAL_OBJECT.RECEIVE_METER_READINGS:
            return {
                ...state,
                metersInLoad: false,
            };
        case ACTION.AUTH.LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
};
