import { co2MetersAction } from 'core/datasets/action';
import { ICleanCO2AndMetersAction, ISetCO2MetersAction, ISetCurrentMonthIdAction } from 'co2Meters/types/reduxTypes';
import { ICO2MeterReadingEmissionsWithStartDate } from 'co2Meters/types/co2Meters';

export const co2Actions = {
    setCO2Meters(data: ICO2MeterReadingEmissionsWithStartDate | null): ISetCO2MetersAction {
        return {
            type: co2MetersAction.SET_CO2_AND_METERS,
            payload: data,
        };
    },
    setCurrentMonthId(index: string | null): ISetCurrentMonthIdAction {
        return {
            type: co2MetersAction.SET_CURRENT_MONTH_INDEX,
            payload: index,
        };
    },
    cleanCO2AndMeters(): ICleanCO2AndMetersAction {
        return {
            type: co2MetersAction.CLEAN_CO2_AND_METERS,
        };
    },
};
