class DoorPinCode {
    static GENERATE = 'GENERATE';
    static UNSET = 'UNSET';

    static REGEX = /^SET$|^GENERATE$|^UNSET$|^[0-9]{6}$/i;

    static REGEX_PIN_VALUE = /^[0-9]{6}$/i;
    private _value = DoorPinCode.UNSET;

    constructor(pin?: string) {
        this.value = pin;
    }

    static isValid(pin: unknown): boolean {
        if (!!pin && typeof pin === 'string') {
            return DoorPinCode.REGEX.test(pin.trim());
        }

        return false;
    }

    static isNumeric(pin: unknown): boolean {
        if (!!pin && typeof pin === 'string') {
            return DoorPinCode.REGEX_PIN_VALUE.test(pin.trim());
        }

        return false;
    }

    get value(): string {
        if (!!this._value) return this._value;

        throw new ReferenceError('Undefined pin value');
    }

    set value(pin: unknown) {
        if (typeof pin === 'undefined') {
            return;
        }

        if (DoorPinCode.isValid(pin)) {
            this._value = (pin as string).trim().toUpperCase();
            return;
        }

        throw new TypeError('Invalid pin value');
    }

    get toJSON(): string {
        return this.value;
    }
}
export default DoorPinCode;
