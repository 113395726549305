import { Base64DataConversionAction } from 'core/types/redux/CoreStateActions';
import { put } from 'redux-saga/effects';

const b64toBlob = (b64Data: string, mimeType: string, sliceSize = 512) => {
    // @ts-ignore
    // eslint-disable-next-line no-constant-condition
    const byteChars = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteChars.length; offset += sliceSize) {
        const slice = byteChars.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: mimeType });
    // eslint-disable-next-line compat/compat
    return URL.createObjectURL(blob);
};

/*
// @ts-ignore
import * as _worker from 'workerize-loader!./../../webworkers/base64toBlobWorker.js';
const worker = new _worker();
*/

export function* handleBase64DataConversion({ payload }: Base64DataConversionAction): Generator {
    const { b64Data, mimeType, callbackAction } = payload;

    //const url = yield apply(worker, worker.b64toBlob, [b64Data, mimeType]);
    const url = b64toBlob(b64Data, mimeType);

    yield put({
        type: callbackAction,
        payload: url,
    });
}
