import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceRequestTypeTitlesMap } from 'serviceRequest/types/serviceRequest';
import CheckBox from '../CheckBox';
import { FILTER_GROUP } from '../../../datasets/filter';

interface FilterCheckboxProps {
    valueName: string;
    name: string;
    resetAllKey: string;
    groupName: string;
    isEnabled: boolean;
    isChecked: boolean;
    onClick: (e: ChangeEvent<HTMLInputElement>) => void;
    serviceRequestTypeTitlesMap?: ServiceRequestTypeTitlesMap;
}

const FilterCheckbox = ({
    valueName,
    resetAllKey,
    groupName,
    isEnabled,
    onClick,
    isChecked,
    name,
    serviceRequestTypeTitlesMap,
}: FilterCheckboxProps): JSX.Element => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const isResetFilterKey = resetAllKey === valueName;
    const inactiveFieldClass = isEnabled || isResetFilterKey ? '' : 'filter-values__item--inactive';

    let customName = t(`filters.${name}.${groupName}.${valueName}`);
    const internalTitle = serviceRequestTypeTitlesMap?.[valueName]?.['internalTitle'];
    const customLabelTextCondition = groupName === FILTER_GROUP.TYPE_ID && serviceRequestTypeTitlesMap?.[valueName];
    if (customLabelTextCondition) {
        customName =
            serviceRequestTypeTitlesMap?.[valueName]?.[language] ||
            serviceRequestTypeTitlesMap?.[valueName]?.['en'] ||
            '';
    }
    const labelText = internalTitle ? t(`maintenanceServices.types.${internalTitle}`) : customName;

    return (
        <CheckBox
            valueName={valueName}
            inputProps={{ 'data-group': groupName }}
            checkBoxClasses={`${name}-filter-checkbox filter-checkbox`}
            isChecked={isChecked}
            onChange={onClick}
            labelText={labelText}
            labelClasses={'filter-values__item ' + inactiveFieldClass}
        />
    );
};
export default FilterCheckbox;
