import { AppState } from 'core/redux/rootReducer';
import { InvoicePdf } from 'invoice/classes/InvoicePdf';
import { invoiceActions } from 'invoice/redux/invoiceActions';
import { HandleReceivedInvoicePdfAction } from 'invoice/types/redux/TempInvoiceStateActions';
import { apply, call, put, select } from 'redux-saga/effects';

export function* handleReceivedInvoicePdf({ payload }: HandleReceivedInvoicePdfAction): Generator {
    const invoicePdfData = payload.data?.invoicePdf;

    if (!invoicePdfData) {
        console.error("[handleReceivedInvoicePdf]: Failed to fetch invoice PDF's data");
        return;
    }

    const selectedInvoiceId = (yield select(({ tempInvoices }: AppState) => tempInvoices.currentInvoiceId)) as
        | number
        | null;

    if (!selectedInvoiceId) {
        console.log('[handleReceivedInvoicePdf saga]: undefined selectedInvoiceId');
        return;
    }

    const invoicePdf = new InvoicePdf({ ...invoicePdfData, invoiceId: selectedInvoiceId });

    yield put(invoiceActions.storeInvoicePdf(invoicePdf.all));

    if (invoicePdf?.file?.url) {
        yield getInvoicePdfBlobUrl(invoicePdf?.file?.url);
        return;
    }

    if (invoicePdf.content) {
        yield put(invoiceActions.convertBase64PdfDataToUrl(invoicePdf.content));
    }
}

function* getInvoicePdfBlobUrl(url: string): Generator {
    try {
        const pdfBlob = (yield call(fetchFile, url)) as Blob;
        // eslint-disable-next-line compat/compat
        const pdfUrl = (yield apply(URL, URL.createObjectURL, [pdfBlob])) as string;

        yield put(invoiceActions.storePdfBLobUrl(pdfUrl));
    } catch (e) {
        console.error('[getInvoicePdfBlobUrl]: Failed!, ', e);
    }
}

async function fetchFile(url: string): Promise<Blob> {
    try {
        const response = await fetch(url);

        return response.blob();
    } catch (e) {
        throw new Error('Failed to fetch');
    }
}
