import { QUERY_FETCH_POLICY } from 'core/hooks/useQueryHelper';
import { AppState } from 'core/redux/rootReducer';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { ExtraServiceFromOrder } from '../../../types/extraServices';
import { getNormalizedExtraServices } from '../../../utils/useExtraServiceHelper';
import { useTranslation } from 'react-i18next';
import { keyBy } from 'lodash';

export const ExtraServicesCollectionQueryDocument = gql`
    query availableExtraServices($rentalAgreementId: Int!) {
        availableExtraServices(rentalAgreementId: $rentalAgreementId) {
            id
            name {
                langCode
                text
            }
            description {
                langCode
                text
            }
            active
            icon
            paymentType
            price
            currency
            visibleInTenantApp
            amount
        }
    }
`;

type ApiResponse = { availableExtraServices: ExtraServiceFromOrder[] };

export default function useQueryExtraServicesCollection() {
    const { i18n } = useTranslation();

    const selectedAgreementId = useSelector(({ agreements }: AppState) => agreements.selectedAgreement?.id);
    const selectedAgreementParentId = useSelector(
        ({ agreements }: AppState) => agreements.selectedAgreement?.parentAgreementId,
    );

    const variables = {
        rentalAgreementId: selectedAgreementParentId || selectedAgreementId,
    };

    const { data, loading, error, called, refetch } = useQuery<ApiResponse>(ExtraServicesCollectionQueryDocument, {
        variables,
        ...QUERY_FETCH_POLICY,
        skip: !selectedAgreementId,
    });

    const extraServices = getNormalizedExtraServices(i18n.language, data?.availableExtraServices || []);
    const extraServicesMap = keyBy(extraServices, 'id');

    return {
        loading,
        error,
        extraServices,
        extraServicesMap,
        called,
        refetch,
    };
}
