import { AnyAction } from 'redux';
import { delay, fork, put, select } from 'redux-saga/effects';
import { find, isEqual, isString } from 'lodash';
import { cancel, cancelled } from 'redux-saga-test-plan/matchers';
import { gql } from '@apollo/client';
import inceptionSelectors from '../redux/inceptionSelectors';
import { AxiosResponse } from 'axios';
import GraphQLClient from 'core/classes/GraphQLClient';
import WebClient from 'core/classes/WebClient';
import WebClientConfig from 'core/classes/WebClientConfig';
import { gqlUri as baseURL } from 'core/config/gql/client';
import { AppState } from 'core/redux/rootReducer';
import { InceptionStatusApiResponse, InceptionUnit } from '../types/inception';
import { inceptionActions } from '../redux/inceptionActionCreators';
import { coreActions } from '../../../core/redux/actions/coreActions';
import { selectedObjectIDSelector } from '../../../agreementObject/redux/agreementObjectsReducer';
import { agreementCollectionSelector } from 'agreement/redux/agreementsReducer';
import IAgreementInstanceData from 'agreement/types/IAgreementData';

const pollTasks = new Map();

const INCEPTION_UNIT_STATUS = gql`
    query getInceptionUnitState($uuid: String!) {
        getInceptionUnitState(uuid: $uuid) {
            statuses
            success
            unitType
            uuid
        }
    }
`;

function* makePollRequest(uuid: string): Generator {
    const token = (yield select(({ auth }: AppState) => auth.token)) as string;
    const currentStatuses = (yield select(inceptionSelectors.inceptionUnitStatusSelector(uuid))) as string[] | null;

    if (token && currentStatuses) {
        try {
            const client = new GraphQLClient(
                new WebClient(
                    new WebClientConfig({
                        headers: {
                            'x-auth-token': token,
                        },
                        baseURL,
                    }),
                ),
            );
            const response = (yield client.request(INCEPTION_UNIT_STATUS, {
                uuid,
            })) as AxiosResponse;

            if ('errors' in response.data) {
                for (const error of response.data.errors) {
                    yield put(coreActions.notification.setError(error.message));
                }
            } else {
                const {
                    data: {
                        getInceptionUnitState: { statuses },
                    },
                } = response.data as InceptionStatusApiResponse;

                if (!isEqual(statuses, currentStatuses)) {
                    yield put(inceptionActions.statusUpdated(response.data));
                }
            }
        } catch (error) {
            const { message } = error as Error;
            yield put(coreActions.notification.setError(message));
        }
    }
}

export function* pollDoorStatus(uuid: string): Generator {
    try {
        while (true) {
            yield makePollRequest(uuid);
            yield delay(3000);
        }
    } finally {
        if (yield cancelled()) {
            yield console.info('Door polling task cancelled', { uuid });
        }
    }
}

export function* pollerRunner(action: AnyAction): Generator {
    const { payload: appIsActive } = action;

    if (appIsActive) {
        const uuids = (yield select(inceptionSelectors.inceptionUnitUuidsSelector)) as string[];
        yield startPolling(uuids);
    } else {
        yield stopPolling();
    }
}
export function* restartPollerFromScratch(): Generator {
    const uuids = (yield select(inceptionSelectors.inceptionUnitUuidsSelector)) as string[];

    yield stopPolling();
    yield startPolling(uuids);
}

export function* handleAgreementSelected(): Generator {
    const agreements = (yield select(agreementCollectionSelector)) as IAgreementInstanceData[];
    const selectedObjectId = (yield select(selectedObjectIDSelector)) as number | null;
    let smartHomeUnits: InceptionUnit[] | null = null;

    if (agreements.length && selectedObjectId) {
        for (const agreement of agreements) {
            const agreementObject = find(agreement.objects, ['id', selectedObjectId]);
            if (agreementObject) {
                smartHomeUnits = agreement.smartHomeUnits || null;
                break;
            }
        }
    }

    yield stopPolling();
    yield put(inceptionActions.unitsFetched(smartHomeUnits ? smartHomeUnits : []));
}

function* startPolling(uuids: string[]): Generator {
    for (const uuid of uuids) {
        if (isString(uuid)) {
            const pollTask = yield fork(pollDoorStatus, uuid);
            yield pollTasks.set(uuid, pollTask);
        }
    }
}

function* stopPolling(): Generator {
    for (const [uuid, task] of pollTasks.entries()) {
        yield cancel(task);
        yield pollTasks.delete(uuid);
    }
}
