import { AgreementStatus } from 'agreement/AgreementStatus';
import IAgreementInstanceData from 'agreement/types/IAgreementData';

export const selectFirstActiveAgreementFromMap = (
    map: {
        [id: number]: IAgreementInstanceData;
    },
    agreementId?: number | string,
    objectId?: number | null,
): IAgreementInstanceData | null => {
    if (!map || !Object.values(map).length) return null;

    const _agreementId = parseInt(`${agreementId}`);

    if (
        _agreementId &&
        _agreementId in map &&
        AgreementStatus.isActive({
            agreement: map[_agreementId],
            objectId: objectId || null,
        })
    ) {
        return map[_agreementId];
    }

    return Object.values(map).find((agreement) => AgreementStatus.isActive({ agreement, objectId: null })) || null;
};
