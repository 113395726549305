import { QueryStatusData } from 'core/hooks/useQueryHelper';
import { useTranslation } from 'react-i18next';
import useMutationResetPwd from 'tenantUser/api/gql/useMutationResetPwd';

const useResetPasswordDataDecorator = (): useResetPasswordDataAdapterReturn => {
    const { t } = useTranslation();
    const { data, error, loading, handleResetPwd } = useMutationResetPwd();
    const text = data?.requestResetPasswordLink?.message || '';

    let isRequestable = true;
    let responseText = '';

    if (text === 'Reset request sent.') {
        responseText = t('notices.requestSent');
        isRequestable = false;
    }
    if (text === 'Invalid email.') responseText = t('notices.invalidEmail');

    if (text.includes('You have already requested')) {
        responseText = t('notices.duplicatedRequest');
        isRequestable = false;
    }

    return { handleResetPwd, error, loading, responseText, isRequestable };
};
export default useResetPasswordDataDecorator;
type useResetPasswordDataAdapterReturn = QueryStatusData & {
    handleResetPwd: (email: string) => void;
    responseText: string;
    isRequestable: boolean;
};
