import { FIREBASE } from 'core/datasets/action';
import { AppState } from 'core/redux/rootReducer';
import { child, get, getDatabase, limitToLast, query, ref } from 'firebase/database';
import { messagingActions } from 'messaging/redux/messagingActions';
import { AddChannelKeyAction } from 'messaging/types/redux/messagingActions';
import { call, putResolve, select, take } from 'redux-saga/effects';

interface MessageDataFromFirebase {
    id: string;
    message: string;
    timestamp: number;
    user: string;
}

export function* fetchChannelMessages({ payload }: AddChannelKeyAction): Generator {
    let userId = (yield select(({ firebase }: AppState) => firebase.user?.uid)) as any;
    if (!userId) {
        yield take([FIREBASE.SET_FIREBASE_USER]);
        userId = (yield select(({ firebase }: AppState) => firebase.user.uid)) as any;
    }

    if (payload) {
        const dbRef = ref(getDatabase());
        const dbKey = `channels/${payload}/messages`;
        const result = yield call(get, query(child(dbRef, dbKey), limitToLast(25)));

        const messagesData = (result as any).val() as any;
        const messages = (
            Object.keys(messagesData).map((id) => ({ id, ...messagesData[id] })) as MessageDataFromFirebase[]
        ).sort((a, b) => a.timestamp - b.timestamp);

        yield putResolve(
            messagingActions.setChannelMessageList(
                payload,
                messages.map((item) => ({
                    id: item.id,
                    authorId: item.id,
                    channelId: payload,
                    author: null,
                    timestamp: item.timestamp,
                    isCurrentUser: item.user === userId,
                    text: item.message,
                })),
            ),
        );
    }
}
