import ConfirmationRequestModal from 'core/components/modalsPortalsNotices/ConfirmationRequestModal';
import LabeledText from 'core/components/documentItems/LabeledText';
import { useTranslation } from 'react-i18next';
import { ExtraServiceActionType, ExtraServiceOrderFormData } from 'extraService/types/extraServiceOrder';
import { EXTRA_SERVICE_ORDER_ACTION } from 'extraService/datasets/extra_service_order_action';
import { APP_LUX_DATE_FORMAT } from 'core/utils/datesHelper';
import PAYMENT_TYPE from 'extraService/datasets/payment_type';
import { ModalProps } from 'core/types/modal';
import { ApolloError } from '@apollo/react-hooks';
import { AxiosError } from 'axios';
import 'pages/ExtraService/components/ExtraServiceRequestConfirmationModal/styles/ExtraServiceRequestConfirmationModal.css';
import { generatedExtraServiceFormClasses } from 'extraService/utils/useExtraServiceHelper';

interface ExtraServiceRequestConfirmationModalProps {
    action: ExtraServiceActionType | null;
    submitHandler: () => void;
    paymentType: string;
    serviceName: string;
    formData: ExtraServiceOrderFormData;
    price: string;
    modalArgs: ModalProps;
    loading?: boolean;
    error?: ApolloError | AxiosError;
}
const ExtraServiceRequestConfirmationModal = ({
    action,
    submitHandler,
    paymentType,
    serviceName,
    formData,
    price,
    modalArgs,
    loading,
    error,
}: ExtraServiceRequestConfirmationModalProps): JSX.Element => {
    const { t } = useTranslation();
    const classes = generatedExtraServiceFormClasses;
    const isOrderAction = action === EXTRA_SERVICE_ORDER_ACTION.ORDER;
    const confirmationTextKey = `extraServices.order.form.confirmation.${paymentType.toLowerCase()}.${action?.toLowerCase()}`;

    const isRepeatingPayment = paymentType === PAYMENT_TYPE.REPEATING;

    const serviceDates = `${formData.fromDate?.toFormat(APP_LUX_DATE_FORMAT)} ${
        isRepeatingPayment
            ? ' - ' + (formData.untilDate?.isValid ? formData.untilDate.toFormat(APP_LUX_DATE_FORMAT) : '...')
            : ''
    }`;

    return (
        <ConfirmationRequestModal
            loading={loading}
            onConfirm={submitHandler}
            baseClass={'extra-service-order'}
            modalArgs={modalArgs}
            error={error}
        >
            <>
                <div className={''}>{t(confirmationTextKey)}</div>
                <div className={classes.confirmationDataContainer}>
                    <div className={classes.confirmationDataRow}>
                        <LabeledText
                            className={classes.confirmationDataItem}
                            primaryText={serviceName}
                            labelText={t('extraServices.order.form.confirmation.labels.serviceName')}
                        />

                        <LabeledText
                            className={classes.confirmationDataItem}
                            primaryText={serviceDates}
                            labelText={t(
                                `extraServices.order.form.confirmation.labels.${
                                    isRepeatingPayment ? 'servicePeriod' : 'serviceDate'
                                }`,
                            )}
                        />

                        <LabeledText
                            className={classes.confirmationDataItem}
                            primaryText={price}
                            labelText={t('extraServices.order.form.confirmation.labels.price')}
                        />
                    </div>

                    {isRepeatingPayment && isOrderAction && (
                        <div className={classes.confirmationDataRow}>
                            <LabeledText
                                className={classes.confirmationDataItem}
                                primaryText={formData?.amount?.toString() || '0'}
                                labelText={t('extraServices.order.form.confirmation.labels.amount')}
                            />
                        </div>
                    )}
                </div>
            </>
        </ConfirmationRequestModal>
    );
};
export default ExtraServiceRequestConfirmationModal;
