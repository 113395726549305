import { gql, useQuery } from '@apollo/client';
import {
    IServiceRequestTypeV2,
    ServiceRequestTypeTitlesMap,
    ServiceRequestTypeMap,
} from 'serviceRequest/types/serviceRequest';
import { QUERY_FETCH_POLICY, QueryStatusData } from '../../../../core/hooks/useQueryHelper';
import {
    generateServiceRequestTypeMap,
    generateServiceRequestTypeNamesMap,
} from 'serviceRequest/utils/serviceRequestUtils';
import { useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';

const ServiceRequestTypesDocument = gql`
    query serviceRequestTypesV3($rentalAgreementUuid: String!) {
        serviceRequestTypesV3(rentalAgreementUuid: $rentalAgreementUuid) {
            id
            internalTitle
            isActive
            isDefault
            titles {
                langCode
                text
            }
        }
    }
`;

const useQueryServiceRequestTypes = (): QueryServiceRequestTypesReturn => {
    const selectedRentalAgreement = useSelector((state: AppState) => state.agreements.selectedAgreement);
    const rentalAgreementUuid = selectedRentalAgreement?.uuid || '';
    const { loading, error, data } = useQuery<{ serviceRequestTypesV3: IServiceRequestTypeV2[] }>(
        ServiceRequestTypesDocument,
        { variables: { rentalAgreementUuid }, ...QUERY_FETCH_POLICY },
    );

    const serviceTypesList = data?.serviceRequestTypesV3 || [];

    return {
        loading,
        error,
        data: serviceTypesList,
        serviceRequestTypeMap: generateServiceRequestTypeMap(serviceTypesList),
        serviceRequestTypeTitlesMap: generateServiceRequestTypeNamesMap(serviceTypesList),
    };
};

export default useQueryServiceRequestTypes;

type QueryServiceRequestTypesReturn = QueryStatusData & {
    data: IServiceRequestTypeV2[];
    serviceRequestTypeMap: ServiceRequestTypeMap;
    serviceRequestTypeTitlesMap: ServiceRequestTypeTitlesMap;
};
