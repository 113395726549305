import { FIREBASE, MESSAGING } from 'core/datasets/action';
import { AppState } from 'core/redux/rootReducer';
import { getDatabase, off, ref } from 'firebase/database';
import { messagingActions } from 'messaging/redux/messagingActions';
import { Message } from 'messaging/types/messages';
import { SelectChannelAction } from 'messaging/types/redux/messagingActions';
import { fork, put, select, take } from 'redux-saga/effects';
import { EventFirebase } from '../../../firebase/classes';
import { onItemAddedWatcher } from '../../../firebase/saga/workers/onItemAddedWatcher';

export function* channelSelected({ payload }: SelectChannelAction): Generator {
    let firebaseUser = (yield select(({ firebase }: AppState) => firebase.user)) as any;
    if (!firebaseUser) {
        yield take([FIREBASE.SET_FIREBASE_USER]);
        firebaseUser = (yield select(({ firebase }: AppState) => firebase.user)) as any;
    }

    const previousChannelId = (yield select(({ messaging }: AppState) => messaging.previousChannelId)) as string;
    if (previousChannelId) {
        off(ref(getDatabase(), `channels/${previousChannelId}/messages`), EventFirebase.CHILD_ADDED);
    }

    if (payload) {
        const dbKey = `channels/${payload}/messages`;

        yield fork(
            onItemAddedWatcher,
            dbKey,
            MESSAGING.ADD_CHANNEL_MESSAGE,
            (data): Message | null => {
                const value = data.val();

                return {
                    id: data.key ?? '',
                    text: value.message,
                    authorId: value.user,
                    timestamp: value.timestamp,
                    channelId: payload,
                    isCurrentUser: value.user === firebaseUser.uid,
                };
            },
            true,
            25,
        );

        yield put(messagingActions.channelViewed(payload));
    }
}
