import CountryCode from 'core/classes/CountryCode';
import countries from 'core/datasets/countries.json';
import CountryData from 'core/types/CountryData';
class CountryDialCode {
    static DEFAULT_DIAL_CODE = '+372';
    private _value = CountryDialCode.DEFAULT_DIAL_CODE;

    constructor(dialCode?: string) {
        this.value = dialCode;
    }
    static isValid(dialCode: unknown): boolean {
        return CountryDialCode.has(dialCode);
    }
    static has(dialCode: unknown): boolean {
        return (
            !!dialCode &&
            typeof dialCode === 'string' &&
            /^\+\d+/.test(dialCode.trim()) &&
            !!countries.filter(({ dial_code }: CountryData) => dial_code === dialCode.trim().toUpperCase()).length
        );
    }

    static byCountryCode(countryCode: string): string {
        if (CountryCode.isValid(countryCode)) {
            return countries.filter(({ code }: CountryData) => code === countryCode.trim().toUpperCase())[0].dial_code;
        }

        throw new TypeError('Invalid country code');
    }

    get value(): string {
        return this._value;
    }

    set value(dialCode: unknown) {
        if (CountryDialCode.isValid(dialCode)) {
            this._value = (dialCode as string).trim().toUpperCase();
            return;
        }

        throw new TypeError('Invalid dial code');
    }
}
export default CountryDialCode;
