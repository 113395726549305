import { Grid } from '@material-ui/core';
import useTenantRegistrationFormController from 'tenantUser/hooks/useTenantRegistrationFormController';
import ControlledInputField from 'core/components/formControlledFields/InputField';
import PasswordControlledField from 'core/components/formControlledFields/PasswordControlledField/PasswordControlledField';
import TermsAndConditionsCheck from 'core/components/TermsAndConditionsCheck';
import SubmitButton from 'core/components/buttons/SubmitButton';

import 'pages/TenantRegistration/components/TenantRegistrationForm/TenantRegistrationForm.css';
import { LogoBig } from 'core/components/Logo';
import LangSelect, { LANG_SELECT_VARIANT } from 'core/components/LangSelect';
import InvitedTenantData from 'tenantUser/types/InvitedTenantData';
import { TenantRegistrationData } from 'tenantUser/types/TenantRegistrationData';

interface TenantRegistrationFormProps {
    data: InvitedTenantData;
    onSubmit: (tenant: TenantRegistrationData) => void;
    loading: boolean;
}

const TenantRegistrationForm = ({ data, onSubmit, loading }: TenantRegistrationFormProps): JSX.Element => {
    const { control, termsArgs, submitButtonArgs, _t, extraTermsArgs } = useTenantRegistrationFormController({
        data,
        onSubmit,
        loading,
    });

    return (
        <form className={'tenant-registration-form'}>
            <div className={'tenant-registration-form__header'}>
                <LogoBig />
                <LangSelect variant={LANG_SELECT_VARIANT.ICON_STANDARD} />
            </div>
            <Grid container spacing={3}>
                <ControlledInputField disabled={true} control={control} name={'email'} label={_t('email')} />

                <ControlledInputField control={control} name={'firstName'} label={_t('firstName')} disabled />

                <ControlledInputField control={control} name={'lastName'} label={_t('lastName')} disabled />

                <ControlledInputField
                    disabled={true}
                    control={control}
                    name={'phone'}
                    label={_t('telephone')}
                    inputProps={{ min: 0 }}
                />

                <PasswordControlledField
                    control={control}
                    name={'password'}
                    id={'password'}
                    label={_t('password')}
                    isRequired={!data?.cognito_uuid}
                    helperText={!!data?.cognito_uuid ? _t('keepPasswordFieldEmptyToUseExisting') : ''}
                />

                <PasswordControlledField
                    control={control}
                    name={'passwordConfirmation'}
                    id={'passwordConfirmation'}
                    label={_t('passwordConfirmation')}
                    isRequired={!data?.cognito_uuid}
                />

                <Grid item xs={12}>
                    <TermsAndConditionsCheck {...termsArgs} />
                </Grid>

                {extraTermsArgs && (
                    <Grid item xs={12}>
                        <TermsAndConditionsCheck {...extraTermsArgs} />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <SubmitButton {...submitButtonArgs} />
                </Grid>
            </Grid>
        </form>
    );
};

export default TenantRegistrationForm;
