import { agreementActions } from 'agreement/redux/agreementActions';
import { AgreementChangeRequestModalStateAction } from 'agreement/types/redux/TempAgreementStateActions';
import { delay, put } from 'redux-saga/effects';

export function* handleAgreementChangeRequestClearingOnDelay({
    payload,
}: AgreementChangeRequestModalStateAction): Generator {
    if (!payload) {
        yield delay(500);
        yield put(agreementActions.clearChangeRequestCall());
    }
}
