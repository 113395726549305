import { resources } from 'resources/default';

export const { ExtraServiceMenuIcon, TipsAndTricks } = resources;
export { default } from 'pages/ExtraServices/components/ExtraServiceIcon/ExtraServiceIcon';

/* icons on the server
 case self::parking:
                return 'fa-parking';
            case self::bike:
                return 'fa-bicycle';
            case self::storage_space:
                return 'fa-door-open';
            case self::stroller_space:
                return 'fa-baby-carriage';
            case self::television:
                return 'fa-tv';
            case self::utensils:
                return 'fa-utensils';
            case self::bed_sheets:
                return 'fa-bed';
            case self::cleaning_broom:
                return 'fa-broom';
            case self::bidrento:
            default:
                return 'icon-bidrento';
 */
