import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import EN from 'translations/en.json';
import ET from 'translations/et.json';
import PL from 'translations/pl.json';
import LV from 'translations/lv.json';
import RO from 'translations/ro.json';
import DE from 'translations/de.json';
import LT from 'translations/lt.json';

//@TODO Seems like this file is not used anywhere

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: EN,
        },
        et: {
            translation: ET,
        },
        pl: {
            translation: PL,
        },
        lv: {
            translation: LV,
        },
        ro: {
            translation: RO,
        },
        de: {
            translation: DE,
        },
        lt: {
            translation: LT,
        },
    },
    lng: 'et',
    fallbackLng: 'en',
    supportedLngs: ['en', 'et', 'pl', 'lv', 'ro', 'de', 'lt'],
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});
export default i18n;
