import i18n from 'i18next';
import { LinkData } from 'meterReading/types/LinkData';
import { IMeterReadingsData } from 'rentalObject/types/rentalObjects';

export const getMeterLinksData = (meterReadingsData: IMeterReadingsData[], selectedObjectID: number) => {
    let getMeterLinksData: LinkData[] = [];

    if (meterReadingsData && meterReadingsData.length > 0) {
        getMeterLinksData = meterReadingsData.map(({ meter }) => {
            const { type, id } = meter;

            return {
                text: i18n.t('documents.meterReadings.' + type),
                path: `meter-reading/${selectedObjectID}/${type}--${id}`,
                typeWithId: `${type}__${id}`,
                type: type,
            };
        });
    }

    return getMeterLinksData;
};
