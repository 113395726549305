import { gql } from 'graphql-tag';

export const gqlRequest = gql`
    mutation getInvoicePdf($id: Int!) {
        invoicePdf(id: $id) {
            content
            mimetype
            name
            file {
                url
                lifetime
            }
        }
    }
`;
