export class ResponseStatus {
    static CODE_200 = 200;
    static CODE_201 = 201;
    static CODE_400 = 400;
    static CODE_401 = 401;

    static isOk(code: unknown): boolean {
        return /^2\d\d$/i.test(typeof code === 'string' ? code.trim() : `${code}`);
    }

    static isUnauthorizedCode(code: unknown): boolean {
        return `${ResponseStatus.CODE_401}` === `${code}`;
    }

    static isTokenExpiredMessage(value: unknown): boolean {
        return /jwt expired/i.test(`${value}`);
    }

    static isRequestTimeOutHappened(message: unknown): boolean {
        return /Request timed out/i.test(`${message}`);
    }

    static isInvalidAccessTokenMessage(message: unknown): boolean {
        return /Invalid auth token/i.test(`${message}`);
    }

    static isInvalidRefreshTokenMessage(message: unknown): boolean {
        return /Invalid refresh token/i.test(`${message}`);
    }
}
