import { DateTime } from 'luxon';
import { DB_LUX_DATE_TIME_FORMAT } from 'core/utils/datesHelper';

export const parseSelectedRentalAgreementValidFromDate = (storedFromDate: string) => {
    if (!storedFromDate?.length) return null;

    const parsedFromDate = DateTime.fromFormat(storedFromDate, DB_LUX_DATE_TIME_FORMAT);

    if (!parsedFromDate.isValid) {
        console.error('Failed to parse date', {
            parsedFromDate,
            reason: parsedFromDate.invalidReason,
            explain: parsedFromDate.invalidExplanation,
        });

        if (process.env.NODE_ENV === 'development') {
            throw new Error(
                `Failed to parse date: ${parsedFromDate.invalidReason}, ${parsedFromDate.invalidExplanation}`,
            );
        }
    }

    return parsedFromDate;
};
