import { AppState } from '../../../core/redux/rootReducer';
import { map } from 'lodash';
import { InceptionUnit } from '../types/inception';

const inceptionUnitSelector =
    (uuid: string) =>
    (state: AppState): InceptionUnit | null => {
        let result: InceptionUnit | null = null;

        if (state.inception.smartHomeUnits) {
            result = state.inception.smartHomeUnits[uuid];
        }

        return result;
    };

const inceptionUnitUuidsSelector = (state: AppState): string[] => {
    let result: string[] = [];

    if (state.inception.smartHomeUnits) {
        result = map(state.inception.smartHomeUnits, (door) => door.uuid);
    }

    return result;
};

const inceptionUnitStatusSelector =
    (uuid: string) =>
    (state: AppState): string[] | null => {
        let result: string[] | null = null;

        const smartHomeUnits = state.inception.smartHomeUnits;
        if (smartHomeUnits) {
            result = smartHomeUnits[uuid]?.statuses || null;
        }

        return result;
    };

export default {
    inceptionUnitSelector,
    inceptionUnitUuidsSelector,
    inceptionUnitStatusSelector,
};
