import { Grid } from '@material-ui/core';
import SubmitButton from 'core/components/buttons/SubmitButton';
import PasswordControlledField from 'core/components/formControlledFields/PasswordControlledField/PasswordControlledField';
import LangSelect, { LANG_SELECT_VARIANT } from 'core/components/LangSelect';
import { LogoBig } from 'core/components/Logo';
import useResetPasswordFormController from 'tenantUser/hooks/useResetPasswordFormController';
import './ResetPasswordForm.css';

interface ResetPasswordFormProps {
    onSubmit(password: string): void;
    loading: boolean;
}

const ResetPasswordForm = ({ onSubmit, loading }: ResetPasswordFormProps): JSX.Element => {
    const { control, submitButtonArgs, _t } = useResetPasswordFormController({ onSubmit, loading });
    return (
        <form className="reset-password-form">
            <div className={'tenant-registration-form__header tenant-registration-form__header--eften'}>
                <LogoBig />
                <LangSelect variant={LANG_SELECT_VARIANT.ICON_STANDARD} />
            </div>
            <Grid container spacing={3}>
                <PasswordControlledField
                    control={control}
                    name={'password'}
                    id={'password'}
                    label={_t('password')}
                    isRequired={true}
                />

                <PasswordControlledField
                    control={control}
                    name={'passwordConfirmation'}
                    id={'passwordConfirmation'}
                    label={_t('passwordConfirmation')}
                    isRequired={true}
                />

                <Grid item xs={12}>
                    <SubmitButton {...submitButtonArgs} />
                </Grid>
            </Grid>
        </form>
    );
};

export default ResetPasswordForm;
