import { INVOICE } from 'core/datasets/action';
import { InvoiceActions } from 'invoice/types/redux/InvoiceActions';
import { InvoicesState } from 'invoice/types/redux/InvoicesState';
import { updateSingleInvoice, turnOnLoading, turnOffLoading } from '../utils/updateInvoiceReducerHelpers';
import { invoicePropsDataToInvoiceData } from 'invoice/utils/invoiceApiMapper';

const initState: InvoicesState = {
    list: [],
    map: {},
};

export const invoicesReducer = (state = initState, action: InvoiceActions): InvoicesState => {
    switch (action.type) {
        case INVOICE.DATA_UPDATED: {
            const { map, list } = action.payload;
            return {
                ...state,
                map,
                list,
            };
        }
        case INVOICE.SINGLE_INVOICE_UPDATE: {
            const { id, data } = action.payload;
            return {
                ...state,
                map: { ...state.map, [id]: invoicePropsDataToInvoiceData(data) },
                list: state.list.map((item) => updateSingleInvoice(item, data)),
            };
        }
        case INVOICE.START_SINGLE_INVOICE_UPDATE: {
            const { id } = action.payload;
            return {
                ...state,
                list: state.list.map((item) => turnOnLoading(item, id)),
            };
        }
        case INVOICE.STOP_SINGLE_INVOICE_UPDATE: {
            const { id } = action.payload;
            return {
                ...state,
                list: state.list.map((item) => turnOffLoading(item, id)),
            };
        }
        default:
            return state;
    }
};
