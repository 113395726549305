import { AgreementStatus } from 'agreement/AgreementStatus';
import { agreementActions } from 'agreement/redux/agreementActions';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { SelectAgreementObjectByIdAction } from 'agreementObject/types/redux/agreementObjectActions';
import { AppState } from 'core/redux/rootReducer';
import { put, select } from 'redux-saga/effects';
import { handleAgreementSelected } from 'smartHome/inception/saga/poller';

export function* handleAgreementSelectionByObjectId({ payload: objectId }: SelectAgreementObjectByIdAction): Generator {
    const agreementsMap = (yield select(({ agreements }: AppState) => agreements.map)) as {
        [id: number]: IAgreementInstanceData;
    };

    if (!agreementsMap) {
        console.error('No agreements for selection');
        return;
    }

    for (const agreement of Object.values(agreementsMap)) {
        for (const object of agreement.objects) {
            if (AgreementStatus.isActive({ agreement, objectId: object.id }) && object.id === objectId) {
                yield put(agreementActions.setSelectedObjectAgreement(agreement));
                break;
            }
        }
    }

    // This function handles any agreement change in settings selector, so for now the most suitable place to manage inception units is here
    yield handleAgreementSelected();
}
