import { AppState } from '../../../redux/rootReducer';
import { EnvironmentType } from '../../../types/EnvironmentType';
import { MenuItems } from '../../../types/redux/MenuReducerState';
import IAgreementObjectInstanceData from '../../../../agreementObject/types/IAgreementObjectData';

type MenuItemsSelector = (state: AppState) => MenuItems;

function shouldHideMeterReadings(agreementObjectsList: { [p: number]: IAgreementObjectInstanceData } | null): boolean {
    let hideMeterReadings = true;

    if (agreementObjectsList) {
        for (const agreementObject of Object.values(agreementObjectsList)) {
            if (agreementObject.meters && agreementObject.meters.length > 0) {
                hideMeterReadings = false;
                break;
            }
        }
    }

    return hideMeterReadings;
}

export const menuItemsSelector =
    (appType: EnvironmentType): MenuItemsSelector =>
    (state: AppState): MenuItems => {
        const menuItems = state.menu.menuItems;
        const isEcampusApp = appType === 'ecampus';

        // Ugly workaround to bypass App store restrictions
        if (isEcampusApp) {
            menuItems['Files'].labelText = 'documents';
            menuItems['TipsAndTricks'].labelText = 'notifications';
        }

        // Another workaround for TTU, to hide meters page if no meters are in agreement objects
        const hideMeterReadings = shouldHideMeterReadings(state.agreementObjects.objects);
        if (isEcampusApp && hideMeterReadings) {
            delete menuItems['MeterReadings'];
        }

        return menuItems;
    };
