import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { coreActions } from '../../../../../core/redux/actions/coreActions';
import { setLogout } from '../../../../../tenantUser/redux/authActions';

const SELF_DELETE = gql`
    mutation SelfDelete {
        selfDeleteTenant {
            success
        }
    }
`;

interface SelfDeleteResult {
    selfDeleteTenant: {
        success: boolean;
    };
}

export default function useSelfDelete(): {
    selfDelete: () => Promise<void>;
    loading: boolean;
} {
    const dispatch = useDispatch();
    const [selfDeleteQuery, { loading, error }] = useMutation<SelfDeleteResult>(SELF_DELETE);

    const selfDelete = async (): Promise<void> => {
        const result = await selfDeleteQuery();

        if (result.data?.selfDeleteTenant.success) {
            dispatch(setLogout());
        }
    };

    useEffect(() => {
        if (error) {
            dispatch(coreActions.notification.setError(error.message));
        }
    }, [error, dispatch]);

    return {
        loading,
        selfDelete,
    };
}
