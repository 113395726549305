import React, { useState } from 'react';
import SubmitButton from 'core/components/buttons/SubmitButton';
import DeleteNotification from './DeleteNotification';
import useSelfDelete from './hooks/useSelfDelete';
import { useTranslation } from 'react-i18next';
import './styles/DeleteAccount.css';

export default function DeleteAccountLink(): React.ReactElement {
    const { t } = useTranslation();

    const [confirmationVisible, setConfirmationVisible] = useState(false);

    const { selfDelete, loading } = useSelfDelete();

    const title = t('deleteAccount.linkTitle');
    const text = t('deleteAccount.confirmationText');

    return (
        <div className="settings-list__item delete-account-item">
            <a
                href={''}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setConfirmationVisible(!confirmationVisible);
                }}
            >
                {title}
            </a>
            {confirmationVisible ? (
                <>
                    <DeleteNotification text={text} />
                    <SubmitButton
                        text={t('callToAction.confirmYes')}
                        actionHandler={selfDelete}
                        loading={loading}
                        classes={'confirm-button'}
                        centerLoader={true}
                    />
                </>
            ) : null}
        </div>
    );
}
