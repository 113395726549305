import { Browser } from '@capacitor/browser';
import { AppState } from 'core/redux/rootReducer';
import { InvoicePdf } from 'invoice/classes/InvoicePdf';
import { invoiceActions } from 'invoice/redux/invoiceActions';
import { InvoicePdfOpenOnNativePlatformAction } from 'invoice/types/redux/TempInvoiceStateActions';
import { apply, put, select } from 'redux-saga/effects';

export function* handleOpeningInvoicePdfOnAndroidPlatform({
    payload: pdfData,
}: InvoicePdfOpenOnNativePlatformAction): Generator {
    if (!pdfData.fileUrl) {
        const replaceAll = (text: string, search: RegExp, replacer: string) => {
            let newText = text;
            const matches = text.match(search);
            if (!!matches && matches.length) matches.forEach(() => (newText = text.replace(search, replacer)));
            return newText;
        };

        const cleanFileName = replaceAll(pdfData.name, /\W/gi, '_');

        const cleanPdfName = `${cleanFileName}.pdf`;

        //@ts-ignore missing PreviewAnyFile in window (for android)
        yield window.PreviewAnyFile.previewBase64(
            //@ts-ignore
            null,
            //@ts-ignore
            null,
            `data:application/pdf;base64, ${pdfData.content}`,
            { name: cleanPdfName },
        );

        return;
    }

    const id = (yield select(({ tempInvoices }: AppState) => tempInvoices.currentInvoiceId)) as number;
    const isActiveUrl = InvoicePdf.isLinkALive(pdfData.receivedAt, pdfData.fileLifetime || 60);

    if (!id) {
        console.error('[handleOpeningInvoicePdfOnNativePlatform]: No invoice id!');
        return;
    }

    if (!isActiveUrl || !pdfData?.fileUrl) {
        yield put(invoiceActions.requestPdf(+id));

        return;
    }

    yield apply(Browser, Browser.open, [{ url: pdfData.fileUrl }]);
}
