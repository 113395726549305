import { Button } from '@material-ui/core';
import { ModalWindow } from '../ModalWindow';
import { makeComponentClasses } from 'core/utils/componentUtils';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../buttons/SubmitButton';
import { ApolloError } from '@apollo/react-hooks';
import { AxiosError } from 'axios';
import ErrorNotice from '../ErrorNotice';

export interface IConfirmationRequestModal {
    modalArgs: {
        closed: boolean;
        handleClosed: () => void;
        size: string;
        showTitle: boolean;
        modalTitle?: string;
        confirmLabel?: string;
        cancelLabel?: string;
        modalClasses?: string;
    };
    baseClass: string;
    customClasses?: string;
    onConfirm: () => void;
    loading?: boolean;
    disabled?: boolean;
    error?: ApolloError | AxiosError;
}
export interface ConfirmationRequestModalProps extends IConfirmationRequestModal {
    children: JSX.Element | string;
}
const ConfirmationRequestModal = ({
    modalArgs,
    children,
    onConfirm,
    loading,
    error,
    disabled = false,
    baseClass = '',
    customClasses = '',
}: ConfirmationRequestModalProps): JSX.Element => {
    const { t } = useTranslation();
    const componentStructure = {
        base: !!baseClass.trim() ? baseClass : 'modal-items',
        notificationText: 'notification-text',
        actionButton: 'action-button',
        actionButtons: 'action-buttons',
        cancelButton: 'action-buttons--terminate',
        confirmButton: 'action-buttons--confirm',
        errorContainer: 'error-container',
    };
    const classes = makeComponentClasses(componentStructure, customClasses, 'modal-items');

    const confirmButtonLabel = modalArgs.confirmLabel || t('callToAction.confirmYes') || '';
    const cancelButtonLabel = modalArgs.cancelLabel || t('callToAction.confirmNo');
    const loadingLabel = t('notices.loading');

    const modalClasses = modalArgs.modalClasses ? modalArgs.modalClasses : '';
    const cancelClasses = `${classes.actionButton} ${classes.cancelButton}`;
    const confirmClasses = `${classes.actionButton} ${classes.confirmButton}`;

    return (
        <ModalWindow classes={`confirmation-modal-window ${modalClasses}`} {...(modalArgs as any)}>
            <div className={classes.base}>
                <div className={classes.notificationText}>{children}</div>
                <div className={classes.actionButtons}>
                    <Button onClick={modalArgs.handleClosed} className={cancelClasses}>
                        {cancelButtonLabel}
                    </Button>
                    <SubmitButton
                        text={confirmButtonLabel}
                        classes={confirmClasses}
                        actionHandler={onConfirm}
                        disabled={loading || disabled}
                        loading={loading}
                        loadingText={loadingLabel}
                    />
                </div>
                <div className={classes.errorContainer}>
                    <ErrorNotice error={error} />
                </div>
            </div>
        </ModalWindow>
    );
};
export default ConfirmationRequestModal;
