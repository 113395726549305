import { InvoiceListViewItem } from 'invoice/classes/InvoiceListViewItem';
import { IInvoiceData } from 'invoice/types/IInvoiceData';
import { IInvoiceListViewData } from 'invoice/types/IInvoiceListViewData';
import { HandleReceivedInvoicesAction } from 'invoice/types/redux/InvoiceActions';
import { put, select } from 'redux-saga/effects';
import { invoiceActions } from 'invoice/redux/invoiceActions';
import { AppState } from 'core/redux/rootReducer';
import { invoicePropsDataToInvoiceData } from 'invoice/utils/invoiceApiMapper';

const asPaymentNotificationSelector = ({ environment }: AppState) => environment.invoiceAsPaymentNotification;

export function* handleReceivedInvoices({ payload }: HandleReceivedInvoicesAction): Generator {
    const {
        data: { invoices },
    } = payload;

    const asPaymentNotification = (yield select(asPaymentNotificationSelector)) as boolean;

    const list: IInvoiceListViewData[] = [];
    const mapData: { [id: number]: IInvoiceData } = {};

    let defaultSelectedInvoice: IInvoiceData | null = null;

    for (const data of invoices) {
        const invoice = invoicePropsDataToInvoiceData(data);

        list.push(new InvoiceListViewItem(invoice, asPaymentNotification).listViewData);
        mapData[invoice.id] = invoice;

        if (defaultSelectedInvoice === null) {
            defaultSelectedInvoice = invoice;
        }
    }

    yield put(invoiceActions.updateInvoicesData({ list, map: mapData }));
}
