import MenuButton from '../MenuButton';
import { useTranslation } from 'react-i18next';
import { useResources } from 'resources/context';

interface MenuToggleButtonProps {
    onClick: () => void;
    isOpen: boolean;
}

const MenuToggleButton = ({ onClick, isOpen: isActiveState }: MenuToggleButtonProps): JSX.Element => {
    const { BurgerIcon } = useResources();
    const { t } = useTranslation();
    const { CloseIcon } = useResources();

    const containerClasses = 'menu-icon-toggle-btn ' + (isActiveState ? 'menu-icon-toggle-btn--active' : '');
    const closeIconClasses =
        'menu-icon-toggle-btn__icon menu-icon-toggle-btn__icon--' + (isActiveState ? 'active' : 'hidden');
    const burgerIconClasses =
        'menu-icon-toggle-btn__icon menu-icon-toggle-btn__icon--' + (isActiveState ? 'hidden' : 'active');
    const buttonClasses = 'menu-icon-toggle-btn__button';

    return (
        <MenuButton
            labelText={t('menu.menu')}
            classes={containerClasses}
            buttonClasses={buttonClasses}
            onClick={onClick}
        >
            <CloseIcon className={closeIconClasses} />
            <BurgerIcon className={burgerIconClasses} />
        </MenuButton>
    );
};

export default MenuToggleButton;
