import Agreement from 'agreement/classes/Agreement';
import { AgreementType } from 'agreement/classes/AgreementType';
import { IObjectAgreementScopeIData } from 'agreement/classes/ObjectAgreementScope';
import { agreementActions } from 'agreement/redux/agreementActions';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { ITenant } from 'agreement/types/ITenant';
import {
    AgreementExtensionProcessCallAction,
    AgreementTerminationProcessCallAction,
} from 'agreement/types/redux/TempAgreementStateActions';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import { EnvironmentType } from 'core/types/EnvironmentType';
import { formatDbDateTimeToAppDate, isTodayOrFuture } from 'core/utils/datesHelper';
import { ILicensePlate } from 'rentalObject/types/rentalObjects';

export class AgreementDetailsViewData {
    get licensePlates(): string {
        return this._licensePlates;
    }

    set licensePlates(value: string) {
        this._licensePlates = value;
    }
    get agreementNumber(): string {
        return this._agreementNumber;
    }

    set agreementNumber(value: string) {
        this._agreementNumber = value;
    }

    get paymentDate(): string {
        return this._paymentDate;
    }

    set paymentDate(value: string | null) {
        this._paymentDate = value || '-';
    }
    get agreementPeriod(): string {
        if (!this._agreementPeriod) {
            throw new TypeError('[AgreementDetailsViewData]: agreementPeriod is not defined');
        }

        return this._agreementPeriod;
    }

    set agreementPeriod(value: string | null) {
        if (!value) {
            throw new TypeError('[AgreementDetailsViewData]: Invalid set agreementPeriod');
        }
        this._agreementPeriod = value;
    }

    get billingSender(): string {
        return this._billingSender;
    }

    set billingSender(value: string) {
        this._billingSender = value;
    }

    get address(): string {
        return this._address || '-';
    }

    set address(value: string | null) {
        this._address = value;
    }

    get tenants(): string {
        return this._tenants;
    }

    set tenants(value: string) {
        this._tenants = value;
    }

    private _tenants = '-';
    private _address: string | null = null;
    private _billingSender = '-';
    private _agreementPeriod = '-';
    private _licensePlates = '-';
    private _paymentDate = '-';
    private _agreementNumber = '-';

    constructor(agreement: IAgreementInstanceData) {
        this.tenants = AgreementDetailsViewData.buildTenantNames(agreement.tenants as ITenant[]);
        this.address = AgreementType.isSingleObject(agreement.type)
            ? AgreementDetailsViewData.buildAddress(agreement.objects[0])
            : null;
        this.billingSender = (agreement.billingSender as { senderName: string })?.senderName || '-';
        this.agreementPeriod = AgreementDetailsViewData.buildPeriod(agreement.agreementDate, agreement.agreementEnd);
        this.paymentDate = (agreement?.paymentDate && `${agreement.paymentDate}`.trim()) || '-';
        this.agreementNumber = (agreement?.agreementNumber && `${agreement.agreementNumber}`.trim()) || '-';
        this.licensePlates = AgreementDetailsViewData.buildLicensePlates(agreement.licensePlates);
    }

    static buildTenantNames(tenants: ITenant[]): string {
        const tenantsNamesArray =
            (tenants as ITenant[])?.map((tenant: ITenant) => {
                const { legalEntityName, legalEntity, firstName, lastName } = tenant;

                return legalEntity ? legalEntityName : firstName + ' ' + lastName;
            }) || [];

        return tenantsNamesArray.join();
    }

    static buildAddress(object: IAgreementObjectInstanceData): string {
        if (!object.id || !Object.keys(object).length) return '';

        const { street, houseNumber, apartmentNumber, roomNumber, city } = object;

        const roomApartmentNumbers = apartmentNumber ? '-' + apartmentNumber : roomNumber ? '-' + roomNumber : '';

        return `${street} ${houseNumber}${roomApartmentNumbers}, ${city}`;
    }

    static buildPeriod(startDate: string | null, endDate: string | null): string {
        const startPeriod = startDate && formatDbDateTimeToAppDate(startDate);

        if (!startDate || !startPeriod) {
            throw new TypeError('[AgreementDetailsViewData]: Invalid agreement start date');
        }

        const endPeriod = (endDate && formatDbDateTimeToAppDate(endDate)) || '...';

        return `${startPeriod} - ${endPeriod}`;
    }

    static buildLicensePlates(plates: ILicensePlate[]): string {
        return plates ? plates.map((licensePlate: ILicensePlate) => licensePlate.value).join(', ') : '';
    }

    static buildAgreementObjectRowsData(
        agreement: IAgreementInstanceData,
        moneyFormatter: (value: number | string, currency?: string) => string,
        extensionEnabled: boolean,
        appType: EnvironmentType,
    ): IAgreementObjectsRowsData[] {
        const rows: IAgreementObjectsRowsData[] = [];
        const isSingleObject = AgreementType.isSingleObject(agreement.type);

        for (const object of agreement.objects) {
            const period = isSingleObject
                ? AgreementDetailsViewData.buildPeriod(agreement.agreementDate, agreement.agreementEnd)
                : object?.agreement
                ? AgreementDetailsViewData.buildPeriod(
                      object?.agreement?.agreementStart,
                      object?.agreement?.agreementEnd,
                  )
                : '-';

            const paymentValue = (isSingleObject ? agreement.paymentAmount : object.agreement?.paymentAmount) || null;
            const payment = paymentValue ? moneyFormatter(paymentValue, agreement.currency || '') : '-';

            const depositValue = (isSingleObject ? agreement.deposit : object.agreement?.deposit) || null;
            const deposit = depositValue ? moneyFormatter(depositValue, agreement.currency || '') : '-';

            const licensePlates = isSingleObject
                ? AgreementDetailsViewData.buildLicensePlates(agreement.licensePlates)
                : object.agreement
                ? AgreementDetailsViewData.buildLicensePlates(object.agreement.licensePlates || [])
                : '-';

            const _agreement = isSingleObject ? agreement : (object.agreement as IObjectAgreementScopeIData);

            const agreementEnd: string | null | undefined = isSingleObject
                ? agreement.agreementEnd
                : object.agreement?.agreementEnd;

            const extendable = !!agreementEnd && isTodayOrFuture(agreementEnd);

            const terminateAgreementTitle =
                appType === 'ecampus'
                    ? 'documents.rentalAgreement.terminateAgreementTTU'
                    : 'documents.rentalAgreement.terminateAgreement';

            const row: IAgreementObjectsRowsData = {
                //hidden
                _actionsMenuData: {
                    agreementId: agreement.id,
                    objectId: object.id,
                    objectAgreementUuid: isSingleObject ? agreement.uuid : object.agreement?.uuid,
                    isAvailable:
                        (Agreement.isActive(_agreement) || Agreement.isFuture(_agreement)) &&
                        !isSingleObject &&
                        agreement.objects.length > 1,
                    actions: {
                        extend: {
                            isVisible: extensionEnabled && Agreement.isActive(_agreement) && extendable,
                            title: 'documents.rentalAgreement.extendAgreement',
                            call: agreementActions.callExtensionProcess(object?.agreement?.uuid),
                        },
                        terminate: {
                            isVisible: true,
                            title: terminateAgreementTitle,
                            call: agreementActions.callTerminationProcess(object?.agreement?.uuid),
                        },
                    },
                },
                agreementPeriod: period,
                address: AgreementDetailsViewData.buildAddress(object),
                paymentAmount: payment || '-',
                deposit: deposit || '-',
                licensePlates: {
                    value: licensePlates,
                    agreementId: agreement.id,
                    isEditable: Agreement.isActive(_agreement),
                    objectAgreementUuid: object.agreement?.uuid,
                },
            };

            rows.push(row);
        }

        return rows;
    }
}

export interface IAgreementObjectsRowsData {
    _actionsMenuData: IAgreementObjectActions;
    agreementPeriod: string;
    address: string;
    paymentAmount: string | null;
    deposit: string | null;
    licensePlates: string | { [id: string]: unknown };
}

export interface IAgreementObjectActions {
    agreementId: number;
    objectId: number;
    objectAgreementUuid?: string;
    isAvailable: boolean;
    actions: {
        extend: ObjectAction;
        terminate: ObjectAction;
    };
}
interface ObjectAction {
    title: string;
    call: AgreementTerminationProcessCallAction | AgreementExtensionProcessCallAction;
    isDisabled?: boolean;
    isVisible?: boolean;
}
