import { useTranslation } from 'react-i18next';
import { ModalWindow } from '../ModalWindow';
import './LoginHelpModal.css';
import SupportText from '../../../../pages/Suppport/SupportText';

interface ILoginHelpModal {
    closed: boolean;
    onClose: () => void;
}

export default function LoginHelpModal({ closed, onClose }: ILoginHelpModal): JSX.Element {
    const { t } = useTranslation();

    return (
        <ModalWindow
            closed={closed}
            handleClosed={onClose}
            size={'sm'}
            showTitle
            modalTitle={t('pages.login.helpModalTitle')}
            classes={'help-info-modal'}
        >
            <SupportText />
        </ModalWindow>
    );
}
