import PAGE_CONTEXT from '../datasets/page_context';
import PAGE_CONTEXT_ACTION from '../datasets/page_context_action';

// connect page context with context action
export const PAGE_CONTEXT_ACTIONS = {
    [PAGE_CONTEXT.USER_PREFERENCES]: PAGE_CONTEXT_ACTION.CHANGE_USER_PREFERENCES,
    [PAGE_CONTEXT.DOOR_CODE]: PAGE_CONTEXT_ACTION.CHANGE_CODE,
    [PAGE_CONTEXT.SERVICE_TYPES]: PAGE_CONTEXT_ACTION.SELECT_SERVICE_TYPE,
    [PAGE_CONTEXT.SERVICE_REQUEST]: PAGE_CONTEXT_ACTION.MAKE_SERVICE_REQUEST,
    [PAGE_CONTEXT.SERVICE_REQUESTS]: PAGE_CONTEXT_ACTION.SELECT_SERVICE_TYPE,
    [PAGE_CONTEXT.NAV_BAR_HIDDEN_MENU]: PAGE_CONTEXT_ACTION.SHOW_HIDDEN_MENU,
    [PAGE_CONTEXT.EXTRA_SERVICES]: PAGE_CONTEXT_ACTION.EXTRA_SERVICES,
} as const;
export default PAGE_CONTEXT_ACTIONS;

export const FILTER_ACTION = {
    [PAGE_CONTEXT.INVOICES]: PAGE_CONTEXT_ACTION.FILTER,
    [PAGE_CONTEXT.SERVICE_REQUESTS]: PAGE_CONTEXT_ACTION.FILTER_SERVICES,
} as const;
