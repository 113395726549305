import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { AppState } from 'core/redux/rootReducer';
import { AgreementChangeRequestType } from 'agreement/classes/AgreementChangeRequestType';
import { AgreementChangeRequestData } from 'agreement/types/AgreementChangeRequestData';

const tempFirstObject: (state: AppState) => IAgreementObjectInstanceData | null = (state: AppState) =>
    state.tempAgreements.selected?.objects[0] || null;

const isOpen: (state: AppState) => boolean = (state: AppState) => state.tempAgreements.isOpenModal;

const loading: (state: AppState) => boolean = (state: AppState) => state.tempAgreements.loading;

const requestType: (state: AppState) => AgreementChangeRequestType = (state: AppState) =>
    state.tempAgreements.requestType;

const changeRequest: (state: AppState) => AgreementChangeRequestData | null = (state: AppState) =>
    state.tempAgreements.changeRequest;

const selected: (state: AppState) => IAgreementInstanceData | null = (state: AppState) => state.tempAgreements.selected;

const terminationRequestedOn: (state: AppState) => string[] = (state: AppState) =>
    state.tempAgreements.terminationRequestedOn;

export const tempAgreementSelector = {
    tempFirstObject,
    isOpen,
    requestType,
    changeRequest,
    selected,
    loading,
    terminationRequestedOn,
};
