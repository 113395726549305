import WebClientConfig from 'core/classes/WebClientConfig';
import larvaConfig from 'core/config/larva/general';
import IWebClientConfig from 'core/types/IWebClientConfig';
import LarvaConnectionConfigData from 'smartHome/larva/types/LarvaConnectionConfigData';
import LarvaConnectionData from 'smartHome/larva/types/LarvaConnectionData';
import getLarvaApiBaseUrl from 'smartHome/larva/utils/getLarvaApiBaseUrl';

export interface ILarvaConnectionConfig extends IWebClientConfig {
    all: LarvaConnectionConfigData;
}

class LarvaConnectionConfig implements ILarvaConnectionConfig {
    private _token?: string;
    private _orgId?: string;
    private _externalId?: string;
    private _agreementUUID?: string;
    private _config?: WebClientConfig;
    private _baseURL?: string;

    constructor({ token, orgId, externalId, agreementUUID }: LarvaConnectionData) {
        this.token = token;
        this.orgId = orgId;
        this.externalId = externalId;
        this.agreementUUID = agreementUUID;
        this.baseURL = getLarvaApiBaseUrl(orgId, externalId, agreementUUID);
        this.clientConfig = new WebClientConfig({
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            baseURL: this.baseURL,
            timeout: larvaConfig?.timeout,
        });
    }

    static isValidProp(value: unknown): boolean {
        return !!value && typeof value === 'string' && !!value.trim().length;
    }

    get baseURL(): string {
        if (this._baseURL) {
            return this._baseURL;
        }

        throw new ReferenceError('Undefined baseURL');
    }
    set baseURL(value) {
        this._baseURL = value;
    }

    get clientConfig(): WebClientConfig {
        if (this._config) {
            return this._config;
        }

        throw new ReferenceError('Undefined config');
    }

    set clientConfig(value: WebClientConfig) {
        this._config = value;
    }

    get agreementUUID(): string {
        if (this._agreementUUID) {
            return this._agreementUUID;
        }

        throw new ReferenceError('Undefined agreementUUID');
    }

    set agreementUUID(value: string) {
        if (LarvaConnectionConfig.isValidProp(value)) {
            this._agreementUUID = value;
            return;
        }
        throw new TypeError('Invalid agreementUUID');
    }

    get externalId(): string {
        if (this._externalId) {
            return this._externalId;
        }

        throw new ReferenceError('Undefined externalId');
    }

    set externalId(value: string) {
        if (LarvaConnectionConfig.isValidProp(value)) {
            this._externalId = value;
            return;
        }

        throw new TypeError('Invalid externalId');
    }

    get orgId(): string {
        if (this._orgId) {
            return this._orgId;
        }

        throw new ReferenceError('Undefined orgId');
    }

    set orgId(value: string) {
        if (LarvaConnectionConfig.isValidProp(value)) {
            this._orgId = value;
            return;
        }

        throw new TypeError('Invalid orgId');
    }

    get token(): string {
        if (this._token) {
            return this._token;
        }

        throw new ReferenceError('Undefined token');
    }

    set token(value: string) {
        if (LarvaConnectionConfig.isValidProp(value)) {
            this._token = value;
            return;
        }

        throw new TypeError('Invalid token');
    }

    get all(): LarvaConnectionConfigData {
        return {
            ...this.clientConfig.all,
            token: this.token,
            orgId: this.orgId,
            externalId: this.externalId,
            agreementUUID: this.agreementUUID,
        };
    }

    makeDeviceMapUrl(): string {
        return `https://mantis.larva.io/api/orgs/${this.orgId}/services/connections/${this.externalId}/access-policies/${this.agreementUUID}/views/units-devices`;
    }
}
export default LarvaConnectionConfig;
