import React, { useMemo } from 'react';
import useWindowSize, { VIEWPORT_BREAKPOINTS } from '../../../hooks/useWindowSize';
import { useLocation, useMatch } from 'react-router-dom';

import SideBar from '../../menu/SideBar';
import NavMenu from '../../menu/NavMenu';

import './styles/PageLayout.css';

export const PageLayout: React.FC<PageLayoutProps> = ({ parent, child }: PageLayoutProps) => {
    const { isMobileWidth, isWidthLessThan } = useWindowSize();

    const isDashboard = useMatch({ path: '/', end: true });

    const location = useLocation();

    const currentPath = 'pathname' in location ? location.pathname.toString() : '';
    const currentPathClass = !!currentPath ? currentPath.replace(/\//g, '-') : '';

    const isChildPageActive = !!useMatch('/:parentPage/:id');

    const isMeterReadingChildPage = !!useMatch('/meter-reading/:id/:meterReading');

    const screenClass = isMobileWidth ? 'mobile' : 'desktop';
    const isClosed = isWidthLessThan(VIEWPORT_BREAKPOINTS.w1125);

    const hiddenByChildClass =
        (isChildPageActive || isMeterReadingChildPage) && isWidthLessThan(VIEWPORT_BREAKPOINTS.w960)
            ? '--hidden-by-child'
            : '';

    const PAGE_LAYOUT_CLASS = 'page-layout';
    const layoutContainerClasses = useMemo(
        () =>
            [
                'page' + currentPathClass,
                PAGE_LAYOUT_CLASS,
                'page-container--' + screenClass,
                !!isDashboard ? ' page-layout__dashboard--' + screenClass : '',
            ].join(' '),
        [currentPathClass, isDashboard, screenClass],
    );

    const contentWrapperClasses = useMemo(
        () =>
            [
                PAGE_LAYOUT_CLASS + '__content-' + currentPathClass,
                PAGE_LAYOUT_CLASS + '__content-wrapper',
                PAGE_LAYOUT_CLASS + '__content--' + screenClass,
                PAGE_LAYOUT_CLASS + '__content--' + screenClass + (isClosed ? '-closed' : ''),
            ].join(' '),
        [isClosed, screenClass, currentPathClass],
    );

    const childPageClasses = useMemo(
        () =>
            [
                'child-page',
                PAGE_LAYOUT_CLASS + '__content',
                PAGE_LAYOUT_CLASS + '__secondary-content',
                PAGE_LAYOUT_CLASS + '__secondary-content--' + screenClass,
                PAGE_LAYOUT_CLASS + '__secondary-content--' + screenClass + (isClosed ? '-closed' : ''),
            ].join(' '),
        [isClosed, screenClass],
    );

    const parentPageClasses = useMemo(
        () =>
            [
                'parent-page',
                'parent-page' + hiddenByChildClass,
                PAGE_LAYOUT_CLASS + '__content',
                PAGE_LAYOUT_CLASS + '__primary-content',
            ].join(' '),
        [hiddenByChildClass],
    );

    return (
        <div className={layoutContainerClasses}>
            {isMobileWidth ? (
                !isChildPageActive && <NavMenu />
            ) : (
                <div className="page-layout__sidebar">
                    <SideBar />
                </div>
            )}
            <div id="protected-route-pages-wrapper" className={contentWrapperClasses}>
                <div className={childPageClasses}>{isMobileWidth && isDashboard ? '' : child}</div>
                <div className={parentPageClasses}>{parent}</div>
            </div>
        </div>
    );
};
export default PageLayout;

type PageLayoutProps = {
    parent?: JSX.Element | JSX.Element[];
    child?: JSX.Element | JSX.Element[];
};
