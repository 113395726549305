import { Control, FieldValues, FormState, useForm, UseFormGetValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import InvitedTenantData from 'tenantUser/types/InvitedTenantData';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useConfig } from '../../resources/config';

const useTenantRegistrationFormSchema = (invitedTenantData: InvitedTenantData): FormSchemaReturnType => {
    const { t } = useTranslation();
    const {
        tenantRegistration: { extraTerms },
    } = useConfig();

    const passwordValidation = Yup.string().test(
        'empty-password-check',
        t('validation.passwordValidationCriteria'),
        (value) => {
            const passwordValidation = Yup.string().matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                t('validation.passwordValidationCriteria'),
            );

            if (!invitedTenantData?.cognito_uuid) {
                return passwordValidation.required().isValidSync(value);
            }

            if (!!value) {
                return passwordValidation.isValidSync(value);
            }

            return true;
        },
    );

    let terms: { [termsType: string]: unknown } = {
        termsAgreed: Yup.boolean().oneOf([true]).required(),
    };

    if (extraTerms) {
        terms = {
            ...terms,
            extraTerms: Yup.boolean().oneOf([true]).required(),
        };
    }

    const schema = Yup.object({
        email: Yup.string().required(),
        firstName: Yup.string().nullable(),
        lastName: Yup.string().nullable(),
        password: passwordValidation,
        passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], t('validation.passwordMustMatch')),
        phone: Yup.number()
            .test('len', t('validation.telephoneMinLength', { length: 3 }), (val): boolean => {
                return (!val && !(val === 0 || val?.toString().length === 0)) || (!!val && val?.toString().length >= 3);
            })
            .transform((value, value2) => (value2 === '' ? null : value))
            .nullable(),
        ...terms,
    });

    const { control, formState, setValue, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        defaultValues: {
            email: invitedTenantData?.email || '',
            firstName: invitedTenantData?.firstName || '',
            lastName: invitedTenantData?.lastName || '',
            phone: invitedTenantData?.phone || '',
            termsAgreed: false,
            password: '',
            passwordConfirmation: '',
        } as FieldValues,
    });

    return { control, formState, setValue, getValues };
};
export default useTenantRegistrationFormSchema;

type FormSchemaReturnType = {
    control: Control;
    formState: FormState<any>;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
};
