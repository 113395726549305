import React, { useContext, ReactElement } from 'react';
import { Resources } from 'resources/resources';

const Empty = (): ReactElement => <div />;

export const defaultResources = {
    LogoSmall: Empty,
    LogoBig: Empty,
    LogoLoginPage: Empty,
    NoItemsIcon: Empty,
    WriteSomethingIcon: Empty,
    DrawSomethingIcon: Empty,
    GreatSearchIcon: Empty,
    UserIcon: Empty,
    CloseIcon: Empty,
    UncheckedIcon: Empty,
    CheckedIcon: Empty,
    WorldIcon: Empty,
    BurgerIcon: Empty,
    HomeIcon: Empty,
    HomeExtraServicesIcon: Empty,
    KebabIcon: Empty,
    FilterIcon: Empty,
    NoItemsImage: Empty,
    WriteSomethingImage: Empty,
    DrawSomethingImage: Empty,
    GreatSearchImage: Empty,
    BubbledBell: Empty,
    StorageExtraServiceIcon: Empty,
    ParkingExtraServiceIcon: Empty,
    BicycleExtraServiceIcon: Empty,
    PencilIcon: Empty,
    ConfirmCheck: Empty,
    RightChevronIcon: Empty,
    Pencil18Icon: Empty,
    ConfirmCheckWhiteIcon: Empty,
    SmartHomeIcon: Empty,
    WarningBubbleIcon: Empty,
    ExtraServiceMenuIcon: Empty,
    ElectricityIcon: Empty,
    ElectricityPlumbing: Empty,
    ElectricityAppliance: Empty,
    ElectricityLocksmith: Empty,
    ElectricityHeating: Empty,
    ElectricityAirConditioning: Empty,
    TipsAndTricks: Empty,
    TipsAndTricksDefaultPreviewImage: '',
};

export const ResourceContext = React.createContext<Resources>(defaultResources);

export const useResources = (): Resources => useContext(ResourceContext);
