/**
 *
 * @param value string | number, if empty or null - returns formatted 0
 * @param symbol
 * @param decimals
 * @param valueName
 */
const formatValueToFixedDecimalsWithSymbol = (
    value: string | number | null,
    symbol = '',
    decimals = 2,
    valueName = '',
): string => {
    const _symbol = !!symbol && typeof symbol === 'string' ? symbol.trim() : '';
    const _spacedSymbol = `${_symbol ? ' ' : ''}${_symbol}`;
    const _decimals = Number.isFinite(decimals) && decimals >= 0 ? decimals : 0;

    const DEFAULT_VALUE = 0;
    const DEFAULT_RESULT = `${DEFAULT_VALUE.toFixed(_decimals)}${_spacedSymbol}`;

    if (typeof value === 'number') return `${value.toFixed(_decimals)}${_spacedSymbol}`.trim();

    let _value;
    if (typeof value === 'string') {
        _value = value.trim().replace(',', '.');
        if (_value.length === 0) return DEFAULT_RESULT;

        _value = parseFloat(_value);
        if (Number.isFinite(_value)) return `${_value.toFixed(_decimals)}${_spacedSymbol}`.trim();
    }

    if (value === null) return DEFAULT_RESULT;

    throw new TypeError(`Wrong ${valueName} value => "${JSON.stringify(value)}" or type => "${typeof value}" provided`);
};
export default formatValueToFixedDecimalsWithSymbol;
