import { CORE, FIREBASE } from 'core/datasets/action';
import { GetNewTokenAction, RequestNewTokenGQLAction, SetFirebaseUser } from 'firebase/types/redux/firebaseActions';
import { DocumentNode } from 'graphql';

export const firebaseActions = {
    getNewToken(): GetNewTokenAction {
        return {
            type: FIREBASE.GET_NEW_TOKEN,
        };
    },
    requestNewTokenGQL(gql: DocumentNode): RequestNewTokenGQLAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: {},
                callbackType: FIREBASE.SET_TOKEN,
            },
        };
    },
    setUser(user: any): SetFirebaseUser {
        return {
            type: FIREBASE.SET_FIREBASE_USER,
            payload: user,
        };
    },
};
