import Button from '@material-ui/core/Button';

import { CircularProgress } from '@material-ui/core';

import './styles/PrimaryActionBtn.css';
import { PrimaryActionBtnProps } from 'core/types/PrimaryActionBtnProps';

export const SubmitButton: PrimaryActionBtn = ({
    text,
    prefixIcon,
    actionHandler,
    classes = '',
    disabled = false,
    loading = false,
    loadingText = '',
    centerLoader,
    form,
    style,
}: PrimaryActionBtnProps) => {
    return (
        <Button
            style={style}
            disabled={disabled}
            onClick={actionHandler}
            disableElevation={true}
            variant="contained"
            color="primary"
            className={`main-action-btn ${classes} ${disabled ? ' main-action-btn--disabled ' : ''}`}
            form={form}
        >
            <div className="main-action-btn__label-icons-wrapper">
                {!loading && !!prefixIcon && prefixIcon}

                {loading && (
                    <CircularProgress
                        className="main-action-btn__label-icon main-action-btn__label-icon--loading"
                        size={20}
                        thickness={4}
                        color="primary"
                        style={{
                            top: centerLoader ? '-10px' : 'inherit',
                            right: centerLoader ? '-6px' : 'inherit',
                        }}
                    />
                )}

                <span className="main-action-btn__label-text">{loading ? loadingText : text}</span>
            </div>
        </Button>
    );
};

type PrimaryActionBtn = (props: PrimaryActionBtnProps) => JSX.Element;

export default SubmitButton;
