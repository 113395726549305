import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ChangeEvent, InputHTMLAttributes, Ref } from 'react';
import { useResources } from 'resources/context';

export interface CheckBoxProps {
    isChecked: boolean;
    onChange(e: ChangeEvent<HTMLInputElement>): void;
    labelText?: string;
    label?: string;
    valueName?: string;
    name?: string;
    labelClasses?: string;
    checkBoxClasses?: string;
    inputProps?: unknown;
    inputRef?: Ref<HTMLInputElement>;
}
const CheckBox = ({
    valueName,
    inputProps,
    checkBoxClasses,
    isChecked,
    onChange,
    labelText,
    label,
    labelClasses,
    inputRef,
    name,
}: CheckBoxProps): JSX.Element => {
    const _labelClasses = [labelClasses, 'checkbox-label'].join(' ');
    const _checkBoxClasses = [checkBoxClasses, 'checkbox-container'].join(' ');
    const { UncheckedIcon, CheckedIcon } = useResources();

    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={name ? name : valueName}
                    //@ts-ignore
                    inputProps={inputProps as InputHTMLAttributes<HTMLInputElement> | undefined}
                    disableRipple={true}
                    disableFocusRipple={true}
                    className={_checkBoxClasses}
                    required={false}
                    checked={isChecked}
                    onChange={onChange}
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    inputRef={inputRef}
                />
            }
            label={label ? label : labelText}
            className={_labelClasses}
            style={{ paddingBottom: '6px' }}
        />
    );
};
export default CheckBox;
