export default class RequestStatus {
    static WAITING_CONNECTION = 'WAITING_CONNECTION';
    static PENDING = 'PENDING';
    static CANCELLED = 'CANCELLED';
    static RESOLVED = 'RESOLVED';
    static REJECTED = 'REJECTED';

    static isValid(value: unknown): boolean {
        return (
            typeof value === 'string' &&
            [
                RequestStatus.WAITING_CONNECTION,
                RequestStatus.REJECTED,
                RequestStatus.RESOLVED,
                RequestStatus.PENDING,
                RequestStatus.CANCELLED,
            ].indexOf(value) !== -1
        );
    }
}
