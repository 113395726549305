import { gql } from '@apollo/client';

export const getMessagingAccessTokenDocument = gql`
    query getMessagingAccessToken {
        getMessagingAccessToken {
            jwt
            generatedAt
            expireAt
        }
    }
`;
