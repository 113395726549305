import { MENU_ITEM_ID } from './menu_config';
import { PAGE_ID } from '../datasets/page_id';
import { Route } from '../types/routes';
/**
 * Routes config for page layout routes
 *
 * Page layout Component consists of 2 blocks:
 *      - parent - displays pages called from menu
 *      - child - displays pages called from parent page content
 *
 *  Every block has its own route switchers
 */

/**
 * Routes IDs
 *
 * We use an object for now because:
 * - it helps to keep route ids unique (as obj index) in the independent route objects
 * - easy to access available IDs
 * */
export const ROUTE_ID = {
    // Routes called from menu
    PARENT: {
        ...MENU_ITEM_ID,
    },
    // Routes called from parent pages
    CHILD: {
        INVOICE: PAGE_ID.INVOICE,
        RENTAL_AGREEMENT: PAGE_ID.RENTAL_AGREEMENT,
        SERVICE: PAGE_ID.SERVICE,
        METER_READING: PAGE_ID.METER_READING,
        NOTICES: PAGE_ID.NOTICES,
        EXTRA_SERVICE: PAGE_ID.EXTRA_SERVICE,
        DOOR_CODE: PAGE_ID.DOOR_CODE,
        FILE: PAGE_ID.FILE,
        MESSAGING: PAGE_ID.MESSAGING,
        TIP_OR_TRICK: PAGE_ID.TIP_OR_TRICK,
        CO2METERS_ASIDE: PAGE_ID.CO2METERS_ASIDE,
    },
};

export const MAIN_ROUTE_PATH = {
    [PAGE_ID.DASHBOARD]: '/',
    [PAGE_ID.INVOICES]: '/invoices',
    [PAGE_ID.RENTAL_AGREEMENTS]: '/RentalAgreements',
    [PAGE_ID.MESSAGING]: '/messaging',
    [PAGE_ID.SERVICES]: '/services',
    [PAGE_ID.EXTRA_SERVICES]: '/extraservices',
    [PAGE_ID.SMART_HOME]: '/smart-home',
    [PAGE_ID.DOOR_CODES]: '/door-codes',
    [PAGE_ID.METER_READINGS]: '/meter-readings',
    [PAGE_ID.FILES]: '/files',
    [PAGE_ID.SETTINGS]: '/settings',
    [PAGE_ID.TIPS_AND_TRICKS]: '/tipsandtricks',
    [PAGE_ID.CO2METERS]: '/co2meters',
};

export const SECONDARY_ROUTE_PATH = {
    [PAGE_ID.DASHBOARD]: ['/'],
    [PAGE_ID.INVOICE]: ['/invoices', '/invoice', '/invoice/:id', '/invoices/:id'],
    [PAGE_ID.RENTAL_AGREEMENT]: [
        '/RentalAgreements',
        '/RentalAgreement',
        '/RentalAgreement/:id',
        '/rentalagreement/:parentId/:id',
    ],
    [PAGE_ID.MESSAGING]: ['/messaging', '/messaging/:id'],
    [PAGE_ID.SERVICE]: ['/services', '/services/:id', '/service/:id'],
    [PAGE_ID.EXTRA_SERVICE]: ['/extraservices', '/extraservice/:id', '/extraservices/:id', '/extraservice/'],
    [PAGE_ID.SMART_HOME]: ['/smart-home'],
    [PAGE_ID.DOOR_CODE]: ['/door-codes', '/door-code/:id', '/door-codes/:id', '/door-access/:id'],
    [PAGE_ID.METER_READING]: [
        '/meter-readings',
        '/meter-reading',
        '/meter-readings/:id',
        '/meter-reading/:id/:meterReading',
    ],
    [PAGE_ID.FILE]: ['/files', '/files/:id', '/file/:id', '/file/'],
    [PAGE_ID.SETTINGS]: ['/settings'],
    [PAGE_ID.TIP_OR_TRICK]: ['/tipsandtricks', '/tipsandtricks/:id'],
    [PAGE_ID.CO2METERS_ASIDE]: ['/co2meters'],
};

export const ROUTES: Route[] = [
    {
        id: ROUTE_ID.PARENT.DASHBOARD,
        path: MAIN_ROUTE_PATH[PAGE_ID.DASHBOARD] as string,
        paths: SECONDARY_ROUTE_PATH[PAGE_ID.DASHBOARD],
        page: PAGE_ID.DASHBOARD,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.INVOICES,
        path: MAIN_ROUTE_PATH[PAGE_ID.INVOICES] as string,
        paths: SECONDARY_ROUTE_PATH[PAGE_ID.INVOICE] as string[],
        page: PAGE_ID.INVOICES,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.RENTAL_AGREEMENTS,
        path: MAIN_ROUTE_PATH[PAGE_ID.RENTAL_AGREEMENTS],
        paths: SECONDARY_ROUTE_PATH[PAGE_ID.RENTAL_AGREEMENT],
        page: PAGE_ID.RENTAL_AGREEMENTS,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.MESSAGING,
        path: MAIN_ROUTE_PATH[PAGE_ID.MESSAGING],
        paths: SECONDARY_ROUTE_PATH[PAGE_ID.MESSAGING],
        page: 'MessagingChannels',
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.CO2METERS,
        path: MAIN_ROUTE_PATH[PAGE_ID.CO2METERS],
        paths: SECONDARY_ROUTE_PATH[PAGE_ID.CO2METERS_ASIDE],
        page: PAGE_ID.CO2METERS,
        parent: true,
    },
    {
        id: ROUTE_ID.CHILD.MESSAGING,
        path: '/messaging',
        paths: ['/messaging/:id'],
        page: 'MessagingChannel',
        parent: false,
    },
    {
        id: ROUTE_ID.PARENT.SERVICES,
        path: '/services',
        paths: ['/services', '/services/:id', '/service/:id'],
        page: 'Services',
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.SMART_HOME,
        path: '/smart-home',
        paths: ['/smart-home'],
        page: PAGE_ID.SMART_HOME,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.DOOR_CODES,
        path: '/door-codes',
        paths: ['/door-codes', '/door-codes/:id', '/door-code/:id', '/door-code/', '/door-access/', '/door-access/:id'],
        page: PAGE_ID.DOOR_CODES,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.FILES,
        path: '/files',
        paths: ['/files', '/files/:id', '/file/:id', '/file/'],
        page: PAGE_ID.FILES,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.TIPS_AND_TRICKS,
        path: '/tipsandtricks',
        paths: ['/tipsandtricks', '/tipsandtricks/:id'],
        page: PAGE_ID.TIPS_AND_TRICKS,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.SETTINGS,
        path: '/settings',
        paths: ['/settings'],
        page: PAGE_ID.SETTINGS,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.EXTRA_SERVICES,
        path: '/extraservices',
        paths: ['/extraservices', '/extraservice/:id', '/extraservices/:id', '/extraservice/'],
        page: PAGE_ID.EXTRA_SERVICES,
        parent: true,
    },
    {
        id: ROUTE_ID.PARENT.METER_READINGS,
        path: '/meter-readings',
        paths: ['/meter-readings', '/meter-reading', '/meter-readings/:id', '/meter-reading/:id/:meterReading'],
        page: PAGE_ID.METER_READINGS,
        parent: true,
    },
    {
        id: ROUTE_ID.CHILD.METER_READING,
        path: '/meter-reading/:id/:meterReading',
        paths: ['/meter-reading/:id/:meterReading'],
        page: ROUTE_ID.CHILD.METER_READING,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.INVOICE,
        path: '/invoice/:id',
        paths: ['/invoice/:id', '/invoices/:id'],
        page: ROUTE_ID.CHILD.INVOICE,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.SERVICE,
        path: '/service/:id',
        paths: ['/services/:id', '/service/:id'],
        page: ROUTE_ID.CHILD.SERVICE,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.RENTAL_AGREEMENT,
        path: '/rentalAgreement/:id',
        paths: ['/rentalAgreement/:id', '/rentalAgreements/:id'],
        page: ROUTE_ID.CHILD.RENTAL_AGREEMENT,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.NOTICES,
        path: '/',
        paths: ['/'],
        page: ROUTE_ID.CHILD.NOTICES,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.CO2METERS_ASIDE,
        path: '/co2meters',
        paths: ['/co2meters'],
        page: ROUTE_ID.CHILD.CO2METERS_ASIDE,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.DOOR_CODE,
        path: '/door-code/:id',
        paths: ['/door-code/:id', '/door-codes/:id', '/door-access/:id'],
        page: PAGE_ID.DOOR_CODE,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.FILE,
        path: '/file/:id',
        paths: ['/file/:id', '/files/:id'],
        page: PAGE_ID.FILE,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.TIP_OR_TRICK,
        path: '/tipsandtricks/:id',
        paths: ['/tipsandtricks/:id'],
        page: PAGE_ID.TIP_OR_TRICK,
        parent: false,
    },
    {
        id: ROUTE_ID.CHILD.EXTRA_SERVICE,
        path: '/extra-service/:id',
        paths: ['/extraservice/:id', '/extraservices/:id'],
        page: PAGE_ID.EXTRA_SERVICE,
        parent: false,
    },
];
