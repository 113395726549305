import { AgreementChangeRequestType } from 'agreement/classes/AgreementChangeRequestType';
import { agreementActions } from 'agreement/redux/agreementActions';
import { AgreementChangeRequestData } from 'agreement/types/AgreementChangeRequestData';
import { AgreementChangeRequestGraphQLVariables } from 'agreement/types/AgreementChangeRequestGraphQLVariables';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { AppState } from 'core/redux/rootReducer';
import { select, put } from 'redux-saga/effects';
import { tempAgreementSelector } from 'agreement/redux/tempAgreementSelector';
import {
    rentalAgreementExtensionDocument,
    rentalAgreementTerminationDocument,
} from '../../api/gql/useMutationChangeRentalAgreement/rentalAgreementDocuments';

export function* handleAgreementChangeRequestSubmitCalls(): Generator {
    const request = (yield select(
        ({ tempAgreements }: AppState) => tempAgreements.changeRequest,
    )) as AgreementChangeRequestData | null;

    const agreement = (yield select(tempAgreementSelector.selected)) as IAgreementInstanceData | null;

    if (!request || !agreement) return;

    const variables: AgreementChangeRequestGraphQLVariables = {
        agreementId: agreement.id,
        date: request.date?.toSQLDate() as string,
    };

    if (request?.rentalAgreementObjectUuid) {
        variables['rentalAgreementObjectUuid'] = request.rentalAgreementObjectUuid;
    }

    yield put(agreementActions.changeLoadingState(true));

    if (AgreementChangeRequestType.isTermination(request.requestType)) {
        yield put(agreementActions.requestAgreementChange(rentalAgreementTerminationDocument, variables));
    }

    if (AgreementChangeRequestType.isExtension(request.requestType)) {
        yield put(agreementActions.requestAgreementChange(rentalAgreementExtensionDocument, variables));
    }
}
