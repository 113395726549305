import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PAGE_CONTEXT from '../../../datasets/page_context';
import AddNewItemButton, { AddNewItemButtonProps } from '../AddNewItemButton/AddNewItemButton';
import { PageContextType } from '../../../types/pageContext';

interface AddNewItemTriggerButton extends AddNewItemButtonProps {
    pageContext?: PageContextType;
}

const mapDispatchToProps = (dispatch: Dispatch, { onClick, pageContext }: AddNewItemTriggerButton) => {
    const contextWithAddNewItem = [PAGE_CONTEXT.SERVICE_REQUESTS, PAGE_CONTEXT.DOOR_CODE, PAGE_CONTEXT.EXTRA_SERVICES];

    return {
        onClick: () => {
            dispatch({
                type: 'ADD',
            });
            onClick && onClick();
        },
        isVisible:
            (!!contextWithAddNewItem && (contextWithAddNewItem as string[])).indexOf(pageContext as string) !== -1,
    };
};

const AddNewItemTriggerButton = connect(null, mapDispatchToProps)(AddNewItemButton);
export default AddNewItemTriggerButton;
