import { AgreementStatus } from 'agreement/AgreementStatus';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { agreementObjectActions } from 'agreementObject/redux/agreementObjectActions';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import { PutAgreementObjectAction } from 'agreementObject/types/redux/agreementObjectActions';
import { AppState } from 'core/redux/rootReducer';
import { put, select } from 'redux-saga/effects';

export function* initSelectObject({ payload: objects }: PutAgreementObjectAction): Generator {
    const selectedObject = (yield select(
        ({ agreementObjects }: AppState) => agreementObjects.selectedObject,
    )) as IAgreementObjectInstanceData;

    const selectedAgreement = (yield select(
        ({ agreements }: AppState) => agreements.selectedAgreement,
    )) as IAgreementInstanceData | null;

    const hasSelectedObjectIdInObjects = selectedObject && selectedObject.id in objects;
    const isSelectedAgreementActive = AgreementStatus.isActive({
        agreement: selectedAgreement,
        objectId: selectedObject?.id,
    });

    if (hasSelectedObjectIdInObjects && isSelectedAgreementActive) {
        return;
    }

    const agreements = (yield select(({ agreements }: AppState) => agreements.map)) as {
        [id: number]: IAgreementInstanceData;
    };

    if (!agreements) {
        console.error('[initSelectObject]: No agreements');
        return;
    }

    const hasSelected = yield selectFirstActiveObject(Object.values(agreements));

    if (hasSelected) return;

    // reset
    yield put(agreementObjectActions.selectAgreementObject(null));
}

export function* selectFirstActiveObject(agreements: IAgreementInstanceData[]): Generator {
    for (const agreement of agreements) {
        for (const object of agreement.objects) {
            if (AgreementStatus.isActive({ agreement, objectId: object.id })) {
                yield put(agreementObjectActions.selectAgreementObject(object));
                return true;
            }
        }
    }

    return false;
}
