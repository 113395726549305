// set context for page and slide-in panel, mapped with action in PAGE_CONTEXT_ACTIONS config
export const PAGE_CONTEXT = {
    INVOICE: 'invoice',
    INVOICES: 'invoices',
    CONTRACTS: 'contracts', // agreements
    CONTRACT: 'rental-agreement',
    METER_READINGS: 'meter-readings',
    METER_READING: 'meter-reading',
    EXTRA_SERVICES: 'extraservices',
    EXTRA_SERVICE: 'extraservice',
    DOOR_CODE: 'door-code',
    DASHBOARD: 'dashboard',
    SERVICE_TYPES: 'service_types',
    SERVICE_REQUEST: 'service_request',
    SERVICE_REQUESTS: 'service_requests',
    SETTINGS: 'settings',
    USER_PREFERENCES: 'user-preferences',
    NAV_BAR_HIDDEN_MENU: 'nav-bar-hidden-menu',
} as const;
export default PAGE_CONTEXT;
