import { AUTH, AUTH_TEMP } from 'core/datasets/action';
import { FormField } from 'core/types/redux/AuthState';
import { TempAuthActions } from 'tenantUser/types/redux/TempAuthActions';
import TempAuthState from 'tenantUser/types/redux/TempAuthState';

const initFieldValue: FormField = {
    value: '',
    invalid: true,
    changed: false,
};

const initState: TempAuthState = {
    email: initFieldValue,
    password: initFieldValue,
    formIsTouched: false,
    errorMessage: '',
    loading: false,
};

export const tempAuthReducer = (state = initState, action: TempAuthActions): TempAuthState => {
    switch (action.type) {
        case AUTH.LOGIN_ASYNC: {
            return {
                ...state,
                loading: true,
            };
        }
        case AUTH.LOGIN_SUCCESS:
            return {
                ...state,
                email: initFieldValue,
                password: initFieldValue,
                errorMessage: '',
                loading: false,
            };
        case AUTH.LOGOUT:
            return {
                ...state,
                email: initFieldValue,
                password: initFieldValue,
            };
        case AUTH.CHANGE_FIELD:
            return {
                ...state,
                formIsTouched: true,
                [action.fieldName]: {
                    ...action.payload,
                    changed: true,
                },
            };
        case AUTH.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case AUTH.PREFILL_EMAIL_FIELD: {
            return {
                ...state,
                email: {
                    value: action.payload,
                    invalid: false,
                    changed: true,
                },
            };
        }
        case AUTH_TEMP.CLEAR_LOADING_STATE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
