import LarvaUnitDevicesMap from 'smartHome/larva/types/LarvaUnitDevicesMap';
import LarvaUnit from 'smartHome/larva/classes/LarvaUnit';
import { LarvaApiUnit, LarvaApiUnits } from '../types/larvaData';
import LarvaConnectionConfig from '../classes/LarvaConnectionConfig';
import LarvaWebClient from '../classes/LarvaWebClient';

export function makeUnitDeviceMapFromApiData(apiUnits: LarvaApiUnits): LarvaUnitDevicesMap {
    return apiUnits.reduce((devicesMap: LarvaUnitDevicesMap, unit: LarvaApiUnit) => {
        // @TODO check the typing. No evidence, that dates are needen, but for now make up stubbed UnitProps object
        // Same goes to devices props object
        return {
            ...devicesMap,
            [unit.id]: new LarvaUnit({
                unit: {
                    ...unit,
                    orgId: 'stubbed',
                    propertyId: 'stubbed',
                    createdAt: 'stubbed',
                    updatedAt: 'stubbed',
                },
                devices: unit.devices.map((device) => ({
                    ...device,
                    orgId: 'stubbed',
                    createdAt: 'stubbed',
                    updatedAt: 'stubbed',
                })),
            }),
        };
    }, {});
}

export const fetchLarvaApiUnits = async (connectionConfig: LarvaConnectionConfig): Promise<LarvaApiUnits> => {
    try {
        const webClient = new LarvaWebClient(connectionConfig);
        const result = (await webClient.get(connectionConfig.makeDeviceMapUrl())) as { data: LarvaApiUnits };

        if (!result.data.length && process.env.NODE_ENV === 'development') {
            console.warn('No data from Larva API response');
            return [];
        }

        return result.data;
    } catch (error: unknown) {
        console.error('Failed fetch Larva unit and device data: ', error);
        return [];
    }
};

export default makeUnitDeviceMapFromApiData;
