import { DateTime } from 'luxon';
import { APP_LUX_DATE_FORMAT, APP_LUX_DATE_TIME_FORMAT } from './index';

export const formatAppDateTimeToAppDate = (dateTime: string): null | string => {
    if (!dateTime || typeof dateTime !== 'string' || !dateTime.trim()) return null;

    const dt = DateTime.fromFormat(dateTime, APP_LUX_DATE_TIME_FORMAT);

    if (dt.isValid) return dt.toFormat(APP_LUX_DATE_FORMAT);

    if (process.env.NODE_ENV === 'development') {
        throw new Error(`Failed to format date: ${dt.invalidReason}, ${dt.invalidExplanation}`);
    }

    if (process.env.NODE_ENV !== 'test') {
        console.error(`Failed to format date: ${dt.invalidReason}, ${dt.invalidExplanation}`);
    }

    return null;
};
