export class WindowSize {
    public static WIDTH_BREAKPOINT_320 = 320;
    public static WIDTH_BREAKPOINT_480 = 480;
    public static WIDTH_BREAKPOINT_680 = 680;
    public static WIDTH_BREAKPOINT_768 = 768;
    public static WIDTH_BREAKPOINT_790 = 790;
    public static WIDTH_BREAKPOINT_960 = 960;
    public static WIDTH_BREAKPOINT_1025 = 1025;
    public static WIDTH_BREAKPOINT_1125 = 1125;
    public static WIDTH_BREAKPOINT_1200 = 1200;
    public static WIDTH_BREAKPOINT_1420 = 1420;

    public static BREAKPOINT_XS_RANGE_NAME: BreakPointRange = 'xs';
    public static BREAKPOINT_SM_RANGE_NAME: BreakPointRange = 'sm';
    public static BREAKPOINT_MD_RANGE_NAME: BreakPointRange = 'md';
    public static BREAKPOINT_LG_RANGE_NAME: BreakPointRange = 'lg';
    public static BREAKPOINT_XL_RANGE_NAME: BreakPointRange = 'xl';
    public static BREAKPOINT_ALL_RANGE_NAME: BreakPointRange = 'all';

    public static RANGE_XS = [0, WindowSize.WIDTH_BREAKPOINT_480 - 1];
    public static RANGE_SM = [WindowSize.WIDTH_BREAKPOINT_480, WindowSize.WIDTH_BREAKPOINT_960 - 1];
    public static RANGE_MD = [WindowSize.WIDTH_BREAKPOINT_960, WindowSize.WIDTH_BREAKPOINT_1125 - 1];
    public static RANGE_LG = [WindowSize.WIDTH_BREAKPOINT_1125, WindowSize.WIDTH_BREAKPOINT_1420 - 1];
    public static RANGE_XL = [WindowSize.WIDTH_BREAKPOINT_1420, Number.MAX_SAFE_INTEGER];
    public static RANGE_ALL = [0, Number.MAX_SAFE_INTEGER];

    public static allRangesNameArray = [
        WindowSize.BREAKPOINT_XS_RANGE_NAME,
        WindowSize.BREAKPOINT_SM_RANGE_NAME,
        WindowSize.BREAKPOINT_MD_RANGE_NAME,
        WindowSize.BREAKPOINT_LG_RANGE_NAME,
        WindowSize.BREAKPOINT_XL_RANGE_NAME,
        WindowSize.BREAKPOINT_ALL_RANGE_NAME,
    ];

    public static allRanges = {
        [WindowSize.BREAKPOINT_XS_RANGE_NAME]: WindowSize.RANGE_XS,
        [WindowSize.BREAKPOINT_SM_RANGE_NAME]: WindowSize.RANGE_SM,
        [WindowSize.BREAKPOINT_MD_RANGE_NAME]: WindowSize.RANGE_MD,
        [WindowSize.BREAKPOINT_LG_RANGE_NAME]: WindowSize.RANGE_LG,
        [WindowSize.BREAKPOINT_XL_RANGE_NAME]: WindowSize.RANGE_XL,
        [WindowSize.BREAKPOINT_ALL_RANGE_NAME]: WindowSize.RANGE_ALL,
    };

    public static getRangeName(size: unknown): BreakPointRange {
        if (typeof size !== 'number') {
            console.error('[WindowSize.getRange]: invalid size value');
            return WindowSize.BREAKPOINT_ALL_RANGE_NAME;
        }

        for (const rangeName in WindowSize.allRanges) {
            const [minValue, maxValue] = WindowSize.allRanges[rangeName as keyof typeof WindowSize.allRanges];

            if (size >= minValue && size <= maxValue) {
                return rangeName as BreakPointRange;
            }
        }

        return WindowSize.BREAKPOINT_ALL_RANGE_NAME;
    }

    public static isValidRangeName(value: unknown): boolean {
        const regExpString = WindowSize.allRangesNameArray.map((range) => `^${range}$`).join('|');
        const regExp = new RegExp(regExpString, 'i');

        return typeof value === 'string' && regExp.test(value);
    }
}

export type BreakPointRange = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'all';
