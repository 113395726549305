import { AgreementStatus } from 'agreement/AgreementStatus';
import { AgreementType } from 'agreement/classes/AgreementType';
import { agreementActions } from 'agreement/redux/agreementActions';
import { tempAgreementSelector } from 'agreement/redux/tempAgreementSelector';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { IAgreementListViewData } from 'agreement/types/IAgreementListViewData';
import { UpdateAgreementsDataAction } from 'agreement/types/redux/AgreementActions';
import { selectFirstActiveAgreementFromMap, selectFirstAgreementFromListOrMap } from 'agreement/utils';
import { AGREEMENT } from 'core/datasets/action';
import { AppState } from 'core/redux/rootReducer';
import { put, select } from 'redux-saga/effects';

export function* handleActiveAgreementSelection({ payload }: UpdateAgreementsDataAction): Generator {
    const { list, map, type } = payload as {
        list: IAgreementListViewData[];
        map: { [id: number]: IAgreementInstanceData };
        type?: typeof AGREEMENT.ALL_AGREEMENTS_RECEIVED | typeof AGREEMENT.INIT_AGREEMENTS_RECEIVED;
    };

    if (type === AGREEMENT.ALL_AGREEMENTS_RECEIVED) {
        return;
    }

    const selectedAgreementBefore = (yield select(
        ({ agreements }: AppState) => agreements.selectedAgreement,
    )) as IAgreementInstanceData | null;

    const selectedObjectID = (yield select(({ agreementObjects }: AppState) => agreementObjects.selectedObjectID)) as
        | number
        | null;

    if ((!list.length || !map || !Object.values(map).length) && selectedAgreementBefore) {
        yield put(agreementActions.setSelectedObjectAgreement(null));
        return;
    }

    if (
        selectedAgreementBefore &&
        selectedAgreementBefore.id in list &&
        AgreementStatus.isActive({
            agreement: selectedAgreementBefore,
            objectId: selectedObjectID || null,
        })
    ) {
        return;
    }

    const selectedAgreement: IAgreementInstanceData | null = selectFirstActiveAgreementFromMap(
        map,
        selectedAgreementBefore?.id,
        AgreementType.isMultiObject(selectedAgreementBefore) ? selectedObjectID : null,
    );

    yield put(agreementActions.setSelectedObjectAgreement(selectedAgreement));
}

export function* handleTempAgreementSelection({ payload }: UpdateAgreementsDataAction): Generator {
    const { list, map, type } = payload as {
        list: IAgreementListViewData[];
        map: { [id: number]: IAgreementInstanceData };
        type?: typeof AGREEMENT.ALL_AGREEMENTS_RECEIVED | typeof AGREEMENT.INIT_AGREEMENTS_RECEIVED;
    };

    if (!list.length || !map || !Object.values(map).length || type === AGREEMENT.INIT_AGREEMENTS_RECEIVED) {
        return;
    }

    const tempSelectedAgreement = (yield select(tempAgreementSelector.selected)) as IAgreementInstanceData | null;
    const tempSelectedAgreementId = tempSelectedAgreement?.id || null;

    const selectedAgreement = selectFirstAgreementFromListOrMap(tempSelectedAgreementId, list, map);

    if (selectedAgreement) {
        yield put(agreementActions.selectAgreement(selectedAgreement));
        return;
    }

    yield put(agreementActions.selectAgreement(Object.values(map)[0]));
}
