import { AxiosResponse } from 'axios';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { ResponseStatus } from 'core/classes/ResponseStatus';
import { coreActions } from 'core/redux/actions/coreActions';
import { put } from 'redux-saga/effects';
import { larvaActions } from 'smartHome/larva/redux/actions/larvaActions';
import { requestToRefreshExpiredToken, setLogout } from 'tenantUser/redux/authActions';
import { AnyAction } from 'redux';

export function* handleErrorResponse(response?: AxiosResponse, action?: AnyAction): Generator {
    let message = '';
    let level = NotificationAlert.ERROR;

    console.log('[Error in response]', response?.data?.errors);
    console.log('[Response]', response);
    if (!response) return;

    if (ResponseStatus.isInvalidRefreshTokenMessage(response?.data?.message)) {
        yield put(setLogout());
        return;
    }

    if (
        ResponseStatus.isUnauthorizedCode(response?.status) ||
        ResponseStatus.isInvalidAccessTokenMessage(response?.data?.message)
    ) {
        message = 'One moment, refreshing...';
        level = NotificationAlert.WARNING;

        yield put(requestToRefreshExpiredToken());

        if (action) {
            yield put(larvaActions.queueAction(action));
        }
    }

    if (/card must be unique/i.test(`${response?.data?.message}`)) {
        message = `notices.card must be unique`;
    }

    if (/PIN is too easy to guess/i.test(`${response?.data?.message}`)) {
        message = `notices.PIN is too easy to guess`;
    }

    if (response.data?.errors?.length) {
        for (const text of response.data?.errors) {
            message = text?.message;
        }
    }

    if (/Internal server Error/i.test(message)) return;

    yield put(
        coreActions.notification.set(
            new NotificationAlert({
                message: message || response?.data?.message || 'notices.criticalError',
                level: level,
            }).all,
        ),
    );
}

export function* handleWsErrorResponse(message: string): Generator {
    if (ResponseStatus.isTokenExpiredMessage(message)) {
        yield put(requestToRefreshExpiredToken());
        yield put(
            coreActions.notification.set(
                new NotificationAlert({
                    message: 'Please wait...',
                    level: NotificationAlert.WARNING,
                }).all,
            ),
        );
        return;
    }

    yield put(
        coreActions.notification.set(
            new NotificationAlert({
                message: 'notices.criticalError',
                level: NotificationAlert.ERROR,
            }).all,
        ),
    );
}
