import { useConfig } from '../../../../resources/config';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/rootReducer';
import { changeLanguage } from 'tenantUser/redux/userProfileActions';
import { LangValueType } from 'tenantUser/types/redux/userProfileStates';
import { isString } from 'lodash';
import { useCallback } from 'react';

interface UseLanguageSelection {
    currentLang: LangValueType;
    setCurrentLanguage: (lang?: string) => void;
}

const FALLBACK_LANG = 'en';

export const useLanguageSelection = (): UseLanguageSelection => {
    const allowedLanguages = useConfig().languages;

    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    const { currentLang } = useSelector(({ userProfile }: AppState) => userProfile);

    const setCurrentLanguage = useCallback(
        (lang?: string) => {
            let nextLang = lang;

            if (!isString(lang) || !allowedLanguages.includes(lang)) {
                nextLang = FALLBACK_LANG;
            }

            i18n.changeLanguage(nextLang).catch((err) => console.log(err));
            dispatch(changeLanguage(nextLang as LangValueType));
        },
        [allowedLanguages, dispatch, i18n],
    );

    return {
        currentLang,
        setCurrentLanguage,
    };
};
