export class AgreementType {
    static SINGLE_OBJECT = 'SINGLE_OBJECT';
    static MULTI_OBJECT = 'MULTI_OBJECT';

    static isMultiObject(value: unknown): boolean {
        return typeof value === 'string' && /MULTI_OBJECT/.test(value);
    }

    static isSingleObject(value: unknown): boolean {
        return typeof value === 'string' && /SINGLE_OBJECT/.test(value);
    }
}
