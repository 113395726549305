import { isArray, isEmpty } from 'lodash';
import {
    IServiceRequestTypeV2,
    ServiceRequestTypeMap,
    ServiceRequestTypeTitlesMap,
    ServiceRequestTypeTitlesValues,
} from 'serviceRequest/types/serviceRequest';

export const generateServiceRequestTypeMap = (serviceRequestTypes?: IServiceRequestTypeV2[]): ServiceRequestTypeMap => {
    const result: ServiceRequestTypeMap = {};

    if (isEmpty(serviceRequestTypes) || !isArray(serviceRequestTypes)) {
        return result;
    }

    serviceRequestTypes.forEach((type) => (result[type.id] = type));

    return result;
};

export const generateServiceRequestTypeNamesMap = (
    serviceRequestTypes?: IServiceRequestTypeV2[],
): ServiceRequestTypeTitlesMap => {
    const result: ServiceRequestTypeTitlesMap = {};

    if (isEmpty(serviceRequestTypes) || !isArray(serviceRequestTypes)) {
        return result;
    }

    for (const type of serviceRequestTypes) {
        const titleMap: ServiceRequestTypeTitlesValues = { internalTitle: '' };
        if (type?.internalTitle) titleMap.internalTitle = type.internalTitle;

        for (const title of type.titles) {
            titleMap[title.langCode] = title.text;
        }
        result[type.id] = titleMap;
    }

    return result;
};
