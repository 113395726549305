import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { AppState } from '../rootReducer';

const selectedAgreement = (state: AppState): IAgreementInstanceData | null => {
    const selectedId = state.agreements.selectedAgreement?.id;
    if (selectedId) {
        return state.agreements.agreements[selectedId] || null;
    }

    return null;
};

const isFullView = ({ core }: AppState): boolean => core.isFullViewMode;

export const settingsSelectors = { selectedAgreement, isFullView };
