import { useQuery } from '@apollo/client';
import useQueryHelper from '../../../../core/hooks/useQueryHelper';
import useQueryErrorHandler from '../../../../core/hooks/useQueryErrorHandler';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { agreementActions } from '../../../redux/agreementActions';
import IAgreementInstanceData from '../../../types/IAgreementData';
import { keyBy } from 'lodash';
import { QueryRentalAgreementsFunc } from '../../../types/QueryRentalAgreementsFunc';
import { queryRentalAgreementDocument } from './queryRentalAgreementDocument';

const useQueryRentalAgreements: QueryRentalAgreementsFunc = () => {
    const dispatch = useDispatch();
    const { ORDER_DIR, ORDER_BY } = useQueryHelper();
    const { errorHandler } = useQueryErrorHandler();

    const { loading, error, data, refetch } = useQuery(queryRentalAgreementDocument, {
        variables: {
            orderDir: ORDER_DIR.desc,
            orderBy: ORDER_BY.agreementDate,
        },
        partialRefetch: true,
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            errorHandler(error);
        },
    });

    useEffect(() => {
        if (data && data.rentalAgreements) {
            const fetchedRentalAgreements: Record<number, IAgreementInstanceData> = keyBy(data.rentalAgreements, 'id');
            dispatch(agreementActions.agreementsFetched(fetchedRentalAgreements));
        }
    }, [data, dispatch]);

    return {
        loading: loading,
        error: error,
        rentalAgreements: data ? data.rentalAgreements : [],
        refetchRentalAgreements: refetch,
    };
};

export default useQueryRentalAgreements;
