import { useTranslation } from 'react-i18next';

import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import './MenuIconButton.css';
import { getIconClass } from '../../../utils/useIconsHelper';
import { ExtraServiceMenuIcon, TipsAndTricks } from 'pages/ExtraServices/components/ExtraServiceIcon';
import InlineLabelMenuButton from '../InlineLabelMenuButton';
import { ICON_ID } from '../../../datasets/icon_id';
import CarbonFootprint from '../../icons/CarbonFootprint';

export type Props = {
    /**
     * defines if badge is visible over icon
     */
    iconName: string;
    isNavBarMenuItem: boolean;
    labelText?: string;
    inlineLabelText?: string;
    classes?: string;
    hasBadge?: boolean;
    labelHidden?: boolean;
    badgeColor?: 'secondary' | 'primary' | 'default';
    iconColor?: 'secondary' | 'primary' | 'default';
    dataTestId?: string;
    onClick?: () => void;
    disabled?: boolean;
    icon?: string;
};

/**
 * Button with icon and badge (dot)
 *
 * @param hasBadge
 * @param badgeColor
 * @param icon
 * @param onClick
 * @param iconColor
 * @param dataTestId
 * @param labelText
 * @constructor
 */
const MenuIconButton = ({
    iconName,
    isNavBarMenuItem,
    onClick,
    iconColor,
    classes = '',
    dataTestId,
    labelHidden = false,
    labelText = '',
    inlineLabelText,
    hasBadge = false,
    badgeColor = 'secondary',
    disabled,
    icon,
}: Props): JSX.Element => {
    const { t } = useTranslation();

    const transformIcons: Record<string, JSX.Element> = {
        [ICON_ID.TIPS_AND_TRICKS]: <TipsAndTricks />,
        [ICON_ID.CO2_METERS]: <CarbonFootprint />,
        [ICON_ID.EXTRA_SERVICES]: <ExtraServiceMenuIcon />,
    };

    const listItemClass = !isNavBarMenuItem ? 'menu-icon-btn__list-item' : '';
    const iconClass = getIconClass(iconName) || '';

    return !!icon && icon in transformIcons ? (
        <InlineLabelMenuButton
            iconColor={iconColor}
            label={labelHidden || !inlineLabelText ? '' : inlineLabelText}
            classes={`menu-icon-btn__icon sidebar-menu-item sidebar-${iconColor}-color`}
        >
            {transformIcons[icon]}
        </InlineLabelMenuButton>
    ) : (
        <IconButton
            className={'menu-icon-btn ' + listItemClass + ' ' + classes}
            color={iconColor}
            onClick={onClick}
            data-testid={dataTestId}
            disableRipple={!isNavBarMenuItem}
            disableFocusRipple={!isNavBarMenuItem}
            disabled={disabled}
            style={{ opacity: disabled ? 0.8 : 1 }}
        >
            <Badge invisible={!hasBadge} color={badgeColor} variant="dot" overlap="rectangular">
                <span className={iconClass + ' menu-icon-btn__icon'} />
            </Badge>

            {!labelHidden && (
                <Typography
                    className={isNavBarMenuItem ? 'menu-icon-btn__label' : ''}
                    variant={isNavBarMenuItem ? 'caption' : 'subtitle1'}
                    display="block"
                    align={isNavBarMenuItem ? 'center' : 'left'}
                >
                    {t('menu.' + (isNavBarMenuItem ? 'navBarMenu.' + labelText : labelText))}
                </Typography>
            )}
        </IconButton>
    );
};
export default MenuIconButton;
