import DashboardNotification from 'core/classes/DashboardNotification';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { AGREEMENT } from 'core/datasets/action';
import { PAGE_URL } from 'core/datasets/page_url';
import { coreActions } from 'core/redux/actions/coreActions';
import { put } from 'redux-saga/effects';

export function* handleDashboardNotifications(action: { type: string; payload: unknown }): Generator {
    const { type, payload } = action;

    switch (type) {
        case AGREEMENT.INIT_AGREEMENTS_FETCHING: {
            yield put(coreActions.dashboardNotifications.clear());
            break;
        }
        case AGREEMENT.AGREEMENT_SELECTED: {
            if (!payload) {
                const notification = new DashboardNotification({
                    message: 'rentalObject.agreementStatus.missingActiveAgreements',
                    level: NotificationAlert.WARNING,
                    path: PAGE_URL.RENTAL_AGREEMENTS,
                });
                yield put(coreActions.dashboardNotifications.push(notification.all));
            }
            break;
        }
    }
}
