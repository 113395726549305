import ActionIconButton from '../ActionIconButton/ActionIconButton';
import { ActionButtonProps } from '../../../types/ActionButtonProps';
import { useResources } from 'resources/context';

const CloseObjectButton = ({
    onClick,
    classes = '',
    isActive = true,
    isEnabled = true,
    isHidden = false,
}: ActionButtonProps): JSX.Element => {
    const { CloseIcon } = useResources();
    const args = {
        onClick,
        classes: `close-object-button ${classes}`.trim(),
        isActive,
        isEnabled,
        isHidden,
        Icon: CloseIcon as React.FC,
    };

    return <ActionIconButton {...args} />;
};
export default CloseObjectButton;
