import { invoiceActions } from 'invoice/redux/invoiceActions';
import { getFilterValue, ORDER_BY, ORDER_DIR } from 'core/hooks/useQueryHelper';
import { put } from 'redux-saga/effects';
import { InvoicesGraphQLRequestVariables } from 'invoice/types/InvoicesGraphQLRequestVariables';
import { queryAllInvoicesDocument } from '../../api/gql/useQueryInvoices/gql/queryAllInvoicesDocument';
import PAGE_CONTEXT from 'core/datasets/page_context';

export function* fetchAllInvoices(): Generator {
    const QUERY_LIMIT = 200;
    const QUERY_OFFSET = 0;

    const variables: InvoicesGraphQLRequestVariables = {
        orderDir: ORDER_DIR.desc,
        orderBy: ORDER_BY.invoiceDate,
        limit: QUERY_LIMIT,
        offset: QUERY_OFFSET,
        filterBy: getFilterValue(PAGE_CONTEXT.INVOICES),
    };

    yield put(invoiceActions.fetchAllInvoices(queryAllInvoicesDocument, variables));
}
