import { Middleware } from 'redux';
import { FIREBASE } from '../../datasets/action';
import { AppState } from '../rootReducer';
import { EventFirebase } from '../../../firebase/classes';
import { messagingActions } from '../../../messaging/redux/messagingActions';

export const fireBaseMiddleware: Middleware = ({ dispatch, getState }) => {
    const updateUnreadCounter = (data: { [p: string]: number }) => {
        if (!data) return 0;

        const totalUnreadMessages = Object.values(data).reduce((next, current) => next + current, 0);
        dispatch(messagingActions.setHasUnreadMessages(totalUnreadMessages > 0));
    };

    return (next) => async (action) => {
        next(action);

        switch (action.type) {
            case FIREBASE.SET_FIREBASE_USER: {
                const { firebase } = getState() as AppState;
                if (!firebase.user) {
                    console.error('No firebase user is set!');
                    return;
                }
                await EventFirebase.subscribeToUnreadMessagesCount(firebase.user.uid, updateUnreadCounter);
            }
        }
    };
};
