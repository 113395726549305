import { NotificationAlert } from 'core/classes/NotificationAlert';
import { ResponseStatus } from 'core/classes/ResponseStatus';
import { coreActions } from 'core/redux/actions/coreActions';
import { put } from 'redux-saga/effects';
import { larvaWSActions } from 'smartHome/larva/redux/actions/larvaWSActions';
import { HandleWsErrorAction } from 'smartHome/larva/types/redux/larvaWsActions';

export function* handleLarvaWsRequestErrors({ payload: message }: HandleWsErrorAction): Generator {
    console.log(message);
    if (ResponseStatus.isRequestTimeOutHappened(message)) {
        yield put(
            coreActions.notification.set(
                new NotificationAlert({
                    message: 'refreshing connections, please wait',
                    level: NotificationAlert.WARNING,
                }).all,
            ),
        );
        yield put(larvaWSActions.tryUpdateConnections());
        yield put(
            coreActions.notification.set(
                new NotificationAlert({
                    message: 'Ready!',
                    level: NotificationAlert.SUCCESS,
                }).all,
            ),
        );
        return;
    }
}
