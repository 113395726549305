import { UpdateAgreementsDataAction } from 'agreement/types/redux/AgreementActions';
import AgreementObject from 'agreementObject/classes/AgreementObject';
import { agreementObjectActions } from 'agreementObject/redux/agreementObjectActions';
import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import { put } from 'redux-saga/effects';

export function* updateAgreementObjectsData({ payload }: UpdateAgreementsDataAction): Generator {
    const { map } = payload;

    if (!map) return;

    const agreementObjects: { [id: number]: IAgreementObjectInstanceData } = {};

    for (const agreement of Object.values(map)) {
        for (const object of agreement.objects) {
            agreementObjects[object.id] = new AgreementObject(object).all;
        }
    }

    yield put(agreementObjectActions.putAgreementObjects(agreementObjects));
}
