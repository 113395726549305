import { AGREEMENT } from 'core/datasets/action';
import { AgreementActions } from 'agreement/types/redux/AgreementActions';
import AgreementsState from 'agreement/types/redux/AgreementsState';
import { AppState } from 'core/redux/rootReducer';
import IAgreementInstanceData from 'agreement/types/IAgreementData';

const initState: AgreementsState = {
    agreements: {},
    selectedAgreement: null,
    list: [],
    map: {},
};

export const agreementsReducer = (state = initState, action: AgreementActions): AgreementsState => {
    switch (action.type) {
        case AGREEMENT.AGREEMENTS_FETCHED: {
            return {
                ...state,
                agreements: action.payload,
            };
        }
        case AGREEMENT.AGREEMENT_SELECTED: {
            return {
                ...state,
                selectedAgreement: action.payload,
            };
        }
        case AGREEMENT.DATA_UPDATED: {
            const { map, list } = action.payload;
            return {
                ...state,
                map,
                list,
            };
        }
        default:
            return state;
    }
};

export const selectedStartDateForSingleAgreementSelector: (state: AppState) => string | null | undefined = (state) =>
    state.agreements.selectedAgreement?.agreementDate;

export const hasActiveAgreementsSelector: (state: AppState) => boolean = (state: AppState) => {
    return state.agreements.list.some((agreement) => agreement.meta?.isActive);
};

export const agreementCollectionSelector = (state: AppState): IAgreementInstanceData[] => {
    const agreementMap = state.agreements.map;

    return Object.values(agreementMap);
};
