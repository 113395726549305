import Logo from './index';
import { LogoBaseProps } from 'core/types/logo';
import { useResources } from 'resources/context';

const LogoBig = ({ classes = '', width }: LogoBaseProps): JSX.Element => {
    const { LogoBig } = useResources();
    const logoClasses = [classes, 'app-logo app-logo--mobile'].join(' ');

    return (
        <Logo classes={logoClasses}>
            {typeof LogoBig === 'string' ? <img src={LogoBig} width={width} /> : <LogoBig width={width} />}
        </Logo>
    );
};

export default LogoBig;
