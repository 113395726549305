import InvitedTenantData from 'tenantUser/types/InvitedTenantData';
import { ApolloError } from '@apollo/react-hooks';
import useQueryValidateTenantInviteHash from 'tenantUser/api/gql/useQueryValidateTenantInviteHash';
import useMutationRegisterTenant from 'tenantUser/api/gql/useMutationRegisterTenant';
import { TenantRegistrationData } from 'tenantUser/types/TenantRegistrationData';

interface RegistrationControllerProps {
    hash: string;
}
const useTenantRegistrationController = ({ hash = '' }: RegistrationControllerProps): RegistrationControllerReturn => {
    const {
        loading: validationLoading,
        error: validationError,
        tenant,
        isValid,
        message,
    } = useQueryValidateTenantInviteHash({ hash });

    const {
        loading: registrationLoading,
        error: registrationError,
        handleRegistration,
    } = useMutationRegisterTenant({ hash });

    return {
        isValidHash: isValid,
        tenant,
        loading: validationLoading,
        registrationLoading,
        message,
        error: validationError,
        registrationError,
        handleRegistration,
    };
};

export default useTenantRegistrationController;
type RegistrationControllerReturn = {
    isValidHash: boolean;
    tenant: InvitedTenantData | null;
    loading: boolean;
    registrationLoading: boolean;
    error?: ApolloError;
    message?: string;
    registrationError?: ApolloError;
    handleRegistration: (tenant: TenantRegistrationData) => void;
};
