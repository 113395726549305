import { AxiosResponse } from 'axios';
import GraphQLClient from 'core/classes/GraphQLClient';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { coreActions } from 'core/redux/actions/coreActions';
import { AppState } from 'core/redux/rootReducer';
import { FormField } from 'core/types/redux/AuthState';
import ReduxAction from 'core/types/ReduxAction';
import { put, select } from 'redux-saga/effects';
import { authActions, setLoginSuccess } from 'tenantUser/redux/authActions';
import { gql } from '@apollo/client';

// Have to override graphql-let code generation, because it generates outdated request
const LoginGQLDocument = gql`
    mutation LogIn($input: LogInInput!) {
        LogIn(input: $input) {
            user {
                id
                email
                firstName
                lastName
                connectedTenants {
                    id
                    email
                    phone
                    ownedBankAccountNumber
                    iban
                    rentalAgreements {
                        id
                    }
                }
            }
            token
            refresh_token
        }
    }
`;

export function* login(action: ReduxAction): Generator {
    const email = (yield select(({ tempAuth }: AppState) => tempAuth.email)) as FormField;
    const password = (yield select(({ tempAuth }: AppState) => tempAuth.password)) as FormField;

    const variables = {
        input: {
            email: email?.value,
            password: password?.value,
        },
    };
    //@ts-ignore
    const client = action.payload.client as GraphQLClient;

    let message = 'notices.criticalError';

    try {
        const response = (yield client.request(LoginGQLDocument, variables)) as AxiosResponse;

        if (response.status === 200) {
            if (response.data?.data?.LogIn) {
                const loginData = response.data?.data?.LogIn;

                yield put(setLoginSuccess(loginData));
                return;
            }

            if (response.data?.errors && response.data?.errors[0]?.path[0] === 'LogIn') {
                message = 'notices.wrongCredentials';
            }
        }

        yield put(
            coreActions.notification.set({
                message: message,
                level: NotificationAlert.ERROR,
            }),
        );
    } catch (error) {
        console.error(error);

        yield put(
            coreActions.notification.set({
                message: message,
                level: NotificationAlert.ERROR,
            }),
        );
        // TODO: handle errors on timeout
    } finally {
        yield put(authActions.clearLoadingState());
    }
}
