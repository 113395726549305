import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useApolloClient } from '@apollo/react-hooks';
import { WindowSize } from 'core/classes/WindowSize';
import AppVersion from 'core/components/AppVersion';
import { MENU_ITEM_ID, MENU_LOCATION } from 'core/config/menu_config';
import { ICON_ID } from 'core/datasets/icon_id';
import MenuIconButton from 'core/components/buttons/MenuIconButton';
import { AppState } from 'core/redux/rootReducer';
import UserPreferencesButton from 'core/components/UserPreferencesButton';
import { setLogout } from 'tenantUser/redux/authActions';
import SideBarMenuComponentList from './SideBarMenuComponentList/SideBarMenuComponentList';
import SidebarHeaderMenu from '../SidebarHeaderMenu';
import { useConfig } from '../../../../resources/config';
import { menuItemsSelector } from '../selectors/selectorsAndPatchers';

export const SideBar: FC = () => {
    const dispatch = useDispatch();

    const { appType } = useConfig();
    const menuItems = useSelector(menuItemsSelector(appType));

    const sideBarMenuIds = useSelector(({ menu }: AppState) => menu.sideBarIds);
    const windowSizeRange = useSelector(({ core }: AppState) => core.windowSizeRange);
    const hasUnreadMessages = useSelector(({ messaging }: AppState) => messaging.hasUnreadMessages);

    const isOpen = useMemo(
        () =>
            windowSizeRange &&
            [WindowSize.BREAKPOINT_LG_RANGE_NAME, WindowSize.BREAKPOINT_XL_RANGE_NAME].includes(windowSizeRange),
        [windowSizeRange],
    );

    const LOGOUT = {
        id: MENU_ITEM_ID.LOGOUT,
        iconName: ICON_ID.LOGOUT,
        labelText: 'logOut',
        location: MENU_LOCATION.MENU_LIST,
    };

    const { pathname } = useLocation();

    const client = useApolloClient();

    const logOutHandler = async () => {
        try {
            await client.clearStore();
            dispatch(setLogout());
        } catch (reason) {
            console.error('Failed to logout');
        }
    };

    return (
        <div className={'desktop-sidebar desktop-sidebar--' + (!isOpen ? 'closed' : 'opened')}>
            <SidebarHeaderMenu />

            <div className="desktop-sidebar__menu-container">
                <SideBarMenuComponentList
                    sideBarMenuIds={sideBarMenuIds}
                    menuItems={menuItems}
                    windowSizeRange={windowSizeRange}
                    isOpen={!!isOpen}
                    pathname={pathname}
                    hasUnreadMessages={hasUnreadMessages}
                />
                {!isOpen && <UserPreferencesButton classes="sidebar-header-menu__user-preferences--closed" />}
                <MenuIconButton labelHidden={!isOpen} {...LOGOUT} isNavBarMenuItem={false} onClick={logOutHandler} />
            </div>
            <AppVersion />
        </div>
    );
};

export default SideBar;
