import { agreementWatchers } from 'agreement/saga/watchers';
import { invoiceWatchers } from 'invoice/saga/watchers';
import { coreWatcher } from 'core/saga/coreWatcher';
import { fileWatcher } from 'file/saga/watchers';
import { all, call, spawn } from 'redux-saga/effects';
import { agreementObjectWatchers } from 'agreementObject/saga/watchers';
import { larvaSmartHomeWatcher } from 'smartHome/larva/saga/watchers';

import { watchUserActions } from 'tenantUser/saga/watchers';
import { rentalObjectsWatchers } from 'rentalObject/saga/watchers';
import { messagingWatchers } from 'messaging/saga/watchers';
import { environmentWatchers } from 'environment/saga/watchers';
import { inceptionSmartHomeWatcher } from 'smartHome/inception/saga/watchers';
import { firebaseWatchers } from '../../firebase/saga/watchers';

export function* rootSaga(): Generator {
    const sagas = [
        watchUserActions,
        agreementObjectWatchers,
        agreementWatchers,
        invoiceWatchers,
        larvaSmartHomeWatcher,
        fileWatcher,
        coreWatcher,
        rentalObjectsWatchers,
        messagingWatchers,
        firebaseWatchers,
        environmentWatchers,
        inceptionSmartHomeWatcher,
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (reason) {
                        console.error(`Failed saga: ${saga?.name}`, reason);
                    }
                }
            }),
        ),
    );
}
