import { AppState } from 'core/redux/rootReducer';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserData } from 'tenantUser/types/user';
import { MENU_CONFIG, MENU_ITEM_ID, MENU_LOCATION } from '../config/menu_config';
import { MenuItemConfig } from '../types/menu';
import { ROUTES } from '../config/routes';
import { Route } from '../types/routes';
import { useConfig } from 'resources/config';

/**
 * MENU_CONFIG and MENU_ROUTES adapter/transformer for specific menu locations
 */
const useMenuAdapter: MenuConfigHook = () => {
    const { larva } = useConfig();

    // larva door access
    const disabledMenuItems: string[] = useMemo(() => [], []);

    const isAllowedToSeeDoorAccessList = useSelector(({ larva }: AppState) => larva.doorAccess?.isAllowedToManage);
    const currentUserEmail = useSelector(({ auth }: AppState) => (auth.user as UserData).email);

    // Messaging activation for demo
    const emailsAllowedForLarva = larva.alwaysAvailableForEmails;
    if (!larva.showIfAvailable && !emailsAllowedForLarva.includes(currentUserEmail)) {
        disabledMenuItems.push(MENU_ITEM_ID.SMART_HOME);
        disabledMenuItems.push(MENU_ITEM_ID.DOOR_CODES);
    } else if (!isAllowedToSeeDoorAccessList) {
        disabledMenuItems.push(MENU_ITEM_ID.DOOR_CODES);
    }

    const menuActiveItems = useMemo(() => {
        if (disabledMenuItems.includes(MENU_ITEM_ID.DOOR_CODES)) {
            disabledMenuItems.push(MENU_ITEM_ID.DOOR_CODES);
        }

        if (!disabledMenuItems.length) return MENU_CONFIG;

        return MENU_CONFIG.filter(({ id }) => !disabledMenuItems.includes(id));
    }, [disabledMenuItems]);

    const ITEMS_WITHOUT_ROUTES = useMemo(() => [MENU_ITEM_ID.MENU_TOGGLE, MENU_ITEM_ID.LOGOUT], []);

    const getPathByID = (id: string) => {
        return ROUTES.filter((route) => route.id === id)[0].path;
    };

    const getPathFor = ({ id }: MenuItemConfig) => {
        try {
            if (ITEMS_WITHOUT_ROUTES.includes(id)) return '';
            return getPathByID(id);
        } catch (e) {
            throw Error('Failed to find path for id: ' + id);
        }
    };

    const getChildrenPathsFor = ({ id }: MenuItemConfig) => {
        try {
            if (ITEMS_WITHOUT_ROUTES.includes(id)) return [''];
            return ROUTES.filter((route) => route.id === id)[0].paths;
        } catch (e) {
            throw Error('Failed to find children paths for id: ' + id);
        }
    };
    // mobile nav bar menu
    const NAV_BAR_MENU = useMemo(
        () =>
            menuActiveItems.filter((item) => {
                if (item.id === MENU_ITEM_ID.METER_READINGS) {
                    return disabledMenuItems.includes(MENU_ITEM_ID.SMART_HOME);
                }
                return item.location === MENU_LOCATION.NAV_BAR && item.id !== MENU_ITEM_ID.MENU_TOGGLE;
            }),
        [disabledMenuItems, menuActiveItems],
    );

    // mobile slide in menu
    const LIST_MENU = useMemo(() => {
        return menuActiveItems.filter((item) => {
            if (item.id === MENU_ITEM_ID.METER_READINGS) {
                return !disabledMenuItems.includes(MENU_ITEM_ID.SMART_HOME);
            }
            return item.location === MENU_LOCATION.MENU_LIST && item.id !== MENU_ITEM_ID.LOGOUT;
        });
    }, [disabledMenuItems, menuActiveItems]);

    const MENU_TOGGLE = useMemo(
        () => menuActiveItems.filter((item) => item.id === MENU_ITEM_ID.MENU_TOGGLE)[0],
        [menuActiveItems],
    );
    const LOGOUT = useMemo(
        () => menuActiveItems.filter((item) => item.id === MENU_ITEM_ID.LOGOUT)[0],
        [menuActiveItems],
    );
    const SETTINGS = useMemo(
        () => menuActiveItems.filter((item) => item.id === MENU_ITEM_ID.SETTINGS)[0],
        [menuActiveItems],
    );

    const PARENT_ROUTES = useMemo(() => ROUTES.filter((route) => route.parent), []);
    const CHILDREN_ROUTES = useMemo(() => ROUTES.filter((route) => !route.parent), []);

    return {
        NAV_BAR_MENU,
        SETTINGS,
        PARENT_ROUTES,
        MENU_TOGGLE,
        LOGOUT: LOGOUT,
        LIST_MENU,
        CHILDREN_ROUTES,
        getPathFor,
        getChildrenPathsFor: getChildrenPathsFor,
        getPathByID,
    };
};

export default useMenuAdapter;

type MenuConfigHook = () => MenuConfigReturn;
type MenuConfigReturn = {
    NAV_BAR_MENU: MenuItemConfig[];
    LIST_MENU: MenuItemConfig[];
    MENU_TOGGLE: MenuItemConfig;
    LOGOUT: MenuItemConfig;
    SETTINGS: MenuItemConfig;
    PARENT_ROUTES: Route[];
    CHILDREN_ROUTES: Route[];
    getPathFor: (menuItem: MenuItemConfig) => string;
    getPathByID: (id: string) => string;
    getChildrenPathsFor: (menuItem: MenuItemConfig) => string[];
};
