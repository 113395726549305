import {
    ICO2Emission,
    ICO2MeterReading,
    IEmissionsDataForChart,
    IMeterReadingsElement,
    MeterType,
} from 'co2Meters/types/co2Meters';
import { TFunction } from 'react-i18next';
import { DateTime } from 'luxon';

export const momentKeyFormat = 'MMMMYYYY';
export const luxonKeyFormat = 'MMMMyyyy';
const monthsRequired = 11;

type CreateEmissionsDataForChartType = (
    emissions: ICO2Emission[] | null,
    meterReadings?: ICO2MeterReading[] | null,
    agreementStartDate?: string | null,
) => IEmissionsDataForChart[];

export const createEmissionsDataForChart: CreateEmissionsDataForChartType = (
    emissions,
    meterReadings,
    agreementStartDate,
) => {
    if (!emissions) return [];

    const months: string[] = [];
    const monthRange = Math.ceil(DateTime.now().diff(DateTime.fromSQL(agreementStartDate || ''), 'month')?.months);
    const monthInChart = monthRange > monthsRequired ? monthRange : monthsRequired;

    for (let i = monthInChart; i >= 0; i--) {
        months.push(DateTime.now().minus({ month: i }).toFormat(luxonKeyFormat));
    }

    const emissionsDatesDictionary = emissions.reduce<Record<string, number>>((acc, { period, value }) => {
        const dateKey = DateTime.fromSQL(period).toFormat(luxonKeyFormat);
        acc[dateKey] = value > 0 ? value : 0;
        return acc;
    }, {});

    const meterReadingsDatesDictionary = (meterReadings || []).reduce<
        Record<string, Record<MeterType, IMeterReadingsElement>>
    >((acc, { period, value, meterType, unit }) => {
        const dateKey = DateTime.fromSQL(period).toFormat(luxonKeyFormat);
        acc[dateKey] = { ...acc[dateKey], [meterType]: { value, unit } };
        return acc;
    }, {});

    return months.map((month, index) => ({
        name: month.slice(0, 3),
        co2: emissionsDatesDictionary[month] || 0,
        id: month,
        meterReadings: meterReadingsDatesDictionary[month] || null,
        index,
    }));
};

export const findLastExistMonthIdWithEmission = (emissions: ICO2Emission[]): string | undefined => {
    for (let i = 0; i <= emissions.length; i++) {
        if (emissions[emissions.length - i]?.value > 0) {
            return DateTime.fromSQL(emissions[emissions.length - i]?.period).toFormat(luxonKeyFormat);
        }
    }
};

export const prepareCO2ChartData = (
    chartEmissions: IEmissionsDataForChart[],
    agreementStartDate?: string | null,
): Partial<IEmissionsDataForChart>[] => {
    if (!agreementStartDate) return [];

    const startAgreementMonthId = DateTime.fromSQL(agreementStartDate).minus({ month: 1 }).toFormat(luxonKeyFormat);
    const startChartIndex = chartEmissions.find(({ id }) => id === startAgreementMonthId)?.index || 0;

    return chartEmissions.map(({ id, co2, index, name }) => {
        const monthChartData: Partial<IEmissionsDataForChart> = { id, name };
        if (index >= startChartIndex) monthChartData.co2 = co2;
        return monthChartData;
    });
};

export const createMonthWithYearTitle = (t: TFunction, monthId?: string | null): string => {
    if (!monthId || typeof monthId !== 'string') return '';

    const translatedMonth = t(`months.${monthId.slice(0, -4).toLowerCase()}`);
    return `${translatedMonth} ${monthId.slice(-4)}`;
};
