import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import './styles/ModalWindow.css';

/**
 * Shows custom content in a modal window
 * @param closed
 * @param handleClosed
 * @param modalTitle
 * @param size
 * @param children
 * @param error
 * @param classes
 * @param showTitle
 * @constructor
 */
export const ModalWindow: ModalWindow = ({
    closed,
    handleClosed,
    modalTitle = '',
    size,
    children,
    classes = '',
    showTitle = true,
}: ModalWindowProps) => {
    return (
        <Dialog
            className={'modal-window ' + classes}
            fullWidth={true}
            maxWidth={size}
            open={!closed}
            onClose={handleClosed}
            aria-labelledby="modal-window-title"
        >
            {showTitle && (
                <DialogTitle className="modal-window__title" id="modal-window-title">
                    {modalTitle}{' '}
                    <IconButton onClick={handleClosed} className="bta-icon-times modal-window__close-btn" />
                </DialogTitle>
            )}
            <div className="modal-window__content">{children}</div>
        </Dialog>
    );
};
export default ModalWindow;

type ModalWindow = (props: ModalWindowProps) => JSX.Element;
type ModalWindowProps = {
    closed: boolean;
    handleClosed: () => void;
    modalTitle?: string;
    children: JSX.Element | string;
    size: 'xs' | 'sm';
    classes?: string;
    showTitle?: boolean;
};
