import { useEffect, useRef } from 'react';

const createRootElement = (id: string): Element => {
    const rootContainer = document.createElement('div');
    rootContainer.setAttribute('id', id);
    return rootContainer;
};

const addRootElement = (rootElement: Element) => {
    /* eslint-disable compat/compat */ // unsupported document.body in firefox 51
    const body = 'body' in document ? document.body : document.getElementsByTagName('body')[0];
    body.insertBefore(rootElement, document.body.lastElementChild?.nextElementSibling as Element);
    /* eslint-enable */
};

const usePortal = (id: string): Element => {
    const rootElemRef = useRef<Element | null>(null);

    useEffect(
        function setupElement() {
            const existingParent = document.querySelector(`#${id}`);
            const parentElem = existingParent || createRootElement(id);

            if (!existingParent) {
                addRootElement(parentElem);
            }

            // @ts-ignore
            parentElem.appendChild(rootElemRef.current);

            return function removeElement() {
                rootElemRef.current?.remove();
                if (!parentElem.childElementCount) {
                    parentElem.remove();
                }
            };
        },
        [id],
    );
    function getRootElem() {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div');
        }
        return rootElemRef.current;
    }

    return getRootElem();
};

export default usePortal;
