import ControlledInputField from '../InputField';
import { ControlledInputFieldProps } from '../../../types/ControlledInputFieldProps';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { KeyboardEvent, MouseEvent, useState } from 'react';

const PasswordControlledField = ({
    control,
    id = 'password-controlled-field',
    name = 'password-controlled-field',
    label = '',
    isRequired,
    handleChange,
    handleKeyEnterPressed,
    readyToSubmit,
    ref,
    classes,
    customClasses,
    ...restOptions
}: ControlledInputFieldProps): JSX.Element => {
    const [visible, setVisible] = useState(false);

    const handleClickShowPassword = () => {
        setVisible((prev) => !prev);
    };
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
        if (readyToSubmit) {
            // @ts-ignore
            handleKeyEnterPressed && handleKeyEnterPressed(event);
        }
    };
    return (
        <ControlledInputField
            ref={ref}
            autoComplete={'current-password'}
            id={id}
            name={name}
            type={visible ? 'text' : 'password'}
            {...(handleChange && { onChange: handleChange })}
            onKeyPress={handleSubmit}
            required={isRequired}
            className={`${classes} ${customClasses}`}
            InputProps={{
                endAdornment: (
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {visible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                ),
            }}
            control={control}
            label={label}
            {...restOptions}
        />
    );
};

export default PasswordControlledField;
