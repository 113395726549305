import { CORE, FILE, FILE_TEMP } from 'core/datasets/action';
import IListViewData from 'core/types/IListViewData';
import { Base64DataConversionAction } from 'core/types/redux/CoreStateActions';
import {
    FetchAllFilesAction,
    UpdateFilesDataAction,
    DownloadFileRequestAction,
    FetchFileAction,
    CallFetchAllFilesAction,
    UpdateFilesListViewDataAction,
} from 'file/types/FilesActions';
import IFileData from 'file/types/IFileData';
import {
    ChangeFilesDownloadingStateAction,
    ChangeFilesLoadingStateAction,
    ChangePreviewLoadingStateAction,
    DataUrlPutAction,
    FetchPreviewFileAction,
    GetFilePreviewRequestAction,
    ResetSelectedFileAction,
    SelectFileAction,
    SelectFilesListViewItemByIdAction,
} from 'file/types/TempFilesStateActions';
import { DocumentNode } from 'graphql';

export const filesActions = {
    updateFilesData(payload: { list: IListViewData[]; map: { [id: number]: IFileData } }): UpdateFilesDataAction {
        return {
            type: FILE.DATA_UPDATED,
            payload,
        };
    },
    updateFilesListViewData(list: IListViewData[]): UpdateFilesListViewDataAction {
        return {
            type: FILE.MARK_SELECTED_LIST_ITEM_BROKEN,
            payload: list,
        };
    },
    selectFile(file: IFileData): SelectFileAction {
        return {
            type: FILE_TEMP.LIST_ITEM_SELECTED,
            payload: file,
        };
    },
    selectViewItemById(id: string): SelectFilesListViewItemByIdAction {
        return {
            type: FILE_TEMP.LIST_ITEM_SELECTED_BY_ID,
            payload: +id,
        };
    },
    fetchAllFiles(gql: DocumentNode): FetchAllFilesAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                callbackType: FILE.ALL_FILES_RECEIVED,
            },
        };
    },
    fetchFile(gql: DocumentNode, id: number): FetchFileAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: {
                    id,
                },
                callbackType: FILE.DOWNLOAD_FILE_ACTION,
            },
        };
    },
    downloadFile(id: number): DownloadFileRequestAction {
        return {
            type: FILE.DOWNLOAD_FILE_REQUEST,
            payload: id,
        };
    },
    getFilePreview(id: number): GetFilePreviewRequestAction {
        return {
            type: FILE_TEMP.GET_PREVIEW_REQUEST,
            payload: id,
        };
    },
    fetchFilePreview(gql: DocumentNode, id: number): FetchPreviewFileAction {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: {
                    id,
                },
                callbackType: FILE_TEMP.UPDATE_SELECTED_FILE_DATA,
            },
        };
    },
    changeLoadingState(status: boolean): ChangeFilesLoadingStateAction {
        return {
            type: FILE_TEMP.LOADING_STATE_CHANGED,
            payload: status,
        };
    },
    changeDownloadingState(status: boolean): ChangeFilesDownloadingStateAction {
        return {
            type: FILE_TEMP.DOWNLOADING_STATE_CHANGED,
            payload: status,
        };
    },
    changePreviewLoadingState(status: boolean): ChangePreviewLoadingStateAction {
        return {
            type: FILE_TEMP.PREVIEW_LOADING_STATE_CHANGED,
            payload: status,
        };
    },
    callToFetchAllFiles(): CallFetchAllFilesAction {
        return {
            type: FILE.FETCH_ALL_FILES,
        };
    },
    convertBase64DataToUrl(b64Data: string, mimeType: string): Base64DataConversionAction {
        return {
            type: CORE.CONVERT_BASE64_TO_URL,
            payload: {
                b64Data,
                mimeType,
                callbackAction: FILE_TEMP.DATA_URL_GENERATED,
            },
        };
    },
    putDataUrl(value: string): DataUrlPutAction {
        return {
            type: FILE_TEMP.DATA_URL_GENERATED,
            payload: value,
        };
    },
    resetSelected(): ResetSelectedFileAction {
        return {
            type: FILE_TEMP.RESET_SELECTED,
        };
    },
    markSelectedFileBroken(): { type: typeof FILE_TEMP.MARK_SELECTED_BROKEN } {
        return {
            type: FILE_TEMP.MARK_SELECTED_BROKEN,
        };
    },
};
