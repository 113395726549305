import { put } from 'redux-saga/effects';
import { ChangeLicencePlatesAction } from 'agreement/types/redux/AgreementActions';
import { AGREEMENT, CORE } from 'core/datasets/action';
import { rentalAgreementLicensePlates } from '../../api/gql/useMutationChangeRentalAgreement/rentalAgreementDocuments';

export function* changeAgreementLicensePlates({ payload }: ChangeLicencePlatesAction): Generator {
    yield put({
        type: CORE.MAKE_GRAPHQL_REQUEST,
        payload: {
            query: rentalAgreementLicensePlates,
            variables: { data: { ...payload } },
            callbackType: AGREEMENT.LICENSE_PLATES_CHANGED,
        },
    });
}
