import LarvaDeviceController from 'smartHome/larva/classes/LarvaDeviceController';
import ILarvaWebSocketClient from 'smartHome/larva/types/ILarvaWebSocketClient';
import { DeviceProps, UnitProps } from 'smartHome/larva/types/larvaData';
import LarvaDeviceObject from 'smartHome/larva/types/LarvaDeviceObject';
import LarvaUnitObject from 'smartHome/larva/types/LarvaUnitObject';
import { UINodeLocal } from '../types/UINodeLocal';

interface LarvaUnitProps {
    unit: UnitProps;
    devices: DeviceProps[];
}

export type LarvaUnits = LarvaUnit[];

class LarvaUnit {
    private _id?: string;
    private _name?: string;
    private _devices?: LarvaDeviceController[];

    constructor({ unit, devices }: LarvaUnitProps) {
        if (!LarvaUnit.isValidUnitProp(unit)) {
            throw new Error('Invalid unit data');
        }

        if (!LarvaUnit.isValidDevicesProp(devices)) {
            throw new Error('Invalid devices data');
        }

        this.id = unit?.id;
        this.name = unit?.name || 'No name';
        this.devices = devices;
    }

    static isValidProp(value: unknown): boolean {
        return !!value && typeof value === 'string' && !!value.trim()?.length;
    }

    static isValidUnitProp(value: UnitProps): boolean {
        return !!value && typeof value === 'object' && !Array.isArray(value) && !!Object.keys(value)?.length;
    }

    static isValidDevicesProp(value: unknown): boolean {
        return Array.isArray(value) && !!value.length;
    }

    get id(): string {
        if (this._id) {
            return this._id;
        }

        throw new ReferenceError('Undefined id');
    }

    set id(value: unknown) {
        if (LarvaUnit.isValidProp(value)) {
            this._id = value as string;
            return;
        }

        throw new TypeError('Invalid id');
    }

    get name(): string {
        if (this._name) {
            return this._name;
        }

        throw new ReferenceError('Undefined name');
    }

    set name(value: string) {
        if (LarvaUnit.isValidProp(value)) {
            this._name = value;
            return;
        }

        throw new TypeError('Invalid name');
    }

    get devices(): LarvaDeviceController[] {
        if (this._devices) {
            return this._devices;
        }

        throw new ReferenceError('Undefined devices');
    }

    set devices(value: unknown) {
        if (LarvaUnit.isValidDevicesProp(value)) {
            this._devices = (value as DeviceProps[]).map(
                (device: DeviceProps) =>
                    new LarvaDeviceController({
                        id: device.id,
                        name: device?.serialNumber || device?.name || 'None',
                        unitId: this.id,
                    }),
            );
            return;
        }

        this._devices = [];
    }

    get hasDevices(): boolean {
        return !!this.devices.length;
    }

    toObject(): LarvaUnitObject {
        const devicesMap: { [id: string]: LarvaDeviceObject } = {};
        const devices = this.devices;

        for (const device of devices) {
            devicesMap[device.id] = device.toObject();
        }

        return {
            type: 'LarvaUnit',
            name: this.name,
            id: this.id,
            devices: devicesMap,
        };
    }

    get connections(): { [id: string]: ILarvaWebSocketClient } {
        const devicesConnections: { [id: string]: ILarvaWebSocketClient } = {};

        for (const device of this.devices) {
            devicesConnections[device.id] = device.connection as ILarvaWebSocketClient;
        }

        return devicesConnections;
    }

    get uiNodes(): UINodeLocal[] {
        const uiNodes: UINodeLocal[] = [];

        for (const device of this.devices) {
            uiNodes.push(...device.uiNodes);
        }

        return uiNodes;
    }
}

export default LarvaUnit;
