import { AppState } from 'core/redux/rootReducer';
import CHART_X_UNIT from 'meterReading/datasets/chart_x_unit';
import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';
import { rentalObjectsActions } from 'rentalObject/redux/rentalObjectActions';
import { getMeterLinksData } from 'rentalObject/utils/getMeterLinksData';

export function* handleReceiveMeterReadings({ payload }: AnyAction): Generator {
    const meterReadingsData = payload.data?.meterReadings || [];
    const selectedObjectID = yield select(({ agreementObjects }: AppState) => agreementObjects.selectedObjectID);

    yield put(rentalObjectsActions.putMeterReadingsData(meterReadingsData));
    yield put(
        rentalObjectsActions.setChartsData({
            metersData: meterReadingsData,
            chartsUnit: CHART_X_UNIT.MONTH,
            meterChartsLinks: getMeterLinksData(meterReadingsData, selectedObjectID as number),
        }),
    );
}
