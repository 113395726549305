import useMutationResetUserPassword from 'tenantUser/api/gql/useMutationResetUserPassword';
import useQueryValidateUserResetPasswordHash from 'tenantUser/api/gql/useQueryValidateUserResetPasswordHash';

const useResetPasswordController = (hash: string): ResetPasswordControllerReturnType => {
    const {
        loading,
        error: validationError,
        isValid,
        message: validationMessage,
    } = useQueryValidateUserResetPasswordHash(hash);

    const {
        loading: resetPasswordLoading,
        error: resetPasswordError,
        handleResetPassword,
    } = useMutationResetUserPassword(hash);

    return {
        handleResetPassword,
        loading,
        isValidHash: isValid,
        resetPasswordLoading,
        validationMessage,
        resetPasswordError,
        validationError,
    };
};

export default useResetPasswordController;

type ResetPasswordControllerReturnType = {
    handleResetPassword(password: string): void;
    loading: boolean;
    isValidHash: boolean;
    resetPasswordLoading: boolean;
    validationMessage: string;
    validationError: unknown;
    resetPasswordError: unknown;
};
