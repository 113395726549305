import { AxiosResponse } from 'axios';
import ClientType from 'core/classes/ClientType';
import IWebClient from 'core/types/IWebClient';
import DoorAccess from 'smartHome/larva/classes/DoorAccess';
import DoorAccessType from 'smartHome/larva/classes/DoorAccessType';
import DoorPinCode from 'smartHome/larva/classes/DoorPinCode';
import IDoorCardRawData from 'smartHome/larva/types/doorAccess/IDoorCardRawData';
import IDoorCodeRawData from 'smartHome/larva/types/doorAccess/IDoorCodeRawData';
import ILarvaDoorAccess from 'smartHome/larva/types/doorAccess/ILarvaDoorAccess';
import ILarvaSelectedDoorAccess from 'smartHome/larva/types/doorAccess/ILarvaSelectedDoorAccess';
import LarvaPaginatedResponse from 'smartHome/larva/types/LarvaPaginatedResponse';

class LarvaDoorAccessController {
    private _client?: IWebClient;

    constructor(webClient: IWebClient) {
        this.client = webClient;
    }

    get client(): IWebClient {
        if (this._client) {
            return this._client;
        }

        throw new ReferenceError('Undefined web client');
    }

    set client(value: unknown) {
        if (
            value &&
            typeof value === 'object' &&
            'type' in value &&
            (value as IWebClient).type === ClientType.WEB_CLIENT
        ) {
            this._client = value as IWebClient;
            return;
        }

        throw new TypeError('Invalid web client');
    }

    createCard(access: unknown): Promise<AxiosResponse> {
        if (access instanceof DoorAccess) {
            return this.client.post('cards', access.cardData) as Promise<AxiosResponse>;
        }

        throw new TypeError('Invalid card');
    }

    createCode(access: unknown): Promise<AxiosResponse> {
        if (access instanceof DoorAccess) {
            // Ugly fix, because backend now throws error on optional id field.
            const codeData = { ...access.codeData };
            // @ts-ignore
            delete codeData.id;

            return this.client.post('users', codeData) as Promise<AxiosResponse>;
        }

        throw new TypeError('Invalid code access');
    }

    async getCodes(): Promise<AxiosResponse<LarvaPaginatedResponse>> {
        const response = (await this.client.get('users')) as Promise<AxiosResponse<LarvaPaginatedResponse>>;
        // @ts-ignore
        return response?.data?.data || [];
    }

    async getCards(): Promise<AxiosResponse<LarvaPaginatedResponse>> {
        const response = (await this.client.get('cards')) as Promise<AxiosResponse<LarvaPaginatedResponse>>;
        //@ts-ignore
        return response?.data?.data || [];
    }

    async updateAccessPin(
        access: ILarvaSelectedDoorAccess,
    ): Promise<AxiosResponse<IDoorCodeRawData> | AxiosResponse<IDoorCardRawData> | undefined> {
        const url = access.accessType === DoorAccessType.CODE ? `users/${access.id}/pin` : `cards/${access.id}`;

        let response;

        switch (access.accessType) {
            case DoorAccessType.CODE: {
                response = (await this.client.post(url, {
                    pin: DoorPinCode.GENERATE,
                })) as AxiosResponse<IDoorCodeRawData>;
                break;
            }
            case DoorAccessType.CARD: {
                response = (await this.client.patch(url, {
                    pin: DoorPinCode.GENERATE,
                })) as AxiosResponse<IDoorCardRawData>;
                break;
            }
            default: {
                console.log('NO REQUEST', access);
            }
        }
        return response;
    }

    async unsetAccessPin(
        access: ILarvaSelectedDoorAccess,
    ): Promise<AxiosResponse<IDoorCodeRawData> | AxiosResponse<IDoorCardRawData> | undefined> {
        const url = access.accessType === DoorAccessType.CODE ? `users/${access.id}/pin` : `cards/${access.id}`;

        let response;

        switch (access.accessType) {
            case DoorAccessType.CODE: {
                response = (await this.client.post(url, {
                    pin: DoorPinCode.UNSET,
                })) as AxiosResponse<IDoorCodeRawData>;
                break;
            }
            case DoorAccessType.CARD: {
                response = (await this.client.patch(url, {
                    pin: DoorPinCode.UNSET,
                })) as AxiosResponse<IDoorCardRawData>;
                break;
            }
            default: {
                console.log('NO REQUEST', access);
            }
        }
        return response;
    }

    async createAccess(access: DoorAccess): Promise<AxiosResponse> {
        if (DoorAccessType.isCode(access.accessType)) {
            return this.createCode(access);
        }

        return this.createCard(access);
    }

    async deleteCard(access: DoorAccess): Promise<AxiosResponse> {
        return (await this.client.delete(`cards/${access.id}`)) as Promise<AxiosResponse>;
    }

    async deleteCode(access: DoorAccess): Promise<AxiosResponse> {
        return (await this.client.delete(`users/${access.id}`)) as Promise<AxiosResponse>;
    }

    async deleteAccess(access: DoorAccess): Promise<AxiosResponse> {
        if (DoorAccessType.isCode(access.accessType)) {
            return this.deleteCode(access);
        }

        return this.deleteCard(access);
    }

    async sendPinCodeBySMS(access: DoorAccess): Promise<unknown | boolean> {
        if (DoorAccessType.isCode(access.accessType)) {
            const url = `users/${access.id}/send-sms`;
            return this.client.post(url, { phone: access.phone });
        }

        return false;
    }

    async sendPinCodeByEmail(access: DoorAccess): Promise<unknown | boolean> {
        if (DoorAccessType.isCode(access.accessType)) {
            const url = `users/${access.id}/send-email`;
            return this.client.post(url, { email: access.email });
        }

        return false;
    }

    async updateAccessData(access: ILarvaDoorAccess, data: { [field: string]: string }): Promise<unknown> {
        const url = access.accessType === DoorAccessType.CODE ? `users/${access.id}` : `cards/${access.id}`;

        return (await this.client.patch(url, data)) as AxiosResponse<IDoorCodeRawData>;
    }
}

export default LarvaDoorAccessController;
