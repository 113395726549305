import { IMeterReadingsData } from '../types/rentalObjects';
import { CORE, RENTAL_OBJECT } from 'core/datasets/action';
import {
    getDataYears,
    getMeterTypesFrom,
    prepareDataForChartsWithXAxisUnit,
} from 'meterReading/utils/chartsDataHelper';
import { ChartsDataSetType } from 'meterReading/types/charts';
import { ObjectMetersSettings } from '../types/ObjectMetersSettings';
import { AppActions } from 'core/types/redux/actions';
import { DocumentNode } from 'graphql';
import { RequestMeterReadingsDataAction } from 'rentalObject/types/redux/rentalObjectActions';

export interface MeterReadingsQueryVars {
    rentalObjectId: number;
    range?: {
        fromDate?: string;
        toDate?: string;
    };
}

export const rentalObjectsActions = {
    requestMeterReadingsData: (objectId?: number | null, date?: string | null): RequestMeterReadingsDataAction => {
        return {
            type: RENTAL_OBJECT.REQUEST_METER_READINGS_DATA_INTO_STORE,
            payload: {
                objectId,
                date,
            },
        };
    },

    putMeterReadingsData: (metersData: IMeterReadingsData[]): AppActions => {
        return {
            type: RENTAL_OBJECT.PUT_METER_READINGS_DATA_INTO_STORE,
            payload: metersData,
        };
    },

    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setChartsData: ({ metersData, chartsUnit, meterChartsLinks }): AppActions => {
        const chartsDataSet: ChartsDataSetType = {
            chartsData: {
                types: getMeterTypesFrom(metersData),
                data: prepareDataForChartsWithXAxisUnit(metersData, chartsUnit),
            },
            dataYears: getDataYears(metersData),
            paths: meterChartsLinks,
        };

        return {
            type: RENTAL_OBJECT.SET_CHARTS_DATA,
            payload: chartsDataSet,
        };
    },

    cleanChartsData: () => {
        return {
            type: RENTAL_OBJECT.CLEAN_CHARTS_DATA,
        };
    },

    setObjectMeters: (meters: ObjectMetersSettings) => {
        return {
            type: RENTAL_OBJECT.SET_OBJECT_METERS,
            payload: meters,
        };
    },

    fetchMeterReadings(gql: DocumentNode, options: MeterReadingsQueryVars, callback?: string): AppActions {
        return {
            type: CORE.MAKE_GRAPHQL_REQUEST,
            payload: {
                query: gql,
                variables: options,
                callbackType: callback || RENTAL_OBJECT.RECEIVE_METER_READINGS,
            },
        };
    },
};
