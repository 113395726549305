import { put, select, take } from 'redux-saga/effects';
import { AppState } from 'core/redux/rootReducer';
import { messagingActions } from 'messaging/redux/messagingActions';
import { FIREBASE } from 'core/datasets/action';
import { sendMessagingChannelMessage } from '../../gql/messagigMutations';

export function* sendNewMessage(): Generator {
    const newMessage = (yield select(({ messaging }: AppState) => messaging.newMessage)) as string;
    yield put(messagingActions.changeNewMessage(''));

    const channelId = (yield select(({ messaging }: AppState) => messaging.currentChannelId)) as string;
    let firebaseToken = (yield select(({ firebase }: AppState) => firebase.token)) as any;
    if (!firebaseToken) {
        yield take(FIREBASE.SET_TOKEN);
        firebaseToken = (yield select(({ firebase }: AppState) => firebase.token)) as any;
    }

    if (newMessage && channelId && firebaseToken) {
        yield put(
            messagingActions.sendNewMessageToServer(sendMessagingChannelMessage, firebaseToken, channelId, newMessage),
        );
    }
}
