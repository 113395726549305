import { IAgreementObjectPropsData } from 'agreement/types/IAgreementPropsData';
import { AgreementObjectRawData } from 'agreementObject/types/AgreementObjectRawData';
import Serializable from 'core/types/Serialisable';
import { ILicensePlate } from 'rentalObject/types/rentalObjects';

interface IObjectAgreementScopeProps extends IAgreementObjectPropsData {
    // ObjectAgreementScope supposed to be used in the multi-objects agreements only
    parentAgreementId: number;
}

export class ObjectAgreementScope implements Serializable {
    get parentAgreementId(): number {
        if (!this._parentAgreementId) {
            throw new TypeError('[ObjectAgreementScope]: parentAgreementId is not defined');
        }

        return this._parentAgreementId;
    }

    set parentAgreementId(value: number | null) {
        if (!value) {
            throw new TypeError('[ObjectAgreementScope]: Invalid parentAgreementId');
        }

        this._parentAgreementId = value;
    }

    get agreementStart(): string {
        if (!this._agreementStart) {
            throw new TypeError('[ObjectAgreementScope]: AgreementStart is not defined');
        }

        return this._agreementStart;
    }

    set agreementStart(value: string | null) {
        if (!value) {
            throw new TypeError('[ObjectAgreementScope]: Invalid agreementStart value');
        }

        this._agreementStart = value;
    }

    get rentalObject(): AgreementObjectRawData {
        if (!this._rentalObject) {
            throw new TypeError('[ObjectAgreementScope]: uuid is not defined');
        }
        return this._rentalObject;
    }

    set rentalObject(value: AgreementObjectRawData | null) {
        if (!value) {
            throw new TypeError('[ObjectAgreementScope]: Invalid rentalObject value');
        }

        this._rentalObject = value;
    }

    get uuid(): string {
        if (!this._uuid) {
            throw new TypeError('[ObjectAgreementScope]: uuid is not defined');
        }
        return this._uuid;
    }

    set uuid(value: string | null) {
        if (!value) {
            throw new TypeError('[ObjectAgreementScope]: Invalid uuid');
        }

        this._uuid = value;
    }

    get id(): number {
        if (!this._id) {
            throw new TypeError('[ObjectAgreementScope]: Id is not defined');
        }

        return this._id;
    }

    set id(value: number | null) {
        if (!value) {
            throw new TypeError('[ObjectAgreementScope]: Invalid id');
        }

        this._id = value;
    }

    private _id: number | null = null;
    private _uuid: string | null = null;
    private _rentalObject: AgreementObjectRawData | null = null;
    private _agreementStart: string | null = null;
    private agreementEnd: string | null = null;
    private paymentAmount: number | null = null;
    private deposit: number | null = null;
    private licensePlates: ILicensePlate[] | null = null;
    private containsVat = false;
    private containsUtilities = false;
    private moveInDate: string | null = null;
    private moveInTime: string | null = null;
    private moveOutDate: string | null = null;
    private moveOutTime: string | null = null;
    private residents: number | null = null;
    private _parentAgreementId: number | null = null;

    constructor(props: IObjectAgreementScopeProps) {
        this.id = props.id;
        this.uuid = props.uuid;
        this.rentalObject = props.rentalObject;
        this.agreementEnd = props.agreementEnd;
        this._agreementStart = props.agreementStart;
        this.paymentAmount = props.paymentAmount;
        this.deposit = props.deposit;
        this.licensePlates = props.licensePlates;
        this.containsVat = props.containsVat;
        this.containsUtilities = props.containsUtilities;
        this.moveInDate = props.moveInDate;
        this.moveInTime = props.moveInTime;
        this.moveOutDate = props.moveOutDate;
        this.moveOutTime = props.moveOutTime;
        this.residents = props.residents;
        this.parentAgreementId = props.parentAgreementId;
    }

    get all(): IObjectAgreementScopeIData {
        return {
            id: this.id,
            uuid: this.uuid,
            rentalObject: this.rentalObject,
            agreementStart: this.agreementStart,
            agreementEnd: this.agreementEnd,
            paymentAmount: this.paymentAmount,
            deposit: this.deposit,
            licensePlates: this.licensePlates,
            containsVat: this.containsVat,
            containsUtilities: this.containsUtilities,
            moveInDate: this.moveInDate,
            moveInTime: this.moveInTime,
            moveOutDate: this.moveOutDate,
            moveOutTime: this.moveOutTime,
            residents: this.residents,
            parentAgreementId: this.parentAgreementId,
        };
    }

    toJSON(): IObjectAgreementScopeIData {
        return this.all;
    }
}

export interface IObjectAgreementScopeIData {
    id: number;
    uuid: string;
    rentalObject: AgreementObjectRawData;
    agreementStart: string;
    agreementEnd: string | null;
    paymentAmount: number | null;
    deposit: number | null;
    licensePlates: ILicensePlate[] | null;
    containsVat: boolean;
    containsUtilities: boolean;
    moveInDate: string | null;
    moveInTime: string | null;
    moveOutDate: string | null;
    moveOutTime: string | null;
    residents: number | null;
    parentAgreementId: number;
}
