import countries from 'core/datasets/countries.json';
import CountryData from 'core/types/CountryData';
class CountryCode {
    static DEFAULT_CODE = 'EE';

    private _value = CountryCode.DEFAULT_CODE;

    constructor(code: string) {
        this.value = code;
    }

    static isValid(code: string): boolean {
        return CountryCode.has(code);
    }

    static has(_code: unknown): boolean {
        return (
            !!_code &&
            typeof _code === 'string' &&
            _code.trim().length === 2 &&
            !!countries.filter(({ code }: CountryData) => code === _code.trim().toUpperCase()).length
        );
    }

    get value(): string {
        return this._value;
    }

    set value(value: string) {
        if (CountryCode.isValid(value)) {
            this._value = value.trim().toUpperCase();
            return;
        }

        throw new TypeError('Invalid country code');
    }
}
export default CountryCode;
