import PublicPageContainer from '../../core/components/layout/PublicPageContainer';
import SupportText from './SupportText';
import { LogoBig } from '../../core/components/Logo';
import LangSelect from '../../core/components/LangSelect';
import { LANG_SELECT_VARIANT } from '../../core/components/LangSelect';
import { Box } from '@material-ui/core';
import './Support.css';
import { useUpdateLangOnParams } from '../../core/components/RoutesSwitcher/hooks/useUpdateLangOnParams';

export default function Support(): JSX.Element {
    useUpdateLangOnParams();

    return (
        <PublicPageContainer isLoading={false}>
            <Box className={'support-wrapper'}>
                <div className="login-form__field-container login-form__field-container--lang">
                    <LogoBig />
                    <LangSelect variant={LANG_SELECT_VARIANT.ICON_STANDARD} />
                </div>
                <SupportText />
            </Box>
        </PublicPageContainer>
    );
}
