import { gql, useQuery } from '@apollo/client';
import { ApolloError } from 'apollo-boost';

const ValidateUserResetPasswordHashDocument = gql`
    query validateUserResetPasswordHash($hash: String!) {
        validateUserResetPasswordHash(hash: $hash) {
            isValid
            message
        }
    }
`;

interface ValidationResponseData {
    isValid: boolean;
    message: string | null;
}

interface ValidationResponse {
    validateUserResetPasswordHash: ValidationResponseData;
}

const useQueryValidateUserResetPasswordHash = (hash: string): QueryValidateUserResetPasswordHashReturnType => {
    const { loading, error, data } = useQuery<ValidationResponse>(ValidateUserResetPasswordHashDocument, {
        variables: {
            hash,
        },
        fetchPolicy: 'cache-and-network',
    });

    return {
        loading,
        error,
        isValid: data?.validateUserResetPasswordHash?.isValid || false,
        message: data?.validateUserResetPasswordHash?.message || '',
    };
};

export default useQueryValidateUserResetPasswordHash;

type QueryValidateUserResetPasswordHashReturnType = {
    loading: boolean;
    error?: ApolloError;
    isValid: boolean;
    message: string;
};
