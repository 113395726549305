import MutationResponse from 'core/types/api/MutationResponse';
import IAgreementInstanceData from 'agreement/types/IAgreementData';
import { AgreementChangeRequestType } from 'agreement/classes/AgreementChangeRequestType';
import { agreementActions } from 'agreement/redux/agreementActions';
import { tempAgreementSelector } from 'agreement/redux/tempAgreementSelector';
import { ReceivedAgreementsChangeRequestResponseAction } from 'agreement/types/ReceivedAgreementsChangeRequestResponseAction';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { AGREEMENT_TEMP } from 'core/datasets/action';
import { coreActions } from 'core/redux/actions/coreActions';
import { put, select, delay } from 'redux-saga/effects';

export function* handleAgreementChangesRequestResponse({
    payload,
}: ReceivedAgreementsChangeRequestResponseAction): Generator {
    const { data } = payload;

    if (!data || !('requestRentalAgreementTermination' in data || 'requestRentalAgreementExtension' in data)) {
        console.log('payload', payload);
        console.error(`[handleAgreementChangesRequestResponse]: wrong payload data`);
        return;
    }

    const responseData =
        'requestRentalAgreementTermination' in data
            ? data['requestRentalAgreementTermination']
            : data['requestRentalAgreementExtension'];

    const requestType =
        'requestRentalAgreementTermination' in data
            ? AgreementChangeRequestType.TERMINATION
            : AgreementChangeRequestType.EXTENSION;

    yield handleResponse(responseData, requestType);
}

function* handleResponse(data: MutationResponse, type: AgreementChangeRequestType): Generator {
    const { isSuccessful, message: errorMessage } = data;
    const successMessage = `documents.rentalAgreement.confirmation.notices.onSuccess.${type}`;
    const successAlert = new NotificationAlert({ message: successMessage, level: NotificationAlert.SUCCESS });
    const errorAlert = new NotificationAlert({ message: errorMessage, level: NotificationAlert.ERROR });

    yield put(agreementActions.changeLoadingState(false));
    yield put(agreementActions.closeChangeRequestModal());
    yield put(coreActions.notification.set(isSuccessful ? successAlert : errorAlert));

    if (isSuccessful && type === AgreementChangeRequestType.TERMINATION) {
        // This is ugly and hacky solution. It blocks 'Request termination button for 5 minutes'
        yield temporaryDisableCurrentAgreementTerminationRequests();
    }
}

function* temporaryDisableCurrentAgreementTerminationRequests(): Generator {
    const agreementToDisable = (yield select(tempAgreementSelector.selected)) as IAgreementInstanceData | null;
    const uuid = agreementToDisable?.uuid || null;
    const delay5mins = 1000 * 60 * 5;

    if (uuid) {
        yield put({
            type: AGREEMENT_TEMP.ADD_SELECTED_TO_BLOCK_LIST,
            payload: uuid,
        });
        yield delay(delay5mins);
        yield put({
            type: AGREEMENT_TEMP.REMOVE_SELECTED_FROM_BLOCK_LIST,
            payload: uuid,
        });
    }
}
