import { EnvironmentType } from '../core/types/EnvironmentType';

export const fullLanguageList = ['en', 'et', 'lv', 'pl', 'ro', 'de', 'lt'];
export type LanguageList = (typeof fullLanguageList)[number];

interface DashboardComponent {
    id?: string;
    position: number;
    visible_for: string | null;
    except_user_emails: string[];
}

export interface SysConfig {
    languages: LanguageList[];
    lng: LanguageList;
    fallbackLng: LanguageList;
    tenantRegistration: {
        terms: {
            urlEng: string;
            type: string;
        } | null;
        extraTerms: {
            urlEng: string;
            type: string;
        } | null;
    };
    dashboardComponents: {
        [index: string]: DashboardComponent;
    };
    larva: {
        showIfAvailable: boolean;
        alwaysAvailableForEmails: string[];
    };
    meterReading: {
        delete: {
            allowedInHours: number;
        };
    };

    appType: EnvironmentType;
}
