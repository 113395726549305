import { DateTime } from 'luxon';
import { LOCALE } from '../../datasets/locale';

export const formatDateTimeFormatToLocalFormat = (date: DateTime, selectedLanguage: string, format: string): string => {
    const selectedLocale = LOCALE.MOMENT[selectedLanguage as keyof typeof LOCALE.MOMENT];

    return date instanceof DateTime ? date.toFormat(format, { locale: selectedLocale }) : 'invalid input date format';
};

export default formatDateTimeFormatToLocalFormat;
