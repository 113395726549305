import { WindowSize } from 'core/classes/WindowSize';
import { MENU_ITEM_ID } from 'core/config/menu_config';
import { MAIN_ROUTE_PATH, SECONDARY_ROUTE_PATH } from 'core/config/routes';
import { ICON_ID } from 'core/datasets/icon_id';
import { MENU_AREA } from 'core/datasets/menuArea';
import { PAGE_ID } from 'core/datasets/page_id';
import { IMenuItem } from 'core/types/redux/MenuReducerState';

export const sideBarMenuIds = [
    MENU_ITEM_ID.DASHBOARD,
    MENU_ITEM_ID.MESSAGING,
    MENU_ITEM_ID.RENTAL_AGREEMENTS,
    MENU_ITEM_ID.INVOICES,
    MENU_ITEM_ID.SERVICES,
    MENU_ITEM_ID.SMART_HOME,
    MENU_ITEM_ID.DOOR_CODES,
    MENU_ITEM_ID.EXTRA_SERVICES,
    MENU_ITEM_ID.METER_READINGS,
    MENU_ITEM_ID.CO2METERS,
    MENU_ITEM_ID.TIPS_AND_TRICKS,
    MENU_ITEM_ID.FILES,
];

export const navBarMenuIds = [
    MENU_ITEM_ID.DASHBOARD,
    MENU_ITEM_ID.INVOICES,
    MENU_ITEM_ID.RENTAL_AGREEMENTS,
    MENU_ITEM_ID.MESSAGING,
    MENU_ITEM_ID.SMART_HOME,
    MENU_ITEM_ID.METER_READINGS,
];

export const slideInMenuIds = [
    MENU_ITEM_ID.SERVICES,
    MENU_ITEM_ID.DOOR_CODES,
    MENU_ITEM_ID.EXTRA_SERVICES,
    MENU_ITEM_ID.METER_READINGS,
    MENU_ITEM_ID.FILES,
    MENU_ITEM_ID.CO2METERS,
    MENU_ITEM_ID.TIPS_AND_TRICKS,
    MENU_ITEM_ID.LOGOUT,
];

export const menuItems: { [id: string]: IMenuItem } = {
    [MENU_ITEM_ID.DASHBOARD]: {
        iconName: ICON_ID.DASHBOARD,
        labelText: 'dashboard',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.DASHBOARD],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.DASHBOARD],
        disabled: false,
        hideOnWindowSize: [],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.MESSAGING]: {
        iconName: ICON_ID.MESSAGES,
        labelText: 'messages',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.MESSAGING],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.MESSAGING],
        disabled: false,
        hideOnWindowSize: [WindowSize.BREAKPOINT_ALL_RANGE_NAME],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.RENTAL_AGREEMENTS]: {
        iconName: ICON_ID.RENTAL_AGREEMENTS,
        labelText: 'rentalAgreements',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.RENTAL_AGREEMENTS],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.RENTAL_AGREEMENT],
        disabled: true,
        hideOnWindowSize: [],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.INVOICES]: {
        iconName: ICON_ID.INVOICES,
        labelText: 'invoices',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.INVOICES],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.INVOICE],
        disabled: false,
        hideOnWindowSize: [],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.SERVICES]: {
        iconName: ICON_ID.EXTRA_SERVICES,
        labelText: 'services',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.SERVICES],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.SERVICE],
        disabled: false,
        hideOnWindowSize: [],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.EXTRA_SERVICES]: {
        iconName: ICON_ID.EXTRA_SERVICES,
        labelText: 'extraServices',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.EXTRA_SERVICES],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.EXTRA_SERVICE],
        disabled: false,
        hideOnWindowSize: [],
        hideFromMenuArea: [],
        icon: ICON_ID.EXTRA_SERVICES,
    },
    [MENU_ITEM_ID.SMART_HOME]: {
        iconName: ICON_ID.SMART_HOME,
        labelText: 'smartHome',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.SMART_HOME],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.SMART_HOME],
        disabled: false,
        hideOnWindowSize: [WindowSize.BREAKPOINT_ALL_RANGE_NAME],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.DOOR_CODES]: {
        iconName: ICON_ID.DOOR_CODE,
        labelText: 'doorCode',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.DOOR_CODES],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.DOOR_CODE],
        disabled: false,
        hideOnWindowSize: [WindowSize.BREAKPOINT_ALL_RANGE_NAME],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.METER_READINGS]: {
        iconName: ICON_ID.METER_READINGS,
        labelText: 'meterReadings',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.METER_READINGS],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.METER_READING],
        disabled: false,
        hideOnWindowSize: [],
        hideFromMenuArea: [MENU_AREA.slideIn],
    },
    [MENU_ITEM_ID.FILES]: {
        iconName: ICON_ID.FILES,
        labelText: 'files',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.FILES],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.FILE],
        disabled: false,
        hideOnWindowSize: [],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.CO2METERS]: {
        iconName: ICON_ID.CO2_METERS,
        labelText: 'co2Meters',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.CO2METERS],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.CO2METERS_ASIDE],
        disabled: false,
        hideOnWindowSize: [WindowSize.BREAKPOINT_ALL_RANGE_NAME],
        hideFromMenuArea: [],
        icon: ICON_ID.CO2_METERS,
    },
    [MENU_ITEM_ID.TIPS_AND_TRICKS]: {
        iconName: ICON_ID.TIPS_AND_TRICKS,
        labelText: 'tipsAndTricks',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.TIPS_AND_TRICKS],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.TIP_OR_TRICK],
        disabled: false,
        hideOnWindowSize: [WindowSize.BREAKPOINT_ALL_RANGE_NAME],
        hideFromMenuArea: [],
        icon: ICON_ID.TIPS_AND_TRICKS,
    },
    [MENU_ITEM_ID.SETTINGS]: {
        iconName: ICON_ID.SETTINGS,
        labelText: 'settings',
        mainRoute: MAIN_ROUTE_PATH[PAGE_ID.SETTINGS],
        secondaryRoutes: SECONDARY_ROUTE_PATH[PAGE_ID.SETTINGS],
        disabled: false,
        hideOnWindowSize: [
            WindowSize.BREAKPOINT_XS_RANGE_NAME,
            WindowSize.BREAKPOINT_LG_RANGE_NAME,
            WindowSize.BREAKPOINT_XL_RANGE_NAME,
        ],
        hideFromMenuArea: [],
    },
    [MENU_ITEM_ID.LOGOUT]: {
        iconName: ICON_ID.LOGOUT,
        labelText: 'logOut',
        mainRoute: '',
        secondaryRoutes: [''],
        disabled: false,
        hideOnWindowSize: [],
        hideFromMenuArea: [],
    },
};
