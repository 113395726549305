import { agreementActions } from 'agreement/redux/agreementActions';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { coreActions } from 'core/redux/actions/coreActions';
import { put } from 'redux-saga/effects';

export function* handleLicensePlatesChangedAction(): Generator {
    yield put(agreementActions.callFetchAllAgreementsDataUpdate());
    yield put(
        coreActions.notification.set(
            new NotificationAlert({
                message: 'documents.rentalAgreement.saved',
                level: NotificationAlert.SUCCESS,
            }),
        ),
    );
}
