/**
 * ID of pages - same as the corresponding page components names
 * Used for lazy loading in the dynamic router
 *
 */
export const PAGE_ID = {
    DASHBOARD: 'Dashboard',
    INVOICE: 'Invoice',
    INVOICES: 'Invoices',
    RENTAL_AGREEMENT: 'RentalAgreement',
    RENTAL_AGREEMENTS: 'RentalAgreements',
    MESSAGING: 'Messaging',
    SERVICES: 'Services',
    SERVICE: 'Service',
    SMART_HOME: 'SmartHome',
    EXTRA_SERVICES: 'ExtraServices',
    EXTRA_SERVICE: 'ExtraService',
    METER_READING: 'MeterReading',
    METER_READINGS: 'MeterReadings',
    DOOR_CODES: 'DoorCodes',
    DOOR_CODE: 'DoorCode',
    FILES: 'Files',
    FILE: 'File',
    SETTINGS: 'Settings',
    NOTICES: 'Notices',
    TIPS_AND_TRICKS: 'TipsAndTricks',
    TIP_OR_TRICK: 'TipOrTrick',
    CO2METERS: 'CO2Meters',
    CO2METERS_ASIDE: 'CO2MetersAside',
};
