import { FiltersState } from '../../../types/redux/filtersState';
import { PageContextType } from '../../../types/pageContext';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import FilterButton, { FilterButtonProps } from '../FilterButton';
import { filterPageContext } from '../../../redux/actions/slideInContextActions';
import { FILTER_ACTION } from '../../../config/page_context_actions';
import FILTER from '../../../datasets/filter';

interface FilterIconTriggerButtonProps extends FilterButtonProps {
    filters?: FiltersState;
    pageContext?: PageContextType;
    onClick?: () => void;
}

const mapDispatchToProps = (
    dispatch: Dispatch,
    { onClick, filters, pageContext }: Partial<FilterIconTriggerButtonProps>,
) => {
    const contextWithFilters = Object.values(FILTER);
    return {
        clickHandle: () => {
            dispatch(
                filterPageContext({
                    pageContext: pageContext as PageContextType,
                    contextAction: FILTER_ACTION[pageContext as keyof typeof FILTER_ACTION],
                }),
            );
            onClick && onClick();
        },
        // @ts-ignore
        isVisible: !!pageContext && contextWithFilters.indexOf(pageContext) !== -1,
        // @ts-ignore
        isEnabled: !!filters && pageContext in filters && filters[pageContext].isEnabled,
    };
};

const FilterIconTriggerButton = connect(null, mapDispatchToProps)(FilterButton);
export default FilterIconTriggerButton;
