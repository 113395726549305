import { gql } from '@apollo/client';

export const queryGetFileDocument = gql`
    query getFile($id: Int!) {
        getFile(id: $id) {
            id
            name
            fileName
            url
            mimeType
            date
            description
            size
            content
        }
    }
`;

export const queryGetFileUrlDocument = gql`
    query getFile($id: Int!) {
        getFile(id: $id) {
            id
            name
            fileName
            url
            mimeType
            date
            description
            size
        }
    }
`;

export const queryGetFilesDocument = gql`
    query getFiles {
        getFiles {
            id
            name
            fileName
            mimeType
            date
            description
            size
        }
    }
`;
