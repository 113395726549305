import { put, select, call } from 'redux-saga/effects';
import { AppState } from 'core/redux/rootReducer';
import { LARVA_WS } from 'core/datasets/action';
import { initOnlyWebsocketFetching } from './initUnitsUiNodesFetching';

export function* fetchAllData(): Generator {
    const isActive = (yield select((state: AppState) => state.core.isAppActive)) as boolean;
    const isOnline = (yield select((state: AppState) => state.core.networkStatus.isConnected)) as boolean;

    if (isOnline && isActive) {
        yield call(initOnlyWebsocketFetching, { type: 'fetchAllData' });
    }

    if (!isActive || !isOnline) {
        yield put({ type: LARVA_WS.CLOSE_CONNECTION });
    }
}
