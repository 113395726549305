// Components
import Typography from '@material-ui/core/Typography';

// Styles
import './LabeledText.css';

export interface IProps {
    labelText?: string;
    primaryText: string;
    className?: string;
}
/**
 * Display text with label over it
 * @param labelText
 * @param primaryText
 * @param className
 * @constructor
 */
const LabeledText = ({ labelText, primaryText, className = '' }: IProps): JSX.Element => {
    return (
        <Typography className={'labeled-text ' + className} component="div">
            {!!labelText && <span className="labeled-text__label">{labelText}</span>}
            <span className="labeled-text__primary">{primaryText}</span>
        </Typography>
    );
};
export default LabeledText;
