import { FIREBASE } from '../../core/datasets/action';
import { FirebaseReducerState } from 'firebase/types/redux/firebaseState';
import { FirebaseActions } from 'firebase/types/redux/firebaseActions';

const initState: FirebaseReducerState = {
    authDone: false,
    token: null,
    expireAt: 0,
    user: null,
};

export const firebaseReducer = (state = initState, action: FirebaseActions): FirebaseReducerState => {
    switch (action.type) {
        case FIREBASE.GET_NEW_TOKEN:
            return {
                ...state,
                user: null,
            };

        case FIREBASE.SET_TOKEN:
            return {
                ...state,
                authDone: true,
                token: action.payload.data.getMessagingAccessToken.jwt,
                expireAt: action.payload.data.getMessagingAccessToken.expireAt,
            };

        case FIREBASE.SET_FIREBASE_USER:
            return {
                ...state,
                user: action.payload as any,
            };

        default:
            return state;
    }
};
