import { UINode } from '@larva.io/clouddevice/dist/types/interface';
import ILarvaWebSocketClient from 'smartHome/larva/types/ILarvaWebSocketClient';
import LarvaDeviceData from 'smartHome/larva/types/LarvaDeviceData';
import LarvaDeviceObject from 'smartHome/larva/types/LarvaDeviceObject';
import { UINodeLocal } from '../types/UINodeLocal';
import { larvaId } from '../utils/makeId';

interface LarvaListeners {
    broadCastCallback: unknown;
    closedCallback?: unknown;
    openCallback?: unknown;
    errorCallback?: unknown;
}

class LarvaDeviceController {
    private _id?: string;
    private _name?: string;
    private _unitId: null | string = null;
    private _connection: null | ILarvaWebSocketClient = null;
    private _uiNodes: UINode[] = [];

    constructor({ id, name, unitId }: LarvaDeviceData) {
        this.id = id;
        this.name = name;
        this.unitId = unitId;
    }
    get uiNodes(): UINodeLocal[] {
        return this._uiNodes.map((item) => ({ ...item, fullId: larvaId(this.unitId ?? '', this.id) }));
    }

    set uiNodes(value: UINode[]) {
        this._uiNodes = value;
    }

    setUiNodes(value: UINode[], connectionId: string): void {
        console.log(value, connectionId);
        this.uiNodes = value;
    }

    get name(): string {
        if (this._name) {
            return this._name;
        }

        throw new ReferenceError('Device name is undefined');
    }

    set name(value: unknown) {
        if (typeof value === 'string') {
            this._name = value;
            return;
        }

        throw new TypeError('Invalid name');
    }

    get id(): string {
        if (this._id) {
            return this._id;
        }

        throw new ReferenceError('Device name is undefined');
    }

    set id(value: unknown) {
        if (typeof value === 'string' && !!value.trim()) {
            this._id = value;
            return;
        }

        throw new TypeError('Invalid ID');
    }

    get connection(): ILarvaWebSocketClient | null {
        return this._connection;
    }

    set connection(value: ILarvaWebSocketClient | null) {
        this._connection = value;
    }

    get unitId(): string | null {
        return this._unitId;
    }

    set unitId(value: string | null) {
        if (value) {
            this._unitId = value;
            return;
        }
        throw new TypeError('Invalid unit id');
    }

    get hasUiNodes(): boolean {
        return !!this.uiNodes?.length;
    }

    async connect(
        client: ILarvaWebSocketClient,
        { broadCastCallback, closedCallback, openCallback, errorCallback }: LarvaListeners,
        shouldBeClosed?: boolean,
    ): Promise<void> {
        this.connection = client;
        if (this.connection.readyState() !== 1 && this.connection.readyState() !== 0) {
            //@ts-ignore
            await this.connection.open(openCallback && openCallback);
        }

        openCallback && this.connection.addEventListener('open', openCallback);
        broadCastCallback && this.connection.addEventListener('broadcast', broadCastCallback);
        closedCallback && this.connection.addEventListener('closed', closedCallback);
        errorCallback && this.connection.addEventListener('error', errorCallback);

        this.uiNodes = await this.connection.getUINodes();

        if (shouldBeClosed) {
            await this.connection.close();
        }
    }

    toObject(): LarvaDeviceObject {
        return {
            type: 'LarvaDevice',
            id: this.id,
            name: this.name,
            unitId: this.unitId as string,
            uiNodes: this.uiNodes,
        };
    }
}
export default LarvaDeviceController;
