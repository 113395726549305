class Email {
    static REGEX = /^[\w\d_&.+-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i;
    private _value?: string;

    constructor(email?: string) {
        this.value = email;
    }

    static isValid(email: unknown): boolean {
        if (!!email && typeof email === 'string') {
            return Email.REGEX.test(email);
        }

        return false;
    }

    get value(): string {
        if (!!this._value) return this._value;

        throw new ReferenceError('Undefined email');
    }

    set value(email: unknown) {
        if (Email.isValid(email)) {
            this._value = (email as string).trim();
            return;
        }

        throw new TypeError('Invalid email');
    }
}
export default Email;
