import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { MenuButtonProps } from '../../../types/menuButton';
import { makeComponentClasses } from '../../../utils/componentUtils';

// MenuIconButton replacer
const MenuButton = ({
    classes: customClasses,
    labelText,
    onClick,
    badgeColor,
    badgeValue,
    children,
    baseClass = 'custom-menu-button',
    buttonClasses = '',
    disabled = false,
    iconColor,
}: Partial<MenuButtonProps>): JSX.Element => {
    const DEFAULT_BADGE_COLOR = 'default';
    const badgeType = !badgeValue ? 'dot' : 'standard';
    const currentBadgeColor = badgeColor || DEFAULT_BADGE_COLOR;
    const currentBadgeValue = badgeValue ?? null;

    const componentEntities = {
        base: baseClass,
        container: 'container',
        iconButton: 'icon-button',
        label: 'label',
        badge: 'badge',
        iconContainer: 'icon-container',
    };
    const classes = makeComponentClasses(
        componentEntities,
        !!customClasses ? customClasses : '',
        baseClass === 'custom-menu-button' ? '' : ' custom-menu-button',
    );

    return (
        <div className={classes.base + ' ' + classes.container}>
            <IconButton
                color={iconColor}
                disabled={disabled}
                className={`${classes.iconButton} ${buttonClasses}`}
                onClick={onClick}
            >
                <Badge
                    className={`${classes.badge} ${classes.iconContainer}`}
                    invisible={!badgeColor && !badgeValue}
                    color={currentBadgeColor}
                    variant={badgeType}
                    badgeContent={currentBadgeValue}
                    overlap="rectangular"
                >
                    {children}
                </Badge>
            </IconButton>
            {!!labelText && (
                <span onClick={onClick} className={classes.label}>
                    {labelText}
                </span>
            )}
        </div>
    );
};

export default MenuButton;
