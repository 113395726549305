export class ViewVariantType {
    static readonly SUCCESS = 'success';
    static readonly DISABLED = 'disabled';
    static readonly WARNING = 'warning';
    static readonly DANGER = 'danger';

    static regex = /^success$|^disabled$|^warning$|^danger$/i;

    static isValid(value: unknown): boolean {
        return typeof value === 'string' && ViewVariantType.regex.test(value);
    }
}
