import { ApolloError } from '@apollo/react-hooks';

import { AlertNotice } from '../AlertNotice';
import { LarvaError } from '@larva.io/clouddevice';
import { AxiosError, AxiosResponse } from 'axios';

interface IErrorNotice {
    error?: ApolloError | LarvaError | AxiosResponse | AxiosError | Error;
    errorHeaderPrefixText?: string;
    errorBodyPrefixText?: string;
    inline?: boolean;
}

/**
 *
 * @param error - Apollo error | LarvaError | undefined
 * @param errorNoticeType string - value from i18n.ts -> notices.
 * @constructor
 */
const ErrorNotice = ({
    error,
    errorHeaderPrefixText = '',
    errorBodyPrefixText = '',
    inline = true,
}: IErrorNotice): JSX.Element => {
    if (!error) return <span />;

    const statusCode = 'statusCode' in error ? error.statusCode : 'data' in error ? error.data.statusCode : '';
    const errorCodeText = !!statusCode ? '(' + statusCode + ')' : '';
    const headerText = errorHeaderPrefixText + errorCodeText;

    const errorBodyMessage = 'message' in error ? error.message : 'data' in error ? error.data.message : '';
    //@ts-ignore missing debugMessage in error
    const errorDebugMessage = 'debugMessage' in error ? error.debugMessage : '';

    const bodyText = !!errorBodyMessage
        ? `${errorBodyPrefixText} ${errorBodyMessage} ${errorDebugMessage ? `(${errorDebugMessage})` : ''}`
        : '';
    return (
        <AlertNotice
            level="ERROR"
            headerText={headerText}
            text={bodyText}
            inline={inline}
            autoHide={null}
            open={true}
        />
    );
};
export default ErrorNotice;
