import { SlideInContextState } from 'core/types/redux/slideInContextState';
import { SlideInContextActions } from 'core/types/redux/slideInContextActions';
import { SLIDE_IN_CONTEXT_ACTION } from 'core/datasets/action';

const initialState: SlideInContextState = {
    pageContext: null,
    contextAction: null,
};
export const slideInContextReducer = (state = initialState, action: SlideInContextActions): SlideInContextState => {
    switch (action.type) {
        case SLIDE_IN_CONTEXT_ACTION.FILTER:
            return {
                ...state,
                ...('payload' in action ? action.payload : {}),
            };
        case SLIDE_IN_CONTEXT_ACTION.RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
