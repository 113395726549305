import { AppState } from 'core/redux/rootReducer';
import { filesActions } from 'file/redux/fileActions';
import IFileData from 'file/types/IFileData';
import { SelectFilesListViewItemByIdAction } from 'file/types/TempFilesStateActions';
import { put, select } from 'redux-saga/effects';

export function* handleFileSelectionById({ payload: id }: SelectFilesListViewItemByIdAction): Generator {
    const selectedId = (yield select(({ tempFiles }: AppState) => tempFiles.selected?.id)) as number;

    if (selectedId === id) return;

    yield put(filesActions.resetSelected());

    const map = (yield select(({ files }: AppState) => files.map)) as { [id: number]: IFileData };

    yield put(filesActions.selectFile(map[id]));
}
