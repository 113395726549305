export class DataSet {
    readonly _dataSetObject;

    constructor(dataSet: { [key: string]: string }) {
        this._dataSetObject = dataSet;
    }

    public hasKey(key: string): boolean {
        const obj = this._dataSetObject;
        return (key as keyof typeof obj) in obj;
    }

    public getValueByKey(key: string): string | null {
        return this.hasKey(key) ? this._dataSetObject[key] : null;
    }
}
