import IWebClientConfig from 'core/types/IWebClientConfig';
import IWebClientConfigData from 'core/types/redux/IWebClientConfigData';
import WebClientConfigHeaders from 'core/types/WebClientConfigHeaders';

class WebClientConfig implements IWebClientConfig {
    private _baseURL?: string;
    private _url?: string;
    private _headers?: WebClientConfigHeaders;
    private _timeout = 30000;

    constructor({ url, baseURL, headers, timeout }: IWebClientConfigData) {
        this.url = url;
        this.baseURL = baseURL;
        this.headers = headers;
        this.timeout = timeout;
    }

    get url(): string {
        return this._url || '';
    }

    set url(value: unknown) {
        if (typeof value === 'undefined') {
            return;
        }

        if (!!value && typeof value === 'string') {
            this._url = value;
            return;
        }

        throw new TypeError('Invalid url');
    }

    get baseURL(): string {
        if (this._baseURL) {
            return this._baseURL;
        }

        throw new ReferenceError('Undefined baseUrl');
    }

    set baseURL(value: unknown) {
        if (!!value && typeof value === 'string') {
            this._baseURL = value;
            return;
        }

        console.log('Invalid baseUrl', { baseUrl: value });
        throw new TypeError('Invalid baseUrl');
    }

    get timeout(): number {
        return this._timeout;
    }

    set timeout(value: unknown) {
        if (!value) return;

        if (typeof value === 'number') {
            this._timeout = value;
            return;
        }

        throw new TypeError('Invalid timeout type');
    }

    get headers(): unknown {
        return this._headers;
    }

    set headers(value: unknown) {
        if (!!value && typeof value === 'object' && !Array.isArray(value)) {
            this._headers = value as WebClientConfigHeaders;
        }
    }

    get all(): IWebClientConfigData {
        return {
            baseURL: this.baseURL,
            url: this.url,
            headers: this.headers,
            timeout: this.timeout,
        };
    }
}
export default WebClientConfig;
