import { AppState } from 'core/redux/rootReducer';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useWindowSize: WindowSizeHook = () => {
    const hasWindow = typeof window !== 'undefined';
    const ratio = hasWindow ? (window.devicePixelRatio < 1 ? window.devicePixelRatio : 1) : 1;

    const initSizes: WindowSize = {
        height: hasWindow ? Math.round(ratio * window.innerHeight) : null,
        width: hasWindow ? Math.round(ratio * window.innerWidth) : null,
    };

    const [sizes, setSizes] = useState<WindowSize>(initSizes);
    const [deviceType, setDeviceType] = useState<string>('');

    const getWindowSize = useCallback(() => {
        return {
            height: hasWindow ? Math.round(ratio * window.innerHeight) : null,
            width: hasWindow ? Math.round(ratio * window.innerWidth) : null,
        };
    }, [hasWindow, ratio]);

    const handleDeviceType = useCallback(() => {
        const userAgent = 'navigator' in window ? window['navigator'].userAgent : '';
        setDeviceType(
            /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)
                ? 'tablet'
                : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                      userAgent,
                  )
                ? 'mobile'
                : 'desktop',
        );
    }, []);

    const handleResize = useCallback(() => {
        setSizes(getWindowSize());
        handleDeviceType();
    }, [getWindowSize, handleDeviceType]);

    useEffect(() => {
        if (hasWindow) {
            window.addEventListener('resize', handleResize);
        }
        handleDeviceType();
        return () => window.removeEventListener('resize', handleResize);
    }, [handleDeviceType, handleResize, hasWindow]);

    const isMobileWidth = useSelector(({ core }: AppState) => core.isMobileWidth);

    const isWidthLessThan = useCallback(
        (value: VIEWPORT_BREAKPOINTS) => {
            return sizes.width ? ratio * sizes.width < value : null;
        },
        [ratio, sizes.width],
    );

    const getScreenWidthClassName = (viewPortBrakePoint: VIEWPORT_BREAKPOINTS) => {
        if (viewPortBrakePoint && Object.values(VIEWPORT_BREAKPOINTS).includes(viewPortBrakePoint)) {
            return isWidthLessThan(viewPortBrakePoint) ? 'mobile' : 'desktop';
        }
        return isMobileWidth ? 'mobile' : 'desktop';
    };

    return {
        height: sizes.height,
        width: sizes.width,
        isMobileWidth,
        deviceType,
        isWidthLessThan,
        getScreenWidthClassName,
    };
};

export default useWindowSize;

type WindowSizeHook = () => WindowSizeReturn;
type WindowSize = {
    height: null | number;
    width: null | number;
};
type WindowSizeReturn = WindowSize & {
    isMobileWidth: boolean | null;
    deviceType: string;
    isWidthLessThan: (value: VIEWPORT_BREAKPOINTS) => boolean | null;
    getScreenWidthClassName: (viewPortBrakePoint: VIEWPORT_BREAKPOINTS) => ScreenWidthClassName;
};

export enum VIEWPORT_BREAKPOINTS {
    w320 = 320,
    w480 = 480,
    w680 = 680,
    w768 = 768,
    w790 = 790,
    w960 = 960,
    w1025 = 1025,
    w1125 = 1125,
    w1200 = 1200,
}
type ScreenWidthClassName = 'desktop' | 'mobile';
