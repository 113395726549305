import { AxiosError, AxiosResponse } from 'axios';
import GraphQLClient from 'core/classes/GraphQLClient';
import { ResponseStatus } from 'core/classes/ResponseStatus';
import WebClient from 'core/classes/WebClient';
import WebClientConfig from 'core/classes/WebClientConfig';
import { gqlUri } from 'core/config/gql/client';
import { coreActions } from 'core/redux/actions/coreActions';
import { AppState } from 'core/redux/rootReducer';
import { handleErrorResponse } from 'core/saga/workers/handleErrorResponse';
import { HandleGraphqlRequestAction } from 'core/types/redux/CoreStateActions';
import { call, put, select } from 'redux-saga/effects';

export function* handleGraphQLRequest(action: HandleGraphqlRequestAction): Generator {
    const token = yield select(({ auth }: AppState) => auth.token);

    if (!token) {
        console.log('%c [WARN]: GQL request cancelled due to invalid token', 'color: orange');
        return;
    }

    const config = new WebClientConfig({
        headers: {
            'x-auth-token': token,
        },
        baseURL: gqlUri,
    });

    const { query, variables, callbackType, passThroughData } = action.payload;

    if (!query) {
        console.log('%c [WARN]: GQL request cancelled due to the invalid request', 'color: orange');
        return;
    }

    const client = new GraphQLClient(new WebClient(config));

    try {
        const response = (yield client.request(query, variables)) as AxiosResponse;

        if (ResponseStatus.isOk(response.status)) {
            if (response?.data) {
                // handle ok response
                yield put({ type: callbackType, payload: { ...response?.data, ...passThroughData } });
            }

            if ('errors' in response?.data) {
                // TODO: HANDLE GRAPHQL ERROR RESPONSES
                yield call(handleErrorResponse, response as AxiosResponse, action);
                yield put(coreActions.handleGraphQLErrorResponse(response, callbackType));
            }

            return;
        }
        console.log('[NOT OK RESPONSE]', response);
    } catch (reason) {
        console.error(reason);

        const response = (reason as AxiosError)?.response;

        yield call(handleErrorResponse, response as AxiosResponse, action);
    }
}
