import { DateTime } from 'luxon';
import { RENTAL_OBJECT } from 'core/datasets/action';
import { put } from 'redux-saga/effects';
import { MeterReadingsQueryVars, rentalObjectsActions } from 'rentalObject/redux/rentalObjectActions';
import { RequestMeterReadingsDataAction } from 'rentalObject/types/redux/rentalObjectActions';
import { parseSelectedRentalAgreementValidFromDate } from 'tenantUser/utils/parseSelectedRentalAgreementValidFromDate';
import { queryMeterReadings } from 'meterReading/gql/queryMeterReadings';

export function* handleRequestMeterReadings({ payload }: RequestMeterReadingsDataAction): Generator {
    const callbackAction = RENTAL_OBJECT.RECEIVE_METER_READINGS;
    const parsedFromDate = parseSelectedRentalAgreementValidFromDate(payload.date || '');
    const fromDate = parsedFromDate ? parsedFromDate.minus({ months: 2 }).startOf('month').toSQLDate() : null;
    const toDate = DateTime.now().plus({ months: 2 }).toSQLDate();

    if (!payload.objectId) return;
    const options: MeterReadingsQueryVars = {
        rentalObjectId: payload.objectId,
        ...(fromDate && {
            range: {
                fromDate,
                toDate,
            },
        }),
    };

    yield put(rentalObjectsActions.fetchMeterReadings(queryMeterReadings, options, callbackAction));
}
