import IAgreementObjectInstanceData from 'agreementObject/types/IAgreementObjectData';
import {
    PutAgreementObjectAction,
    SelectAgreementObjectAction,
    SelectAgreementObjectByIdAction,
} from 'agreementObject/types/redux/agreementObjectActions';
import { AGREEMENT_OBJECT } from 'core/datasets/action';

export const agreementObjectActions = {
    selectAgreementObjectById: (id: number): SelectAgreementObjectByIdAction => {
        return {
            type: AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED_BY_ID,
            payload: id,
        };
    },
    selectAgreementObject: (selectedObject: IAgreementObjectInstanceData | null): SelectAgreementObjectAction => {
        return {
            type: AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED,
            payload: selectedObject,
        };
    },
    putAgreementObjects: (agreementObjectMap: {
        [id: number]: IAgreementObjectInstanceData;
    }): PutAgreementObjectAction => {
        return {
            type: AGREEMENT_OBJECT.PUT_AGREEMENT_OBJECTS,
            payload: agreementObjectMap,
        };
    },
};
