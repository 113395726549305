import { takeEvery, all, fork } from 'redux-saga/effects';

import { AUTH, CORE, USER_PROFILE } from 'core/datasets/action';
import { logout, login, changeLanguage } from 'tenantUser/saga/workers';

function* watchLogout(): Generator {
    yield takeEvery(AUTH.LOGOUT, logout);
}

function* watchLogin(): Generator {
    yield takeEvery(AUTH.LOGIN_ASYNC, login);
}

function* watchLangChange(): Generator {
    yield takeEvery([CORE.INIT_NETWORK_STATUS, USER_PROFILE.CHANGE_LANGUAGE], changeLanguage);
}

export function* watchUserActions(): Generator {
    yield all([fork(watchLogout), fork(watchLogin), fork(watchLangChange)]);
}
