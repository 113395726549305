import { IMenuItem } from 'core/types/redux/MenuReducerState';
import { WindowSize, BreakPointRange } from 'core/classes/WindowSize';
import { MENU_AREA } from 'core/datasets/menuArea';

export const filterSideBar = (item: IMenuItem, windowSizeRange?: BreakPointRange): boolean => {
    const { hideOnWindowSize, hideFromMenuArea } = item;
    const hiddenCondition =
        hideOnWindowSize.includes(WindowSize.BREAKPOINT_ALL_RANGE_NAME) || hideFromMenuArea.includes(MENU_AREA.sideBar);
    if (hiddenCondition) return false;

    return !!(windowSizeRange && !hideOnWindowSize.includes(windowSizeRange));
};
