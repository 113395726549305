import { NotificationAlert } from 'core/classes/NotificationAlert';
import fetchRefreshToken from 'core/config/gql/fetchRefreshToken';
import { AUTH } from 'core/datasets/action';
import { coreActions } from 'core/redux/actions/coreActions';
import { Middleware } from 'redux';
import { authActions, refreshExpiredToken } from 'tenantUser/redux/authActions';

export const refreshTokenMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        const server = process.env.REACT_APP_GQL_PROD_SERVER_URL;

        if (action.type === AUTH.REQUEST_TO_REFRESH_EXPIRED_TOKEN) {
            const handleToken = (token: string) => {
                dispatch(refreshExpiredToken(token));
            };

            const handleError = () => {
                dispatch(authActions.setLogout());
                dispatch(
                    coreActions.notification.set(
                        new NotificationAlert({
                            message: 'tenantUser.notices.authExpired',
                            level: NotificationAlert.WARNING,
                        }),
                    ),
                );
            };

            if (server) {
                fetchRefreshToken(server, handleToken, handleError).catch((error) => {
                    console.error(error);
                });
            }
        }

        return next(action);
    };
