import useTenantRegistrationFormSchema from 'tenantUser/hooks/useTenantRegistrationFormSchema';
import { useState } from 'react';
import { Control, FieldErrors, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TermsAndConditionsProps } from 'core/types/TermsAndConditionsProps';
import InvitedTenantData from 'tenantUser/types/InvitedTenantData';
import { TenantRegistrationData } from 'tenantUser/types/TenantRegistrationData';
import { useConfig } from 'resources/config';

interface TenantRegistrationFormControllerProps {
    data: InvitedTenantData;
    onSubmit: (tenant: TenantRegistrationData) => void;
    loading: boolean;
}
const useTenantRegistrationFormController = ({
    data,
    onSubmit,
    loading,
}: TenantRegistrationFormControllerProps): TenantRegistrationFormControllerReturnType => {
    const { t, i18n } = useTranslation();
    const _t = (toTranslate: string) => t(`pages.registration.${toTranslate}`);

    const {
        tenantRegistration: { terms, extraTerms },
    } = useConfig();

    const { control, getValues, setValue } = useTenantRegistrationFormSchema(data);
    const { errors, isValid } = useFormState({ control });

    const [confirmedTerms, setConfirmedTerms] = useState(() => false);
    const [confirmedExtraTerms, setConfirmedExtraTerms] = useState(() => false);

    const termsArgs: TermsAndConditionsProps = {
        checked: confirmedTerms,
        handleChecked: () =>
            setConfirmedTerms((prev) => {
                setValue('termsAgreed', !prev, { shouldDirty: true, shouldValidate: true });
                return !prev;
            }),
        label: _t('agreeTo'),
        externalLink: terms?.urlEng.replace('{{lang}}', i18n.language),
        externalLinkText: _t(terms?.type ?? ''),
    };

    const extraTermsArgs: TermsAndConditionsProps | null = extraTerms
        ? {
              checked: confirmedExtraTerms,
              handleChecked: () =>
                  setConfirmedExtraTerms((prev) => {
                      setValue('extraTerms', !prev, { shouldDirty: true, shouldValidate: true });
                      return !prev;
                  }),
              label: _t('agreeTo'),
              externalLink: extraTerms.urlEng.replace('{{lang}}', i18n.language),
              externalLinkText: _t(extraTerms.type),
          }
        : null;

    const submitButtonArgs = {
        loading: loading,
        text: t('pages.registration.register'),
        actionHandler: () => onSubmit(getValues() as TenantRegistrationData),
        disabled: !isValid,
        loadingText: t('notices.processing'),
    };

    return {
        submitButtonArgs,
        control,
        loading,
        termsArgs,
        extraTermsArgs,
        errors,
        _t,
    };
};

export default useTenantRegistrationFormController;

type TenantRegistrationFormControllerReturnType = {
    submitButtonArgs: {
        loading: boolean;
        text: string;
        actionHandler: () => void;
        disabled: boolean;
        loadingText: string;
    };
    control: Control;
    loading: boolean;
    termsArgs: TermsAndConditionsProps;
    extraTermsArgs: TermsAndConditionsProps | null;
    errors?: FieldErrors;
    _t(key: string): string;
};
