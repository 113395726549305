import { Capacitor } from '@capacitor/core';
import FileMimeType from 'file/classes/FileMimeType';
import IFileData from 'file/types/IFileData';
import IFileRawData from 'file/types/IFileRawData';

export default class FileDto {
    private readonly id;
    private readonly name;
    private readonly fileName;
    private readonly url;
    private readonly mimeType;
    private readonly date;
    private readonly description;
    private readonly size;
    private readonly content;

    constructor({ id, name, fileName, url, mimeType, date, description, size, content }: IFileRawData) {
        this.id = id;
        this.name = name;
        this.fileName = fileName;
        this.url = url;
        this.mimeType = mimeType;
        this.date = date;
        this.description = description;
        this.size = size;
        this.content = content;
    }

    get all(): IFileData {
        return {
            id: this.id,
            name: this.name,
            fileName: this.fileName,
            url: this.url,
            mimeType: this.mimeType,
            date: this.date,
            description: this.description,
            size: this.size,
            b64Data: this.content || '',
            hasPreview:
                FileMimeType.isImage(this.mimeType) ||
                ((FileMimeType.isPdf(this.mimeType) || FileMimeType.isPlainText(this.mimeType)) &&
                    !Capacitor.isNativePlatform()),
        };
    }

    toJSON(): IFileData {
        return this.all;
    }
}
