/**
 * URL of pages - used while building navication links
 *
 */

import { PAGE_ID } from './page_id';

export const PAGE_URL = Object.keys(PAGE_ID).reduce(
    (a, c) => ({ ...a, [c]: PAGE_ID[c as keyof typeof PAGE_ID].toLocaleLowerCase() }),
    {},
) as typeof PAGE_ID;
