// slide panel actions context, mapped with context in PAGE_CONTEXT_ACTIONS config, used in translation
export const PAGE_CONTEXT_ACTION = {
    FILTER: 'filter',
    ADD_DATA: 'add-data',
    EXTEND_CONTRACT: 'extend-contract',
    ORDER_EXTRA_SERVICE: 'order-extra-service',
    ORDER_MAINTENANCE: 'order-maintenance',
    CHANGE_CODE: 'change-code',
    ADD_METER_READING: 'add-meter-reading',
    CHANGE_USER_PREFERENCES: 'changeUserPreferences',
    SELECT_SERVICE_TYPE: 'selectServiceType',
    MAKE_SERVICE_REQUEST: 'makeServiceRequest',
    FILTER_SERVICES: 'filterServices',
    SHOW_HIDDEN_MENU: 'showHiddenMenu',
    EXTRA_SERVICES: 'extraServices',
} as const;
export default PAGE_CONTEXT_ACTION;
