import { LinearProgress } from '@material-ui/core';
import { LogoLogin } from '../Logo';

import './styles/Intro.css';
import { useResources } from 'resources/context';
import { useSelector } from 'react-redux';
import { isMobileWidthSelector } from '../../redux/reducers/coreReducer';

export const Intro: IntroType = ({ isLoading = false, classes = '' }: IntroProps) => {
    const { LogoLoginPage } = useResources();
    const isMobileWidth = useSelector(isMobileWidthSelector);

    const SCREEN_CLASSNAME = isMobileWidth ? 'mobile' : 'desktop';

    return (
        <div
            className={
                `company-intro company-intro__container company-intro__container--${SCREEN_CLASSNAME} ` + classes
            }
        >
            {!isLoading && (
                <LogoLoginPage className={'company-intro__logo-bg  company-intro__logo-bg--' + SCREEN_CLASSNAME} />
            )}
            <div className={`company-intro__wrapper company-intro__wrapper--${SCREEN_CLASSNAME} `}>
                {isLoading && (
                    <div className="company-intro__logo-loading-wrapper">
                        <LogoLogin classes="company-intro__logo company-intro__logo--white" width="200px" />
                        <LinearProgress className="company-intro__loading" />
                    </div>
                )}
            </div>
        </div>
    );
};
export default Intro;

type IntroType = (props: IntroProps) => JSX.Element;
export type IntroProps = {
    isLoading?: boolean;
    classes?: string;
};
