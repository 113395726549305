import { TextField } from '@material-ui/core';

const SettingsListItemField = (props: { value?: string; label: string }): JSX.Element | null => {
    return props.value ? (
        <div className="settings-list__item">
            <TextField
                disabled={true}
                className={'settings-list__user-data'}
                variant={'outlined'}
                value={props.value}
                label={props.label}
            />
        </div>
    ) : null;
};

export default SettingsListItemField;
