import React from 'react';

const CarbonFootprint: React.FC = () => {
    return (
        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.82041 13.2108C1.33817 12.1266 1.13967 10.9375 1.24358 9.75546C1.3475 8.57341 1.7504 7.43722 2.41441 6.45378C3.85641 4.31678 8.58041 2.43278 16.5844 0.800781C16.3164 7.65178 15.4624 12.1458 14.0214 14.2828C13.0121 15.7791 11.4638 16.8277 9.69974 17.2097C7.93572 17.5916 6.09233 17.2775 4.55441 16.3328C5.11518 15.3455 5.89103 14.4972 6.82441 13.8508L7.33141 13.4988C7.76742 13.1967 8.06558 12.7338 8.16028 12.2119C8.25499 11.69 8.13849 11.1518 7.83641 10.7158C7.53432 10.2798 7.07141 9.98161 6.5495 9.8869C6.02758 9.7922 5.48942 9.9087 5.05341 10.2108L4.54641 10.5628C3.49796 11.2887 2.57685 12.1831 1.82041 13.2098V13.2108ZM2.56641 20.0708C2.56641 20.336 2.46105 20.5904 2.27351 20.7779C2.08598 20.9654 1.83162 21.0708 1.56641 21.0708C1.30119 21.0708 1.04684 20.9654 0.859299 20.7779C0.671763 20.5904 0.566406 20.336 0.566406 20.0708C0.566406 16.6048 2.26641 13.3588 5.11641 11.3848L5.62341 11.0328C5.84141 10.8817 6.11049 10.8235 6.37145 10.8708C6.63241 10.9182 6.86387 11.0673 7.01491 11.2853C7.16595 11.5033 7.2242 11.7724 7.17685 12.0333C7.12949 12.2943 6.98041 12.5257 6.76241 12.6768L6.25541 13.0288C5.11725 13.8171 4.18711 14.8696 3.54464 16.096C2.90216 17.3224 2.56651 18.6863 2.56641 20.0708Z"
                fill="#5B6073"
            />
        </svg>
    );
};

export default CarbonFootprint;
