import { UserData } from 'tenantUser/types/user';
import SettingsListItemField from './SettingsListItemField';
import { useTranslation } from 'react-i18next';
import { ITenant } from 'agreement/types/ITenant';

const SettingsShortVersion = (props: { user: UserData }) => {
    const {
        user: { firstName, lastName },
    } = props;
    const { t } = useTranslation();

    const fullName = `${firstName} ${lastName}`;
    const fullNameLabel = t('slidePanel.fullName');

    return (
        <>
            <SettingsListItemField value={props.user.email} label={'Email'} />
            <SettingsListItemField value={fullName} label={fullNameLabel} />
        </>
    );
};

const SettingsListTTU = (props: { user: UserData; tenant: ITenant | null }): JSX.Element => {
    const { tenant } = props;
    const { t } = useTranslation();

    if (!tenant) {
        return <SettingsShortVersion user={props.user} />;
    }

    const { phone, ownedBankAccountNumber, iban } = tenant;

    const phoneLabel = t('slidePanel.phoneNumber');
    const accountLabel = t('slidePanel.accountNumber');
    const ibanLabel = t('slidePanel.iban');

    return (
        <>
            <SettingsShortVersion user={props.user} />
            <SettingsListItemField value={phone} label={phoneLabel} />
            <SettingsListItemField value={ownedBankAccountNumber} label={accountLabel} />
            <SettingsListItemField value={iban} label={ibanLabel} />
        </>
    );
};

export default SettingsListTTU;
