import { LARVA_WS } from 'core/datasets/action';
import LarvaWsClientWrapper from 'smartHome/larva/classes/LarvaWsClientWrapper';
import LarvaDeviceController from 'smartHome/larva/classes/LarvaDeviceController';
import LarvaQueuedRequest from 'smartHome/larva/classes/LarvaQueuedRequest';
import {
    AddRequestToConnectionWaitQueueAction,
    ConnectionsUpdatedSignal,
    HandleQueuePendingRequestAction,
    HandleWsErrorAction,
} from 'smartHome/larva/types/redux/larvaWsActions';

export const larvaWSActions = {
    handleQueuedPendingRequests(unhandledRequests: LarvaQueuedRequest[]): HandleQueuePendingRequestAction {
        return {
            type: LARVA_WS.HANDLE_QUEDED_PENDING_REQUESTS,
            payload: unhandledRequests,
        };
    },
    updatedConnectionsSignal(): ConnectionsUpdatedSignal {
        return {
            type: LARVA_WS.WS_CONNECTIONS_UPDATED,
        };
    },
    addRequestToConnectionWaitQueue(
        request: LarvaQueuedRequest,
        connectionId: string,
    ): AddRequestToConnectionWaitQueueAction {
        return {
            type: LARVA_WS.ADD_REQUEST_TO_CONNECTION_WAIT_QUEUE,
            payload: {
                request,
                connectionId,
            },
        };
    },
    addRequestToQueue(queue: LarvaQueuedRequest) {
        return {
            type: LARVA_WS.ADD_REQUEST_TO_QUEUE,
            payload: queue,
        };
    },
    tryUpdateConnections() {
        return {
            type: LARVA_WS.TRY_UPDATE_WS_CONNECTIONS,
        };
    },
    saveConnection(device: LarvaDeviceController, client: LarvaWsClientWrapper) {
        return {
            type: LARVA_WS.SAVE_WS_CONNECTION,
            payload: {
                device,
                client,
            },
        };
    },
    clientSaved(connectionId?: string) {
        return {
            type: LARVA_WS.CLIENT_SAVED,
            payload: connectionId,
        };
    },
    connectionOpened(id: string) {
        return {
            type: LARVA_WS.CONNECTION_OPENED,
            payload: id,
        };
    },
    handleNodeOutput(request: LarvaQueuedRequest) {
        return {
            type: LARVA_WS.NODE_OUTPUT,
            payload: request,
        };
    },
    handleNodeRequest(request: LarvaQueuedRequest) {
        return {
            type: LARVA_WS.NODE_REQUEST,
            payload: request,
        };
    },
    handleWsErrors(message: string): HandleWsErrorAction {
        return {
            type: LARVA_WS.REQUEST_ERROR,
            payload: message,
        };
    },
};
