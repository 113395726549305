import { AlertLevel, AlertNotice } from 'core/components/modalsPortalsNotices/AlertNotice';
import { coreActions } from 'core/redux/actions/coreActions';
import { AppState } from 'core/redux/rootReducer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const AlertNotifications = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const notification = useSelector(({ core }: AppState) => core.notification);

    if (!notification) {
        return <></>;
    }

    const onClose = () => dispatch(coreActions.notification.clear());

    return (
        <AlertNotice
            autoHide={3000}
            open={!!notification}
            level={notification.level as AlertLevel}
            text={t(notification.message)}
            onClose={onClose}
        />
    );
};

export default AlertNotifications;
