import { Link } from 'react-router-dom';
import MenuButton from '../../buttons/MenuButton';
import { LogoSmall } from '../../Logo';
import useWindowSize, { VIEWPORT_BREAKPOINTS } from '../../../hooks/useWindowSize';
import UserPreferencesButton from '../../UserPreferencesButton';

const SidebarHeaderMenu = (): JSX.Element => {
    const { isWidthLessThan } = useWindowSize();
    const isClosed = !!isWidthLessThan(VIEWPORT_BREAKPOINTS.w1125);
    return (
        <div className={'sidebar-header-menu desktop-sidebar__header--' + (isClosed ? 'closed' : 'opened')}>
            <Link className={'sidebar-header-menu__logo sidebar-header-menu__item'} to={'/'}>
                <MenuButton classes={'desktop-sidebar__header-logo-button'}>
                    <LogoSmall classes={'desktop-sidebar__header-logo'} />
                </MenuButton>
            </Link>
            <UserPreferencesButton classes="sidebar-header-menu__user-preferences sidebar-header-menu__item" />
        </div>
    );
};
export default SidebarHeaderMenu;
