export function SelectedFiles({ className, files }: { className: string; files: File[] }): JSX.Element | null {
    if (!files || !files.length) {
        return null;
    }

    return (
        <div className={className}>
            {Object.values(files).map((file, index) => (
                <div key={index}>{file.name}</div>
            ))}
        </div>
    );
}
