import { AUTH, LARVA_WS } from 'core/datasets/action';
import { Middleware } from 'redux';
import LarvaQueuedRequest from 'smartHome/larva/classes/LarvaQueuedRequest';
import { larvaWSActions } from 'smartHome/larva/redux/actions/larvaWSActions';

export const larvaWsRequestsMiddleware: Middleware = (store) => {
    const { dispatch } = store;

    let unhandledRequests: LarvaQueuedRequest[] = [];
    const waitingConnectionRequests = new Map<string /* connectionId */, LarvaQueuedRequest[]>();

    return (next) => (action) => {
        next(action);

        switch (action.type) {
            case AUTH.LOGOUT: {
                unhandledRequests = [];
                waitingConnectionRequests.clear();
                break;
            }

            case LARVA_WS.ADD_REQUEST_TO_QUEUE: {
                const request = action.payload as LarvaQueuedRequest;

                unhandledRequests.push(request);
                break;
            }

            case LARVA_WS.CLEAR_QUEUE: {
                unhandledRequests = [];
                waitingConnectionRequests.clear();
                break;
            }

            case LARVA_WS.ADD_REQUEST_TO_CONNECTION_WAIT_QUEUE: {
                const request = action.payload.request as LarvaQueuedRequest;
                const connectionId = action.payload.connectionId as string;

                const requests = waitingConnectionRequests.has(connectionId)
                    ? [...(waitingConnectionRequests.get(connectionId) as LarvaQueuedRequest[]), request]
                    : [request];

                waitingConnectionRequests.set(connectionId, requests);
                break;
            }

            case LARVA_WS.WS_CONNECTIONS_UPDATED: {
                dispatch(larvaWSActions.handleQueuedPendingRequests(unhandledRequests));
                unhandledRequests = [];
                break;
            }

            case LARVA_WS.CLIENT_SAVED: {
                const connectionId = action.payload;

                if (waitingConnectionRequests.size && waitingConnectionRequests.has(connectionId)) {
                    const requests = waitingConnectionRequests.get(connectionId);
                    dispatch(larvaWSActions.handleQueuedPendingRequests(requests as LarvaQueuedRequest[]));
                    waitingConnectionRequests.delete(connectionId);
                }

                break;
            }
        }
    };
};
