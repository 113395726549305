import { Box, CircularProgress } from '@material-ui/core';
import './styles/LoadingIndicator.css';

export const LoadingIndicator: LoadingIndicator = () => {
    const size = 25;
    return <CircularProgress className="loading-indicator" size={size} thickness={3} />;
};
type LoadingIndicator = () => JSX.Element;

export const FullSpaceLoader = ({ height }: { height?: string | number }): JSX.Element => {
    return (
        <Box
            style={{
                height,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
            }}
        >
            <LoadingIndicator />
        </Box>
    );
};
