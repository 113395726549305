import { IconButton } from '@material-ui/core';
import LabeledText from 'core/components/documentItems/LabeledText';

const exclamationIconClasses = 'bta-icon-exclamation list-item__icon--danger';

export default function DeleteNotificaiton(props: { text: string }): React.ReactElement {
    return (
        <div
            className={'list-item'}
            style={{
                backgroundColor: '#de494929',
                borderRadius: 20,
            }}
        >
            <div className="list-item__background" />
            <div
                className="list-item__icon-text"
                style={{
                    padding: 16,
                }}
            >
                <IconButton color="primary" className={'list-item__icon ' + exclamationIconClasses} />
                <LabeledText className={''} labelText={''} primaryText={props.text} />
            </div>
        </div>
    );
}
