import { ResetContextAction, SetContextAction } from '../../types/redux/slideInContextActions';
import { SLIDE_IN_CONTEXT_ACTION } from '../../datasets/action';
import { SlideInContextState } from '../../types/redux/slideInContextState';

export const filterPageContext = (context: SlideInContextState): SetContextAction => {
    return {
        type: SLIDE_IN_CONTEXT_ACTION.FILTER,
        payload: context,
    };
};

export const resetSlideInContext = (): ResetContextAction => {
    return {
        type: SLIDE_IN_CONTEXT_ACTION.RESET,
    };
};
