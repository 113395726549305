import { FC, lazy, LazyExoticComponent } from 'react';
export const joinClasses = (...classes: string[]): string => classes.join(' ');
export const joinClassesWithDash = (...classes: string[]): string => classes.join('-');
export const joinClassesWithUnderscores = (...classes: string[]): string => classes.join('__');
export const underscoreBaseWithCustomClass = (baseClass: string, customClass: string): string =>
    [baseClass, customClass].join('__');
export const joinClassesWithDashes = (...classes: string[]): string => classes.join('--');
export const underscoreBaseWithEachCustomClass = (baseClass: string, classes: string[]): string =>
    joinClasses(...classes.map((item) => underscoreBaseWithCustomClass(baseClass, item)));
export const dashBaseWithEachCustomClass = (baseClass: string, classes: string[]): string =>
    joinClasses(...classes.map((item) => (item.trim().length > 0 ? joinClassesWithDash(baseClass, item) : '')));

export const makeComponentClasses = (
    componentClasses: ComponentStructureNames,
    customClasses: string,
    defaultBaseClass = '',
): ComponentStructureNames => {
    const newClasses: ComponentStructureNames = { base: '' };
    const { base } = componentClasses;
    const customClassesArray = customClasses?.split(' ');

    for (const name in componentClasses) {
        if (name === 'base') continue;
        newClasses[name] = underscoreBaseWithCustomClass(base, componentClasses[name]);

        if (!!defaultBaseClass) {
            newClasses[name] =
                newClasses[name] + ' ' + underscoreBaseWithCustomClass(defaultBaseClass, componentClasses[name]);
        }
    }

    newClasses['base'] = joinClasses(
        base,
        customClasses,
        dashBaseWithEachCustomClass(base, customClassesArray),
        defaultBaseClass,
    );
    return { ...newClasses };
};

export const makeBemClasses = makeComponentClasses;

export const makeComponentIds = (
    componentObjects: ComponentStructureNames,
    idBase: string,
): ComponentStructureNames => {
    const base = !!idBase && idBase.length > 0 ? idBase : componentObjects.base;

    const newIds: ComponentStructureNames = {
        base: base,
    };

    for (const name in componentObjects) {
        if (name === 'base') continue;
        newIds[name] = underscoreBaseWithCustomClass(base, componentObjects[name]);
    }
    return { ...newIds };
};

interface lazyLoadProps {
    componentName: string;
    fallBackComponentName: string;
    parentFolder: string;
}

export const lazyLoad = ({
    componentName,
    fallBackComponentName,
    parentFolder,
}: lazyLoadProps): LazyExoticComponent<FC> => {
    const pathWithoutBackSlash =
        parentFolder.charAt(parentFolder.length - 1) === '/'
            ? parentFolder.slice(0, parentFolder.length - 1)
            : parentFolder;

    // dynamic import has to be static with limited dynamic values, cannot use fully dynamic paths
    // https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import
    return lazy(() =>
        import(`../../../${pathWithoutBackSlash}/${componentName}`).catch((error: unknown) => {
            console.error(`Failed to import component, try to import fallback`, { error });
            return import(`../../../${pathWithoutBackSlash}/${fallBackComponentName}`);
        }),
    );
};
interface ComponentStructureNames {
    base: string;
    [name: string]: string;
}
