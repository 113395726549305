import PAGE_CONTEXT from './page_context';

const FILTER = {
    INVOICES: PAGE_CONTEXT.INVOICES,
    SERVICE_REQUESTS: PAGE_CONTEXT.SERVICE_REQUESTS,
} as const;
export default FILTER;

export const FILTER_GROUP = {
    STATUS: 'status',
    TYPE: 'type',
    TYPE_ID: 'typeId',
} as const;
