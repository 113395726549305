import { AuthState } from 'core/types/redux/AuthState';
import { AuthActions } from 'core/types/redux/authActions';
import { AUTH } from 'core/datasets/action';

export const initState: AuthState = {
    user: '',
    token: '',
    refresh_token: '',
    isLoggedIn: false,
};

export const authReducer = (state = initState, action: AuthActions): AuthState => {
    switch (action.type) {
        case AUTH.TOKEN_CHANGED: {
            return {
                ...state,
                token: action.payload,
            };
        }
        case AUTH.REFRESH_TOKEN_CHANGED: {
            return {
                ...state,
                refresh_token: action.payload,
            };
        }
        case AUTH.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                refresh_token: action.payload.refresh_token,
                isLoggedIn: true,
            };
        case AUTH.LOGOUT:
            return {
                ...state,
                user: '',
                token: '',
                refresh_token: '',
                isLoggedIn: false,
            };
        case AUTH.EXPIRED_TOKEN_REFRESHED: {
            return {
                ...state,
                token: action.payload,
            };
        }
        case AUTH.TOKENS_CHANGED: {
            return {
                ...state,
                token: action.payload.token,
                refresh_token: action.payload.refresh_token,
            };
        }
        default:
            return state;
    }
};
