import formatValueToFixedDecimalsWithSymbol from './formatValueToFixedDecimalsWithSymbol';

import { CurrencyDataSet } from './CurrencySymbolsDataSet';
export const CURRENCY = '€';
export const DECIMALS = 2;

export const PERCENT = ' %';
const moneyHelper: MoneyHelper = () => {
    const currencyHelper = new CurrencyDataSet(CURRENCY);

    const formatMoneyValue: HelperFunc = (value, currency) => {
        const symbol = currencyHelper.getSymbol(currency as string);
        return formatValueToFixedDecimalsWithSymbol(value, symbol, DECIMALS, 'money');
    };

    const formatFeeValue: HelperFunc = (value) => {
        return formatValueToFixedDecimalsWithSymbol(value, '%', 2, 'fee');
    };

    return { formatMoneyValue, formatFeeValue };
};

export default moneyHelper;
export const { formatMoneyValue, formatFeeValue } = moneyHelper();
export { formatValueToFixedDecimalsWithSymbol };

type MoneyHelper = () => FormatMoneyValueReturn;
type FormatMoneyValueReturn = {
    /**
     * Format money value:
     *  - set number of decimals (2 by default)
     *  - add currency
     * @param value
     */
    formatMoneyValue: HelperFunc;
    /**
     * Add % to value
     * @param value
     */
    formatFeeValue: HelperFunc;
};

type HelperFunc = (value: number | string, currency?: string) => string;
