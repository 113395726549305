import { AppState } from 'core/redux/rootReducer';
import IListViewData from 'core/types/IListViewData';
import FileDto from 'file/classes/FileDto';
import FileListViewItem from 'file/classes/FileListViewItem';
import { filesActions } from 'file/redux/fileActions';
import { HandleFetchedFilesAction } from 'file/types/FilesActions';
import IFileData from 'file/types/IFileData';
import { put, select } from 'redux-saga/effects';

export function* handleReceivedFiles({ payload }: HandleFetchedFilesAction): Generator {
    const { getFiles: files } = payload.data;
    const selectedFileId = (yield select(({ tempFiles }: AppState) => tempFiles.selected?.id)) as number | null;

    const list: IListViewData[] = [];
    const map: { [id: number]: IFileData } = {};
    let selectedFile: null | IFileData = null;

    for (const file of files) {
        const fileData = new FileDto(file).all;

        if (file.id in map) continue;

        list.push(new FileListViewItem(file).listViewData);
        map[file.id] = fileData;

        if (!selectedFileId && !selectedFile) selectedFile = fileData;
    }

    if (selectedFileId && selectedFileId in map) {
        selectedFile = map[selectedFileId];
    }

    yield put(filesActions.updateFilesData({ list, map }));
    yield put(filesActions.changeLoadingState(false));

    if (selectedFile) {
        yield put(filesActions.selectFile(selectedFile));
    }
}
