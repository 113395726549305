import CountryCode from 'core/classes/CountryCode';
import CountryDialCode from 'core/classes/CountryDialCode';

interface TelephoneNumberProps {
    number: number;
    countryCode?: CountryCode;
}

class TelephoneNumber {
    private _dialCode = new CountryDialCode(CountryDialCode.DEFAULT_DIAL_CODE);
    private _number?: number;
    private _countryCode?: CountryCode;

    constructor({ number, countryCode }: TelephoneNumberProps) {
        this.number = number;
        this.countryCode = countryCode;
    }

    static isValidNumber(value: unknown): boolean {
        return typeof value === 'number' && `${value}`.length >= 3;
    }

    get dialCode(): CountryDialCode {
        return this._dialCode;
    }

    private set dialCode(value: unknown) {
        if (!value) {
            return;
        }

        if (value instanceof CountryDialCode) {
            this._dialCode = value;
            return;
        }

        throw new TypeError('Invalid dial code');
    }

    get number(): number {
        if (!this._number) {
            throw new ReferenceError('Undefined telephone number');
        }

        return this._number;
    }

    set number(value: unknown) {
        if (TelephoneNumber.isValidNumber(value)) {
            this._number = value as number;
            return;
        }

        throw new TypeError('Invalid telephone number');
    }

    get fullNumber(): string {
        return `${this.dialCode.value}${this.number}`;
    }

    get countryCode(): CountryCode {
        if (this._countryCode) {
            return this._countryCode;
        }
        throw new ReferenceError('Undefined country code');
    }

    set countryCode(value: unknown) {
        if (typeof value === 'undefined') {
            this._countryCode = new CountryCode(CountryCode.DEFAULT_CODE);

            return;
        }

        if (value instanceof CountryCode) {
            this._countryCode = value;
            const dialCode = CountryDialCode.byCountryCode(value.value);
            this.dialCode = new CountryDialCode(dialCode);

            return;
        }

        throw new TypeError('Invalid country code');
    }
}
export default TelephoneNumber;
