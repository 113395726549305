import Agreement from 'agreement/classes/Agreement';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { coreActions } from 'core/redux/actions/coreActions';
import { DateTime } from 'luxon';
import useQueryServiceRequest from '../../api/gql/useQueryServiceRequest';
import { RentalObjectSelectData } from 'rentalObject/types/rentalObjects';
import { QueryStatusData } from 'core/hooks/useQueryHelper';
import { RentalObject, ServiceData, ServiceRequest } from '../../types/serviceRequest';
import { RowData } from 'core/components/documentItems/DocumentDetails';
import { APP_LUX_DATE_TIME_SHORT_FORMAT, DB_LUX_DATE_TIME_FORMAT } from 'core/utils/datesHelper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
    addServiceRequestFiles,
    changeServiceRequestFieldValue,
    resetServiceRequestFormValues,
    selectRentalObjectValue,
} from '../../redux/serviceRequestActions';
import useMutationMakeServiceRequest from '../../api/gql/useMutationMakeServiceRequest';
import { ApolloError } from 'apollo-boost';
import { AxiosError } from 'axios';
import { FiltersState } from 'core/types/redux/filtersState';

const useServiceRequestController = (): ServiceRequestControllerReturnType => {
    const [selectedFiles, setFiles] = useState<File[] | null>(null);

    const {
        t,
        i18n: { language },
    } = useTranslation();

    const selectedObjectID = useSelector(({ agreementObjects }: AppState) => agreementObjects.selectedObjectID);
    const objects = useSelector(({ agreementObjects }: AppState) => agreementObjects.objects);
    const filters = useSelector(({ filters }: AppState) => filters);
    const agreements = useSelector(({ agreements }: AppState) => agreements.map);

    const [selectedRentalObjectID, setSelectedRentalObjectID] = useState(selectedObjectID);

    const {
        serviceRequests: serviceRequestsData,
        loading,
        error,
        getServiceRequests,
        lazyServiceRequests,
    } = useQueryServiceRequest();

    const serviceRequests = useMemo(() => {
        return serviceRequestsData
            ? serviceRequestsData.map((item) => ({ ...item, meta: { id: item.id } }))
            : serviceRequestsData;
    }, [serviceRequestsData]);

    const dispatch = useDispatch();

    const handleSelectChange = (event: ChangeEvent<{ value: unknown; name?: unknown }>) => {
        const value = event.target?.value as string;
        setSelectedRentalObjectID(() => (!!value ? +value : null));
        dispatch(selectRentalObjectValue(event));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeServiceRequestFieldValue(event));
    };

    const handleOnFileSelect = (files: File[]) => {
        setFiles(files);
        dispatch(addServiceRequestFiles(files));
    };

    const requestForm = useSelector(({ serviceRequests }: AppState) => serviceRequests.requestForm);
    const {
        createServiceRequest,
        data,
        error: serviceRequestError,
        loading: serviceRequestLoading,
        called,
        createRequest,
        uploading,
    } = useMutationMakeServiceRequest();

    const isValidForm = (requestForm: RequestForm) => {
        return Object.values(requestForm).every((fieldValue) => !!fieldValue);
    };

    const handleSubmitForm = () => {
        if (!isValidForm(requestForm)) {
            dispatch(
                coreActions.notification.set(
                    new NotificationAlert({
                        message: t('notices.allFieldsRequired'),
                        level: NotificationAlert.WARNING,
                    }),
                ),
            );
            return false;
        }

        const uuid =
            Object.values(agreements).find((agreement) =>
                Agreement.hasObject(agreement, +requestForm.rentalObjectSelect),
            )?.uuid || '';

        const serviceData: ServiceData = {
            rentalAgreementUuid: uuid,
            rentalObjectId: Number(requestForm.rentalObjectSelect),
            serviceTypeId: Number(requestForm.serviceType),
            title: requestForm.requestTitle,
            description: requestForm.requestDescription,
            files: null,
        };

        if (!!selectedFiles) {
            createRequest({ files: selectedFiles, serviceData });
        } else {
            createServiceRequest(serviceData);
        }
    };

    useEffect(() => {
        if (error || loading || !data) {
            return;
        }

        if (data.createServiceRequestV2) {
            dispatch(
                coreActions.notification.set(
                    new NotificationAlert({
                        message: t('notices.isDone'),
                        level: NotificationAlert.SUCCESS,
                    }),
                ),
            );
            dispatch(resetServiceRequestFormValues());
        }
    }, [data, loading, error, dispatch, t]);

    const serviceRequestById = (id: string) => {
        if (!serviceRequests) return null;

        const service = serviceRequests.filter((item: ServiceRequest) => +id === +item.id);
        return service.length > 0 ? service[0] : null;
    };

    const serviceDetailsFor = (service: ServiceRequest) => {
        if (!service) return [] as RowData[];

        const customTitlesMap = service.serviceRequestType.titles.reduce<Record<string, string>>(
            (acc, { text, langCode }) => {
                acc[langCode] = text;
                return acc;
            },
            {},
        );

        const serviceTranslationKey = 'pages.service.';
        const fields = ['rentalObject', 'submitDate', 'status', 'type', 'description'];
        if ('files' in service && service.files.length > 0) fields.push('files');

        return fields.map((name) => {
            if (!(name in service && service[name as keyof ServiceRequest]))
                return {
                    labelText: t(serviceTranslationKey + name),
                    primaryText: '',
                };
            let serviceFieldValue = '' + service[name as keyof ServiceRequest];

            switch (name) {
                case 'rentalObject': {
                    const rentalObject: RentalObject = service[name as keyof ServiceRequest] as RentalObject;
                    serviceFieldValue = `${rentalObject.street} ${rentalObject.houseNumber}-${
                        rentalObject.roomNumber ?? rentalObject.apartmentNumber
                    }, ${rentalObject.city}`;
                    break;
                }
                case 'type':
                    if (service.serviceRequestType.isDefault) {
                        serviceFieldValue = !!serviceFieldValue.trim()
                            ? t(`maintenanceServices.types.${serviceFieldValue}`)
                            : '';
                    } else {
                        serviceFieldValue = customTitlesMap?.[language] || customTitlesMap?.['en'];
                    }
                    break;
                case 'status':
                    serviceFieldValue = !!serviceFieldValue.trim()
                        ? t(`maintenanceServices.statuses.${serviceFieldValue}`)
                        : '';
                    break;
                case 'submitDate':
                    serviceFieldValue = !!serviceFieldValue.trim()
                        ? `date:${DateTime.fromFormat(serviceFieldValue, DB_LUX_DATE_TIME_FORMAT).toFormat(
                              APP_LUX_DATE_TIME_SHORT_FORMAT,
                          )}`
                        : '';
                    break;
                case 'files': {
                    serviceFieldValue = '';
                    break;
                }
            }
            return {
                labelText: t(serviceTranslationKey + name),
                primaryText: serviceFieldValue,
            };
        });
    };

    return {
        handleSelectChange,
        handleInputChange,
        handleSubmitForm,
        serviceRequestById,
        serviceRequests,
        loading,
        error: serviceRequestError || error,
        //@ts-ignore
        rentalObjectsData: (objects && Object.values(objects)) || [],
        loadingObjects: false,
        serviceDetailsFor,
        selectedRentalObjectID,
        serviceRequestError,
        serviceRequestLoading,
        data,
        getServiceRequests,
        called,
        lazyServiceRequests,
        files: requestForm.files || [],
        selectedFiles,
        handleOnFileSelect,
        uploading,
        filters,
    };
};
export default useServiceRequestController;

type ServiceRequestControllerReturnType = QueryStatusData & {
    handleSelectChange: (event: ChangeEvent<{ value: unknown; name?: unknown }>) => void;
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSubmitForm: () => void;
    serviceRequests: ServiceRequest[];
    rentalObjectsData: RentalObjectSelectData[];
    loadingObjects: boolean;
    serviceRequestById: (id: string) => ServiceRequest | null;
    serviceDetailsFor: (service: ServiceRequest) => RowData[];
    selectedRentalObjectID: number | null;
    serviceRequestLoading: boolean;
    serviceRequestError?: ApolloError | AxiosError;
    data?: {
        makeServiceRequest: {
            id: string;
        };
    };
    getServiceRequests: () => void;
    called: boolean;
    lazyServiceRequests: ServiceRequest[];
    files: File[];
    selectedFiles: File[] | null;
    handleOnFileSelect: (event: File[]) => void;
    uploading: number;
    filters: FiltersState;
    requestForm: RequestForm;
};

export type RequestForm = {
    rentalObjectSelect: string;
    serviceType: string;
    requestTitle: string;
    requestDescription: string;
    files: File[];
};
