import { ViewVariantType } from 'core/classes/ViewVariantType';
import IListViewData from 'core/types/IListViewData';
import DoorAccessType from 'smartHome/larva/classes/DoorAccessType';
import IDoorCodeRawData from 'smartHome/larva/types/doorAccess/IDoorCodeRawData';

export default class DoorCodeRawData {
    static PATH = '/door-access';
    private readonly _id: string;
    private readonly _orgId: string;
    private readonly _externalId: string;
    private readonly _firstname: string;
    private readonly _lastname: string;
    private readonly _secondLastname: string;
    private readonly _title: string;
    private readonly _email: string;
    private readonly _phone: string;
    private readonly _pin: 'SET' | 'UNSET';
    private readonly _accessPolicyRole: 'user' | 'master';

    constructor({
        id,
        orgId,
        externalId,
        firstname,
        lastname,
        secondLastname,
        title,
        email,
        phone,
        pin,
        accessPolicyRole,
    }: IDoorCodeRawData) {
        this._id = id;
        this._orgId = orgId;
        this._externalId = externalId;
        this._firstname = firstname;
        this._lastname = lastname;
        this._secondLastname = secondLastname;
        this._title = title;
        this._email = email;
        this._phone = phone;
        this._pin = pin;
        this._accessPolicyRole = accessPolicyRole;
    }

    static fromJSON(value: unknown): DoorCodeRawData {
        return new DoorCodeRawData(value as IDoorCodeRawData);
    }
    get id(): string {
        return this._id;
    }

    get orgId(): string {
        return this._orgId;
    }

    get externalId(): string {
        return this._externalId;
    }

    get firstname(): string {
        return this._firstname;
    }

    get lastname(): string {
        return this._lastname;
    }

    get secondLastname(): string {
        return this._secondLastname;
    }

    get title(): string {
        return this._title;
    }

    get email(): string {
        return this._email;
    }

    get phone(): string {
        return this._phone;
    }

    get pin(): 'SET' | 'UNSET' {
        return this._pin;
    }

    get accessPolicyRole(): 'user' | 'master' {
        return this._accessPolicyRole;
    }

    get all(): IDoorCodeRawData {
        return {
            id: this.id,
            orgId: this.orgId,
            externalId: this.externalId,
            firstname: this.firstname,
            lastname: this.lastname,
            secondLastname: this.secondLastname,
            title: this.title,
            email: this.email,
            phone: this.phone,
            pin: this.pin,
            accessPolicyRole: this.accessPolicyRole,
        };
    }

    get isPinCodeSet(): boolean {
        return /^set$/i.test(this.pin);
    }

    get listViewData(): IListViewData {
        return {
            path: `${DoorCodeRawData.PATH}/${this.id}`,
            labelText: this.accessPolicyRole,
            primaryText: `${this.firstname} ${this.lastname}`,
            leftBlockVariant: this.isPinCodeSet ? ViewVariantType.SUCCESS : ViewVariantType.DISABLED,
            rightBlockVariant: undefined,
            meta: {
                id: this.id,
                type: DoorAccessType.CODE,
            },
        };
    }

    toJSON(): IDoorCodeRawData {
        return this.all;
    }
}
