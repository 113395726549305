import { gql } from '@apollo/client';

export const rentalAgreementExtensionDocument = gql`
    mutation requestRentalAgreementExtension($data: AgreementChangeRequestInput!) {
        requestRentalAgreementExtension(data: $data) {
            isSuccessful
            message
        }
    }
`;

export const rentalAgreementTerminationDocument = gql`
    mutation requestRentalAgreementTermination($data: AgreementChangeRequestInput!) {
        requestRentalAgreementTermination(data: $data) {
            isSuccessful
            message
        }
    }
`;

export const rentalAgreementLicensePlates = gql`
    mutation licensePlate($data: LicensePlatesInput!) {
        licensePlate(data: $data) {
            licensePlates {
                value
            }
        }
    }
`;
