import { MenuItemConfig } from '../types/menu';
import { ICON_ID } from '../datasets/icon_id';
import { PAGE_ID } from '../datasets/page_id';

/**
 * MENU_LIST:
 *    - mobile screen - hidden menu list,
 *    - desktop screen - sidebar menu
 * NAV_BAR:
 *    - mobile screens - menu bar on bottom
 */
export const MENU_LOCATION = {
    NAV_BAR: 'navBar',
    MENU_LIST: 'menuList',
};

export const MENU_ITEM_ID = {
    DASHBOARD: PAGE_ID.DASHBOARD,
    INVOICES: PAGE_ID.INVOICES,
    RENTAL_AGREEMENTS: PAGE_ID.RENTAL_AGREEMENTS,
    MESSAGING: PAGE_ID.MESSAGING,
    SERVICES: PAGE_ID.SERVICES,
    SMART_HOME: PAGE_ID.SMART_HOME,
    EXTRA_SERVICES: PAGE_ID.EXTRA_SERVICES,
    METER_READINGS: PAGE_ID.METER_READINGS,
    DOOR_CODES: PAGE_ID.DOOR_CODES,
    FILES: PAGE_ID.FILES,
    TIPS_AND_TRICKS: PAGE_ID.TIPS_AND_TRICKS,
    SETTINGS: PAGE_ID.SETTINGS,
    CO2METERS: PAGE_ID.CO2METERS,
    MENU_TOGGLE: 'MENU_TOGGLE', // not a page
    LOGOUT: 'LOGOUT', // not a page
};

/**
 * Menu configuration
 * @deprecated use menuReducer and redux flow instead
 */
export const MENU_CONFIG: MenuItemConfig[] = [
    {
        id: MENU_ITEM_ID.DASHBOARD,
        iconName: ICON_ID.DASHBOARD,
        labelText: 'dashboard',
        location: MENU_LOCATION.NAV_BAR,
    },
    {
        id: MENU_ITEM_ID.INVOICES,
        iconName: ICON_ID.INVOICES,
        labelText: 'invoices',
        location: MENU_LOCATION.NAV_BAR,
    },
    {
        id: MENU_ITEM_ID.RENTAL_AGREEMENTS,
        iconName: ICON_ID.RENTAL_AGREEMENTS,
        labelText: 'rentalAgreements',
        location: MENU_LOCATION.NAV_BAR,
    },
    {
        id: MENU_ITEM_ID.MESSAGING,
        iconName: ICON_ID.MESSAGES,
        labelText: 'messages',
        location: MENU_LOCATION.NAV_BAR,
    },
    {
        id: MENU_ITEM_ID.SERVICES,
        iconName: ICON_ID.EXTRA_SERVICES,
        labelText: 'services',
        location: MENU_LOCATION.MENU_LIST,
    },
    {
        id: MENU_ITEM_ID.MENU_TOGGLE,
        iconName: ICON_ID.MENU_TOGGLE,
        labelText: 'menu',
        location: MENU_LOCATION.NAV_BAR,
    },
    {
        id: MENU_ITEM_ID.SMART_HOME,
        iconName: ICON_ID.SMART_HOME,
        labelText: 'smartHome',
        location: MENU_LOCATION.NAV_BAR,
    },
    {
        id: MENU_ITEM_ID.DOOR_CODES,
        iconName: ICON_ID.DOOR_CODE,
        labelText: 'doorCode',
        location: MENU_LOCATION.MENU_LIST,
    },

    {
        id: MENU_ITEM_ID.EXTRA_SERVICES,
        iconName: ICON_ID.EXTRA_SERVICES,
        labelText: 'extraServices',
        location: MENU_LOCATION.MENU_LIST,
    },
    {
        id: MENU_ITEM_ID.METER_READINGS,
        iconName: ICON_ID.METER_READINGS,
        labelText: 'meterReadings',
        location: MENU_LOCATION.NAV_BAR,
    },
    {
        id: MENU_ITEM_ID.FILES,
        iconName: ICON_ID.FILES,
        labelText: 'files',
        location: MENU_LOCATION.MENU_LIST,
    },
    {
        id: MENU_ITEM_ID.TIPS_AND_TRICKS,
        iconName: ICON_ID.TIPS_AND_TRICKS,
        labelText: 'tipsAndTricks',
        location: MENU_LOCATION.MENU_LIST,
    },
    {
        id: MENU_ITEM_ID.LOGOUT,
        iconName: ICON_ID.LOGOUT,
        labelText: 'logOut',
        location: MENU_LOCATION.MENU_LIST,
    },
    {
        id: MENU_ITEM_ID.CO2METERS,
        iconName: ICON_ID.CO2_METERS,
        labelText: 'co2Meters',
        location: MENU_LOCATION.MENU_LIST,
    },
];
