import { AgreementStatus } from 'agreement/AgreementStatus';
import Agreement from 'agreement/classes/Agreement';
import { AgreementSelectedAction } from 'agreement/types/redux/AgreementActions';
import AgreementObject from 'agreementObject/classes/AgreementObject';
import { agreementObjectActions } from 'agreementObject/redux/agreementObjectActions';
import { selectFirstActiveObject } from 'agreementObject/saga/workers/initSelectObject';
import { SelectAgreementObjectByIdAction } from 'agreementObject/types/redux/agreementObjectActions';
import { AppState } from 'core/redux/rootReducer';
import { put, select } from 'redux-saga/effects';

export function* selectAgreementObjectById({ payload: id }: SelectAgreementObjectByIdAction): Generator {
    const agreementObjects = (yield select(({ agreementObjects }: AppState) => agreementObjects.objects)) as {
        [id: number]: AgreementObject;
    };

    if (agreementObjects && Object.keys(agreementObjects).indexOf(`${id}`) !== -1) {
        const newSelectedObject = agreementObjects[id];

        yield put(agreementObjectActions.selectAgreementObject(newSelectedObject));
    }
}

export function* selectAgreementObjectOnSelectedAgreementChange({
    payload: selectedAgreement,
}: AgreementSelectedAction): Generator {
    const selectedObjectId = (yield select(({ agreementObjects }: AppState) => agreementObjects.selectedObjectID)) as
        | number
        | null;

    if (!selectedAgreement) {
        return;
    }

    if (
        !selectedObjectId ||
        !Agreement.hasObject(selectedAgreement, selectedObjectId) ||
        !AgreementStatus.isActive({
            agreement: selectedAgreement,
            objectId: selectedObjectId,
        })
    ) {
        yield selectFirstActiveObject([selectedAgreement]);
    }
}
