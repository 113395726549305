import { ROUTES } from './routes';

export const LAZY_COMPONENTS_MAP_CONFIG = {
    pages: {
        map: ROUTES,
        componentKey: 'page',
        parentFolder: 'pages',
        fallBackComponentName: 'Dashboard',
    },
} as const;
