import INotificationAlertInitData, { NotificationAlert } from 'core/classes/NotificationAlert';
import INotificationAlertInstanceData from 'core/types/INotificationInstanceData';

interface IDashboardNotificationProps extends INotificationAlertInitData {
    path?: string;
}

export interface IDashboardNotificationData extends INotificationAlertInstanceData {
    path: string;
}

class DashboardNotification extends NotificationAlert {
    private _path = '';

    constructor({ message, level, path }: IDashboardNotificationProps) {
        super({ message, level });
        this.path = path || '';
    }

    get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
    }

    get all(): IDashboardNotificationData {
        return {
            message: this.message,
            level: this.level.toLowerCase(),
            path: this.path,
        };
    }

    toJSON(): IDashboardNotificationData {
        return this.all;
    }
}

export default DashboardNotification;
