import { DateTime } from 'luxon';
import { APP_LUX_DATE_FORMAT } from './index';

export const formatDbDateTimeToAppDate = (
    dateTime?: string | null,
    format = APP_LUX_DATE_FORMAT,
    minusMonth = 0,
): string | null => {
    if (!dateTime || typeof dateTime !== 'string' || !dateTime.trim()) return null;

    const dt = DateTime.fromSQL(dateTime);

    if (dt.isValid) return dt.minus({ months: minusMonth }).toFormat(format);
    return null;
};
