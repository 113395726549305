import { AgreementStatus } from 'agreement/AgreementStatus';
import { AppState } from 'core/redux/rootReducer';
import { formatDbDateTimeToAppDate } from 'core/utils/datesHelper';
import RentalObjectContractStatus from 'pages/Settings/components/RentalObjectContractStatus/RentalObjectContractStatus';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const AgreementStatusContainer = (): JSX.Element => {
    const { t } = useTranslation();

    const selectedAgreement = useSelector(({ agreements }: AppState) => agreements.selectedAgreement);
    const selectedObjectID = useSelector(({ agreementObjects }: AppState) => agreementObjects.selectedObjectID);

    const isActiveAgreement = AgreementStatus.isActive({
        agreement: selectedAgreement,
        objectId: selectedObjectID,
    });

    const periodStartDate = AgreementStatus.getPeriodStartDate({
        agreement: selectedAgreement,
        objectId: selectedObjectID,
    });

    const periodEndDate = AgreementStatus.getPeriodEndDate({
        agreement: selectedAgreement,
        objectId: selectedObjectID,
    });

    const isActiveText = isActiveAgreement ? 'agreementPeriod' : 'inactiveContract';

    const statusText = t(`rentalObject.agreementStatus.${isActiveText}`, {
        start: formatDbDateTimeToAppDate(periodStartDate || ''),
        end: periodEndDate ? formatDbDateTimeToAppDate(periodEndDate || '') : '...',
    });

    return (
        <RentalObjectContractStatus
            agreementId={`${selectedAgreement?.id || ''}`}
            isActiveAgreement={isActiveAgreement}
            period={statusText}
        />
    );
};

export default AgreementStatusContainer;
