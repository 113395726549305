import { ViewVariantType } from 'core/classes/ViewVariantType';
import { AppState } from 'core/redux/rootReducer';
import IListViewData from 'core/types/IListViewData';
import { filesActions } from 'file/redux/fileActions';
import { put, select } from 'redux-saga/effects';

export function* handleSelectedListItemAsBrokenAction(): Generator {
    const selectedId = ((yield select(({ tempFiles }: AppState) => tempFiles.selected?.id)) as number) || null;
    const list = (yield select(({ files }: AppState) => files.list)) as IListViewData[];

    if (!selectedId || !list.length) return;

    const newList = list.map((file) => {
        const _file = { ...file };

        if (_file.meta?.id && +_file.meta?.id === selectedId) {
            _file.leftBlockVariant = ViewVariantType.DANGER;
        }

        return _file;
    });

    yield put(filesActions.updateFilesListViewData(newList));
}
