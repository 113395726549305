import { IInvoiceListViewData } from '../types/IInvoiceListViewData';
import { InvoiceListViewItem } from '../classes/InvoiceListViewItem';
import { IInvoicePropsData } from '../types/IInvoicePropsData';
import { invoicePropsDataToInvoiceData } from './invoiceApiMapper';

export function updateSingleInvoice(item: IInvoiceListViewData, data: IInvoicePropsData): IInvoiceListViewData {
    if (item?.meta?.id !== String(data.id)) return item;
    return { ...new InvoiceListViewItem(invoicePropsDataToInvoiceData(data), false).listViewData };
}

export function turnOnLoading(item: IInvoiceListViewData, id: string): IInvoiceListViewData {
    if (item?.meta?.id !== id) return item;

    return { ...item, meta: { ...item.meta, loading: true } };
}

export function turnOffLoading(item: IInvoiceListViewData, id: string): IInvoiceListViewData {
    if (item?.meta?.id !== id) return item;

    return { ...item, meta: { ...item.meta, loading: false } };
}
