export const PAYMENT_TYPE = {
    REPEATING: 'recurring',
    SINGLE: 'one_time',
} as const;

export default PAYMENT_TYPE;

export enum PaymentType {
    Recurring = 'recurring',
    OneTime = 'one_time',
}
