import { Filter as IFilter, FilterValueType, InvoicesFilterValues } from '../../../types/filter';
import { ChangeEvent } from 'react';
import { hasEnabledValueIn } from '../../../utils/useFiltersHelper';
import FilterCheckbox from '../../buttons/FilterCheckbox';
import { ServiceRequestTypeTitlesMap } from 'serviceRequest/types/serviceRequest';

interface FilterValuesCheckboxListProps extends IFilter {
    values: FilterValueType;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    groupName: string;
    serviceRequestTypeTitlesMap?: ServiceRequestTypeTitlesMap;
}

const FilterValuesCheckboxList = ({
    values,
    handleChange,
    resetAllKey,
    name,
    groupName,
    serviceRequestTypeTitlesMap,
}: FilterValuesCheckboxListProps): JSX.Element => {
    const valueNames = Object.keys(values).sort((value) => (value === resetAllKey ? -1 : 1));
    const isEnabled = hasEnabledValueIn(values);

    return (
        <>
            {valueNames.map((valueName) => {
                return (
                    <FilterCheckbox
                        key={valueName}
                        isChecked={values[valueName as keyof InvoicesFilterValues]}
                        onClick={handleChange}
                        isEnabled={isEnabled}
                        valueName={valueName}
                        resetAllKey={resetAllKey}
                        name={name}
                        groupName={groupName}
                        serviceRequestTypeTitlesMap={serviceRequestTypeTitlesMap}
                    />
                );
            })}
        </>
    );
};

export default FilterValuesCheckboxList;
