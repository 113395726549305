import { AxiosResponse } from 'axios';
import { IDashboardNotificationData } from 'core/classes/DashboardNotification';
import { NotificationAlert } from 'core/classes/NotificationAlert';
import { BreakPointRange } from 'core/classes/WindowSize';
import { CORE } from 'core/datasets/action';
import INotificationAlertInstanceData from 'core/types/INotificationInstanceData';
import NetworkStatus from 'core/types/NetworkStatus';
import {
    AppActiveStateAction,
    ChangedFullViewModeAction,
    ChangedWindowWidthRangeAction,
    ChangeOpenStateCommandAction,
    ClearDashboardNotificationAction,
    ClearNotificationAction,
    DeviceErrorAction,
    DeviceErrorListAddAction,
    DeviceErrorListRemoveAction,
    GraphqlErrorResponseHandleAction,
    InitNetworkStatusAction,
    NetworkStatusAction,
    PushDashboardNotificationAction,
    RefreshDataSignal,
    RefreshLayoutSignal,
    ResetErrorStateForComponentAction,
    SetErrorStateForComponentAction,
    SetFinishedLoadingStateForComponentAction,
    SetIsMobileWidthStateAction,
    SetLoadingStateForComponentAction,
    SetNotificationAction,
} from 'core/types/redux/CoreStateActions';
import { stack } from 'core/utils/stack';

export const changeNetworkStatus = (status: NetworkStatus): NetworkStatusAction => {
    return {
        type: CORE.NETWORK_STATUS_CHANGED,
        payload: status,
    };
};

export const setInitialNetworkStatus = (status: NetworkStatus): InitNetworkStatusAction => {
    return {
        type: CORE.INIT_NETWORK_STATUS,
        payload: status,
    };
};

export const changeAppActiveStatus = (isActive: boolean): AppActiveStateAction => {
    return {
        type: CORE.APP_ACTIVE_STATUS_CHANGED,
        payload: isActive,
    };
};

export const coreActions = {
    refreshData(): RefreshDataSignal {
        return {
            type: CORE.REFRESH_DATA_CALLED,
        };
    },
    refreshLayoutData(): RefreshLayoutSignal {
        return {
            type: CORE.LAYOUT_DATA_REFRESHED,
        };
    },
    setLoadingStateForComponent(id: string): SetLoadingStateForComponentAction {
        return {
            type: CORE.COMPONENT_STARTED_LOADING,
            payload: id,
        };
    },
    setIsMobileState(isMobileWidth: boolean): SetIsMobileWidthStateAction {
        return {
            type: CORE.IS_MOBILE_WIDTH_STATE_CHANGED,
            payload: isMobileWidth,
        };
    },
    setFinishedLoadingStateForComponent(id: string): SetFinishedLoadingStateForComponentAction {
        return {
            type: CORE.COMPONENT_FINISHED_LOADING,
            payload: id,
        };
    },
    setErrorStateForComponent(id: string, message: string): SetErrorStateForComponentAction {
        return {
            type: CORE.SET_COMPONENT_ERROR,
            payload: {
                error: message,
                componentId: id,
            },
        };
    },
    resetErrorStateForComponent(id: string): ResetErrorStateForComponentAction {
        return {
            type: CORE.RESET_COMPONENT_ERROR,
            payload: id,
        };
    },
    changeFullViewMode(state: boolean): ChangedFullViewModeAction {
        return {
            type: CORE.FULL_VIEW_MODE_CHANGED,
            payload: state,
        };
    },
    setWindowSizeRange(rangeName: BreakPointRange): ChangedWindowWidthRangeAction {
        return {
            type: CORE.WINDOW_WIDTH_RANGE_CHANGED,
            payload: rangeName,
        };
    },
    slidePanel: {
        changeOpenState(): ChangeOpenStateCommandAction {
            return {
                type: CORE.SLIDE_PANEL_OPEN_STATE_CHANGED,
            };
        },
    },
    dashboardNotifications: {
        push(notification: IDashboardNotificationData): PushDashboardNotificationAction {
            return {
                type: CORE.PUSH_DASHBOARD_NOTIFICATION,
                payload: notification,
            };
        },
        clear(): ClearDashboardNotificationAction {
            return {
                type: CORE.CLEAR_DASHBOARD_NOTIFICATION,
            };
        },
    },
    notification: {
        setSuccess(message: string): SetNotificationAction {
            return {
                type: CORE.SET_NOTIFICATION,
                payload: new NotificationAlert({
                    message: message,
                    level: NotificationAlert.SUCCESS,
                }),
            };
        },
        setWarning(message: string): SetNotificationAction {
            return {
                type: CORE.SET_NOTIFICATION,
                payload: new NotificationAlert({
                    message: message,
                    level: NotificationAlert.WARNING,
                }),
            };
        },
        setError(message: string): SetNotificationAction {
            return {
                type: CORE.SET_NOTIFICATION,
                payload: new NotificationAlert({
                    message: message,
                    level: NotificationAlert.ERROR,
                }),
            };
        },
        set(notification: INotificationAlertInstanceData): SetNotificationAction {
            return {
                type: CORE.SET_NOTIFICATION,
                payload: notification,
            };
        },
        clear(): ClearNotificationAction {
            return {
                type: CORE.CLEAR_NOTIFICATION,
            };
        },
    },
    device: {
        error: {
            notification(message: string | null, data?: unknown): DeviceErrorAction {
                return {
                    type: CORE.DEVICE_GLOBAL_ERROR,
                    payload: {
                        id: null,
                        showNotification: true,
                        errorMessage: message,
                        stackTrace: stack(2),
                        errorData: data,
                    },
                };
            },
            component(id: string, message: string | null, data?: unknown): DeviceErrorAction {
                return {
                    type: CORE.DEVICE_GLOBAL_ERROR,
                    payload: {
                        id,
                        showNotification: false,
                        errorMessage: `${message}`,
                        stackTrace: stack(2),
                        errorData: data,
                    },
                };
            },
            addToList(fullId: string, error: string | null): DeviceErrorListAddAction {
                return {
                    type: CORE.DEVICE_ADD_ERROR,
                    payload: {
                        fullId,
                        error,
                    },
                };
            },
            removeFromList(fullId: string, error?: string): DeviceErrorListRemoveAction {
                return {
                    type: CORE.DEVICE_REMOVE_ERROR,
                    payload: {
                        fullId,
                        error,
                    },
                };
            },
        },
    },
    handleGraphQLErrorResponse(response: AxiosResponse, action: unknown): GraphqlErrorResponseHandleAction {
        return {
            type: CORE.RECEIVED_ERROR_GRAPHQL_RESPONSE,
            payload: {
                response,
                callbackType: action,
            },
        };
    },
};
