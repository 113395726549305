import { AnyAction } from 'redux';
import { put } from 'redux-saga/effects';
import { gql } from '@apollo/client';
import { inceptionActions } from '../redux/inceptionActionCreators';

export const CREATE_INCEPTION_ACTIVITY = gql`
    mutation createInceptionActivity($data: CreateInceptionActivityInput!) {
        createInceptionActivity(data: $data) {
            success
        }
    }
`;

export function* handleCreateInceptionActivity(action: AnyAction): Generator {
    const { state, uuid } = action.payload;

    yield put(inceptionActions.activity({ state, uuid }));
}
