import { ChangeEvent, forwardRef, KeyboardEvent, MouseEvent, Ref, RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton, FormHelperText } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';

import './styles/PasswordField.css';

export const PasswordField = (props: PasswordFieldProps, refProp?: RefObject<unknown> | null): JSX.Element => {
    const { handleChange, classes, handleKeyEnterPressed, readyToSubmit }: PasswordFieldProps = props;

    const { t } = useTranslation();
    const password = useSelector(({ tempAuth }: AppState) => tempAuth.password);
    const [visible, setVisible] = useState(false);
    const invalidPassword = password.changed ? password.invalid : false;
    const errorText = invalidPassword ? t('pages.login.fieldIsRequired') : '';

    const handleClickShowPassword = () => {
        setVisible((prev) => !prev);
    };
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: KeyboardEvent<HTMLInputElement>) => {
        if (readyToSubmit) handleKeyEnterPressed && handleKeyEnterPressed(event);
    };

    return (
        <FormControl className={'password-field ' + classes} variant="outlined" fullWidth={true}>
            <InputLabel error={invalidPassword} required={true} htmlFor="login-form__password">
                {t('pages.login.password')}
            </InputLabel>
            <OutlinedInput
                ref={refProp}
                autoComplete={'current-password'}
                id="login-form__password"
                name="password"
                type={visible ? 'text' : 'password'}
                onChange={handleChange}
                onKeyPress={handleSubmit}
                required={true}
                className="login-form__input login-form__input-password "
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                            {visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={t('pages.login.password').length + 70}
                error={invalidPassword}
            />
            <FormHelperText className={'password-field__helper-text'} error={invalidPassword}>
                {errorText}
            </FormHelperText>
        </FormControl>
    );
};

// @ts-ignore missing current
export default forwardRef(PasswordField);

export type PasswordFieldProps = {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
    readyToSubmit: boolean;
    handleKeyEnterPressed?: (event: KeyboardEvent<HTMLInputElement>) => void;
    classes?: string;
    ref?: Ref<HTMLDivElement>;
};
