import { useState } from 'react';

import { MENU_ITEM_ID } from 'core/config/menu_config';
import PAGE_CONTEXT from '../../../datasets/page_context';
import MenuIconBtnList from '../../lists/MenuIconBtnList';
import AppNavBar from '../AppNavBar';
import useSlidePanel from '../../SlidePanel/hooks/useSlidePanel/useSlidePanel';
import SlidePanel from '../../SlidePanel';

/**
 * Nav menu page
 * @constructor
 */
export const NavMenu = (): JSX.Element => {
    const [selected, setSelected] = useState<string>(MENU_ITEM_ID.DASHBOARD);
    const { handleClose, getSlidePanelArgs, isOpen } = useSlidePanel({
        pageContext: PAGE_CONTEXT.NAV_BAR_HIDDEN_MENU,
    });

    const menuActionHandler = (): void => {
        handleClose();
    };
    const slidePanelArgs = {
        ...getSlidePanelArgs(),
    };

    const navBarActionHandler = (key: string): void => {
        setSelected(() => key);
        if (key === MENU_ITEM_ID.MENU_TOGGLE) handleClose();
        if (key !== MENU_ITEM_ID.MENU_TOGGLE && isOpen) handleClose();
    };

    return (
        <>
            <AppNavBar isOpen={isOpen} actionHandler={navBarActionHandler} activeItem={selected} />
            <SlidePanel {...slidePanelArgs} hasHeader={false}>
                <MenuIconBtnList actionHandler={menuActionHandler} activeItem={selected} />
            </SlidePanel>
        </>
    );
};
export default NavMenu;
