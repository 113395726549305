import MenuReducerState from '../../types/redux/MenuReducerState';
import { MENU_ITEM_ID } from '../../config/menu_config';
import { menuItems, navBarMenuIds, sideBarMenuIds, slideInMenuIds } from '../../datasets/menuElements';
import { MenuActions } from '../../types/redux/MenuActions';
import { MENU } from '../../datasets/action';
import { MENU_ITEM_PROPERTY_NAME } from '../../datasets/menuItemPropertyName';

const initState: MenuReducerState = {
    activeMenuId: MENU_ITEM_ID.DASHBOARD,
    menuItems: { ...menuItems },
    sideBarIds: [...sideBarMenuIds],
    navBarIds: [...navBarMenuIds],
    slideInIds: [...slideInMenuIds],
};

export const menuReducer = (state = initState, action: MenuActions): MenuReducerState => {
    switch (action.type) {
        case MENU.MENU_ITEM_PROPERTY_CHANGED: {
            const { itemId, property, value } = action.payload;

            if (!(itemId in state.menuItems)) {
                console.error(`[MENU.MENU_ITEM_PROPERTY_CHANGED]: Invalid menu id: ${itemId}`);
                return state;
            }

            if (!Object.values(MENU_ITEM_PROPERTY_NAME).includes(property)) {
                console.error(`[MENU.MENU_ITEM_PROPERTY_CHANGED]: Invalid property name: ${property}`);
                return state;
            }

            return {
                ...state,
                menuItems: {
                    ...state.menuItems,
                    [itemId]: {
                        ...state.menuItems[itemId],
                        [property]: value,
                    },
                },
            };
        }
        default: {
            return state;
        }
    }
};
