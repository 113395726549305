import useQueryErrorHandler from 'core/hooks/useQueryErrorHandler';
import { QueryStatusData } from 'core/hooks/useQueryHelper';
import { ApolloError } from '@apollo/react-hooks';
import { useCallback } from 'react';
import { useConfig } from '../../../../../../resources/config';
import { gql, useMutation } from '@apollo/client';

const pushNotificationMutationDocument = gql`
    mutation pushNotificationToken($data: PushNotificationTokenInput!) {
        pushNotificationToken(data: $data) {
            device_type
            token
        }
    }
`;

export const useMutationPushNotificationToken = (): MutationPushNotificationToken => {
    const config = useConfig();
    const { errorHandler } = useQueryErrorHandler();

    const [changeRentalAgreementLicensePlateMutation, { data, error, loading, called }] = useMutation(
        pushNotificationMutationDocument,
    );

    const savePushNotificationToken = useCallback(
        (device_type: string, token: string) => {
            changeRentalAgreementLicensePlateMutation({
                variables: {
                    data: {
                        device_type: device_type,
                        token: token,
                        application_type: config.appType,
                    },
                },
                errorPolicy: 'all',
            }).catch((error: ApolloError) => {
                console.log('ERROR SAVING PUSH NOTIFICATIONS TOKEN', { error });
                errorHandler(error);
            });
        },
        [changeRentalAgreementLicensePlateMutation, config.appType, errorHandler],
    );

    return { savePushNotificationToken, data, error, loading, called };
};

type MutationPushNotificationToken = QueryStatusData & {
    savePushNotificationToken: (device_type: string, token: string) => void;
    data?: {
        pushNotificationToken: {
            device_type: string;
            token: string;
        };
    } | null;
    called: boolean;
};
