import { getRefreshToken } from 'core/utils/localStorage/localStorage';

const fetchRefreshToken = (
    gqlUri: string,
    handleToken?: (token: string) => void,
    handleError?: () => void,
): Promise<void> => {
    const refreshToken = getRefreshToken();
    const operationName = 'RefreshToken';
    const method = 'post';
    const headers = {
        'content-type': 'application/json',
    };
    const query =
        'mutation RefreshToken($refreshToken: String!) {refreshToken(refreshToken: $refreshToken) { token, refresh_token}}';

    const refreshMutation = {
        operationName,
        variables: {
            refreshToken,
        },
        query,
    };

    const options = {
        method,
        headers,
        body: JSON.stringify(refreshMutation),
    };

    return fetch(gqlUri, options)
        .then((response) => {
            return response.json();
        })
        .then((json) => {
            if ('errors' in json) {
                handleError && handleError();
                console.log('%c [AUTH]: Invalid refresh token', 'color: red');
                throw new Error('Invalid refresh Token');
            }

            const token = json.data.refreshToken.token;

            handleToken && handleToken(token);
        })
        .catch((error) => console.error('[Fetch RefreshToken error]', error));
};
export default fetchRefreshToken;
