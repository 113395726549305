import {
    CancelExtraServiceOrderResponseType,
    ChangeExtraServiceOrderResponseType,
    ExtraServiceOrderInput,
} from '../../../types/extraServiceOrder';
import { QueryStatusData } from 'core/hooks/useQueryHelper';
import { useCallback, useState } from 'react';
import useQueryErrorHandler from '../../../../core/hooks/useQueryErrorHandler';
import { gql, useMutation } from '@apollo/client';

const cancelExtraServiceDocument = gql`
    mutation cancelExtraServiceOrder($serviceData: ExtraServiceOrderInput!) {
        cancelExtraServiceOrder(serviceData: $serviceData) {
            isSuccessful
            message
        }
    }
`;

const changeExtraServiceDocument = gql`
    mutation changeExtraServiceOrder($serviceData: ExtraServiceOrderInput!) {
        changeExtraServiceOrder(serviceData: $serviceData) {
            isSuccessful
            message
        }
    }
`;

const useMutationChangeExtraServiceOrder = (): MutationChangeExtraServiceOrderReturnType => {
    const { errorHandler } = useQueryErrorHandler();

    const [cancelRequest, cancelResponse] = useMutation(cancelExtraServiceDocument);
    const [changeRequest, changeResponse] = useMutation(changeExtraServiceDocument);

    const [response, setResponse] = useState<any>(changeResponse || cancelResponse);

    const sendRequestForAction = useCallback(
        (serviceData: ExtraServiceOrderInput, action: string) => {
            const options = {
                variables: {
                    serviceData,
                },
            };
            if (action === 'CHANGE') {
                changeRequest(options)
                    .catch(errorHandler)
                    .then(() => setResponse(() => changeResponse));
            }
            if (action === 'CANCEL') {
                cancelRequest(options)
                    .catch(errorHandler)
                    .then(() => setResponse(() => cancelResponse));
            }
        },
        [cancelRequest, cancelResponse, changeRequest, changeResponse, errorHandler],
    );

    const cancelExtraServiceOrder = useCallback(
        (serviceData: ExtraServiceOrderInput) => sendRequestForAction(serviceData, 'CANCEL'),
        [sendRequestForAction],
    );
    const changeExtraServiceOrder = useCallback(
        (serviceData: ExtraServiceOrderInput) => sendRequestForAction(serviceData, 'CHANGE'),
        [sendRequestForAction],
    );

    return {
        response: response?.data as ChangeExtraServiceOrderResponseType | CancelExtraServiceOrderResponseType,
        error: response?.error,
        loading: response?.loading,
        cancelExtraServiceOrder,
        changeExtraServiceOrder,
    };
};

export default useMutationChangeExtraServiceOrder;

type MutationChangeExtraServiceOrderReturnType = QueryStatusData & {
    response?: ChangeExtraServiceOrderResponseType | CancelExtraServiceOrderResponseType;
    cancelExtraServiceOrder(serviceData: ExtraServiceOrderInput): void;
    changeExtraServiceOrder(serviceData: ExtraServiceOrderInput): void;
};
