class DoorAccessType {
    static CODE = 'code';
    static CARD = 'card';

    static isValid(value: unknown): boolean {
        return typeof value === 'string' ? /^code$|^card$/i.test(value as string) : false;
    }

    static isCode(value: unknown): boolean {
        return DoorAccessType.isValid(value) && (value as string).toLowerCase() === DoorAccessType.CODE;
    }
}
export default DoorAccessType;
