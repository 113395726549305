import { NotificationAlert } from 'core/classes/NotificationAlert';
import { coreActions } from 'core/redux/actions/coreActions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useTenantRegistrationController from 'tenantUser/hooks/useTenantRegistrationController';
import PublicPageContainer from '../../core/components/layout/PublicPageContainer';
import TenantRegistrationForm from './components/TenantRegistrationForm';
import InvitedTenantData from '../../tenantUser/types/InvitedTenantData';
import './Register.css';
import { changeLanguage } from 'tenantUser/redux/userProfileActions';
import { LangValueType } from 'tenantUser/types/redux/userProfileStates';
import { LOCAL_STORAGE } from '../../core/datasets/local_storage';
import { LANGUAGES } from '../../core/datasets/languages';
import { useUpdateLangOnParams } from '../../core/components/RoutesSwitcher/hooks/useUpdateLangOnParams';
import { useLoginLinkWithLang } from '../../core/components/LangSelect/hooks/useLoginLinkWithLang';

const TenantRegistration = (): JSX.Element => {
    const { hash } = useParams<{ hash: string }>();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useUpdateLangOnParams();
    const loginLinkWithLang = useLoginLinkWithLang();

    const {
        isValidHash,
        tenant,
        loading,
        error: hashValidationError,
        handleRegistration,
        registrationError,
        registrationLoading,
    } = useTenantRegistrationController({ hash: hash || '' });

    const noHash = !(hash && hash.trim());
    const someHashError = !loading && (!isValidHash || !!hashValidationError || !tenant);
    const someRegistrationError = !registrationLoading && registrationError;
    const isLocalStorageProfileSet = localStorage.getItem(LOCAL_STORAGE.USER_PROFILE) !== null;

    if (noHash) {
        navigate(loginLinkWithLang);
    }

    if (someHashError) {
        navigate(loginLinkWithLang);
        dispatch(
            coreActions.notification.set({
                message: 'validation.invalidInvitationLink',
                level: NotificationAlert.ERROR,
            }),
        );
    }

    if (someRegistrationError) {
        navigate(loginLinkWithLang);
        dispatch(
            coreActions.notification.set({
                message: registrationError.message,
                level: NotificationAlert.ERROR,
            }),
        );
    }

    if (!!tenant?.language && !isLocalStorageProfileSet) {
        for (const key in LANGUAGES) {
            if (LANGUAGES[key as keyof typeof LANGUAGES] === tenant.language) {
                dispatch(changeLanguage(tenant.language as LangValueType));
                break;
            }
        }
    }

    return (
        <PublicPageContainer
            isLoading={loading}
            hideContentOnLoading={true}
            customClasses={'registration-form-container'}
        >
            <TenantRegistrationForm
                data={tenant as InvitedTenantData}
                onSubmit={handleRegistration}
                loading={registrationLoading}
            />
        </PublicPageContainer>
    );
};

export default TenantRegistration;
