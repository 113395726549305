import { makeComponentClasses } from 'core/utils/componentUtils';
import { useResources } from 'resources/context';

export interface ListItemWithArrowProps {
    text?: string;
    clickHandler: () => void;
    customClasses?: string;
    icon?: JSX.Element;
    content?: JSX.Element;
}

const ListItemWithArrow = ({
    clickHandler,
    text,
    customClasses = '',
    icon,
    content,
}: ListItemWithArrowProps): JSX.Element => {
    const { HomeIcon } = useResources();
    const { base, iconTextClass, iconClass, textClass } = makeComponentClasses(
        {
            base: 'list-item-with-arrow-btn',
            iconTextClass: 'icon-text',
            iconClass: 'icon',
            textClass: 'text',
        },
        customClasses,
    );

    return (
        <a className={base} onClick={clickHandler}>
            <div className={iconTextClass}>
                {!!icon ? icon : <HomeIcon className={iconClass} />}
                {!!text && <span className={textClass}>{text}</span>}
                {!!content && <div className={textClass}>{content}</div>}
            </div>
            <span className={'bta-icon-chevron-right btn-link-with-arrow__icon--right'} />
        </a>
    );
};
export default ListItemWithArrow;
