import FileDto from 'file/classes/FileDto';
import { filesActions } from 'file/redux/fileActions';
import { UpdateSelectedFileAction } from 'file/types/TempFilesStateActions';
import { put } from 'redux-saga/effects';

export function* handleSelectedFileUpdate({ payload }: UpdateSelectedFileAction): Generator {
    const fileRawData = payload?.data?.getFile;

    if (!fileRawData) return;

    const fileData = new FileDto(fileRawData).all;
    yield put(filesActions.selectFile(fileData));

    if (fileData.b64Data) {
        yield put(filesActions.convertBase64DataToUrl(fileData.b64Data, fileData.mimeType));
    }
}
