import { i18n as i18nLarva } from '@larva.io/webcomponents-react';
import { AppState } from 'core/redux/rootReducer';
import i18n from 'i18next';
import { apply, fork, select } from 'redux-saga/effects';
import { ChangeLanguageActions } from 'tenantUser/types/redux/userProfileActions';

export function* changeLanguage({ payload: language }: ChangeLanguageActions): Generator {
    let _lang = (yield select(({ userProfile }: AppState) => userProfile.currentLang)) as string;

    if (typeof language === 'string') {
        _lang = language;
    }

    try {
        yield fork(changeAppLanguage, _lang);
        yield fork(changeLarvaLanguage, _lang);
    } catch (reason) {
        console.log(reason);
    }
}

function* changeAppLanguage(lang: string) {
    yield apply(i18n, i18n.changeLanguage, [lang]);
}

function* changeLarvaLanguage(lang: string) {
    yield apply(i18nLarva, i18nLarva.changeLanguage, [lang]);
}
