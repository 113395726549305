import { AnyAction } from 'redux';
import { ICO2ReducerState } from 'co2Meters/types/reduxTypes';
import { co2MetersAction } from 'core/datasets/action';
import { AppState } from 'core/redux/rootReducer';
import { ICO2Emission, IEmissionsDataForChart } from 'co2Meters/types/co2Meters';
import { createEmissionsDataForChart } from 'co2Meters/helpers';

const initState: ICO2ReducerState = {
    emissions: null,
    meterReadings: null,
    emissionsDataForChart: null,
    currentMonthId: null,
};

const actionMatcher = (action: AnyAction): ((type: co2MetersAction) => boolean) => {
    return function (type: co2MetersAction) {
        return action.type === type;
    };
};

export const co2Reducer = (state = initState, action: AnyAction): ICO2ReducerState => {
    const actionMatches = actionMatcher(action);

    if (actionMatches(co2MetersAction.SET_CO2_AND_METERS)) {
        const { emissions, meterReadings, agreementStartDate } = action.payload || {};
        const newState = {
            ...state,
            emissions: emissions || null,
            meterReadings: meterReadings || null,
        };
        if (emissions) {
            const emissionsDataForChart = createEmissionsDataForChart(emissions, meterReadings, agreementStartDate);
            newState.emissionsDataForChart = emissionsDataForChart;
            newState.currentMonthId = emissionsDataForChart[emissionsDataForChart.length - 1].id;
        }
        return newState;
    }

    if (actionMatches(co2MetersAction.SET_CURRENT_MONTH_INDEX)) {
        return { ...state, currentMonthId: action.payload };
    }

    if (actionMatches(co2MetersAction.CLEAN_CO2_AND_METERS)) {
        return { ...state, ...initState };
    }

    return state;
};

export const emissionsSelector: (state: AppState) => ICO2Emission[] | null | undefined = (state) =>
    state.co2Meters.emissions;
export const emissionsDataForChartSelector: (state: AppState) => IEmissionsDataForChart[] | null = (state) =>
    state.co2Meters.emissionsDataForChart;
export const currentMonthIdSelector: (state: AppState) => string | null = (state) => state.co2Meters.currentMonthId;
