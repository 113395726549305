import { AUTH, LARVA_WS } from 'core/datasets/action';
import RequestStatus from 'core/classes/RequestStatus';
import { Middleware } from 'redux';
import LarvaWsClientWrapper from 'smartHome/larva/classes/LarvaWsClientWrapper';
import LarvaDeviceController from 'smartHome/larva/classes/LarvaDeviceController';
import LarvaQueuedRequest from 'smartHome/larva/classes/LarvaQueuedRequest';
import { larvaWSActions } from 'smartHome/larva/redux/actions/larvaWSActions';

export const larvaWSConnectionsMiddleware: Middleware = (store) => {
    const { dispatch, getState } = store;

    const connections = new Map<string, LarvaWsClientWrapper>();
    const connectionMap = new Map<string, string>();

    return (next) => async (action) => {
        const isLoggedIn = getState().auth.isLoggedIn;

        if (!isLoggedIn) {
            next(action);
            return;
        }

        switch (action.type) {
            case AUTH.LOGOUT: {
                if (connections.size) {
                    for (const connection of connections.values()) {
                        connection.close();
                    }
                }

                connections.clear();
                connectionMap.clear();
                break;
            }
            case LARVA_WS.CLEAR_WS_CONNECTION: {
                for (const connection of connections.values()) {
                    await connection
                        .close()
                        .catch((error) => console.log('WEBSOCKET CONNECTION CLOSE ERROR', { error }));
                }
                connections.clear();
                connectionMap.clear();
                break;
            }
            case LARVA_WS.SAVE_WS_CONNECTION: {
                const { device, client }: { device: LarvaDeviceController; client: LarvaWsClientWrapper } =
                    action.payload;
                const connectionId = `${device.unitId}_${device.id}`;

                connections.set(connectionId, client);

                if (device.hasUiNodes) {
                    const uiNodes = device.uiNodes;

                    for (const node of uiNodes) {
                        if (node.ui.visible) {
                            connectionMap.set(node.node.id, connectionId);
                        }
                    }
                }

                dispatch(larvaWSActions.clientSaved(connectionId));
                break;
            }

            case LARVA_WS.NODE_OUTPUT:
            case LARVA_WS.NODE_REQUEST: {
                const request = action.payload as LarvaQueuedRequest;

                const connectionId = connectionMap.get(request.source as string);
                const connection = connections.get(connectionId as string);

                if (!connection) {
                    request.status = RequestStatus.WAITING_CONNECTION;
                    dispatch(larvaWSActions.addRequestToQueue(request));

                    break;
                }

                if (typeof connection?.readyState() === 'undefined') {
                    console.log('UNDEFINED READY STATE');
                }

                switch (connection.readyState()) {
                    case WebSocket.OPEN: {
                        try {
                            return await connection.handleRequest(request);
                        } catch (error) {
                            console.error('Failed to handle node request: ', error, request);
                            request.status = RequestStatus.REJECTED;
                            dispatch(larvaWSActions.addRequestToConnectionWaitQueue(request, connectionId as string));
                        }
                        break;
                    }
                    case WebSocket.CLOSED: {
                        try {
                            await connection.open();
                            dispatch(larvaWSActions.connectionOpened(connectionId as string));
                        } catch (error) {
                            console.error(error);
                            request.status = RequestStatus.REJECTED;
                            dispatch(larvaWSActions.addRequestToConnectionWaitQueue(request, connectionId as string));
                        }
                        break;
                    }
                    default: {
                        dispatch(larvaWSActions.addRequestToConnectionWaitQueue(request, connectionId as string));
                    }
                }

                break;
            }
        }
        next(action);
    };
};
