import { FilterGroupType, FilterValueType } from '../../types/filter';
import { FILTER_GROUP } from '../../datasets/filter';
import { ServiceRequest } from 'serviceRequest/types/serviceRequest';

export const hasEnabledValueIn = (group: FilterGroupType): boolean => {
    const values = Object.values(group as keyof FilterGroupType);
    return values.filter((value) => !value).length > 0;
};

export const hasEnabled = (groups: FilterGroupType): boolean => {
    const hasEnabled = false;
    for (const name in groups) {
        if (hasEnabledValueIn(groups[name as keyof FilterGroupType])) return true;
    }
    return hasEnabled;
};

export const getFilterValuesForGroup = (
    group: (typeof FILTER_GROUP)[keyof typeof FILTER_GROUP],
    data: { [key: string]: string | number }[],
): string[] => {
    if (!data) return [];

    const uniqueValues: string[] = [];

    for (const service of data) {
        if (uniqueValues.indexOf(<string>service[group]) === -1) uniqueValues.push(<string>service[group]);
    }

    return uniqueValues;
};

export const getDefaultValuesForGroup = (
    group: (typeof FILTER_GROUP)[keyof typeof FILTER_GROUP],
    data: { [key: string]: string | number }[] | string,
    resetAllKey = 'all',
): { [key: string]: boolean } => {
    if (group === FILTER_GROUP.TYPE_ID && Array.isArray(data)) {
        const serviceRequests = data as unknown as ServiceRequest[];
        return serviceRequests?.reduce<{ [key: string]: boolean }>(
            (acc, { serviceRequestType }) => {
                if (serviceRequestType?.id && !acc[String(serviceRequestType.id)]) {
                    acc[String(serviceRequestType.id)] = true;
                }
                return acc;
            },
            { [resetAllKey]: true },
        );
    }

    const uniqueValues = getFilterValuesForGroup(group, data as { [key: string]: string | number }[]);
    const defaultValues = { [resetAllKey]: true };
    uniqueValues.forEach((value) => (defaultValues[value as keyof typeof defaultValues] = true));
    return defaultValues;
};

interface SetDefaultValuesForGroupProps {
    groupValues: FilterValueType;
    defaultValue: boolean | string;
}

export const setDefaultValuesForGroup = ({
    groupValues,
    defaultValue = true,
}: SetDefaultValuesForGroupProps): { [key: string]: string | boolean } => {
    let defaultValues: { [key: string]: string | boolean } = { ...groupValues };
    for (const key in defaultValues) {
        defaultValues = {
            ...defaultValues,
            [key as keyof FilterValueType]: defaultValue,
        };
    }
    return defaultValues;
};
