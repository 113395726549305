import { DataSet } from '../DataSets';
import CURRENCY_SYMBOLS from '../../datasets/currency_symbol.json';

export class CurrencyDataSet extends DataSet {
    public defaultCurrency;

    constructor(defaultCurrency: string) {
        super(CURRENCY_SYMBOLS);
        this.defaultCurrency = defaultCurrency;
    }
    getSymbol(code: string): string {
        if (!code || typeof code !== 'string' || code.trim().length === 0) return '';
        const symbol = this.getValueByKey(code.trim().toLowerCase());
        return symbol ?? code.trim();
    }
}
