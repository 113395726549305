import { ApolloError } from '@apollo/react-hooks';
import { ServerError } from '@apollo/client/link/utils';
import { setAuthErrorMessage, requestToRefreshExpiredToken, setLogout } from 'tenantUser/redux/authActions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { QUERY_ERROR_RESPONSE } from './index';
import { useTranslation } from 'react-i18next';

const useQueryErrorHandler = (): QueryErrorHandlerReturn => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const errorHandler = useCallback(
        (error: ApolloError) => {
            const { networkError, graphQLErrors, message } = error;

            if (networkError) {
                const statusCode = (networkError as ServerError).statusCode;

                switch (statusCode) {
                    case 401:
                        dispatch(requestToRefreshExpiredToken());
                        break;

                    default:
                        dispatch(setAuthErrorMessage(t('notices.networkError') + message));
                        break;
                }
            }

            if (graphQLErrors) {
                switch (message) {
                    case QUERY_ERROR_RESPONSE.INTERNAL_SERVER_ERROR:
                        dispatch(setAuthErrorMessage(t('notices.wrongCredentials')));
                        break;

                    case QUERY_ERROR_RESPONSE.EXPIRED_AUTH_TOKEN:
                        dispatch(requestToRefreshExpiredToken());
                        break;

                    case QUERY_ERROR_RESPONSE.INVALID_AUTH_TOKEN:
                        dispatch(setLogout());

                        break;
                    default:
                        dispatch(setAuthErrorMessage(t('notices.serverError') + message));
                        break;
                }
            }
        },
        [dispatch, t],
    );
    return { errorHandler };
};
export default useQueryErrorHandler;

type QueryErrorHandlerReturn = {
    errorHandler: (error: ApolloError) => void;
};
