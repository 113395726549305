import { gql } from '@apollo/client';

export const getEnvironmentSettingsDocument = gql`
    query getEnvironmentSettings {
        getEnvironmentSettings {
            invoiceAsPaymentNotification
            messagingSettings {
                isMessagingEnabled
                displayName
            }
            tipsAndTricksEnabled
            emissionsEnabled
            agreementExtensionButtonEnabled
            agreementTerminationMinimumPriorToDays
        }
    }
`;
