import PageContextType from '../../../types/pageContext';
import Filter from '../../selectors/Filter';

interface FiltersListProps {
    context: PageContextType;
}
// Good place to call a list of filters, now only 1 filter is implemented
const FiltersList = ({ context }: FiltersListProps): JSX.Element => {
    return <Filter context={context} />;
};
export default FiltersList;
