import { AnyAction } from 'redux';
import { Inception } from '../../../core/datasets/action';
import { inceptionActionMatcher } from './inceptionActionMatcher';
import { InceptionUnits } from '../types/inception';
import { InceptionReducerState } from '../types/inceptionReducer';

const initState: InceptionReducerState = {
    smartHomeUnits: null,
};

export const inceptionReducer = (state = initState, action: AnyAction): InceptionReducerState => {
    let newState = state;

    const matches = inceptionActionMatcher(action);

    if (matches(Inception.UNIT_LIST_FETCHED)) {
        const inceptionUnitsList = action.payload;

        if (Array.isArray(inceptionUnitsList) && inceptionUnitsList.length) {
            const smartHomeUnits: InceptionUnits = {};

            for (const unit of inceptionUnitsList) {
                smartHomeUnits[unit.uuid] = { ...unit, statuses: ['Unknown'] };
            }

            newState = { ...state, smartHomeUnits };
        } else {
            newState = { ...state, smartHomeUnits: null };
        }
    }

    if (matches(Inception.UNIT_STATUS_RECEIVED)) {
        const {
            data: {
                getInceptionUnitState: { statuses, uuid, type },
            },
        } = action.payload;

        if (state.smartHomeUnits) {
            newState = {
                ...state,
                smartHomeUnits: { ...state.smartHomeUnits, [uuid]: { ...state.smartHomeUnits[uuid], statuses, type } },
            };
        }
    }

    return newState;
};
