import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PAYMENT_TYPE from 'extraService/datasets/payment_type';
import FormContainer from 'core/components/forms/FormContainer';
import SubmitButton from 'core/components/buttons/SubmitButton';
import { ExtraServiceOrderFormProps } from './index';
import { EXTRA_SERVICE_ORDER_ACTION } from 'extraService/datasets/extra_service_order_action';
import {
    ExtraServiceActionType,
    ExtraServiceOrderFormData,
    ExtraServicePaymentType,
} from 'extraService/types/extraServiceOrder';
import DATE_PICKER_TYPE from 'core/datasets/date_picker_type';
import ExtraServiceRequestConfirmationModal from 'pages/ExtraService/components/ExtraServiceRequestConfirmationModal/ExtraServiceRequestConfirmationModal';
import { extraServiceAmountIsValid } from '../RepeatingServiceOrderFields/helpers';
import {
    datesAreValidAndSameAndHasAmount,
    generatedExtraServiceFormClasses,
    generateExtraServiceFormInitialData,
    generateMaxDate,
    generateMinDate,
} from 'extraService/utils/useExtraServiceHelper';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../core/redux/rootReducer';
import useMutationOrderExtraService from 'extraService/api/gql/useMutationOrderExtraService/useMutationOrderExtraService';
import useMutationChangeExtraServiceOrder from 'extraService/api/gql/useMutationChangeExtraServiceOrder/useMutationChangeExtraServiceOrder';
import FormDatePicker from '../../../../core/components/selectors/FormDatePicker/FormDatePicker';
import OrderServiceAmountTextField from '../RepeatingServiceOrderFields/OrderServiceAmountTextField';
import { isNull } from 'lodash';
import useQueryExtraServicesOrders from '../../../../extraService/api/gql/useQueryExtraServicesOrders';

const ExtraServiceOrderForm = ({
    action = EXTRA_SERVICE_ORDER_ACTION.ORDER,
    paymentType,
    serviceName,
    price,
    dateFrom,
    dateUntil,
    handleParentClose,
    orderId,
    isConfirmed,
}: ExtraServiceOrderFormProps): JSX.Element => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const classes = generatedExtraServiceFormClasses;

    const {
        orderExtraService,
        data: orderResponse,
        loading,
        called,
        error: orderError,
    } = useMutationOrderExtraService();
    const {
        cancelExtraServiceOrder,
        changeExtraServiceOrder,
        error: changeError,
    } = useMutationChangeExtraServiceOrder();
    const { getOrders } = useQueryExtraServicesOrders();

    const selectedAgreementId = useSelector(({ agreements }: AppState) => agreements.selectedAgreement?.id);

    const [isFormValueSame, setIsFormValueSame] = useState(false);
    const [modalClosed, setModalClosed] = useState(true);

    const [formData, setFormData] = useState(
        generateExtraServiceFormInitialData(Number(orderId ?? id), Number(selectedAgreementId)),
    );

    const isNotCancelAction = action !== EXTRA_SERVICE_ORDER_ACTION.CANCEL;
    const isOrderAction = action === EXTRA_SERVICE_ORDER_ACTION.ORDER;
    const isNotCancelActionOrConfirmed = isNotCancelAction || isConfirmed;

    const onConfirm = (
        action: ExtraServiceActionType,
        orderId: number | null,
        paymentType: ExtraServicePaymentType,
    ) => {
        if (isNull(orderId)) {
            console.error('Order id is empty');
            return;
        }

        const date = paymentType === PAYMENT_TYPE.SINGLE ? formData.fromDate : formData.untilDate;

        if (action === EXTRA_SERVICE_ORDER_ACTION.ORDER) {
            orderExtraService(formData);
        }

        if (orderId && date && action === EXTRA_SERVICE_ORDER_ACTION.CANCEL) {
            cancelExtraServiceOrder({ orderId, date: date.toSQLDate() });
        }

        if (orderId && date && action === EXTRA_SERVICE_ORDER_ACTION.CHANGE) {
            changeExtraServiceOrder({ orderId, date: date.toSQLDate() });
        }
    };

    useEffect(() => {
        if (!isOrderAction && dateFrom) {
            setFormData((current) => ({ ...current, fromDate: DateTime.fromSQL(dateFrom) }));
        }

        if (!isOrderAction && dateUntil) {
            setFormData((current) => ({ ...current, untilDate: DateTime.fromSQL(dateUntil) }));
        }
    }, [isOrderAction, dateFrom, dateUntil]);

    useEffect(() => {
        setIsFormValueSame(datesAreValidAndSameAndHasAmount(formData, dateFrom, dateUntil));
    }, [dateFrom, dateUntil, formData]);

    const isSubmitButtonDisabled =
        !extraServiceAmountIsValid(formData?.amount || 0) || (!isOrderAction && isFormValueSame);

    const isRepeatingPayment = paymentType === PAYMENT_TYPE.REPEATING;

    const submitHandler = () => {
        onConfirm(action, orderId, paymentType as ExtraServicePaymentType);
        // we wait data update only for new orders, in other cases close all modals and panels
        if (EXTRA_SERVICE_ORDER_ACTION.ORDER !== action) {
            handleParentClose();
        }
    };

    useEffect(() => {
        if (!loading && orderResponse?.orderExtraService?.isSuccessful) {
            getOrders().finally();
            handleParentClose();
        }
    }, [orderResponse, loading, called]);

    const dateInputPaymentClass = `extra-service-order-form__date-input--${
        paymentType === PAYMENT_TYPE.REPEATING ? 'repeating' : 'single'
    }`;

    return (
        <FormContainer>
            <form className={classes.base} role={'form'}>
                {isRepeatingPayment
                    ? isNotCancelActionOrConfirmed && (
                          <>
                              <div className={classes.periodContainer}>
                                  <FormDatePicker
                                      isDisabled={action !== EXTRA_SERVICE_ORDER_ACTION.ORDER}
                                      isRequired={true}
                                      clearable={false}
                                      minDate={generateMinDate({
                                          dateType: DATE_PICKER_TYPE.DATE_FROM,
                                          paymentType,
                                          formData,
                                      })}
                                      maxDate={generateMaxDate({
                                          dateType: DATE_PICKER_TYPE.DATE_FROM,
                                          paymentType,
                                          formData,
                                      })}
                                      classes={`extra-service-order-form__date-from-input extra-service-order-form__input ${dateInputPaymentClass}`}
                                      label={t(`extraServices.order.form.${DATE_PICKER_TYPE.DATE_FROM}.${paymentType}`)}
                                      value={
                                          formData[
                                              DATE_PICKER_TYPE.DATE_FROM as keyof ExtraServiceOrderFormData
                                          ] as DateTime | null
                                      }
                                      onChange={(next: DateTime | null) =>
                                          setFormData((current) => {
                                              return { ...current, fromDate: next };
                                          })
                                      }
                                  />
                                  <FormDatePicker
                                      isRequired={false}
                                      isDisabled={false}
                                      clearable={true}
                                      minDate={generateMinDate({
                                          dateType: DATE_PICKER_TYPE.DATE_UNTIL,
                                          paymentType,
                                          formData,
                                      })}
                                      maxDate={generateMaxDate({
                                          dateType: DATE_PICKER_TYPE.DATE_UNTIL,
                                          paymentType,
                                          formData,
                                      })}
                                      classes={`extra-service-order-form__date-until-input extra-service-order-form__input ${dateInputPaymentClass}`}
                                      label={t(
                                          `extraServices.order.form.${DATE_PICKER_TYPE.DATE_UNTIL}.${paymentType}`,
                                      )}
                                      value={
                                          formData[
                                              DATE_PICKER_TYPE.DATE_UNTIL as keyof ExtraServiceOrderFormData
                                          ] as DateTime | null
                                      }
                                      onChange={(next: DateTime | null) =>
                                          setFormData((current) => {
                                              return { ...current, untilDate: next };
                                          })
                                      }
                                  />
                              </div>
                              {isOrderAction && (
                                  <OrderServiceAmountTextField
                                      className={`${generatedExtraServiceFormClasses.inputContainer} extra-service-order-form__amount-input`}
                                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                          setFormData((current) => {
                                              const next = Number(event.target.value);
                                              return { ...current, amount: isNaN(next) ? current.amount : next };
                                          });
                                      }}
                                      label={t('extraServices.order.form.amount')}
                                      value={formData.amount}
                                  />
                              )}
                          </>
                      )
                    : isNotCancelAction && (
                          <div className={classes.periodContainer}>
                              <FormDatePicker
                                  isDisabled={action !== EXTRA_SERVICE_ORDER_ACTION.ORDER}
                                  isRequired={true}
                                  clearable={false}
                                  minDate={generateMinDate({
                                      dateType: DATE_PICKER_TYPE.DATE_FROM,
                                      paymentType,
                                      formData,
                                  })}
                                  maxDate={generateMaxDate({
                                      dateType: DATE_PICKER_TYPE.DATE_FROM,
                                      paymentType,
                                      formData,
                                  })}
                                  classes={`extra-service-order-form__date-from-input extra-service-order-form__input ${dateInputPaymentClass}`}
                                  label={t(`extraServices.order.form.${DATE_PICKER_TYPE.DATE_FROM}.${paymentType}`)}
                                  value={
                                      formData[
                                          DATE_PICKER_TYPE.DATE_FROM as keyof ExtraServiceOrderFormData
                                      ] as DateTime | null
                                  }
                                  onChange={(next: DateTime | null) =>
                                      setFormData((current) => {
                                          return { ...current, fromDate: next };
                                      })
                                  }
                              />
                          </div>
                      )}
                <div className={classes.submitButtonContainer}>
                    <SubmitButton
                        disabled={isSubmitButtonDisabled}
                        text={t('extraServices.order.form.submitButtonLabel')}
                        actionHandler={() => setModalClosed(false)}
                    />
                </div>
                <ExtraServiceRequestConfirmationModal
                    action={action}
                    submitHandler={submitHandler}
                    paymentType={paymentType}
                    serviceName={serviceName}
                    formData={formData}
                    price={price}
                    modalArgs={{
                        closed: modalClosed,
                        handleClosed: () => setModalClosed(true),
                        size: 'lg',
                        showTitle: false,
                        confirmLabel: t('extraServices.order.form.confirmButtonOk'),
                        cancelLabel: t('extraServices.order.form.confirmButtonCancel'),
                    }}
                    loading={loading}
                    error={orderError || changeError}
                />
            </form>
        </FormContainer>
    );
};

export default ExtraServiceOrderForm;
