import larvaConfig from 'core/config/larva/general';

const getLarvaApiBaseUrl = (orgId: string, externalId: string, agreementUUID: string): string => {
    if (!orgId) {
        throw new Error('Missing required orgId');
    }

    if (!externalId) {
        throw new Error('Missing required externalId');
    }

    if (!agreementUUID) {
        throw new Error('Missing required agreementUUID');
    }

    return `${larvaConfig.mantisUrl}/${orgId}/services/connections/${externalId}/access-policies/${agreementUUID}`;
};
export default getLarvaApiBaseUrl;
