import { yupResolver } from '@hookform/resolvers/yup';
import { Control, FieldValues, FormState, useForm, UseFormGetValues } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const useResetPasswordFormSchema = (): ResetPasswordFormSchemaReturnType => {
    const { t } = useTranslation();

    const schema = Yup.object({
        password: Yup.string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, t('validation.passwordValidationCriteria'))
            .required(),
        passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], t('validation.passwordMustMatch')),
    });

    const { control, formState, setValue, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
            passwordConfirmation: '',
        } as FieldValues,
    });

    return { control, formState, setValue, getValues };
};

export default useResetPasswordFormSchema;

type ResetPasswordFormSchemaReturnType = {
    control: Control;
    formState: FormState<any>;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
};
