import { all, fork, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { ACTION, AGREEMENT, AGREEMENT_OBJECT, Inception } from '../../../core/datasets/action';
import { handleAgreementSelected, pollerRunner, restartPollerFromScratch } from './poller';
import { handleCreateInceptionActivity } from './workers';

function* watchActivityRequest(): Generator {
    yield takeEvery([Inception.ACTIVITY_CREATED], handleCreateInceptionActivity);
}

function* watchDoorPolling(): Generator {
    yield takeLeading([ACTION.CORE.APP_ACTIVE_STATUS_CHANGED], pollerRunner);
}

function* watchSmartHomeUnitsFetched(): Generator {
    yield takeLeading([Inception.UNIT_LIST_FETCHED], restartPollerFromScratch);
}

function* watchAgreementSelected(): Generator {
    yield takeLatest(
        [AGREEMENT.AGREEMENT_SELECTED, AGREEMENT.DATA_UPDATED, AGREEMENT_OBJECT.AGREEMENT_OBJECT_SELECTED],
        handleAgreementSelected,
    );
}

export function* inceptionSmartHomeWatcher(): Generator {
    yield all([
        fork(watchDoorPolling),
        fork(watchActivityRequest),
        fork(watchSmartHomeUnitsFetched),
        fork(watchAgreementSelected),
    ]);
}
