import { gql } from '@apollo/client';

export const queryRentalAgreementDocument = gql`
    query rentalAgreements($orderBy: OrderBy, $orderDir: Sort) {
        rentalAgreements(orderBy: $orderBy, orderDir: $orderDir) {
            id
            agreementNumber
            tenants {
                id
                firstName
                lastName
                email
                legalEntity
                legalEntityName
                ownedBankAccountNumber
                iban
                phone
            }
            billingSender {
                id
                senderName
            }
            agreementDate
            agreementEnd
            paymentAmount
            prePayment
            paymentDate
            deposit
            rentalObject {
                id
                rentalType
                street
                houseNumber
                apartmentNumber
                roomNumber
                city
                country
                meters {
                    id
                    identifier
                    type
                    unit
                }
                allowAddMeterReadings
                smartHome {
                    isAvailable
                    type
                    meters_reading
                    organization_name
                    organization_id
                    external_id
                }
            }
            rentalAgreementObjects {
                id
                uuid
                rentalObject {
                    id
                    rentalType
                    street
                    houseNumber
                    apartmentNumber
                    roomNumber
                    city
                    country
                    meters {
                        id
                        identifier
                        type
                        unit
                    }
                    allowAddMeterReadings
                    smartHome {
                        isAvailable
                        type
                        meters_reading
                        organization_name
                        organization_id
                        external_id
                    }
                }
                agreementStart
                agreementEnd
                paymentAmount
                deposit
                licensePlates {
                    value
                }
                containsVat
                containsUtilities
                moveInDate
                moveInTime
                moveOutDate
                moveOutTime
                residents
            }
            uuid
            currency
            licensePlates {
                value
            }
            smartHomeUnits {
                name
                reportingId
                uuid
                unitType
            }
        }
    }
`;
