import { FilterGroupType } from '../../../types/filter';
import { useTranslation } from 'react-i18next';
import FilterValuesCheckboxList from '../../lists/FilterValuesCheckboxList';
import useFilterIn from './hooks/useFilterIn/useFilterIn';
import PageContextType from '../../../types/pageContext';
import { FILTER_GROUP } from '../../../datasets/filter';
import { isEmpty } from 'lodash';

export interface FilterProps {
    context: PageContextType;
}

const Filter = ({ context }: FilterProps): JSX.Element | null => {
    const { t } = useTranslation();
    const { currentFilterArgs, serviceRequestTypeTitlesMap } = useFilterIn({ context });

    const { groups, handleChange, isEnabled, name, resetAllKey } = currentFilterArgs;

    const groupNames = Object.keys(groups);
    if (!groupNames || groupNames.length === 0 || !groups) return null;

    return (
        <>
            {groupNames.map((groupName) => {
                const values = groups[groupName as keyof FilterGroupType];
                const showList = groupName !== FILTER_GROUP.TYPE_ID || !isEmpty(serviceRequestTypeTitlesMap);

                return (
                    showList && (
                        <div key={groupName} className="filter">
                            <div className="filter-group">
                                <div className="filter-group-name">{t(`filters.${name}.titles.${groupName}`)}</div>
                                <div className="filter-values">
                                    <FilterValuesCheckboxList
                                        groups={groups}
                                        groupName={groupName}
                                        handleChange={handleChange}
                                        isEnabled={isEnabled}
                                        name={name}
                                        resetAllKey={resetAllKey}
                                        values={values}
                                        serviceRequestTypeTitlesMap={serviceRequestTypeTitlesMap}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                );
            })}
        </>
    );
};

export default Filter;
