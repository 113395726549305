import { AGREEMENT, AUTH, ENVIRONMENT } from 'core/datasets/action';
import { all, call, takeEvery } from 'redux-saga/effects';
import { fetchEnvironmentSettings } from './workers/fetchEnvironmentSettings';

function* watchAgreements(): Generator {
    yield takeEvery([AGREEMENT.FETCH_AGREEMENTS_ASYNC, AUTH.LOGIN_SUCCESS], fetchEnvironmentSettings);
}

function* watchFetchEnvironmentSettings(): Generator {
    yield takeEvery([ENVIRONMENT.FETCH_ENVIRONMENT_SETTINGS], fetchEnvironmentSettings);
}

export function* environmentWatchers(): Generator {
    yield all([call(watchAgreements), call(watchFetchEnvironmentSettings)]);
}
