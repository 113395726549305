import { AxiosResponse } from 'axios';
import WebClient from 'core/classes/WebClient';
import ClientType from 'core/classes/ClientType';
import LarvaConnectionConfig from 'smartHome/larva/classes/LarvaConnectionConfig';
import IWebClient from 'core/types/IWebClient';

class LarvaWebClient implements IWebClient {
    private _type = ClientType.WEB_CLIENT;

    private _config?: LarvaConnectionConfig;
    private _client?: WebClient;

    constructor(connectionConfig: LarvaConnectionConfig) {
        this.config = connectionConfig;
        this.client = new WebClient(connectionConfig.clientConfig);
    }

    get type(): string {
        return this._type;
    }

    get config(): LarvaConnectionConfig {
        if (this._config) {
            return this._config;
        }

        throw new ReferenceError('Undefined config');
    }

    set config(value: unknown) {
        if (value instanceof LarvaConnectionConfig) {
            this._config = value as LarvaConnectionConfig;
            return;
        }

        throw new TypeError('Invalid config');
    }

    get client(): WebClient {
        if (this._client) {
            return this._client;
        }

        throw new ReferenceError('Undefined client');
    }

    set client(value: WebClient) {
        this._client = value;
    }

    async get(url: unknown): Promise<AxiosResponse> {
        return this.client.get(url);
    }

    async delete(url: unknown): Promise<AxiosResponse> {
        return this.client.delete(url);
    }

    async patch(url: unknown, payload: unknown): Promise<AxiosResponse> {
        return this.client.patch(url, payload);
    }

    async post(url: unknown, payload: unknown): Promise<AxiosResponse> {
        return this.client.post(url, payload);
    }
}
export default LarvaWebClient;
