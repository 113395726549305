import { LARVA_DOOR_ACCESS_TEMP, LARVA_TEMP } from 'core/datasets/action';
import TempLarvaState from 'smartHome/larva/types/redux/TempLarvaState';
import { TempLarvaStateActions } from 'smartHome/larva/types/redux/TempLarvaStateActions';

const initState: TempLarvaState = {
    doorAccess: {
        selected: null,
    },
    dashboard: {
        loading: false,
    },
};

export const tempLarvaReducer = (state = initState, action: TempLarvaStateActions): TempLarvaState => {
    switch (action.type) {
        case LARVA_DOOR_ACCESS_TEMP.DOOR_ACCESS_SELECTED: {
            return {
                ...state,
                doorAccess: {
                    ...state.doorAccess,
                    selected: {
                        ...action.payload,
                    },
                },
            };
        }
        case LARVA_TEMP.DASHBOARD.LOADING_STARTED: {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    loading: true,
                },
            };
        }
        case LARVA_TEMP.DASHBOARD.LOADING_COMPLETED: {
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    loading: false,
                },
            };
        }
        default:
            return state;
    }
};
