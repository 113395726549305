import { IInvoiceListViewData } from 'invoice/types/IInvoiceListViewData';
import { IListViewItem } from 'core/types/IListViewItem';
import { ViewVariantType } from 'core/classes/ViewVariantType';
import { APP_LUX_DATE_FORMAT } from 'core/utils/datesHelper';
import { PAGE_URL } from 'core/datasets/page_url';
import { IInvoiceData } from 'invoice/types/IInvoiceData';

export class InvoiceListViewItem implements IListViewItem {
    static PATH = PAGE_URL.INVOICE;
    private readonly invoiceData: IInvoiceData;
    private readonly asPaymentNotification: boolean;

    constructor(rawData: IInvoiceData, asPaymentNotification: boolean) {
        this.invoiceData = rawData;
        this.asPaymentNotification = asPaymentNotification;
    }

    get listViewData(): IInvoiceListViewData {
        const { id, invoiceDate, invoiceNumber, totalSum, paid, partiallyPaid } = this.invoiceData;

        return {
            path: `/${InvoiceListViewItem.PATH}/${id}`,
            labelText: `pages.invoice.${this.asPaymentNotification ? 'payment' : 'invoice'}NumberValue`,
            primaryText: invoiceDate?.toFormat(APP_LUX_DATE_FORMAT) ?? '',
            leftBlockVariant: ViewVariantType.SUCCESS,
            meta: {
                id: `${id}`,
                invoiceNumber: invoiceNumber,
                invoiceDate: invoiceDate?.toFormat(APP_LUX_DATE_FORMAT) ?? '',
                totalSum: totalSum,
                paid: paid,
                partiallyPaid: partiallyPaid,
                loading: false,
            },
        };
    }

    toJSON(): IInvoiceListViewData {
        return this.listViewData;
    }
}
