import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { useTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { LOCALE } from 'core/datasets/locale';

import styles from './FormContainer.module.css';

interface FormContainerProps {
    children: JSX.Element | JSX.Element[];
    classes?: string;
}

const FormContainer = ({ children, classes = '' }: FormContainerProps): JSX.Element => {
    const { i18n } = useTranslation();
    const selectedLanguage = i18n.language;
    const selectedLocale = LOCALE.MOMENT[selectedLanguage as keyof typeof LOCALE.MOMENT];

    const classNames = [styles.formContainer, classes].join(' ');
    return (
        <MuiPickersUtilsProvider libInstance={DateTime} locale={selectedLocale} utils={LuxonUtils}>
            <div className={classNames}>{children}</div>
        </MuiPickersUtilsProvider>
    );
};
export default FormContainer;
