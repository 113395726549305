import { Method } from 'axios';

class RequestMethodType {
    static GET = 'GET' as Method;
    static POST = 'POST' as Method;
    static PUT = 'PUT' as Method;
    static DELETE = 'DELETE' as Method;
    static PATCH = 'PATCH' as Method;

    static isValid(value: unknown): boolean {
        if (typeof value === 'string') {
            return /^GET$|^POST$|^PUT$|^PATCH$|^DELETE$/i.test(value as string);
        }
        return false;
    }
}

export default RequestMethodType;
