import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SUPPORT_EMAIL = 'info@bidrento.com';

export default function SupportText(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Typography variant={'body1'} className={'help-info-modal-text'}>
            {t('pages.login.helpModalBeforeMail')}
            <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
            {t('pages.login.helpModalAfterMail')}
        </Typography>
    );
}
