export default class FileSize {
    static B = 'B';
    static kB = 'kB';
    static MB = 'MB';
    static GB = 'GB';

    static isValid(value: unknown): boolean {
        return typeof value === 'string' && /^\d+$/.test(value);
    }

    static convert(value: string): string {
        if (!FileSize.isValid(`${value}`)) return `- B`;

        const length = `${value}`.length;

        if (length > 9) {
            const _value = (+value / 1073741824).toFixed(1);
            return `${_value} ${FileSize.GB}`;
        }

        if (length > 6) {
            const _value = (+value / 1048576).toFixed(1);
            return `${_value} ${FileSize.MB}`;
        }

        if (length > 3) {
            const _value = (+value / 1024).toFixed(1);
            return `${_value} ${FileSize.kB}`;
        }

        return `${value} ${FileSize.B}`;
    }
}
