import Logo from './index';
import { LogoBaseProps } from '../../types/logo';
import { useResources } from 'resources/context';

const LogoSmall = ({ classes = '' }: LogoBaseProps): JSX.Element => {
    const { LogoSmall } = useResources();
    const logoClasses = [classes, 'app-logo app-logo--desktop'].join(' ');

    return (
        <Logo classes={logoClasses}>
            {typeof LogoSmall === 'string' ? <img src={LogoSmall} width="120px" /> : <LogoSmall />}
        </Logo>
    );
};

export default LogoSmall;
