import { IChartData, PeriodUnit } from '../../types/charts';
import { DateTime } from 'luxon';
import CHART_X_UNIT from '../../datasets/chart_x_unit';
import { APP_LUX_DATE_FORMAT } from 'core/utils/datesHelper';

export const findLastNotZeroAmount = (data: IChartData[], period: PeriodUnit): number => {
    const today = DateTime.now();
    const periodStart =
        period === CHART_X_UNIT.MONTH
            ? // the date in charts data is the beginning of the month
              today.startOf(period).toFormat(APP_LUX_DATE_FORMAT)
            : today.toFormat(APP_LUX_DATE_FORMAT);
    let index = data.findIndex((item) => item.date === periodStart);

    while (index > 0) {
        const amount = data[index--].amount;
        if (amount > 0) {
            return amount;
        }
    }
    return 0;
};
export default findLastNotZeroAmount;
